import styled from '@emotion/styled';
import clsx from 'clsx';
import { composeClasses } from 'src/utils';

import Radio, { RadioProps } from './Radio';
import { useRadioGroup } from '../RadioGroup';

const classNamePrefix = 'OutlinedRadio';

const useUtilityClasses = (props: { checked?: boolean }) => {
  const { checked } = props;

  const slots = {
    root: ['root', checked && 'checked'],
    icon: ['icon'],
    label: ['label'],
  };

  return composeClasses(classNamePrefix, slots);
};

function shouldForwardProp(prop: PropertyKey) {
  return prop !== 'theme' && prop !== 'as' && prop !== 'checked';
}

const OutlinedRadioRoot = styled('label')(({ theme }) => ({
  flex: '1 1 auto',
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #eee',
  borderRadius: 4,
  padding: 12,
  cursor: 'pointer',
  transition: theme.transitions.create('all'),
  '&:not(:last-of-type)': {
    marginBottom: 10,
  },
}));

const OutlinedRadioIcon = styled('div', { shouldForwardProp })<{
  checked?: boolean;
}>(({ theme, checked }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: 10,
  fontSize: 18,
  color: theme.palette.text?.disabled,
  ...(checked && { color: theme.palette.icon }),

  '& > svg': {
    color: 'inherit',
    transition: theme.transitions.create('all'),
  },
}));

const OutlinedRadioLabel = styled('div', { shouldForwardProp })<{
  checked?: boolean;
}>(({ theme, checked }) => ({
  flex: 1,
  fontSize: 14,
  color: theme.palette.text?.secondary,
  transition: theme.transitions.create('color'),
  ...(checked && { color: theme.palette.text?.primary }),
}));

export type OutlinedRadioProps = RadioProps & {
  icon?: React.ReactElement;
  label?: string;
};

const OutlinedRadio: React.FC<OutlinedRadioProps> = (props) => {
  const radioGroup = useRadioGroup();

  const { icon: Icon, label, className, checked: checkedProp, ...rest } = props;

  let checked = checkedProp;

  if (radioGroup) {
    if (typeof checked === 'undefined') {
      checked = radioGroup.value === props.value;
    }
  }

  const classes = useUtilityClasses({ checked });

  return (
    <OutlinedRadioRoot className={clsx(classes.root, className)}>
      {Icon && (
        <OutlinedRadioIcon checked={checked} className={classes.icon}>
          {Icon}
        </OutlinedRadioIcon>
      )}
      <OutlinedRadioLabel checked={checked} className={classes.label}>
        {label}
      </OutlinedRadioLabel>
      <Radio {...rest} checked={checked} />
    </OutlinedRadioRoot>
  );
};

export default OutlinedRadio;
