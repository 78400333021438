import { createSvgIcon } from './utils';

export default createSvgIcon(
  [
    <circle key="1" id="椭圆形" cx="2" cy="3" r="2" />,
    <circle key="2" id="椭圆形备份" cx="9" cy="3" r="2" />,
    <circle key="3" id="椭圆形备份-2" cx="16" cy="3" r="2" />,
  ],
  'RowExtend'
);
