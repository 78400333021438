import React from 'react';

import clsx from 'clsx';
import styled from '@emotion/styled';

import { composeClasses } from 'src/utils';
import { Typography } from 'src/components/Typography';

interface Classes {
  root?: string | undefined;
  li?: string | undefined;
  ol?: string | undefined;
  separator?: string | undefined;
}

interface BreadcrumbsProps {
  className?: string | undefined;
  classes?: Classes | undefined;
  separator?: React.ReactNode | undefined;
  children?: React.ReactNode;
}

const classPrefix = 'breadcrumbs';

const useUtilityClasses = (props: Pick<BreadcrumbsProps, 'classes'>) => {
  const { classes } = props;

  const slots = {
    root: ['root'],
    li: ['li'],
    ol: ['ol'],
    separator: ['separator'],
  };

  return composeClasses(classPrefix, slots, classes as Record<string, string>);
};

function shouldForwardProp(prop: PropertyKey) {
  return prop !== 'theme' && prop !== 'as' && prop !== 'classes';
}

const BreadcrumbsRoot = styled('div', {
  label: `${classPrefix}Root`,
  shouldForwardProp,
})({
  overflow: 'hidden',
  width: 'fit-content',
});

const BreadcrumbsOl = styled('ol', {
  label: `${classPrefix}Ol`,
  shouldForwardProp,
})({
  display: 'flex',
  padding: 0,
  margin: 0,
  listStyle: 'none',

  [`& .${classPrefix}-li`]: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flexShrink: 1000,
    display: 'inline-block',
  },
});

const BreadcrumbsSeparator = styled('li', {
  label: `${classPrefix}Separator`,
  shouldForwardProp,
})({
  display: 'flex',
  userSelect: 'none',
  marginLeft: 8,
  marginRight: 8,
});

function insertSeparators(
  items: React.ReactNode[],
  className: string | undefined = '',
  separator: React.ReactNode = '/'
): React.ReactNode[] {
  return items.reduce<React.ReactNode[]>((acc, current, index) => {
    if (index < items.length - 1) {
      acc = acc.concat(
        current,
        <BreadcrumbsSeparator
          aria-hidden
          key={`separator-${index}`}
          className={className}
        >
          {separator}
        </BreadcrumbsSeparator>
      );
    } else {
      acc.push(current);
    }
    return acc;
  }, []);
}

const Breadcrumbs = React.forwardRef<HTMLDivElement, BreadcrumbsProps>(
  function Breadcrumbs(props, ref) {
    const { className, separator = '/', children, ...rest } = props;
    const classes = useUtilityClasses({ classes: rest.classes });

    const allItems = React.Children.toArray(children)
      .filter((child) => React.isValidElement(child))
      .map((child, index) => (
        <li className={classes.li} key={`child-${index}`}>
          {child}
        </li>
      ));

    return (
      <BreadcrumbsRoot
        ref={ref}
        className={clsx(classes.root, className)}
        {...rest}
      >
        <BreadcrumbsOl className={classes.ol}>
          {insertSeparators(allItems, classes.separator, separator)}
        </BreadcrumbsOl>
      </BreadcrumbsRoot>
    );
  }
);

export default Breadcrumbs;
