import React from 'react';
import { Link } from 'react-router-dom';
import { ResourceType } from 'src/shared/constants';
import { ID } from 'src/shared/interfaces';
import { getResourceLink } from 'src/utils/resource';

export interface BaseProps {
  id?: ID;
  name?: string;
}

const Base: React.FC<BaseProps> = (props) => {
  const { id, name } = props;

  if (!id) return null;

  return <Link to={getResourceLink(ResourceType.repository, { id })}>{name}</Link>;
};

export default Base;
