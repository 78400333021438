import React from 'react';
import styled from '@emotion/styled';

import LogoSvg from 'src/assets/logo.svg';

const LogoRoot = styled('img', { label: 'Logo' })({
  width: '100%',
  height: 'auto',
});

interface LogoProps {
  className?: string;
}

const Logo: React.FC<LogoProps> = ({ className }) => {
  return <LogoRoot src={LogoSvg} className={className} alt="logo" />;
};

export default React.memo(Logo);
