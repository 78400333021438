import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import {
  isMockEnabled,
  initializeAxiosMockAdapter,
} from 'src/__mock__/mock.config';

import { setToken, refreshToken } from './interceptors/token';
import { addPendingRequest, removePendingRequest } from './interceptors/cancel';
import formData from './interceptors/formData';
import { AppStore } from 'src/app/store';
import { logout } from 'src/app/auth.slice';

export class HttpClient {
  protected readonly _http: AxiosInstance;

  private static instance: HttpClient;

  constructor(baseURL: string = '') {
    this._http = axios.create({
      baseURL,
      timeout: 1 * 60 * 1000,
      headers: { 'Content-Type': 'application/json' },
    });

    // mock
    if (isMockEnabled()) {
      initializeAxiosMockAdapter(this._http);
    }
  }

  static getInstance(baseURL?: string) {
    if (!HttpClient.instance) {
      HttpClient.instance = new HttpClient(baseURL);
    }
    return HttpClient.instance;
  }

  setInterceptors(store: AppStore) {
    // 请求拦截
    this._http.interceptors.request.use((config) => {
      setToken(config);
      formData(config);
      removePendingRequest(config);
      addPendingRequest(config);
      return config;
    });

    // 响应拦截
    this._http.interceptors.response.use(
      (response) => {
        refreshToken(response);
        removePendingRequest(response.config);
        return response;
      },
      (error) => {
        const { config, response } = error;
        // There is no `config` provided when request is canceled
        if (config) {
          removePendingRequest(config);
        }

        // TODO meaningful error code
        if (
          response &&
          response.data.code === 500 &&
          response.data.message === 'token错误或为空'
        ) {
          store.dispatch(logout());
        }

        return Promise.reject(error);
      }
    );
  }

  get<T = unknown>(url: string, config?: AxiosRequestConfig) {
    return this._http.get<T>(url, config);
  }

  post<T = unknown>(url: string, data?: any, config?: AxiosRequestConfig) {
    return this._http.post<T>(url, data, config);
  }

  patch<T = unknown>(url: string, data?: any, config?: AxiosRequestConfig) {
    return this._http.patch<T>(url, data, config);
  }

  put<T = unknown>(url: string, data?: any, config?: AxiosRequestConfig) {
    return this._http.put<T>(url, data, config);
  }

  delete<T = unknown>(url: string, config?: AxiosRequestConfig) {
    return this._http.delete<T>(url, config);
  }
}
