import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppState } from 'src/app/store';

import repositoryService from 'src/services/repository';
import { Loading } from 'src/shared/constants';
import { ID, Recent } from 'src/shared/interfaces';
import { getErrorMessage } from 'src/utils';

interface State {
  data: Recent | undefined | null;
  loading: Loading;
}

const name = 'repository/details/latest';

const initialState: State = { data: null, loading: Loading.idle };

export const fetch = createAsyncThunk<Recent, ID>(
  `${name}/fetch`,
  async (baseId, { rejectWithValue }) => {
    try {
      const response = await repositoryService.getListOfRecent({ baseId });
      return response.data as Recent;
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

const slice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.loading = Loading.pending;
    });

    builder.addCase(fetch.fulfilled, (state, action) => {
      state.loading = Loading.fulfilled;
      state.data = action.payload;
    });

    builder.addCase(fetch.rejected, (state) => {
      state.loading = Loading.rejected;
      state.data = null;
    });
  },
});

export default slice.reducer;

export const selectIsLoading = (state: AppState) =>
  state.repository.details.latest.loading === Loading.pending;

export const selectData = (state: AppState) =>
  state.repository.details.latest.data;
