import { CollectState } from 'src/services/appBar';

/**
 * 文档跳转链接
 */
export function getPaperLink(paper: CollectState) {
  return `/book/${paper.baseFolderId}?pageId=${paper.id}`;
}

/**
 * 文件夹跳转链接
 * @param folder
 */
export function getFolderLink(folder: CollectState) {
  if (folder.baseFolderId) {
    return `/folder/${folder.baseFolderId}?pageId=${folder.id}`;
  }
  return `/folder/${folder.id}`;
}

/**
 * 知识本条状链接
 * @param book
 */
export function getBookLink(book: CollectState) {
  return `/book/${book.id}`;
}

/**
 * 知识库跳转链接
 * @param repository
 */
export function getRepositoryLink(repository: CollectState) {
  return `/repository/${repository.id}`;
}

/**
 * 问答跳转链接
 * @param qa
 */
export function getQALink(qa: any) {
  return `/repository/${qa.baseId}/questions/${qa.qId}?answer_id=${qa.id}`;
}
