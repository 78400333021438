import React from 'react';
import styled from '@emotion/styled';

import { CONTENT_WIDTH } from 'src/shared/constants';
import clsx from 'clsx';

const PREFIX = 'MainLayout-Content';

const classNames = {
  root: `${PREFIX}__root`,
  wrapper: `${PREFIX}__wrapper`,
  main: `${PREFIX}__main`,
};

const ContentRoot = styled('div')(({ theme }) => ({
  [`&.${classNames.root}`]: {
    position: 'relative',
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    paddingTop: 64,
  },
  [`& .${classNames.wrapper}`]: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    width: '100%',
  },
  [`& .${classNames.main}`]: {
    WebkitBoxFlex: 1,
    MozBoxFlex: 1,
    flexGrow: 1,
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('xs')]: {
      width: CONTENT_WIDTH['xs'],
    },
    [theme.breakpoints.up('md')]: {
      width: CONTENT_WIDTH['md'],
    },
    [theme.breakpoints.up('lg')]: {
      width: CONTENT_WIDTH['lg'],
    },
    [theme.breakpoints.up('xl')]: {
      width: CONTENT_WIDTH['xl'],
    },
  },
}));

export interface ContentProps {
  className?: string | undefined;
}

const Content: React.FC<ContentProps> = ({ className, children }) => {
  return (
    <ContentRoot className={clsx(classNames.root, className)}>
      <div className={classNames.wrapper}>
        <main className={classNames.main}>{children}</main>
      </div>
    </ContentRoot>
  );
};

export default Content;
