import React from 'react';
import styled from '@emotion/styled';

import { Popover } from 'src/components/Popover';

import Trigger from './Trigger';
import Tabs from '../common/Tabs/Tabs';
import { TabPane } from '../common/Tabs';
import Paper from './Paper';
import Folder from './Folder';
import Book from './Book';
import Repository from './Repository';
import QA from './QA';

const PREFIX = 'AppBar-Favorites';

const classNames = {
  root: `${PREFIX}__root`,
};

const FavoritesRoot = styled('div')({
  [`&.${classNames.root}`]: {
    position: 'relative',
    width: 500,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
  },
});

const Favorites: React.FC = () => {
  return (
    <Popover trigger="click" button={<Trigger />}>
      <FavoritesRoot className={classNames.root}>
        <Tabs centered tabBarGutter={50} animated={false}>
          <TabPane tab="文档" key="1">
            <Paper />
          </TabPane>
          <TabPane tab="文件夹" key="2">
            <Folder />
          </TabPane>
          <TabPane tab="知识本" key="3">
            <Book />
          </TabPane>
          <TabPane tab="知识库" key="4">
            <Repository />
          </TabPane>
          <TabPane tab="问答" key="5">
            <QA />
          </TabPane>
        </Tabs>
      </FavoritesRoot>
    </Popover>
  );
};

export default Favorites;
