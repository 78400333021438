import React, { useCallback } from 'react';
import styled from '@emotion/styled';

import { Company as CompanyIcon } from 'src/components/Icons';
import { ID } from 'src/shared/interfaces';
import { ListItem } from 'src/components/List';
import { Typography } from 'src/components/Typography';

import Grid from '../../common/Grid';
import List from '../../common/List';
import Checkbox from '../../common/Checkbox';
import Button from '../../common/Button';
import { Department } from '../../interface';
import { useMemberDispatch, useMemberSelector } from '../../Provider';
import {
  selectLoading,
  selectSearch,
  selectSelectedList,
} from '../../reducers/selectors';
import {
  getRootDepartment,
  isDepartmentChecked,
  isDepartmentDisabled,
} from '../../utils';
import {
  deselectDepartment,
  selectDepartment,
  showMembers,
} from '../../reducers/actions';

const IconRoot = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: 10,
  fontSize: 18,
});

const StyledButton = styled(Button)({
  fontSize: 14,
});

const Departments: React.FC = () => {
  const dispatch = useMemberDispatch();

  const loading = useMemberSelector(selectLoading('search'));
  const { result: list } = useMemberSelector(selectSearch);
  const selectedList = useMemberSelector(selectSelectedList);

  const handleCheck = useCallback(
    (id: ID) => {
      return (event: any) => {
        const { checked } = event.target;
        if (checked) {
          dispatch(selectDepartment(id));
        } else {
          dispatch(deselectDepartment(id));
        }
      };
    },
    [dispatch]
  );

  const handleView = useCallback(
    (root: Department | null, department: Department) => {
      return () => {
        if (root) {
          dispatch(
            showMembers({
              departmentId: root.id,
              subdepartmentId: department.id,
            })
          );
        }
      };
    },
    [dispatch]
  );

  return (
    <List loading={loading} isEmpty={list.length === 0}>
      {(list as Department[]).map((item) => {
        const root = getRootDepartment(item);
        const checked = isDepartmentChecked(item, selectedList);
        const disabled = isDepartmentDisabled(item, selectedList);
        return (
          <ListItem>
            <Grid key={item.id}>
              <Grid item>
                <Checkbox
                  checked={checked}
                  disabled={disabled}
                  onChange={handleCheck(item.id)}
                />
                <IconRoot>
                  <CompanyIcon fontSize={16} />
                </IconRoot>
                <div style={{ flex: 1, minWidth: 0 }}>
                  <Typography noWrap variant="body2">
                    {item.title}
                  </Typography>
                </div>
                <div style={{ flex: 4, minWidth: 0 }}>
                  <Typography
                    align="center"
                    noWrap
                    variant="body2"
                    color="textSecondary"
                  >
                    {root?.title}
                  </Typography>
                </div>

                <div style={{ flex: 1, textAlign: 'right' }}>
                  <StyledButton onClick={handleView(root, item)}>
                    查看
                  </StyledButton>
                </div>
              </Grid>
            </Grid>
          </ListItem>
        );
      })}
    </List>
  );
};

export default Departments;
