import { SpringConfig, config as builtInConfig } from 'react-spring';

type Config = SpringConfig | undefined | null;
type Timeout = number | undefined | null;

/**
 * react-spring config
 * @param config
 * @param timeout
 * @returns
 */
export function getSpringConfig(
  config?: Config,
  timeout?: Timeout
): SpringConfig {
  if (config) {
    return config;
  }

  if (typeof timeout === 'number' && timeout > 0) {
    return { duration: timeout };
  }

  return builtInConfig.gentle;
}
