import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { AppState } from 'src/app/store';

import AppBarService, { CollectState } from 'src/services/appBar';
import { Loading } from 'src/shared/constants';
import { getErrorMessage } from 'src/utils';
import { FavoriteType } from './constants';

const name = 'appBar/favorites' as const;

export interface State {
  papers: CollectState[];
  folders: CollectState[];
  books: CollectState[];
  repositories: CollectState[];
  qa: CollectState[];
  type: FavoriteType;
  loading: Loading;
  currentRequestId: string | undefined;
}

const initialState: State = {
  papers: [],
  folders: [],
  books: [],
  repositories: [],
  qa: [],
  type: FavoriteType.papers,
  loading: Loading.idle,
  currentRequestId: undefined,
};

export const fetchList = createAsyncThunk<CollectState[], FavoriteType, { rejectValue: string }>(
  `${name}/fetchList`,
  async (type, { rejectWithValue }) => {
    try {
      const response = await AppBarService.fetchCollect({ type });
      console.log(response);
      return response.data.data;
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  }
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchList.pending, (state, action) => {
      state.loading = Loading.pending;
      state.currentRequestId = action.meta.requestId;
    });
    builder.addCase(fetchList.fulfilled, (state, action) => {
      if (state.currentRequestId === action.meta.requestId) {
        state.loading = Loading.fulfilled;
        state.type = action.meta.arg;
        state.currentRequestId = undefined;
        if (action.meta.arg === FavoriteType.papers) {
          state.papers = action.payload;
        }
        if (action.meta.arg === FavoriteType.folders) {
          state.folders = action.payload;
        }
        if (action.meta.arg === FavoriteType.books) {
          state.books = action.payload;
        }
        if (action.meta.arg === FavoriteType.repositories) {
          state.repositories = action.payload;
        }
        if (action.meta.arg === FavoriteType.qa) {
          state.qa = action.payload;
        }
      }
    });
    builder.addCase(fetchList.rejected, (state, action) => {
      if (state.currentRequestId === action.meta.requestId) {
        state.loading = Loading.rejected;
        state.type = action.meta.arg;
        state.currentRequestId = undefined;
      }
    });
  },
});

export const { reset } = slice.actions;

export default slice.reducer;

/** Selectors */
export const selectEntities = createSelector(
  [(state: AppState) => state.appBar.favorites, (state: AppState, type: FavoriteType) => type],
  (favorites, type) => {
    switch (type) {
      case FavoriteType.papers: {
        return favorites.papers;
      }
      case FavoriteType.folders: {
        return favorites.folders;
      }
      case FavoriteType.books: {
        return favorites.books;
      }
      case FavoriteType.repositories: {
        return favorites.repositories;
      }
      case FavoriteType.qa: {
        return favorites.qa;
      }
      default:
        return [];
    }
  }
);
