import React, { useContext, useMemo, useCallback } from 'react';
import styled from '@emotion/styled';
import { Empty } from 'antd';

import {
  UploadContext,
  UploadStatus,
  setUploadStatus,
  retryUploadFile,
} from '../../Provider';

import { toArray } from '../../utils';
import FileItem from '../FileItem';
import TableRow from './TableRow';
import TableCell from './TableCell';

const PREFIX = 'TableBody';

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('tbody')({});

const TableBody: React.FC = () => {
  const { state, dispatch } = useContext(UploadContext);

  const files = useMemo(() => {
    if (state.tasks) {
      return toArray(state.tasks).filter((t) => Boolean(t));
    }
    return [];
  }, [state.tasks]);

  const handleRetry = useCallback(
    (id: number) => {
      return () => {
        dispatch(retryUploadFile(id));
      };
    },
    [dispatch]
  );

  const handleCancel = useCallback(
    (id: number) => {
      return () => {
        dispatch(setUploadStatus(id, UploadStatus.Cancel));
      };
    },
    [dispatch]
  );

  return (
    <Root className={classes.root}>
      {files.length === 0 ? (
        <TableRow>
          <TableCell colSpan={5}>
            <Empty description="" />
          </TableCell>
        </TableRow>
      ) : (
        files.map(({ id, file, progress, speed, status, owner }) => (
          <FileItem
            key={id}
            file={file}
            progress={Math.floor(progress * 100)}
            speed={speed}
            status={status}
            owner={owner}
            onRetry={handleRetry(id)}
            onCancel={handleCancel(id)}
          />
        ))
      )}
    </Root>
  );
};

export default TableBody;
