import React, { useContext } from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';
import { Typography } from 'src/components/Typography';
import { useMatch, useResolvedPath } from 'react-router';

import MenuContext from './context';
import Tooltip from './Tooltip';
import 'tippy.js/dist/tippy.css';

const PREFIX = 'MenuItem';

const classNames = {
  root: `${PREFIX}__root`,
  collapsed: `${PREFIX}__collapsed`,
  inner: `${PREFIX}__inner`,
  icon: `${PREFIX}__icon`,
  label: `${PREFIX}__label`,
  active: `${PREFIX}__active`,
};

const MenuItemRoot = styled('li')(({ theme }) => ({
  [`&.${classNames.root}`]: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '0 8px',
    marginBottom: 14,
  },
  [`& .${classNames.inner}`]: {
    width: '100%',
    height: 42,
    lineHeight: '38px',
    cursor: 'pointer',
    padding: '0 12px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 6,
    '&:hover': {
      backgroundColor: theme.palette.background?.paper,
    },
  },
  [`& .${classNames.icon}`]: {},
  [`& .${classNames.label}`]: {},
  [`& .${classNames.icon}+.${classNames.label}`]: {
    marginLeft: 8,
  },
  [`&.${classNames.collapsed}`]: {
    [`& .${classNames.label}`]: {
      opacity: 0,
    },
  },
  [`&.${classNames.active}`]: {
    [`&.${classNames.root}`]: {
      color: theme.palette.primary?.main,
    },
    [`& .${classNames.inner}`]: {
      backgroundColor: theme.palette.background?.paper,
    },
    [`& .${classNames.icon}`]: {
      color: theme.palette.primary?.main,
    },
  },
}));

export interface MenuItemProps {
  onClick?: React.MouseEventHandler<HTMLLIElement>;
  className?: string | undefined;
  icon?: JSX.Element;
  to?: string;
}

const MenuItem: React.FC<MenuItemProps> = ({
  children,
  onClick,
  className,
  icon: Icon,
  ...rest
}) => {
  const { collapsed } = useContext(MenuContext);
  // TODO: 这个选中状态的逻辑能不能提到父组件中，通过props传递
  const resolvedPath = useResolvedPath(rest.to || '');
  const isActive = useMatch(resolvedPath.pathname);

  return (
    <MenuItemRoot
      className={clsx(classNames.root, className, {
        [classNames.collapsed]: collapsed,
        [classNames.active]: isActive,
      })}
      onClick={onClick}
      {...rest}
    >
      <Tooltip content={children} visible={collapsed}>
        <div className={classNames.inner}>
          {Icon && React.cloneElement(Icon, { className: classNames.icon })}
          <Typography className={classNames.label} noWrap>
            {children}
          </Typography>
        </div>
      </Tooltip>
    </MenuItemRoot>
  );
};

export default MenuItem;
