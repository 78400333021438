import React, { useCallback, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'src/hooks';
import { getListOfSidebar } from 'src/pages/Admin/KnowledgeBase/slice';
import { Book } from 'src/components/Icons';

import { MenuItem } from '../Menu';
import Extra from './Extra';
import { useNavigate } from 'react-router';

const Repository: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const list = useAppSelector((state) => state.authBaseReducer.sidebar);

  const handleClick = useCallback(
    (path: string) => {
      return () => {
        navigate(path);
      };
    },
    [navigate]
  );

  useEffect(() => {
    dispatch(getListOfSidebar());
  }, [dispatch]);

  if (!list || list.length === 0) return null;

  return (
    <Extra title="管理的知识库">
      {list.map((item) => {
        const path = `/repository/${item.id}`;
        return (
          <MenuItem key={item.id} icon={<Book />} to={path} onClick={handleClick(path)}>
            {item.name}
          </MenuItem>
        );
      })}
    </Extra>
  );
};

export default Repository;
