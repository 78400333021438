import React from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';

const PREFIX = 'TableCell';

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('td')({
  [`&.${classes.root}`]: {
    padding: '16px 24px',
    textAlign: 'left',
  },
});

export interface OverrideTypeMap {
  props: {};
  defaultComponent: React.ElementType;
}

export type OverrideProps<
  M extends OverrideTypeMap,
  C extends React.ElementType
> = Omit<React.ComponentPropsWithRef<C>, keyof M['props']> & M['props'];

export type DefaultComponentProps<M extends OverrideTypeMap> = Omit<
  React.ComponentPropsWithRef<M['defaultComponent']>,
  keyof M['props']
> & M['props']; // prettier-ignore

export interface OverrideComponent<M extends OverrideTypeMap> {
  <C extends React.ElementType>(
    props: { component: C } & OverrideProps<M, C>
  ): JSX.Element | null;
  (props: DefaultComponentProps<M>): JSX.Element | null;
}

const TableCell: OverrideComponent<{
  props: { component?: React.ElementType; className?: string | undefined };
  defaultComponent: 'td';
}> = React.forwardRef(function TableCell(props, ref) {
  const { className, component: Component = 'td', children, ...rest } = props;
  return (
    <Root
      className={clsx(classes.root, className)}
      as={Component}
      ref={ref}
      {...rest}
    >
      {children}
    </Root>
  );
});

export default TableCell;
