import React from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';
import { Avatar } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { Typography } from 'src/components/Typography';
import { shouldForwardProp } from 'src/utils';
import ListItemIcon from './ListItemIcon';
import ListItemActions from './ListItemActions';
import { ID } from 'src/shared/interfaces';

const PREFIX = 'AppBar-Notification--ListItem';

const classNames = {
  root: `${PREFIX}__root`,
  head: `${PREFIX}__head`,
  body: `${PREFIX}__body`,
  content: `${PREFIX}__content`,
  meta: `${PREFIX}__meta`,
  avatar: `${PREFIX}__avatar`,
  icon: `${PREFIX}__icon`,
  extra: `${PREFIX}__extra`,
  title: `${PREFIX}__title`,
  actions: `${PREFIX}__actions`,
  mark: `${PREFIX}__mark`,
};

const ListItemRoot = styled('div', {
  shouldForwardProp: (prop) => shouldForwardProp(prop) && prop !== 'read',
})<{ read?: number }>(({ read }) => ({
  [`&.${classNames.root}`]: {
    position: 'relative',
    padding: 10,
    borderRadius: 10,
    display: 'block',
    cursor: 'pointer',
    color: '#333',
    '&:hover': {
      backgroundColor: '#f6f7fb',
      [`& .${classNames.extra}`]: {
        visibility: 'visible',
      },
    },
  },
  [`& .${classNames.extra}`]: {
    position: 'absolute',
    right: 10,
    top: 10,
    left: 'auto',
    bottom: 'auto',
    visibility: 'hidden',
    fontSize: 16,
    [`& .${classNames.mark}`]: {
      marginRight: 20,
      color: '#07c160',
      ...(read === 1 && {
        display: 'none',
      }),
    },
  },
  [`& .${classNames.icon}`]: {
    marginRight: 10,
    ...(read === 1 && {
      opacity: 0.4,
    }),
  },
  [`& .${classNames.head}`]: {
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    paddingRight: 68,
    ...(read === 0 && {
      '&::before': {
        content: '""',
        flexShrink: 0,
        width: 6,
        height: 6,
        display: 'inline-block',
        backgroundColor: '#ff3b30',
        borderRadius: '100%',
        marginRight: 8,
      },
    }),
    ...(read === 1 && {
      color: '#999',
    }),
  },
  [`& .${classNames.body}`]: {
    display: 'flex',
    paddingLeft: 26,

    [`& .${classNames.avatar}`]: {
      ...(read === 1 && {
        opacity: 0.4,
      }),
    },
    [`& .${classNames.content}`]: {
      marginLeft: 10,
      flex: 1,
      minWidth: 0,

      ...(read === 1 && {
        color: '#999',
      }),
      [`& .${classNames.meta}`]: {
        marginTop: 20,
        color: '#999',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...(read === 1 && {
          color: '#999',
        }),
      },
    },
  },
  [`& .${classNames.title}`]: {
    marginTop: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > p': {
      paddingRight: 20,
    },
  },
  [`& .${classNames.actions}`]: {
    position: 'relative',
    flexShrink: 0,
  },
}));

export interface BaseListItemProps<T extends React.ElementType<any>> {
  className?: string | undefined;
  as?: T;
  title?: string;
  date?: string;
  base?: string;
  avatar?: string;
  target?: string;
  read?: number;
  resource?: string;
  onMark?: React.MouseEventHandler<HTMLSpanElement> | undefined;
  onRemove?: React.MouseEventHandler<HTMLSpanElement> | undefined;
  showActions?: boolean;
  id: ID;
  status?: number;
  authorityId: ID;
  isManage?: boolean;
}

// prettier-ignore
export type ListItemProps<T extends React.ElementType<any>> = 
  React.PropsWithChildren<BaseListItemProps<T>> &
  React.ComponentPropsWithRef<T>;

function ListItem<T extends React.ElementType<any>>(props: ListItemProps<T>) {
  const {
    className,
    as = 'div',
    title,
    target,
    date,
    base,
    avatar,
    read,
    resource,
    onMark,
    onRemove,
    showActions = false,
    id,
    status,
    authorityId,
    isManage = false,
    ...rest
  } = props;

  return (
    <ListItemRoot className={clsx(classNames.root, className)} as={as} read={read} {...rest}>
      <div className={classNames.head}>
        <ListItemIcon resource={resource} className={classNames.icon} />
        <Typography noWrap>{target}</Typography>
      </div>
      <div className={classNames.body}>
        <Avatar size={38} src={avatar} className={classNames.avatar} />
        <div className={classNames.content}>
          <div className={classNames.title}>
            <Typography noWrap>{title}</Typography>
            <div className={classNames.actions}>
              {showActions && isManage && (
                <ListItemActions id={id} status={status} authorityId={authorityId} />
              )}
            </div>
          </div>
          <div className={classNames.meta}>
            <Typography variant="body2">{date}</Typography>
            <Typography variant="body2">{base}</Typography>
          </div>
        </div>
      </div>
      <div className={classNames.extra}>
        {read !== undefined && <CheckOutlined onClick={onMark} className={classNames.mark} />}
        <CloseOutlined onClick={onRemove} />
      </div>
    </ListItemRoot>
  );
}

export default ListItem;
