import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAppDispatch } from 'src/hooks';
import { reset as resetRepository } from './slice';
import { reset as resetChildren } from './Main/Children/slice';
import { reset as resetQAList } from '../../QA/List/slice';

const Wrapper: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(
    () => () => {
      console.log('repository reset');
      dispatch(resetRepository());
      dispatch(resetChildren());
      dispatch(resetQAList());
    },
    [dispatch]
  );

  return <Outlet />;
};

export default Wrapper;
