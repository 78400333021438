import { createSlice, createAsyncThunk, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { AppState } from 'src/app/store';

import authService, { IChangeImageParams } from 'src/services/auth';
import { HttpResponse, User } from 'src/shared/interfaces';

interface AuthState {
  isLoggedIn: boolean;
  user: User | null;
  needUpdatePassword?: boolean;
}

function isLoggedIn() {
  authService.authentication();
  return authService.isAuthenticated();
}

const initialState: AuthState = {
  isLoggedIn: isLoggedIn(),
  user: null,
  needUpdatePassword: false,
};

type LoginReturned = { apiToken: string };
type LoginParams = { phone: string; password: string };
type SendCodeParams = { phone: any };
type CodeLoginParams = { phone: string; code: string };
type PasswordResetParams = {
  phone: any;
  code: any;
  password: string;
  repassword: string;
};

export const login = createAsyncThunk<LoginReturned, LoginParams>(
  'auth/login',
  async ({ phone, password }, { requestId, rejectWithValue }) => {
    try {
      const response = await authService.login(phone, password);
      return response.data;
    } catch (err) {
      const message = err.isAxiosError ? err.response.data : err.message;
      return rejectWithValue(message);
    }
  }
);

export const logout = createAsyncThunk('auth/logout', () => {
  authService.logout();
});

export const getProfile = createAsyncThunk<User, void>(
  'auth/profile',
  async (_, { requestId, rejectWithValue }) => {
    try {
      const response = await authService.getProfile();
      return response.data;
    } catch (err) {
      const message = err.isAxiosError ? err.response.data : err.message;
      return rejectWithValue(message);
    }
  }
);
/** 更改密码 */
export const changePassword = createAsyncThunk<User, LoginParams>(
  'auth/changePassword',
  async ({ phone, password }, { rejectWithValue }) => {
    try {
      const response = await authService.changePassword(phone, password);
      return response.data;
    } catch (err) {
      const message = err.isAxiosError ? err.response.data : err.message;
      return rejectWithValue(message);
    }
  }
);

// 获取验证码
export const sendCode = createAsyncThunk<void, SendCodeParams>(
  'auth/sendCode',
  async ({ phone }, { rejectWithValue }) => {
    try {
      await authService.sendCode(phone);
    } catch (err) {
      const message = err.isAxiosError ? err.response.data : err.message;
      return rejectWithValue(message);
    }
  }
);

// 验证码登录
export const codeLogin = createAsyncThunk<LoginReturned, CodeLoginParams>(
  'auth/codeLogin',
  async ({ phone, code }, { rejectWithValue }) => {
    try {
      const response = await authService.codeLogin(phone, code);
      return response.data;
    } catch (err) {
      const message = err.isAxiosError ? err.response.data : err.message;
      return rejectWithValue(message);
    }
  }
);

// 忘记密码
export const passwordReset = createAsyncThunk<HttpResponse<{}>, PasswordResetParams>(
  'auth/passwordReset',
  async ({ phone, code, password, repassword }, { rejectWithValue }) => {
    try {
      const response = await authService.passwordReset(phone, code, password, repassword);
      return response;
    } catch (err) {
      const message = err.isAxiosError ? err.response.data : err.message;
      return rejectWithValue(message);
    }
  }
);

export const changeImage = createAsyncThunk<User, Partial<IChangeImageParams>>(
  'auth/changeImage',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authService.changeImage(data);
      return response.data;
    } catch (err) {
      const message = err.isAxiosError ? err.response.data : err.message;
      return rejectWithValue(message);
    }
  }
);

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loggedIn(state) {
      state.isLoggedIn = true;
    },
    loggedOut(state) {
      state.isLoggedIn = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state) => {
      state.isLoggedIn = true;
    });

    builder.addCase(login.rejected, (state) => {
      state.isLoggedIn = false;
    });

    builder.addCase(getProfile.fulfilled, (state, action: PayloadAction<User>) => {
      state.user = action.payload;
      // checkPass 密码与手机号是否相同，相同则跳转至修改密码页面
      state.needUpdatePassword = action.payload.checkPass;
    });
    builder.addCase(getProfile.rejected, (state) => {
      state.user = null;
      state.isLoggedIn = false;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.user = null;
      state.isLoggedIn = false;
    });

    builder.addCase(codeLogin.fulfilled, (state) => {
      state.isLoggedIn = true;
    });

    builder.addCase(codeLogin.rejected, (state) => {
      state.isLoggedIn = false;
    });

    builder.addCase(changeImage.fulfilled, (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    });
  },
});

export const { loggedIn, loggedOut } = slice.actions;

export default slice.reducer;

export const selectUser = (state: AppState) => state.auth.user;

export const selectIsManager = createSelector(
  (state: AppState) => state.auth.user?.manage,
  (manage) => manage && manage < 3
);
