import React from 'react';
import styled from '@emotion/styled';

import TableHead from './TableHead';
import TableBody from './TableBody';

const PREFIX = 'UploadDrawerBody';

const classes = {
  root: `${PREFIX}-root`,
  table: `${PREFIX}-table`,
};

const Root = styled('table')({
  [`&.${classes.root}`]: {
    tableLayout: 'fixed',
    width: '100%',

    '& th, td': {
      borderBottom: '1px solid #eee',
    },
  },
});

const Table: React.FC = () => {
  return (
    <Root className={classes.root}>
      <TableHead />
      <TableBody />
    </Root>
  );
};

export default Table;
