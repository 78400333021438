import { createSvgIcon } from './utils';

export default createSvgIcon(
  [
    <path
      key="1"
      d="M11.6540393,1 C15.2277339,1 18.1341403,3.84497741 18.1325343,7.34273142 C18.1325343,9.73725407 16.9781994,11.8109141 14.8798521,12.9220482 L14.8798522,12.9220483 C16.4994667,13.3841256 17.9957475,14.2010286 19.26,15.3134098 L19.26,15.3134099 C14.0691362,16.0283679 10.4409925,20.8140083 11.156275,26.0024494 C11.1694599,26.0980874 11.1841052,26.1935185 11.2002056,26.2887095 C7.23594701,26.29029 3.49146178,26.2918706 2.41461575,26.2839679 C0.0759014773,26.2681624 0,25.1238493 0,24.0522411 C0,18.8854399 3.55473154,14.2576285 8.32386819,12.923644 C6.22392731,11.8109418 5.17078814,9.73884988 5.17078814,7.34275286 C5.17236942,3.84499885 8.08034477,1 11.6556144,1 L11.6540393,1 Z"
    />,
    <path
      key="2"
      d="M18.975,16.81 L18.9749999,16.81 C19.8483995,16.81 20.5564285,17.518029 20.5564285,18.3914286 C20.5564285,18.3914286 20.5564285,18.3914286 20.5564285,18.3914286 L20.5564285,20.7635714 L22.9285714,20.7635714 L22.9285714,20.7635714 C23.801971,20.7635715 24.51,21.4716005 24.51,22.345 C24.51,23.2183996 23.801971,23.9264286 22.9285714,23.9264286 L20.5548472,23.9264286 L20.5564286,26.2985715 L20.5564286,26.2985714 C20.5564286,27.171971 19.8483995,27.88 18.975,27.88 C18.1016005,27.88 17.3935714,27.171971 17.3935714,26.2985714 L17.39199,23.9264286 L15.0214286,23.9264286 L15.0214286,23.9264286 C14.148029,23.9264285 13.44,23.2183995 13.44,22.345 C13.44,21.4716004 14.148029,20.7635714 15.0214286,20.7635714 L17.3935714,20.7635714 L17.3935714,18.3914285 L17.3935714,18.3914288 C17.3935713,17.5180292 18.1016005,16.81 18.975,16.81 C18.975,16.81 18.975,16.81 18.975,16.81 L18.975,16.81 Z"
    />,
  ],
  'AddPerson'
);
