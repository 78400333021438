import { httpClient } from 'src/utils';

import { ListHttpResponse, Review, ID, HttpResponse } from 'src/shared/interfaces';
import { Category } from 'src/pages/Review/constants';

type FetchListArgs = {
  baseId?: ID;
  type?: Category;
  keyword?: string;
};

class ReviewService {
  async fetchList(data: FetchListArgs) {
    const response = await httpClient.get<ListHttpResponse<Review>>('/authority', { params: data });

    return response.data;
  }

  /**
   * 处理审核
   * @param id
   * @param status 1: 同意， 2: 拒绝
   * @returns
   */
  async resolve(id: ID, status: 1 | 2) {
    const response = await httpClient.patch<HttpResponse<{}>>(`/authority/${id}`, { status });
    return response.data;
  }

  /**
   * 审核详情
   * @param id
   * @returns
   */
  async fetchDetails(id: ID) {
    const response = await httpClient.get<HttpResponse<Review>>(`/authority/${id}`);
    return response.data;
  }
}

const reviewService = new ReviewService();

export default reviewService;
