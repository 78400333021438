/**
 * https://sourcegraph.com/github.com/mui-org/material-ui/-/blob/packages/material-ui-system/src/colorManipulator.js
 */
import { clamp } from '.';
import { isObject } from './checkType';

export type RGB = `rgb(${string})`;
export type RGBA = `rgba(${string})`;
export type HEX = `#${string}`;
export type Decomposed = { type: 'rgb' | 'rgba'; values: number[] };

// 仅用于提示支持的颜色格式，无实际用处
export const SUPPORTED_COLOR_FORMAT = ['#nnn', '#nnnnnn', 'rgb()', 'rgba()'];

function isColorDecomposed(color: unknown): color is Decomposed {
  return isObject(color) && typeof (color as Decomposed).type !== 'undefined';
}

function isHex(color: string): color is HEX {
  return color.charAt(0) === '#';
}

export function hexToRgb(color: string): string {
  const hex = color.substr(1);
  const re = new RegExp(`.{1,${hex.length >= 6 ? 2 : 1}}`, 'g');
  let colors = hex.match(re);
  if (colors && colors[0].length === 1) {
    colors = colors.map((n) => n + n);
  }

  if (colors) {
    const type = colors.length === 4 ? 'rgba' : 'rgb';
    const values = colors.map((n, index) => {
      return index < 3
        ? parseInt(n, 16)
        : Math.round((parseInt(n, 16) / 255) * 1000) / 1000;
    });

    return `${type}(${values.join(', ')})`;
  }

  return '';
}

export function intToHex(int: number): string {
  const hex = int.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
}

export function rgbToHex(color: string): string {
  if (isHex(color)) {
    return color;
  }

  const { values } = decomposeColor(color);
  const hex = values
    .map((n, i) => {
      if (i === 3) {
        return intToHex(Math.round(255 * n));
      }
      return intToHex(n);
    })
    .join('');

  return `#${hex}`;
}

export function decomposeColor(color: string | Decomposed): Decomposed {
  if (isColorDecomposed(color)) {
    return color;
  }

  if (isHex(color)) {
    return decomposeColor(hexToRgb(color));
  }

  const marker = color.indexOf('(');
  const type = color.substr(0, marker) as 'rgb' | 'rgba';
  if (!['rgb', 'rgba'].includes(type)) {
    throw new Error(`Unsupported \`${color}\` color.`);
  }

  const values = color
    .substring(marker + 1, color.length - 1)
    .split(',')
    .map((value) => parseFloat(value));

  return { type, values };
}

export function recomposeColor(color: Decomposed): string {
  const values = color.values.map((n, i) => {
    // 前 3 位，小数转整数，透明度保持不变
    return i < 3 ? parseInt(`${n}`, 10) : n;
  });

  if (!['rgb', 'rgba'].includes(color.type)) {
    throw new Error(`Unsupported \`${color}\` color.`);
  }

  return `${color.type}(${values.join(', ')})`;
}

/**
 * 获取颜色亮度
 * Formula: https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-tests
 * @param color
 * @returns
 */
export function getLuminance(color: string): number {
  const { values } = decomposeColor(color);

  const [r, g, b] = values.map((val) => {
    val /= 255;
    return val <= 0.03928 ? val / 12.92 : ((val + 0.055) / 1.055) ** 2.4;
  });

  return Number((0.2126 * r + 0.7152 * g + 0.0722 * b).toFixed(3));
}

/**
 * 获取对比度
 * Formula: https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-tests
 * @param foreground
 * @param background
 * @returns 范围 [1, 21]
 */
export function getContrastRatio(
  foreground: string,
  background: string
): number {
  const lumA = getLuminance(foreground);
  const lumB = getLuminance(background);
  return (Math.max(lumA, lumB) + 0.05) / (Math.min(lumA, lumB) + 0.05);
}

/**
 * 添加透明度， 返回 rgba()
 * @param color
 * @param value
 * @returns
 */
export function alpha(color: string, value: number): string {
  const decomposed = decomposeColor(color);
  if (decomposed.type === 'rgb') {
    decomposed.type += 'a';
  }

  decomposed.values[3] = value;

  return recomposeColor(decomposed);
}

/**
 * 颜色变暗
 * @param color
 * @param coefficient 系数: [0, 1]
 * @returns
 */
export function darken(color: string, coefficient: number): string {
  const { type, values } = decomposeColor(color);
  coefficient = clamp(coefficient);

  for (let i = 0; i < 3; i++) {
    values[i] *= 1 - coefficient;
  }

  return recomposeColor({ type, values });
}

/**
 * 颜色变亮
 * @param color
 * @param coefficient 系数: [0, 1]
 * @returns
 */
export function lighten(color: string, coefficient: number): string {
  const { type, values } = decomposeColor(color);
  coefficient = clamp(coefficient);

  for (let i = 0; i < 3; i++) {
    values[i] += (255 - values[i]) * coefficient;
  }

  return recomposeColor({ type, values });
}

/**
 * 根据亮度自动变亮/变暗
 * @param color
 * @param coefficient
 * @returns
 */
export function emphasize(color: string, coefficient: number): string {
  return getLuminance(color) > 0.5
    ? darken(color, coefficient)
    : lighten(color, coefficient);
}
