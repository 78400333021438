import React from 'react';
import styled from '@emotion/styled';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const PREFIX = 'Review-Details--UserInfo';

const classNames = {
  root: `${PREFIX}__root`,
  avatar: `${PREFIX}__avatar`,
};

const UserInfoRoot = styled('div')({
  [`&.${classNames.root}`]: {
    position: 'relative',
    backgroundColor: '#f6f7fb',
    borderRadius: 10,
    padding: 16,
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      flex: 1,
    },
  },
  [`& .${classNames.avatar}`]: {
    marginRight: 10,
  },
});

export interface UserInfoProps {
  name?: string;
  phone?: string;
  department?: string;
  avatar?: string;
}
const UserInfo: React.FC<UserInfoProps> = (props) => {
  const { name, phone, department, avatar } = props;

  return (
    <UserInfoRoot className={classNames.root}>
      <div>
        <Avatar src={avatar} icon={<UserOutlined />} className={classNames.avatar} />
        <span>{name}</span>
      </div>
      <div>
        <span>手机号: </span>
        <span>{phone}</span>
      </div>
      <div>{department}</div>
    </UserInfoRoot>
  );
};

export default UserInfo;
