import { UploadDrawerSize, UploadStatus } from './constants';

export interface UploadTask {
  id: number;
  file: File;
  status: UploadStatus; // 状态： 等待/上传中/成功/失败
  owner: any; // 文件保存到的文件夹
  progress: number;
  speed: number;
}

export interface UploadState {
  tasks: Record<number, UploadTask> | undefined;
  open: boolean;
  size: UploadDrawerSize;
  showTip: boolean; // 上传完成提示
}

const initialUploadState: UploadState = {
  tasks: undefined,
  open: false,
  size: UploadDrawerSize.Maximize,
  showTip: false,
};

export default initialUploadState;
