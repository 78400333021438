import { Spin } from 'antd';

import Box from 'src/components/Box';

const PageLoading: React.FC = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: 300,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
      }}
    >
      <Spin size="large" />
    </Box>
  );
};

export default PageLoading;
