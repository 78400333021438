import React from 'react';
import {
  ELEMENT_BLOCKQUOTE,
  someNode,
  toggleNodeType,
  usePlateEditorState,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
} from '@udecode/plate';

import {
  Quote,
  HeadingLevel1 as H1,
  HeadingLever2 as H2,
  HeadingLever3 as H3,
  HeadingLever4 as H4,
  HeadingLever5 as H5,
  HeadingLever6 as H6,
} from '../../icons';
import ToolbarButton from '../ToolbarButton';

const BlockTypes = [
  ELEMENT_BLOCKQUOTE,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
] as const;

type BlockType = typeof BlockTypes[number];

const BlockTypeIcons: Record<BlockType, React.ComponentType<any>> = {
  [ELEMENT_BLOCKQUOTE]: Quote,
  [ELEMENT_H1]: H1,
  [ELEMENT_H2]: H2,
  [ELEMENT_H3]: H3,
  [ELEMENT_H4]: H4,
  [ELEMENT_H5]: H5,
  [ELEMENT_H6]: H6,
};

export interface BlockToolbarButtonProps {
  type: BlockType;
  inactiveType?: string | undefined;
}

const BlockToolbarButton: React.FC<BlockToolbarButtonProps> = (props) => {
  const { type, inactiveType } = props;
  const editor = usePlateEditorState();

  const Icon = BlockTypeIcons[type];

  const handleToggleNodeType = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (editor) {
      toggleNodeType(editor, { activeType: type, inactiveType });
    }
  };

  return (
    <ToolbarButton
      active={!!editor?.selection && someNode(editor, { match: { type } })}
      onMouseDown={handleToggleNodeType}
    >
      <Icon color="inherit" />
    </ToolbarButton>
  );
};

export default BlockToolbarButton;
