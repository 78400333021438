import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  EntityState,
} from '@reduxjs/toolkit';

import { HttpResponse, ID, ListData } from 'src/shared/interfaces';
import adminService, {
  IBaseMemberList,
  IGetBaseMember,
  IRemoveBaseMemberParams,
  IUsers,
} from 'src/services/admin';
import repositoryService from 'src/services/repository';
import { getErrorMessage } from 'src/utils';
import { Loading } from 'src/shared/constants';
import { AppState } from 'src/app/store';

export interface State {
  current: ID | null;
  showBlacklist: boolean;
  selectedKeys: ID[];
  keyword: string;
  sort: string;
  department: EntityState<IBaseMemberList> & {
    loading: Loading;
  };
  member: EntityState<IUsers> & {
    loading: Loading;
    page: number;
    total: number;
  };
}

const departmentEntity = createEntityAdapter<IBaseMemberList>({
  selectId: (department) => department.id,
});

const memberEntity = createEntityAdapter<IUsers>({
  selectId: (user) => user.id,
});

const initialState: State = {
  showBlacklist: false,
  current: null,
  selectedKeys: [],
  keyword: '',
  sort: '',
  department: departmentEntity.getInitialState({
    loading: Loading.idle,
  }),
  member: memberEntity.getInitialState({
    loading: Loading.idle,
    page: 1,
    total: 0,
  }),
};

type ThunkApiConfig = { rejectValue: string; state: AppState };

const name = 'repository/member';

/**
 * 知识库部门
 */
export const fetchDepartments = createAsyncThunk<
  IBaseMemberList[],
  ID,
  ThunkApiConfig
>(`${name}/fetchDepartments`, async (id, { rejectWithValue }) => {
  try {
    const response = await adminService.baseMember({ id });
    return response.data;
  } catch (err) {
    return rejectWithValue(getErrorMessage(err));
  }
});

/**
 * 获取部门成员
 */
export const fetchMembersOfDepartment = createAsyncThunk<
  ListData<IUsers>,
  IGetBaseMember,
  ThunkApiConfig
>(`${name}/fetchMembersOfDepartment`, async (data, { rejectWithValue }) => {
  try {
    const response = await adminService.getBaseMember(data);
    return response.data;
  } catch (err) {
    return rejectWithValue(getErrorMessage(err));
  }
});

/**
 * 删除部门/拉黑成员
 */
export const removeMemberOrDepartment = createAsyncThunk<
  string,
  Partial<IRemoveBaseMemberParams>,
  ThunkApiConfig
>(`${name}/remove`, async (data, { rejectWithValue, getState }) => {
  const {
    member: { showBlacklist, current },
  } = getState();
  try {
    const response = await adminService.removeBaseMember({
      type: showBlacklist ? 2 : 1,
      departmentId: current || '',
      ...data,
    });
    return response.message;
  } catch (err) {
    return rejectWithValue(getErrorMessage(err));
  }
});

/**
 * 添加成员
 */
export const addMembers = createAsyncThunk<
  HttpResponse<{}>,
  any,
  { rejectValue: string }
>(
  `${name}/addMembers`,
  async ({ id, members, departments }, { rejectWithValue }) => {
    try {
      const response = await repositoryService.addMembers(id, {
        members,
        departments,
      });
      return response;
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  }
);

/**
 * 新增/取消管理员
 */
export const baseMemberAdmin = createAsyncThunk<void, any, ThunkApiConfig>(
  'base/baseMemberAdmin',
  async (data, { rejectWithValue }) => {
    try {
      await adminService.baseMemberAdmin(data);
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setCurrent(state, action) {
      state.current = action.payload;
    },
    setShowBlacklist(state, action) {
      state.showBlacklist = action.payload;
    },
    setPage(state, action) {
      state.member.page = action.payload;
    },
    setSelectedKeys(state, action) {
      state.selectedKeys = action.payload;
    },
    setKeyword(state, action) {
      state.keyword = action.payload;
    },
    setSort(state, action) {
      state.sort = action.payload;
    },
    reset(state) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDepartments.pending, (state, action) => {
        state.department.loading = Loading.pending;
      })
      .addCase(fetchDepartments.fulfilled, (state, action) => {
        state.department.loading = Loading.fulfilled;
        departmentEntity.setAll(state.department, action.payload);
      })
      .addCase(fetchDepartments.rejected, (state, action) => {
        state.department.loading = Loading.rejected;
        departmentEntity.setAll(state.department, []);
      });

    builder
      .addCase(fetchMembersOfDepartment.pending, (state) => {
        state.member.loading = Loading.pending;
      })
      .addCase(fetchMembersOfDepartment.fulfilled, (state, action) => {
        state.member.loading = Loading.fulfilled;
        memberEntity.setAll(state.member, action.payload.data);
        state.member.total = action.payload.meta.pagination.total;
      })
      .addCase(fetchMembersOfDepartment.rejected, (state) => {
        state.member.loading = Loading.rejected;
        memberEntity.setAll(state.member, []);
      });

    builder.addCase(removeMemberOrDepartment.fulfilled, (state, action) => {
      const { departmentId, userId } = action.meta.arg;
      if (typeof departmentId !== 'undefined') {
        departmentEntity.removeOne(state.department, departmentId);
      }

      if (typeof userId !== 'undefined') {
        memberEntity.removeMany(
          state.member,
          Array.isArray(userId) ? userId : [userId]
        );
      }
    });
  },
});

export default slice.reducer;

export const {
  setCurrent,
  setShowBlacklist,
  setPage,
  setSelectedKeys,
  setKeyword,
  setSort,
  reset,
} = slice.actions;

export const {
  selectAll: selectAllDepartments,
  selectById: selectDepartmentById,
} = departmentEntity.getSelectors((state: AppState) => state.member.department);

// prettier-ignore
export const { 
  selectAll: selectAllMembers, 
  selectById: selectMemberById 
} = memberEntity.getSelectors((state: AppState) => state.member.member);
