import React from 'react';

import styled from '@emotion/styled';
import { Empty, message } from 'antd';

import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from 'src/components/List';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { remove, selectList } from 'src/pages/Pack/details.slice';
import { getResourceIcon } from 'src/utils';
import { ellipsis } from 'src/shared/styles';
import { Close } from 'src/components/Icons';
import { ID } from 'src/shared/interfaces';
import { useDialogContext } from 'src/components/Dialog';
import { Tips } from 'src/shared/constants';

const ShareListRoot = styled(List)({
  padding: '0 8px',
  overflow: 'auto',
});

const ShareListItem = styled(ListItem)({
  '&:hover .ShareListItem-action': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const Action = styled('div')({
  justifySelf: 'flex-end',
  display: 'none',
});

const StyledEmpty = styled(Empty)({
  paddingBottom: 20,
});

const ShareList: React.FC = () => {
  const dispatch = useAppDispatch();
  const list = useAppSelector(selectList('wait'));
  const { openDialog } = useDialogContext();

  const handleClick = React.useCallback(
    (id: ID, name: string) => {
      return () => {
        const { variant, content } = Tips.pack.remove;
        openDialog({
          variant,
          content: content(name),
          onOk: async () => {
            const resultAction = await dispatch(remove(id));
            if (remove.fulfilled.match(resultAction)) {
              message.success('删除成功');
            } else {
              message.error(resultAction.payload);
            }
          },
        });
      };
    },
    [dispatch, openDialog]
  );

  if (list.length === 0) {
    return <StyledEmpty />;
  }

  return (
    <ShareListRoot>
      {list.map((item) => {
        const { component: Icon = React.Fragment, color } = getResourceIcon(
          item.resource.resource
        );
        return (
          <ShareListItem className="ShareList-item" key={item.id}>
            <ListItemIcon>
              <Icon color={color} />
            </ListItemIcon>
            <ListItemText>
              <div style={ellipsis}>
                {item.resource.name || item.resource.title}
              </div>
            </ListItemText>
            <Action
              className="ShareListItem-action"
              onClick={handleClick(item.id, item.resource.name)}
            >
              <Close color="#ddd" fontSize={18} />
            </Action>
          </ShareListItem>
        );
      })}
    </ShareListRoot>
  );
};

export default ShareList;
