import React from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';

const PREFIX = 'TableRow';

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('tr')({});

type TableRowProps = React.ComponentPropsWithoutRef<'tr'>;

const TableRow = React.forwardRef<HTMLTableRowElement, TableRowProps>(
  function TableRow(props, ref) {
    const { children, className, ...rest } = props;
    return (
      <Root ref={ref} className={clsx(classes.root, className)} {...rest}>
        {children}
      </Root>
    );
  }
);

export default TableRow;
