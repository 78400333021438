import React, { useMemo } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import { Plate, PlateProps, ELEMENT_PARAGRAPH } from '@udecode/plate';

import HeadingToolbar from './components/HeadingToolbar';

import { plugins } from './plugins';
import { getPlainText } from './utils';

const PREFIX = 'TextEditor';

const classNames = {
  root: `${PREFIX}__root`,
  editor: `${PREFIX}__editor`,
  wrapper: `${PREFIX}__wrapper`,
  wordsCounter: `${PREFIX}__wordsCounter`,
  separator: `${PREFIX}__separator`,
  error: `${PREFIX}__error`,
};

const TextEditorRoot = styled('div')({
  [`&.${classNames.root}`]: {
    position: 'relative',
    border: '1px solid #dadada',
    borderRadius: 4,
  },
  [`& .${classNames.wrapper}`]: {
    height: 280,
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'relative',
  },
  [`& .${classNames.editor}`]: {},
  [`& .${classNames.wordsCounter}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontSize: 12,
    borderTop: '1px solid #dadada',
    height: 32,
    paddingLeft: 8,
    paddingRight: 8,
    [`& .${classNames.separator}`]: {
      marginLeft: 3,
      marginRight: 3,
    },
    [`& .${classNames.error}`]: {
      marginRight: 8,
      color: '#ff3b30',
    },
  },
});

export interface TextEditorProps {
  id?: string;
  value?: PlateProps['value'];
  onChange?: PlateProps['onChange'] | undefined;
  initialValue?: PlateProps['initialValue'];
  placeholder?: string;
  className?: string;
  maxLength?: number;
}

const TextEditor: React.FC<TextEditorProps> = (props) => {
  const {
    id = 'TextEditor',
    value,
    onChange,
    initialValue = [{ type: ELEMENT_PARAGRAPH, children: [{ text: '' }] }],
    placeholder = '请输入...',
    className,
    maxLength,
  } = props;

  const editableProps = useMemo<PlateProps['editableProps']>(
    () => ({
      autoCapitalize: 'off',
      autoComplete: 'off',
      autoCorrect: 'off',
      spellCheck: false,
      placeholder,
      style: {
        padding: 6,
      },
    }),
    [placeholder]
  );

  const content = getPlainText(value || [{ type: ELEMENT_PARAGRAPH, children: [{ text: '' }] }]);

  return (
    <TextEditorRoot className={clsx(classNames.root, className)}>
      <HeadingToolbar />
      <div className={classNames.wrapper}>
        <Plate
          id={id}
          initialValue={initialValue}
          value={value}
          plugins={plugins}
          onChange={onChange}
          editableProps={editableProps}
        />
      </div>
      {maxLength && (
        <div className={classNames.wordsCounter}>
          <span>{content.length}</span>
          <span className={classNames.separator}>/</span>
          <span>{maxLength}</span>
        </div>
      )}
    </TextEditorRoot>
  );
};

export default TextEditor;
