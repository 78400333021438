/**
 * 后台管理 -审核
 */

import { httpClient } from 'src/utils';

import {
  ListHttpResponse,
  HttpResponse,
  ID,
  // PaginationParams,
} from 'src/shared/interfaces';

export interface Authority {
  id: ID;
  userName: string;
  name: string;
  resource: number;
  resourceId: ID;
  departmentId?: ID;
  type: number;
  base?: {
    createdAt: string;
    deletedAt: number;
    id: ID;
    img: string;
    introduction: string;
    isOpen: number;
    name: string;
    notice: string;
    updatedAt: string;
  };
  union: {
    id: ID;
    baseFolderId?: ID;
    name: string;
    resource: number;
    baseFolderType: number;
    resourceId: ID;
    resourceName: number;
    fileUrl: string;
  };
  status: number;
  authAt: string;
  job?: string;
  phone?: string;
  departments?: string;
}

class AuthorityService {
  /**
   * 审核列表
   * @param params
   * @returns
   */
  async fetchAuthority(params: {
    baseId?: ID;
    page?: number;
    limit?: number;
    type: number;
    keyword?: string;
  }) {
    const response = await httpClient.get<ListHttpResponse<Authority>>(
      '/authority',
      {
        params,
      }
    );
    return response.data;
  }
  /**
   * 审核处理
   * @param data
   * @returns
   */
  async examineAuthority(data: { status: ID; id: ID }) {
    const response = await httpClient.patch<HttpResponse<Authority>>(
      `/authority/${data.id}`,
      {
        status: data.status,
      }
    );
    return response.data;
  }

  async examineDetail(id: ID) {
    const response = await httpClient.get<HttpResponse<Authority>>(
      `/authority/${id}`
    );
    return response.data;
  }
}

/**
 * @deprecated
 */
export default new AuthorityService();
