/**
 * 文本溢出
 */
export const ellipsis: React.CSSProperties = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  marginBottom: 0,
};

/** 冒号 */
export const colon: React.CSSProperties = {
  content: '":"',
  margin: '0 8px 0 2px',
  position: 'relative',
  top: -0.5,
};

/**
 * 布局尺寸
 */
/** TODO: use correct type instead of any */
export const layoutMediaQuery: any = {
  '@media (min-width: 0px)': {
    width: 960,
  },
  '@media (min-width: 960px)': {
    width: 960,
  },
  '@media (min-width: 1280px)': {
    width: 1120,
  },
  '@media (min-width: 1600px)': {
    width: 1260,
  },
  '@media (min-width: 1920px)': {
    width: 1520,
  },
};

export const sideBarMediaQuery: any = {
  '@media (min-width: 0px)': {
    width: 200,
  },
  '@media (min-width: 960px)': {
    width: 200,
  },
  '@media (min-width: 1280px)': {
    width: 256,
  },
  '@media (min-width: 1600px)': {
    width: 300,
  },
};

export const contentMediaQuery: any = {
  '@media (min-width: 0px)': {
    width: 760,
  },
  '@media (min-width: 960px)': {
    width: 760,
  },
  '@media (min-width: 1280px)': {
    width: 864,
  },
  '@media (min-width: 1600px)': {
    width: 960,
  },
  '@media (min-width: 1920px)': {
    width: 1220,
  },
};
