import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { AppState } from 'src/app/store';

import qaService from 'src/services/QA';
import { Loading } from 'src/shared/constants';
import { ID } from 'src/shared/interfaces';
import { getErrorMessage } from 'src/utils';
import { Report } from '../types';
import { reportAdapter } from '../utils';
import { defaultTitle } from './constants';
import { Type } from '../constants';

export interface State {
  data: Report[];
  loading: Loading;
}

const name = 'QAReport' as const;

const initialState: State = {
  data: [],
  loading: Loading.idle,
};

/**
 * 获取举报理由列表
 */
export const fetchReportList = createAsyncThunk<
  Report[],
  void,
  { rejectValue: string; state: AppState }
>(
  `${name}/fetchReportList`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await qaService.fetchReportList();
      return response.data.map((item) => reportAdapter(item));
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  },
  {
    condition: (_, { getState }) => {
      const { QA } = getState();
      return QA.report.loading === Loading.idle && QA.report.data.length === 0;
    },
  }
);

type ReportParams = {
  id: ID; // 问题/答案 id
  rId: ID; // 举报理由 id
  detail?: string; // 详细描述
  type?: Type; // 类型， 1: 问题 2: 答案
};

/**
 * 举报
 */
export const report = createAsyncThunk<void, ReportParams, { rejectValue: string }>(
  `${name}/report`,
  async ({ id, type = Type.question, ...rest }, { rejectWithValue }) => {
    try {
      await qaService.report(id, { type, ...rest });
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  }
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReportList.pending, (state) => {
      state.loading = Loading.pending;
    });

    builder.addCase(fetchReportList.fulfilled, (state, action) => {
      state.loading = Loading.fulfilled;
      state.data = action.payload;
    });

    builder.addCase(fetchReportList.rejected, (state) => {
      state.loading = Loading.rejected;
    });
  },
});

export const { reset } = slice.actions;

export default slice.reducer;

/** Selectors */
export const selectReportItem = createSelector(
  [(state: AppState) => state.QA.report.data, (_: AppState, id: ID | undefined) => id],
  (reports, id) => reports.find((item) => item.id === id)
);

export const selectReportItemTitle = createSelector(
  [(state: AppState, id: ID | undefined) => selectReportItem(state, id)],
  (report) => {
    if (report && report.needReason) {
      return report.content;
    }
    return defaultTitle;
  }
);
