import React from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';

import { Search as SearchSvgIcon } from 'src/components/Icons';
import { composeClasses } from 'src/utils';

interface SearchInputProps extends React.ComponentPropsWithRef<'input'> {
  className?: string;
  placeholder?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  variant?: 'contained' | 'outline' | undefined;
  defaultValue?: string;
}

const classPrefix = 'Search';

const useUtilityClasses = (props: Pick<SearchInputProps, 'variant'>) => {
  const { variant = 'contained' } = props;
  const slots = {
    root: ['root', variant],
    input: ['input'],
    icon: ['icon'],
  };
  return composeClasses(classPrefix, slots);
};

function shouldForwardProp(prop: PropertyKey) {
  return prop !== 'theme' && prop !== 'as' && prop !== 'variant';
}

const SearchRoot = styled('div', {
  label: `${classPrefix}Root`,
  shouldForwardProp,
})<{ variant?: 'contained' | 'outline' | undefined }>(({ variant = 'contained', theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: 40,
  fontSize: 16,
  ...(variant === 'contained' && {
    backgroundColor: theme.palette.background?.search || '#fefefe',
    borderRadius: 22,
    paddingLeft: 20,
    paddingRight: 20,
  }),
  ...(variant === 'outline' && {
    borderBottom: '1px solid #eee',
  }),
}));

const SearchIcon = styled('div', { label: `${classPrefix}Icon` })({
  fontSize: 16,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: 10,
});

const SearchInput = styled('input', { label: `${classPrefix}Input` })({
  border: 'none',
  outline: 'none',
  backgroundColor: 'transparent',
  marginLeft: 6,
  fontSize: 16,
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  '&::placeholder': {
    color: '#ccc',
    textOverflow: 'ellipsis',
  },
});

const Search = React.forwardRef<HTMLInputElement, SearchInputProps>(function Search(
  { className, variant, ...rest },
  ref
) {
  const classes = useUtilityClasses({ variant });
  return (
    <SearchRoot className={clsx(classes.root, className)} variant={variant}>
      <SearchIcon className={classes.icon}>
        <SearchSvgIcon />
      </SearchIcon>
      <SearchInput {...rest} ref={ref} className={classes.input} />
    </SearchRoot>
  );
});

export default Search;
