import styled from '@emotion/styled';

const SvgIconRoot = styled('svg', { label: 'SvgIcon' })((props) => ({
  userSelect: 'none',
  width: '1em',
  height: '1em',
  display: 'inline-block',
  fill: 'currentColor',
  flexShrink: 0,
  transition: 'fill 250ms linear',
  fontSize: props.fontSize || 'inherit',
  color: props.color || props.theme.palette.icon,
}));

export type SvgIconPropsType = {
  className?: string;
  color?: string;
  component?: React.ElementType;
  fontSize?: string | number;
  title?: string;
  viewBox?: string;
  style?: React.CSSProperties;
};

const SvgIcon: React.FC<SvgIconPropsType> = ({
  children,
  className,
  color = '',
  component = 'svg',
  fontSize = 'inherit',
  title = '',
  viewBox = '0 0 18 18',
}) => {
  return (
    <SvgIconRoot
      as={component}
      className={className}
      focusable={false}
      viewBox={viewBox}
      color={color}
      fontSize={fontSize}
    >
      {children}
      {title ? <title>{title}</title> : null}
    </SvgIconRoot>
  );
};

export default SvgIcon;
