import React from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';

import { shouldForwardProp, composeClasses } from 'src/utils';

type Classes = {
  root?: string | undefined;
  icon?: string | undefined;
  label?: string | undefined;
};

interface IconButtonProps {
  icon?: React.ReactNode | undefined;
  className?: string | undefined;
  classes?: Classes | undefined;
  onClick?: ((event: React.MouseEvent<HTMLDivElement>) => void) | undefined;
  disabled?: boolean;
  style?: React.CSSProperties;
}

const classPrefix = 'IconButton';

function useUtilityClasses(props: IconButtonProps) {
  const { classes, disabled } = props;

  const slots = {
    root: ['root', disabled && 'disabled'],
    icon: ['icon'],
    label: ['label'],
  };

  return composeClasses(classPrefix, slots, classes);
}

const IconButton = React.memo<IconButtonProps>(function IconButton({
  className,
  icon: Icon,
  classes,
  disabled,
  onClick,
  children,
  style,
}) {
  const classNames = useUtilityClasses({ classes, disabled });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!disabled) {
      onClick?.(event);
    }
  };

  return (
    <div
      className={clsx(classNames.root, className)}
      style={style}
      onClick={handleClick}
    >
      <div className={classNames.icon}>{Icon}</div>
      <div className={classNames.label}>{children}</div>
    </div>
  );
});

export default styled(IconButton, { label: classPrefix, shouldForwardProp })(
  ({ theme }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',

    [`&.${classPrefix}-disabled`]: {
      color: theme.palette.action?.disabled,
    },

    [`& .${classPrefix}-icon`]: {
      display: 'flex',
      alignItems: 'center',

      [`& + .${classPrefix}-label`]: {
        marginLeft: 10,
      },
    },

    [`& .${classPrefix}-label`]: {
      flexShrink: 0,
    },
  })
);
