import React from 'react';
import { Link } from 'react-router-dom';
import { List } from 'antd';

import { Book as RepositoryIcon } from 'src/components/Icons';
import useFavorites from '../useFavorites';
import { FavoriteType } from '../constants';
import ListItem from '../ListItem';
import { getRepositoryLink } from '../utils';

const Repository: React.FC = () => {
  const { loading, entities } = useFavorites(FavoriteType.repositories);

  console.log(entities);

  return (
    <List
      loading={loading}
      dataSource={entities}
      renderItem={(item) => {
        const href = getRepositoryLink(item);
        return (
          <ListItem
            key={item.id}
            icon={<RepositoryIcon color="#ffd7c1" />}
            title={item.name}
            content={item.introduction}
            as={Link}
            to={href}
          />
        );
      }}
    />
  );
};

export default Repository;
