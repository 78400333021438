import React from 'react';
import { useSpring, animated } from 'react-spring';

import { ownerWindow } from 'src/utils';
import { useForkRef } from 'src/hooks';

import { BaseTransitionProps } from '../transition';
import { getSpringConfig } from '../utils';

type Direction = 'left' | 'right' | 'up' | 'down';

function getTranslateValue(direction: Direction, node: HTMLElement | null) {
  if (!node) return;
  const rect = node.getBoundingClientRect();
  const containerWindow = ownerWindow(node);

  const computedStyle = containerWindow.getComputedStyle(node);
  const transform =
    computedStyle.getPropertyValue('-webkit-transform') ||
    computedStyle.getPropertyValue('transform');

  let offsetX = 0;
  let offsetY = 0;

  if (transform && transform !== 'none' && typeof transform === 'string') {
    const transformValues = transform.split('(')[1].split(')')[0].split(',');
    offsetX = parseInt(transformValues[4], 10);
    offsetY = parseInt(transformValues[5], 10);
  }

  if (direction === 'left') {
    return {
      x: containerWindow.innerWidth + offsetX - rect.left,
      y: 0,
    };
  }

  if (direction === 'right') {
    return {
      x: -1 * (rect.left + rect.width - offsetX),
      y: 0,
    };
  }

  if (direction === 'up') {
    return {
      x: 0,
      y: containerWindow.innerHeight + offsetY - rect.top,
    };
  }

  // direction === 'down'
  return {
    x: 0,
    y: -1 * (rect.top + rect.height - offsetY),
  };
}

export interface SlideProps extends BaseTransitionProps {
  direction?: Direction;
}

const Slide = React.forwardRef<HTMLDivElement, SlideProps>(function Slide(
  props,
  ref
) {
  const {
    children,
    in: inProp,
    className,
    onEnter,
    onEntered,
    onExit,
    onExited,
    style,
    config,
    timeout,
    direction = 'down',
  } = props;
  const nodeRef = React.useRef<HTMLDivElement>(null);
  const handleRef = useForkRef(nodeRef, ref);
  const [translate, setTranslate] = React.useState<{ x: number; y: number }>();

  const springConfig = getSpringConfig(config, timeout);

  const styles = useSpring({
    from: translate,
    to: inProp ? { x: 0, y: 0 } : translate,
    config: springConfig,
    onStart: () => {
      if (inProp && onEnter) {
        onEnter();
      }
      if (!inProp && onExit) {
        onExit();
      }
    },
    onRest: () => {
      if (inProp && onEntered) {
        onEntered();
      }
      if (!inProp && onExited) {
        onExited();
      }
    },
  });

  React.useEffect(() => {
    if (nodeRef.current) {
      setTranslate(getTranslateValue(direction, nodeRef.current));
    }
  }, [direction]);

  return (
    <animated.div
      className={className}
      ref={handleRef}
      style={{ ...styles, ...style }}
    >
      {children}
    </animated.div>
  );
});

export default Slide;
