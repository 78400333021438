import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { Logo as LogoComponent } from 'src/components/Logo';

const PREFIX = 'MainLayout-AppBar--Logo';

const classNames = {
  root: `${PREFIX}__root`,
};

const LogoRoot = styled(Link)({
  [`&.${classNames.root}`]: {
    display: 'block',
    width: 152,
    fontSize: 0,
    flexShrink: 0,
  },
});

export interface LogoProps {
  className?: string | undefined;
}

const Logo: React.FC<LogoProps> = ({ className }) => {
  return (
    <LogoRoot className={clsx(classNames.root, className)} to="/repository">
      <LogoComponent />
    </LogoRoot>
  );
};

export default Logo;
