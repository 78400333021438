import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { AppState } from 'src/app/store';
import favoritesService, { FavoritesParams } from 'src/services/favorites';
import repositoryService from 'src/services/repository';
import { Loading } from 'src/shared/constants';
import {
  BookOrFolder,
  ID,
  ListData,
  PaginationParams,
} from 'src/shared/interfaces';
import { getErrorMessage } from 'src/utils';

interface State {
  loading: Loading;
  page: number;
  hasNextPage: boolean;
}

type TabKey = 'all' | 'books' | 'folders';

type FetchArgs = {
  type?: 1 | 2 | undefined;
  baseId: ID;
  keyword?: string;
} & PaginationParams;

const name = 'repository/details/children';

const entityAdapter = createEntityAdapter<BookOrFolder>({
  selectId: (bookOrFolder) => bookOrFolder.id,
});

/**
 * 列表
 */
export const fetchBookOrFolder = createAsyncThunk<
  ListData<BookOrFolder>,
  Partial<FetchArgs>
>(`${name}/fetch`, async (data, { rejectWithValue }) => {
  try {
    const response = await repositoryService.fetchBookOrFolder(data);
    return response.data;
  } catch (err) {
    const message = getErrorMessage(err);
    return rejectWithValue(message);
  }
});

/**
 * 新建知识本 or 文件夹
 */
export const createBookOrFolder = createAsyncThunk<
  void,
  Partial<BookOrFolder>,
  { rejectValue: string }
>(`${name}/create`, async (data, { rejectWithValue }) => {
  try {
    await repositoryService.createBookOrFolder(data);
  } catch (err) {
    const message = getErrorMessage(err);
    return rejectWithValue(message);
  }
});

/**
 * 添加/取消收藏
 */
export const collect = createAsyncThunk<
  ID,
  Partial<FavoritesParams> & { subtype: 1 | 2 | undefined },
  { rejectValue: { message: string } }
>(`${name}/collect`, async (params, { rejectWithValue }) => {
  try {
    const response = await favoritesService.favorite(params);
    return response.data.id;
  } catch (err) {
    const message = err.isAxiosError ? err.response.data : err.message;
    return rejectWithValue(message);
  }
});

/**
 * 更新知识本/文件夹
 */
export const updateBookOrFolder = createAsyncThunk<
  Partial<BookOrFolder>,
  { id: ID; data: Partial<BookOrFolder> },
  { rejectValue: string }
>(`${name}/update`, async ({ id, data }, { rejectWithValue }) => {
  try {
    const response = await repositoryService.updateBookOrFolder(id, data);
    return response.data;
  } catch (err) {
    const message = getErrorMessage(err);
    return rejectWithValue(message);
  }
});

/**
 * 删除知识本/文件夹
 */
export const removeBookOrFolder = createAsyncThunk<
  void,
  { id: ID; type: 1 | 2 | undefined }
>(`${name}/remove`, async ({ id }, { rejectWithValue }) => {
  try {
    await repositoryService.removeBookOrFolder(id);
  } catch (err) {
    const message = getErrorMessage(err);
    return rejectWithValue(message);
  }
});

const slice = createSlice({
  name,
  initialState: {
    keyword: '',
    refresh: false,
    tab: 'all',
    all: entityAdapter.getInitialState<State>({
      loading: Loading.idle,
      page: 1,
      hasNextPage: true,
    }),
    books: entityAdapter.getInitialState<State>({
      loading: Loading.idle,
      page: 1,
      hasNextPage: true,
    }),
    folders: entityAdapter.getInitialState<State>({
      loading: Loading.idle,
      page: 1,
      hasNextPage: true,
    }),
  },
  reducers: {
    setPage(state, action: PayloadAction<[TabKey, number]>) {
      const [tab, page] = action.payload;
      state[tab].page = page;
    },
    setKeyword(state, action) {
      state.keyword = action.payload;
    },
    setRefresh(state, action) {
      state.refresh = action.payload;
    },
    setTab(state, action) {
      state.tab = action.payload;
    },
    reset(state) {
      state.keyword = '';
      state.refresh = false;
      state.tab = 'all';
      state.all = entityAdapter.getInitialState<State>({
        loading: Loading.idle,
        page: 1,
        hasNextPage: true,
      });
      state.books = entityAdapter.getInitialState<State>({
        loading: Loading.idle,
        page: 1,
        hasNextPage: true,
      });
      state.folders = entityAdapter.getInitialState<State>({
        loading: Loading.idle,
        page: 1,
        hasNextPage: true,
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBookOrFolder.pending, (state, action) => {
      const { type } = action.meta.arg;
      const tabKey = getTabKey(type);
      state[tabKey].loading = Loading.pending;
    });

    builder.addCase(fetchBookOrFolder.fulfilled, (state, action) => {
      const { total_pages } = action.payload.meta.pagination;
      const { type } = action.meta.arg;
      const tabKey = getTabKey(type);
      state[tabKey].loading = Loading.fulfilled;
      state[tabKey].hasNextPage = state[tabKey].page < total_pages;
      if (state[tabKey].page === 1) {
        entityAdapter.setAll(state[tabKey], action.payload.data);
      } else {
        entityAdapter.upsertMany(state[tabKey], action.payload.data);
      }
    });

    builder.addCase(fetchBookOrFolder.rejected, (state, action) => {
      const { type } = action.meta.arg;
      const tabKey = getTabKey(type);
      state[tabKey].loading = Loading.rejected;
      state[tabKey].page = 1;
      state[tabKey].hasNextPage = true;
      entityAdapter.removeAll(state[tabKey]);
    });

    builder.addCase(createBookOrFolder.fulfilled, (state, action) => {
      const { type } = action.meta.arg;
      const tabKey = getTabKey(type);
      state[tabKey].page = 1;
      state.all.page = 1;
    });

    builder.addCase(collect.fulfilled, (state, action) => {
      const { subtype, resourceId } = action.meta.arg;
      const tabKey = getTabKey(subtype);
      if (resourceId) {
        entityAdapter.updateOne(state[tabKey], {
          id: resourceId,
          changes: { collect: action.payload },
        });
        entityAdapter.updateOne(state.all, {
          id: resourceId,
          changes: { collect: action.payload },
        });
      }
    });

    builder.addCase(updateBookOrFolder.fulfilled, (state, action) => {
      const { data, id: resourceId } = action.meta.arg;
      const tabKey = getTabKey(data.type);
      if (resourceId) {
        entityAdapter.updateOne(state[tabKey], {
          id: resourceId,
          changes: action.payload,
        });
        entityAdapter.updateOne(state.all, {
          id: resourceId,
          changes: action.payload,
        });
      }
    });

    builder.addCase(removeBookOrFolder.fulfilled, (state, action) => {
      const { type, id: resourceId } = action.meta.arg;
      const tabKey = getTabKey(type);
      if (resourceId) {
        entityAdapter.removeOne(state[tabKey], resourceId);
        entityAdapter.removeOne(state.all, resourceId);
      }
    });
  },
});

export default slice.reducer;

export const { setPage, setKeyword, reset, setTab } = slice.actions;

export const selectAll = (tabKey: TabKey) => {
  const { selectAll } = entityAdapter.getSelectors(
    (state: AppState) => state.repository.details.children[tabKey]
  );
  return selectAll;
};

export const selectById = (tabKey: TabKey, id: ID) =>
  createSelector(
    (state: AppState) => {
      const { selectById } = entityAdapter.getSelectors(
        (state: AppState) => state.repository.details.children[tabKey]
      );
      return selectById(state, id);
    },
    (current: BookOrFolder | undefined) => current
  );

export const selectPage = (tabKey: TabKey) => (state: AppState) => {
  return state.repository.details.children[tabKey].page;
};

export const selectHasNextPage = (tabKey: TabKey) => (state: AppState) => {
  return state.repository.details.children[tabKey].hasNextPage;
};

export const selectIsLoading = (tabKey: TabKey) => (state: AppState) => {
  return state.repository.details.children[tabKey].loading === Loading.pending;
};

export const selectKeyword = (state: AppState) => {
  return state.repository.details.children.keyword;
};

export const selectRefresh = (state: AppState) => {
  return state.repository.details.children.refresh;
};

/**
 * Tab Key
 * @param type
 * @returns
 */
export function getTabKey(type: 1 | 2 | undefined): TabKey {
  switch (type) {
    case 1: {
      return 'books';
    }
    case 2: {
      return 'folders';
    }
    default: {
      return 'all';
    }
  }
}
