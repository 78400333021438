export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export interface Breakpoints {
  keys: Breakpoint[];
  /**
   * Each breakpoint (a key) matches with a fixed screen width (a value).
   * @default {
   *    xs: 0,
   *    sm: 960,
   *    md: 1280,
   *    lg: 1600,
   *    xl: 1920,
   * }
   */
  values: { [Key in Breakpoint]: number };
  /**
   * @param key - A breakpoint key (`xs`, `sm`, etc.) or a screen width number in px.
   * @returns A media query string ready to be used with most styling solutions, which matches screen widths greater than the screen size given by the breakpoint key (inclusive).
   */
  up: (key: Breakpoint) => string;
  /**
   * @param key - A breakpoint key (`xs`, `sm`, etc.) or a screen width number in px.
   * @returns A media query string ready to be used with most styling solutions, which matches screen widths less than the screen size given by the breakpoint key (exclusive).
   */
  down: (key: Breakpoint) => string;
  /**
   * @param start - A breakpoint key (`xs`, `sm`, etc.) or a screen width number in px.
   * @param end - A breakpoint key (`xs`, `sm`, etc.) or a screen width number in px.
   * @returns A media query string ready to be used with most styling solutions, which matches screen widths greater than
   *          the screen size given by the breakpoint key in the first argument (inclusive) and less than the screen size given by the breakpoint key in the second argument (exclusive).
   */
  between: (start: Breakpoint, end: Breakpoint) => string;
  /**
   * @param key - A breakpoint key (`xs`, `sm`, etc.) or a screen width number in px.
   * @returns A media query string ready to be used with most styling solutions, which matches screen widths starting from
   *          the screen size given by the breakpoint key (inclusive) and stopping at the screen size given by the next breakpoint key (exclusive).
   */
  only: (key: Breakpoint) => string;
  /**
   * @param key - A breakpoint key (`xs`, `sm`, etc.).
   * @returns A media query string ready to be used with most styling solutions, which matches screen widths stopping at
   *          the screen size given by the breakpoint key (exclusive) and starting at the screen size given by the next breakpoint key (inclusive).
   */
  not: (key: Breakpoint) => string;
}

export interface BreakpointsOptions extends Partial<Breakpoints> {
  /**
   * The increment divided by 100 used to implement exclusive breakpoints.
   * For example, `step: 5` means that `down(500)` will result in `'(max-width: 499.95px)'`.
   * @default 5
   */
  step?: number | undefined;
  /**
   * The unit used for the breakpoint's values.
   * @default 'px'
   */
  unit?: string | undefined;
}

export default function createBreakpoints(breakpoints: BreakpointsOptions): Breakpoints {
  const {
    values = {
      xs: 0,
      sm: 960,
      md: 1280,
      lg: 1600,
      xl: 1920,
    },
    unit = 'px',
    step = 5,
    ...other
  } = breakpoints;

  const keys = Object.keys(values) as Breakpoint[];

  function up(key: Breakpoint) {
    return `@media (min-width:${values[key]}${unit})`;
  }

  function down(key: Breakpoint) {
    return `@media (max-width:${values[key] - step / 100}${unit})`;
  }

  function between(start: Breakpoint, end: Breakpoint) {
    return `@media (min-width:${values[start]}${unit}) and (max-width:${
      values[end] - step / 100
    }${unit})`;
  }

  function only(key: Breakpoint) {
    if (keys.indexOf(key) + 1 < keys.length) {
      return between(key, keys[keys.indexOf(key) + 1]);
    }
    return up(key);
  }

  function not(key: Breakpoint) {
    const index = keys.indexOf(key);
    if (index === 0) {
      return up(keys[1]);
    }
    if (index === keys.length - 1) {
      return down(keys[index]);
    }

    return between(key, keys[keys.indexOf(key) + 1]).replace('@media', '@media not all and');
  }

  return {
    keys,
    values,
    up,
    down,
    between,
    only,
    not,
    ...other,
  };
}
