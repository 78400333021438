import { createSvgIcon } from './utils';

export default createSvgIcon(
  [
    <circle cx="8" cy="9" r="2" key="1" />,
    <circle cx="8" cy="16" r="2" key="2" />,
    <circle cx="8" cy="23" r="2" key="3" />,
  ],
  'More'
);
