import { configureStore, Dispatch } from '@reduxjs/toolkit';

import rootReducer from './rootReducer';

export type AppStore = ReturnType<typeof configureAppStore>;
export type AppState = ReturnType<typeof rootReducer>;
export type AppDispatch = AppStore['dispatch'];
export type AppThunkApiConfig = {
  state?: AppState;
  dispatch?: Dispatch;
  extra?: unknown;
  rejectValue?: string;
  serializedErrorType?: unknown;
};

export function configureAppStore(preloadedState?: AppState) {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
  });

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./rootReducer', () => store.replaceReducer(rootReducer));
  }

  return store;
}
