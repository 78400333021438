import React from 'react';

import Title from './Title';
import Subdepartments from './Subdepartments';
import Toolbar from './Toolbar';
import List from './List';

const Members: React.FC = () => {
  return (
    <React.Fragment>
      <Title />
      <Subdepartments />
      <Toolbar />
      <List />
    </React.Fragment>
  );
};

export default Members;
