import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { AppState } from 'src/app/store';

import studyService from 'src/services/study';
import { Loading } from 'src/shared/constants';
import { ID, ListData, StudyRecord } from 'src/shared/interfaces';
import { getErrorMessage } from 'src/utils';

export type LabelOption = { label: string; value: number | string };

export interface State {
  folder: LabelOption | null;
  subfolder: LabelOption | null;
  keyword: string;
  loading: Loading;
  list: StudyRecord[];
  total: number;
}

const initialState: State = {
  folder: null,
  subfolder: null,
  keyword: '',
  loading: Loading.idle,
  list: [],
  total: 0,
};

type ThunkApiConfig = {
  rejectValue: string;
};

type FetchListExtraOptions = {
  page?: number;
  keyword?: string;
  sort?: string;
};

type FetchListOptions = {
  folder: ID | null | undefined;
  subfolder: ID | null | undefined;
  options: FetchListExtraOptions;
};

export const fetchList = createAsyncThunk<
  ListData<StudyRecord>,
  FetchListOptions,
  ThunkApiConfig
>('study/list', async ({ folder, subfolder, options }, { rejectWithValue }) => {
  try {
    const response = await studyService.fetchList(folder, subfolder, options);
    return response.data;
  } catch (err) {
    return rejectWithValue(getErrorMessage(err));
  }
});

const slice = createSlice({
  name: 'study',
  initialState,
  reducers: {
    setFolder: (state, action) => {
      state.folder = action.payload;
    },
    setSubfolder: (state, action) => {
      state.subfolder = action.payload;
    },
    setKeyword: (state, action) => {
      state.keyword = action.payload;
    },
    reset: (state) => {
      state.folder = null;
      state.subfolder = null;
      state.keyword = '';
      state.list = [];
      state.loading = Loading.idle;
      state.total = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchList.pending, (state) => {
      state.loading = Loading.pending;
    });
    builder.addCase(fetchList.fulfilled, (state, action) => {
      state.loading = Loading.fulfilled;
      state.list = action.payload.data;
      state.total = action.payload.meta.pagination.total;
    });
    builder.addCase(fetchList.rejected, (state, action) => {
      state.loading = Loading.rejected;
      state.list = [];
      state.total = 0;
    });
  },
});

export const { setFolder, setSubfolder, setKeyword, reset } = slice.actions;

export default slice.reducer;

// selectors
export const selectFetching = createSelector(
  (state: AppState) => state.study.loading,
  (loading) => loading === Loading.idle
);

export const selectLoading = createSelector(
  (state: AppState) => state.study.loading,
  (loading) => loading === Loading.pending
);
