import { Theme } from '@emotion/react';
import createBreakpoints from './createBreakpoints';

import createTransition from './createTransition';
import createTypography from './createTypography';

const themes: Theme = {
  palette: {
    common: {
      white: '#fff',
      black: '#000',
    },
    primary: {
      main: '#5a5aee',
      contrastText: '#fff',
    },
    error: {
      main: '#ff3b30',
      contrastText: '#fff',
    },
    warning: {
      main: '#fe805f',
      contrastText: '#fff',
    },
    divider: '#eee',
    icon: '#a3aab1',
    link: '#5a5aee',
    background: {
      default: '#fff',
      paper: '#f6f7fb',
      secondary: '#999',
      search: '#efefef',
    },
    text: {
      primary: '#333',
      secondary: '#999',
      disabled: '#ccc',
      hint: '#ccc',
    },
    action: {
      disabled: '#ccc',
    },
    secondary: {
      main: '',
      contrastText: '',
    },
    info: {
      main: '',
      contrastText: '',
    },
    success: {
      main: '#07C160',
      contrastText: '#fff',
    },
  },
  shadows: {
    regular: '0 0 16px 0 rgba(0, 0, 0, 0.1)',
  },
  typography: createTypography(),
  transitions: createTransition(),
  breakpoints: createBreakpoints({}),
};

if (process.env.NODE_ENV !== 'production') {
  (window as any).KWB_THEME = themes;
}

export default themes;
