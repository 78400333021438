import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { Loading } from 'src/shared/constants';
import { FavoriteType } from './constants';
import { selectEntities, fetchList, reset } from './slice';

export default function useFavorites(type: FavoriteType) {
  const dispatch = useAppDispatch();
  const entities = useAppSelector((state) => selectEntities(state, type));
  const loading = useAppSelector((state) => state.appBar.favorites.loading);

  useEffect(() => {
    console.log('useEffect');
    const promise = dispatch(fetchList(type));
    return () => {
      promise.abort();
      dispatch(reset());
    };
  }, [dispatch, type]);

  return { entities, loading: loading === Loading.pending };
}
