import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const pulse = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
`;

const ImageRoot = styled('div', { label: 'ImageRoot' })({
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.11)',
  overflow: 'hidden',
});

type ImageProps = {
  src: string | undefined;
  alt?: string | undefined;
  objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
  className?: string;
};

const Image = React.memo<ImageProps>(function Image({
  src,
  alt = '',
  className,
}) {
  return (
    <ImageRoot className={className}>
      {src && <img src={src} alt={alt} />}
    </ImageRoot>
  );
});

export default styled(Image, { label: 'Image' })<ImageProps>(
  ({ objectFit }) => ({
    '& > img': {
      width: '100%',
      height: '100%',
      ...(objectFit && { objectFit }),
    },

    '& .Image-loading': {
      height: '100%',
      overflow: 'hidden',
      position: 'relative',
      display: 'block',
      backgroundColor: 'rgba(0, 0, 0, 0.11)',
      animation: `${pulse} 1.6s linear 0.5s infinite`,
    },
  })
);
