import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import commonService from 'src/services/common';
import { Cover } from 'src/shared/interfaces';
import { getErrorMessage } from 'src/utils';

export interface State {
  covers: Cover[];
}

const name = 'config';

const initialState: State = {
  covers: [],
};

/**
 * 默认封面
 */
export const fetchCovers = createAsyncThunk<Cover[], void, { rejectValue: string }>(
  `${name}/covers`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await commonService.fetchCovers();
      return response.data;
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  }
);

const slice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCovers.fulfilled, (state, action) => {
        state.covers = action.payload;
      })
      .addCase(fetchCovers.rejected, (state) => {
        state.covers = [];
      });
  },
});

export default slice.reducer;
