import { httpClient } from 'src/utils';
import { ResourceType } from 'src/shared/constants';
import {
  HttpResponse,
  ID,
  Cover,
  ListHttpResponse,
} from 'src/shared/interfaces';

type RequestForAccessArgument = {
  resource: ResourceType;
  resourceId: ID;
  type: 0 | 1 | 2 | 3;
};

export type StsTokenData = {
  RequestId: string;
  AssumedRoleUser: { Arn: string; AssumedRoleId: string };
  Credentials: {
    SecurityToken: string;
    AccessKeyId: string;
    AccessKeySecret: string;
    Expiration: string;
  };
};

class CommonService {
  /**
   * 权限
   * @param resource
   * @param resourceId
   * @returns
   */
  async checkAuth(resource: ResourceType, resourceId: ID) {
    const response = await httpClient.post<HttpResponse<{ auth: 1 | 0 }>>(
      '/checkAuthority',
      {
        resource,
        resourceId,
      }
    );
    return response.data;
  }

  /**
   * 文件/文件夹权限
   * @param id
   */
  async checkFileAuth(id: ID) {
    const response = await httpClient.get<HttpResponse<any>>(
      `/authCheck/${id}`
    );
    return response.data;
  }

  async fetchDirectoryTree(id: ID) {
    const response = await httpClient.get<ListHttpResponse<any>>(
      `/directoryTree/${id}`
    );
    return response.data;
  }

  /** 默认封面 */
  async fetchCovers() {
    const response = await httpClient.get<HttpResponse<Cover[]>>(
      '/defaultImg/1'
    );
    return response.data;
  }

  /**
   * 申请权限
   * @param data
   * @returns
   */
  async requestForAccess(data: RequestForAccessArgument) {
    const response = await httpClient.post<HttpResponse<{}>>(
      '/authority',
      data
    );

    return response.data;
  }

  async downloadAsPDF(content: string) {
    const response = await httpClient.post<Blob>(
      '/pdf',
      { content },
      { responseType: 'blob' }
    );
    return response.data;
  }

  async getFileUrl(id: ID) {
    const response = await httpClient.get<
      HttpResponse<{ id: string | number; fileUrl: string }>
    >(`/file/url/${id}`);

    return response.data;
  }

  async getOssStsToken() {
    const response = await httpClient.get<HttpResponse<StsTokenData>>(
      '/getSTS'
    );
    return response.data;
  }
}

const commonService = new CommonService();

export default commonService;
