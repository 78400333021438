import styled from '@emotion/styled';

import { shouldForwardProp } from 'src/utils';

export default styled('div', { label: 'ListItemIcon', shouldForwardProp })(
  () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    marginRight: 10,
    fontSize: 18,
  })
);
