import React from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';

import { shouldForwardProp, composeClasses } from 'src/utils';

export interface ModalPaperProps<C extends React.ElementType = 'div'> {
  className?: string | undefined;
  style?: React.CSSProperties;
  square?: boolean;
  boxShadow?: boolean;
  as?: C;
  children?: React.ReactNode;
}

const classNamePrefix = 'ModalPaper';

const useUtilityClasses = (props: Pick<ModalPaperProps, 'square' | 'boxShadow'>) => {
  const { square, boxShadow } = props;
  const slots = {
    root: ['root', square && 'square', boxShadow && 'boxShadow'],
  };
  return composeClasses(classNamePrefix, slots);
};

const ModalPaperRoot = styled('div', {
  label: classNamePrefix,
  shouldForwardProp: (prop) => shouldForwardProp(prop) && prop !== 'square' && prop !== 'boxShadow',
})<Pick<ModalPaperProps, 'square' | 'boxShadow'>>(({ square = false, boxShadow = true }) => ({
  backgroundColor: '#fff',
  minWidth: 620,
  // maxHeight: 'calc(100vh - 300px)',
  border: '1px solid #eee',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  ...(!square && { borderRadius: 10 }),
  ...(boxShadow && { boxShadow: '0 0 30px 0 rgba(0, 0, 0, 0.1)' }),
}));

const ModalPaper = React.forwardRef(function ModalPaper<C extends React.ElementType = 'div'>(
  props: React.PropsWithChildren<ModalPaperProps<C>> & React.ComponentPropsWithoutRef<C>,
  ref: React.ComponentPropsWithRef<C>['ref']
) {
  const { className, style, square, boxShadow, children, as, ...rest } = props;

  const classes = useUtilityClasses({ square, boxShadow });

  return (
    <ModalPaperRoot
      className={clsx(classes.root, className)}
      ref={ref}
      style={style}
      as={as}
      {...rest}
    >
      {children}
    </ModalPaperRoot>
  );
});

export default ModalPaper;
