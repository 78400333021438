import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppState } from 'src/app/store';
import studyService from 'src/services/study';
import { getErrorMessage } from 'src/utils';

interface State {
  resourceId: string | number | null;
  recordId: string | number | null;
}

const initialState: State = { resourceId: null, recordId: null };

type CreateReturnType = {
  id: string | number;
};

export const createRecord = createAsyncThunk<
  CreateReturnType | null,
  void,
  { state: AppState; rejectValue: string }
>('study_record/create', async (_, { getState, rejectWithValue }) => {
  try {
    const { resourceId } = getState().studyRecord;
    if (resourceId) {
      const response = await studyService.createRecord(5, resourceId);
      console.log(response);
      return response.data;
    }
    return null;
  } catch (err) {
    return rejectWithValue(getErrorMessage(err));
  }
});

export const updateRecord = createAsyncThunk<
  void,
  number,
  { state: AppState; rejectValue: string }
>('study_record/update', async (duration, { getState, rejectWithValue }) => {
  try {
    const { recordId } = getState().studyRecord;
    if (recordId) {
      await studyService.updateRecord(recordId, duration);
    }
  } catch (err) {
    return rejectWithValue(getErrorMessage(err));
  }
});

const slice = createSlice({
  name: 'studyRecord',
  initialState,
  reducers: {
    setResource: (state, action) => {
      state.resourceId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createRecord.fulfilled, (state, action) => {
      state.recordId = action.payload?.id || null;
    });
  },
});

export const { setResource } = slice.actions;

export default slice.reducer;
