import React, { useEffect } from 'react';

import { Button, Select } from 'antd';
import styled from '@emotion/styled';
import * as Yup from 'yup';

import { Form, Formik, FormikHelpers } from 'formik';
import {
  Modal,
  ModalTitle,
  ModalBody,
  ModalActions,
  ModalPaper,
} from 'src/components/unstable_Modal';
import { Field, Input } from 'src/components/Fields';
import { Grow } from 'src/components/Transitions';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { fetchDetails as fetchRepositoryDetails } from 'src/pages/Repository/Details/slice';

import AuthField from './AuthField';
import { ID } from 'src/shared/interfaces';

const StyledButton = styled(Button)(({ theme }) => ({
  padding: '10px 24px',
  height: 44,
  borderColor: theme.palette.primary?.main,
}));

interface Step1Props {
  open?: boolean;
  onCancel?: () => void;
  onFinish?: (value: FormValues) => void;
  disabledAuth?: boolean;
  initialValues?: FormValues | undefined;
  baseId?: ID;
}

type FormValues = { name: string; authType: string };

const initialValues: FormValues = {
  name: '',
  authType: '1',
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('请填写资料包名称'),
});

const Step1: React.FC<Step1Props> = (props) => {
  const {
    open = false,
    onCancel,
    onFinish,
    disabledAuth = false,
    initialValues: initialValuesProp,
    baseId,
  } = props;

  const dispatch = useAppDispatch();
  const details = useAppSelector((state) => state.repository.details.data.data);

  const handleSubmit = async (values: FormValues, helper: FormikHelpers<FormValues>) => {
    await onFinish?.(values);
    helper.setSubmitting(false);
  };

  useEffect(() => {
    if (String(details?.id) !== String(baseId) && baseId) {
      dispatch(fetchRepositoryDetails(baseId));
    }
  }, [baseId, details, dispatch]);

  const authOptions = [
    { key: '1', label: '浏览权限', value: '1' },
    details?.manage === 1 && { key: '2', label: '编辑权限', value: '2' },
  ].filter((t): t is any => Boolean(t));

  return (
    <Modal center open={open} onClose={onCancel}>
      <Grow in={open}>
        <ModalPaper>
          <Formik
            initialValues={{ ...initialValues, ...initialValuesProp }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ isSubmitting }) => (
              <Form noValidate>
                <ModalTitle>分享</ModalTitle>
                <ModalBody>
                  <Field name="name" label="分享资料包名称" required>
                    <Input variant="outlined" placeholder="请输入名称" />
                  </Field>
                  <Field name="authType" label="分享权限" custom>
                    <AuthField disabled={disabledAuth} options={authOptions} />
                  </Field>
                </ModalBody>
                <ModalActions>
                  <StyledButton onClick={onCancel}>取消</StyledButton>
                  <StyledButton loading={isSubmitting} type="primary" htmlType="submit">
                    下一步
                  </StyledButton>
                </ModalActions>
              </Form>
            )}
          </Formik>
        </ModalPaper>
      </Grow>
    </Modal>
  );
};

export default Step1;
