export function toArray<T>(obj: Record<number, T> | undefined): T[] {
  if (!obj) return [];
  return Object.values(obj);
}

/**
 * 获取视频时长
 *
 * 单位：秒
 * @param file
 * @returns
 */
export function getVideoDuration(file: File): Promise<number> {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      resolve(video.duration);
    };
    video.src = window.URL.createObjectURL(file);
  });
}

/**
 * 获取音频时长
 *
 * 单位: 秒
 * @param file
 * @returns
 */
export function getAudioDuration(file: File): Promise<number> {
  return new Promise((resolve, reject) => {
    const audio = new Audio();
    audio.onloadedmetadata = () => {
      window.URL.revokeObjectURL(audio.src);
      resolve(audio.duration);
    };
    audio.src = window.URL.createObjectURL(file);
  });
}
