import React from 'react';
import { Navigate, Routes, Route } from 'react-router';

import { MAIN_MENUS, MANAGEMENT_MENUS, KNOWLEDGE_MENUS } from 'src/config/menus';
import PageLoading from 'src/components/PageLoading';
import AuthGuard from 'src/guard/Auth';
import ManagerGuard from 'src/guard/Manager/Manager';
import { AuthLayout, MainLayout, RepositoryAdminLayout } from 'src/layouts';
import { Recent as RecentExtra } from 'src/layouts/MainLayout';
import { Repository as RepositoryExtra } from 'src/layouts/MainLayout';
import RepositoryWrapper from 'src/pages/Repository/Details/Wrapper';
import QARoot from 'src/pages/QA';
import Watermark from 'src/layouts/Watermark';
import Container from 'src/components/Container';

import {
  AutoLoginPage,
  RedirectPage,
  NotFound,
  Login,
  ForgetPassword,
  Home,
  Favorites,
  RepositoryDetails,
  Drafts,
  Share,
  Review,
  KnowledgeBase,
  Backend,
  Trash,
  RepositorySettings,
  RepositoryOperations,
  KnowledgeBaseMember,
  KnowledgeBaseShare,
  Templates,
  Folder,
  Setting,
  Search,
  KnowledgeBooks,
  Study,
  QADetails,
  Ask,
  Answer,
  ReportAdmin,
  Follows,
  QAAdmin,
  ForceUpdatePassword
} from './elements';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/auth" element={<AuthLayout />}>
        <Route index element={<Navigate to="/auth/login" replace />} />
        <Route
          path="login"
          element={
            <React.Suspense fallback={<PageLoading />}>
              <Login />
            </React.Suspense>
          }
        />
        <Route
          path="forget-password"
          element={
            <React.Suspense fallback={<PageLoading />}>
              <ForgetPassword />
            </React.Suspense>
          }
        />
      </Route>
      <Route
        path="redirect"
        element={
          <React.Suspense fallback={<PageLoading />}>
            <RedirectPage />
          </React.Suspense>
        }
      />
      <Route
        path="autoLogin"
        element={
          <React.Suspense fallback={<PageLoading />}>
            <AutoLoginPage />
          </React.Suspense>
        }
      />
      <Route path="/">
        <Route index element={<Navigate to="/repository" replace />} />
        <Route element={<AuthGuard />}>
          <Route path="update-password" element={<MainLayout />}>
            <Route
              index
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <ForceUpdatePassword />
                </React.Suspense>
              }
            />
          </Route>
          <Route element={<MainLayout menus={MAIN_MENUS} extra={<RecentExtra />} />}>
            <Route
              path="repository"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <Home />
                </React.Suspense>
              }
            />
            <Route element={<Watermark />}>
              <Route
                path="share"
                element={
                  <React.Suspense fallback={<PageLoading />}>
                    <Share />
                  </React.Suspense>
                }
              />
            </Route>
            <Route element={<Watermark />}>
              <Route
                path="favorites"
                element={
                  <React.Suspense fallback={<PageLoading />}>
                    <Favorites />
                  </React.Suspense>
                }
              />
            </Route>
            <Route element={<Watermark />}>
              <Route
                path="draft"
                element={
                  <React.Suspense fallback={<PageLoading />}>
                    <Drafts />
                  </React.Suspense>
                }
              />
            </Route>
            <Route element={<Watermark />}>
              <Route
                path="follows"
                element={
                  <React.Suspense fallback={<PageLoading />}>
                    <Follows />
                  </React.Suspense>
                }
              />
            </Route>
            <Route path="management" />
          </Route>
          <Route path="repository">
            <Route path=":id" element={<MainLayout />}>
              <Route element={<RepositoryWrapper />}>
                <Route
                  index
                  element={
                    <React.Suspense fallback={<PageLoading />}>
                      <RepositoryDetails />
                    </React.Suspense>
                  }
                />
                <Route path="questions" element={<QARoot />}>
                  <Route
                    path="ask"
                    element={
                      <React.Suspense fallback={<PageLoading />}>
                        <Ask />
                      </React.Suspense>
                    }
                  />
                  <Route path=":questionId">
                    <Route element={<Watermark />}>
                      <Route
                        index
                        element={
                          <React.Suspense fallback={<PageLoading />}>
                            <QADetails />
                          </React.Suspense>
                        }
                      />
                    </Route>
                    <Route
                      path="answer"
                      element={
                        <React.Suspense fallback={<PageLoading />}>
                          <Answer />
                        </React.Suspense>
                      }
                    />
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route element={<Watermark />}>
              <Route path=":id" element={<MainLayout menus={KNOWLEDGE_MENUS} />}>
                <Route path="admin" element={<RepositoryAdminLayout />}>
                  <Route
                    index
                    element={
                      <React.Suspense fallback={<PageLoading />}>
                        <Review />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="member"
                    element={
                      <React.Suspense fallback={<PageLoading />}>
                        <KnowledgeBaseMember />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="share"
                    element={
                      <React.Suspense fallback={<PageLoading />}>
                        <KnowledgeBaseShare />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="template"
                    element={
                      <React.Suspense fallback={<PageLoading />}>
                        <Templates />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="study"
                    element={
                      <React.Suspense fallback={<PageLoading />}>
                        <Study />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="operate"
                    element={
                      <React.Suspense fallback={<PageLoading />}>
                        <RepositoryOperations />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="settings"
                    element={
                      <React.Suspense fallback={<PageLoading />}>
                        <RepositorySettings />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="recycle"
                    element={
                      <React.Suspense fallback={<PageLoading />}>
                        <Trash />
                      </React.Suspense>
                    }
                  />
                  <Route
                    path="report"
                    element={
                      <React.Suspense fallback={<PageLoading />}>
                        <ReportAdmin />
                      </React.Suspense>
                    }
                  />
                </Route>
              </Route>
            </Route>
          </Route>
          <Route path="management" element={<ManagerGuard />}>
            <Route element={<Watermark />}>
              <Route element={<MainLayout menus={MANAGEMENT_MENUS} extra={<RepositoryExtra />} />}>
                <Route
                  index
                  element={
                    <React.Suspense fallback={<PageLoading />}>
                      <Container>
                        <Review />
                      </Container>
                    </React.Suspense>
                  }
                />
                <Route
                  path="knowledge-base"
                  element={
                    <React.Suspense fallback={<PageLoading />}>
                      <KnowledgeBase />
                    </React.Suspense>
                  }
                />
                <Route
                  path="qa"
                  element={
                    <React.Suspense fallback={<PageLoading />}>
                      <QAAdmin />
                    </React.Suspense>
                  }
                />
                <Route
                  path="backend"
                  element={
                    <React.Suspense fallback={<PageLoading />}>
                      <Backend />
                    </React.Suspense>
                  }
                />
              </Route>
            </Route>
          </Route>
          <Route element={<Watermark />}>
            <Route path="folder" element={<MainLayout />}>
              <Route
                path=":id"
                element={
                  <React.Suspense fallback={<PageLoading />}>
                    <Folder />
                  </React.Suspense>
                }
              />
            </Route>
          </Route>
          <Route element={<Watermark />}>
            <Route path="book" element={<MainLayout disableHeaderGutter />}>
              <Route
                path=":id"
                element={
                  <React.Suspense fallback={<PageLoading />}>
                    <KnowledgeBooks />
                  </React.Suspense>
                }
              />
            </Route>
          </Route>
          <Route element={<MainLayout />}>
            <Route
              path="setting"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <Setting />
                </React.Suspense>
              }
            />
            <Route
              path="search"
              element={
                <React.Suspense fallback={<PageLoading />}>
                  <Search />
                </React.Suspense>
              }
            />
          </Route>
          <Route
            path="*"
            element={
              <React.Suspense fallback={<PageLoading />}>
                <NotFound />
              </React.Suspense>
            }
          />
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRoutes;
