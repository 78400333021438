import React from 'react';
import styled from '@emotion/styled';

import richTextStyles from 'src/shared/styles/richText';

const TextRenderRoot = styled('div')({ ...richTextStyles } as any);

const TextRender: React.FC = ({ children }) => {
  return <TextRenderRoot>{children}</TextRenderRoot>;
};

export default TextRender;
