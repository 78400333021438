import { createSlice } from '@reduxjs/toolkit';

import { AppState } from './store';

type State = {
  items: { path: string; label: string }[];
};

const initialState: State = { items: [] };

const slice = createSlice({
  name: 'breadcrumbs',
  initialState,
  reducers: {
    setBreadcrumbs(state, action) {
      state.items = action.payload;
    },
  },
});

export default slice.reducer;

export const { setBreadcrumbs } = slice.actions;

export const selectBreadcrumbs = (state: AppState) => state.breadcrumbs.items;
