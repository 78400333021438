import styled from '@emotion/styled';

const SeparatorRoot = styled('div', { label: 'SeparatorRoot' })({
  paddingLeft: 8,
  paddingRight: 8,
  fontSize: 14,
  color: '#333',
});

const Separator: React.FC = () => {
  return <SeparatorRoot>至</SeparatorRoot>;
};

export default Separator;
