import React, { useRef, useContext, useMemo, useEffect } from 'react';
import styled from '@emotion/styled';
import OSS from 'ali-oss';
import { batch } from 'react-redux';
import { message } from 'antd';

import { IconButton } from 'src/components/IconButton';
import { Upload as UploadIcon } from 'src/components/Icons';

import {
  UploadContext,
  setUploadFiles,
  setDrawerOpen,
  setDrawerSize,
  UploadDrawerSize,
  UploadStatus,
  setUploadStatus,
  setUploadProgress,
  cancelUploadFile,
  setUploadSpeed,
  setDrawerShowTip,
} from './Provider';
import { Typography } from 'src/components/Typography';
import { extname, md5 } from 'src/utils/file';
import draftService from 'src/services/draft';
import { usePrevious } from 'src/hooks';
import { useDialogContext } from 'src/components/Dialog';

import { getAudioDuration, getVideoDuration, toArray } from './utils';
import commonService, { StsTokenData } from 'src/services/common';
import { UploadTask } from './Provider/state';
import { format } from 'date-fns';

const PREFIX = 'RepositoryFileUpload';

const classes = {
  root: `${PREFIX}-root`,
  control: `${PREFIX}-control`,
};

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [`& .${classes.control}`]: {
    display: 'none',
  },
}));

const Upload: React.FC<{
  folder?: any;
  onFinished?: (() => void) | undefined;
  canUpload?: boolean;
}> = ({ folder, onFinished, canUpload = true }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const uploaderInstance = useRef<Record<string, OSS>>({});
  const stsToken = useRef<StsTokenData | null>(null);
  const uploadCancelInstance = useRef<Map<number, () => Promise<OSS.NormalSuccessResponse>>>(
    new Map()
  );
  const { openDialog } = useDialogContext();

  const { dispatch, state } = useContext(UploadContext);

  const prevTasks = usePrevious(toArray(state.tasks));

  const hasFinished = useMemo(() => {
    if (state.tasks) {
      return Object.values(state.tasks).every((item) => item.status > UploadStatus.Uploading);
    }
    return false;
  }, [state.tasks]);

  useEffect(() => {
    if (hasFinished) {
      onFinished?.();
    }
  }, [hasFinished, onFinished]);

  const handleClick = () => {
    if (!canUpload) {
      openDialog({
        variant: 'warning',
        content: '您没有权限上传文件',
        type: 'tip',
      });
    } else {
      if (inputRef.current) {
        inputRef.current.click();
      }
    }
  };

  useEffect(() => {
    const getMediaFileDuration = async (file: File) => {
      let duration = 0;
      if (/^video\//.test(file.type)) {
        duration = await getVideoDuration(file);
      }
      if (/^audio\//.test(file.type)) {
        duration = await getAudioDuration(file);
      }

      return duration;
    };

    const uploadSingleFile = async (task: UploadTask) => {
      const { id, file, progress, owner } = task;
      dispatch(setUploadStatus(id, UploadStatus.Prepare));
      let duration = await getMediaFileDuration(file);
      const hash = await md5(file);
      dispatch(setUploadStatus(id, UploadStatus.Uploading));
      try {
        if (stsToken.current) {
          const client = new OSS({
            bucket: 'zdkwb',
            accessKeyId: stsToken.current.Credentials.AccessKeyId,
            accessKeySecret: stsToken.current.Credentials.AccessKeySecret,
            stsToken: stsToken.current.Credentials.SecurityToken,
            secure: process.env.NODE_ENV === 'production',
          });
          uploaderInstance.current[id] = client;
          let prevProgress = progress;
          let timestamps = Date.now();
          const ext = extname(file);
          const date = format(new Date(), 'yyyy-MM');
          let filename = `${date}/${hash}${ext}`;
          if (process.env.NODE_ENV !== 'production') {
            filename = `test/${filename}`;
          }
          const result = await client.multipartUpload(filename, file, {
            mime: file.type,
            parallel: 1,
            progress: (percent, checkpoint) => {
              // 设置取消上传函数
              if (!uploadCancelInstance.current.has(id)) {
                uploadCancelInstance.current.set(id, () =>
                  client.abortMultipartUpload(filename, checkpoint.uploadId)
                );
              }

              // 上传进度
              dispatch(setUploadProgress(id, percent >= 1 ? 0.99 : percent));

              // 上传速度
              if (Date.now() - timestamps > 1000) {
                const duration = (Date.now() - timestamps) / 1000;
                const speed = ((percent - prevProgress) * file.size) / duration;
                dispatch(setUploadSpeed(id, speed));
                prevProgress = percent;
                timestamps = Date.now();
              }
            },
          });
          const url = `https://zdkwb.oss-cn-hangzhou.aliyuncs.com/${result.name}`;
          await draftService.uploadFile(
            {
              baseId: owner.baseId,
              baseFolderId: owner.baseFolderId,
              resourceDirectoryId: owner.parentFolder,
              length: duration,
              fileHash: hash,
              fileName: file.name,
              fileSize: file.size,
              fileUrl: url,
            } as any,
            {}
          );
          batch(() => {
            dispatch(setUploadProgress(id, 1));
            dispatch(setUploadStatus(id, UploadStatus.Success));
            dispatch(setDrawerShowTip(true));
          });
        }
      } catch (e) {
        dispatch(setUploadStatus(id, UploadStatus.Failed));
      }
      if (uploadCancelInstance.current.has(id)) {
        uploadCancelInstance.current.delete(id);
      }
    };

    const upload = async () => {
      if (state.tasks) {
        const hasNewTask = toArray(state.tasks).some(
          (item) => !prevTasks.map((item) => item.id).includes(item.id)
        );

        if (hasNewTask) {
          if (!stsToken.current) {
            const response = await commonService.getOssStsToken();
            stsToken.current = response.data;
          }
          const tasks = toArray(state.tasks).filter((t) => t.status === UploadStatus.Pending);
          console.log(tasks);
          for (const task of tasks) {
            uploadSingleFile(task);
          }
        }
      }
    };

    upload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tasks, dispatch]);

  useEffect(() => {
    // 取消上传任务
    if (state.tasks) {
      toArray(state.tasks)
        .filter((task) => task.status === UploadStatus.Cancel)
        .forEach(async (task) => {
          const { id } = task;
          dispatch(cancelUploadFile(id));
          if (uploadCancelInstance.current.get(id)) {
            const cancel = uploadCancelInstance.current.get(id);
            await cancel?.();
            uploadCancelInstance.current.delete(id);
          }
        });
    }
  }, [state.tasks, dispatch]);

  useEffect(() => {
    // 关闭上传窗口时取消说有正在进行的上传任务
    if (!state.open) {
      //@ts-expect-error
      for (const key of uploadCancelInstance.current.keys()) {
        const cancel = uploadCancelInstance.current.get(key);
        cancel?.();
      }
      uploadCancelInstance.current.clear();
    }
  }, [state.open]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files ? Array.from(event.target.files) : null;
    batch(() => {
      dispatch(setUploadFiles(files, folder));
      dispatch(setDrawerOpen(true));
      dispatch(setDrawerSize(UploadDrawerSize.Maximize));
    });
    event.target.value = '';
  };

  return (
    <Root className={classes.root}>
      <input
        type="file"
        className={classes.control}
        ref={inputRef}
        onChange={handleChange}
        multiple
      />
      <IconButton icon={<UploadIcon />} onClick={handleClick}>
        <Typography>上传文件</Typography>
      </IconButton>
    </Root>
  );
};

export default React.memo(Upload);
