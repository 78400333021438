import { withProps, CodeBlockElement } from '@udecode/plate';

import { CSSProp } from 'styled-components';
import styles from 'src/shared/styles/richText';

const Codeblock = withProps(CodeBlockElement, {
  styles: {
    root: styles.pre as CSSProp,
  },
});

export default Codeblock;
