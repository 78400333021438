import { httpClient } from 'src/utils';

import {
  ID,
  ListHttpDataResponse,
  Timestamp,
  ListHttpResponse,
  HttpResponse,
} from 'src/shared/interfaces';

export interface CollectState {
  type?: number;
  resource: number;
  content: string;
  baseFolderType: number;
  baseFolderId: number;
  id: ID;
  name: string;
  img: string;
  introduction: string;
  notice: string;
  isOpen: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | number;
}

export interface HistoryState {
  id: ID;
  userId: ID;
  baseId: ID;
  resourceId: ID;
  baseFolderId: ID;
  resourcePageId: ID;
  isDirectory: ID;
  resourceDirectoryId: ID;
  resources: string;
  resource: number;
  title: string;
  name: string;
  baseFolderName: string;
  baseName: string;
  content: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: number;
}
class AppBarService {
  /**
   * 收藏夹
   * @param params
   * @returns
   */
  async fetchCollect(params: { type: number }) {
    let response = await httpClient.get<ListHttpDataResponse<CollectState>>(
      '/head/collect',
      {
        params,
      }
    );
    return response.data;
  }
  /**
   * 最近历史
   * @param params
   * @returns
   */
  async fetchHistory(params: { type: number }) {
    let response = await httpClient.get<ListHttpDataResponse<HistoryState>>(
      '/head/recently',
      {
        params,
      }
    );
    return response.data;
  }

  // 全局搜索
  async globalSearch(data: Partial<ISearchParams>) {
    let response = await httpClient.post<ListHttpResponse<ISearch>>(
      '/search',
      data
    );
    return response.data;
  }

  // 消息列表
  async getMessage(params: Partial<IMessageParams>) {
    let response = await httpClient.get<ListHttpResponse<IMessage>>(
      '/message',
      {
        params,
      }
    );
    return response.data;
  }

  // 设置已读
  async setMessage(data: { id: string }) {
    let response = await httpClient.post<HttpResponse<{}>>('/message', data);
    return response.data;
  }

  // 知识库成员部门删除
  async removeMessage(data: Partial<IRemoveMessageParams>) {
    const response = await httpClient.delete<HttpResponse<{}>>(
      `/message/${data.id}`,
      {
        params: { all: data.all },
      }
    );
    return response.data;
  }
}
export interface IRemoveMessageParams {
  id: string;
  all: string;
}
export interface IMessage extends Timestamp {
  userName: string;
  joinName: string;
  id: ID;
  userId: ID;
  joinUserId: ID;
  type: ID;
  baseId: ID;
  baseFolderId: ID;
  resource: ID;
  resourceId: ID;
  baseName: string;
  name: string;
  read: ID;
  baseFolderType: ID;
}
export interface IMessageParams {
  page: number;
  limit: number;
  type: number;
}
export interface ISearchParams {
  keyword: string;
  userName: string;
  type: string;
  createdStartTime: string;
  createdEndTime: string;
  editStartTime: string;
  editEndTime: string;
  page: number;
  limit: number;
}
export interface ISearch extends Timestamp {
  id: ID;
  name: string;
  baseId: ID;
  baseName: string;
  content: string;
  resource: ID;
  baseFolderType: ID;
  baseFolderId: ID;
  userName: string;
}
export default new AppBarService();
