import {
  DepartmentItem,
  ID,
  ListHttpResponse,
  Member,
} from 'src/shared/interfaces';
import { httpClient } from 'src/utils';

class DepartmentService {
  baseUrl = '/department';

  /**
   * 部门列表
   * @param withUser 是否查询部门成员
   * @returns
   */
  async list(withUser: boolean = false) {
    const response = await httpClient.get<ListHttpResponse<DepartmentItem>>(
      this.baseUrl,
      {
        params: { withUser: withUser ? 1 : undefined },
      }
    );
    return response.data;
  }

  /**
   * 部门详情
   * @param id
   * @returns
   */
  async details(id: ID, limit: number = 16) {
    const response = await httpClient.get<ListHttpResponse<Member>>(
      `${this.baseUrl}/${id}`,
      { params: { limit } }
    );
    return response.data;
  }
}

const departmentService = new DepartmentService();

export default departmentService;
