import React, { useEffect } from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

import {
  shouldForwardProp,
  getResourceIcon,
  getResourceBaseUrl,
  getResourceType,
} from 'src/utils';
import { ellipsis } from 'src/shared/styles';
import { Scrollbar } from 'src/components/Scrollbar';

import { List } from 'antd';

import { HistoryState } from 'src/services/appBar';
import { ID } from 'src/shared/interfaces';

import { useAppDispatch, useAppSelector } from 'src/hooks';
import {
  TabKey,
  TabKeyEnum,
  fetchHistory,
  selectAll,
  selectIsLoading,
} from './slice';

interface DocumentsProps {
  className?: string;
  tabKey: TabKey;
}

const Documents = React.memo<DocumentsProps>(function Documents({
  className,
  tabKey,
}) {
  const dispatch = useAppDispatch();
  const navigator = useNavigate();

  const dataSource = useAppSelector(selectAll(tabKey));
  const loading = useAppSelector(selectIsLoading(tabKey));

  const getIcon = (resource: number) => {
    const { component: Icon = React.Fragment, color } =
      getResourceIcon(resource);
    return <Icon color={color} />;
  };

  useEffect(() => {
    dispatch(
      fetchHistory({
        type: TabKeyEnum[tabKey],
      })
    );
  }, [dispatch, tabKey]);

  const handleItemClick = (item: HistoryState) => {
    const resourceType = getResourceType(item.resource);
    const baseUrl = getResourceBaseUrl(resourceType);
    let query: ID = '';
    let restFull: ID = '';

    if (item.resource === 1) {
      restFull = item.id;
    } else {
      restFull = item.id;
      if (item.resource >= 4) {
        restFull = item.baseFolderId || '';
        if (item.resource === 4) {
          query = item.resourceId;
        } else {
          query = item.resourceDirectoryId;
        }
      }
    }

    navigator(`/${baseUrl}/${restFull}?pageId=${query}`, { replace: false });
  };

  return (
    <Scrollbar>
      <List
        loading={loading}
        className={clsx('Documents-root', className)}
        dataSource={dataSource}
        renderItem={(item) => (
          <ListItem
            className={clsx('ListItem-root')}
            onClick={() => handleItemClick(item)}
          >
            <div className="ListItem-header">
              <div className="listItem-header-left">
                <div className="ListItem-icon">{getIcon(item.resource)}</div>
                <div className="ListItem-title">
                  {item.title ? item.title : item.name}
                </div>
              </div>
              {item.baseFolderName && (
                <div className="ListItem-title">{item.baseFolderName}</div>
              )}
            </div>
            {item.content && (
              <div
                className="ListItem-description"
                dangerouslySetInnerHTML={{ __html: item?.content || '' }}
              ></div>
            )}
          </ListItem>
        )}
      />
    </Scrollbar>
  );
});

export default Documents;

const ListItem = styled('div', { label: 'ListItem', shouldForwardProp })(
  ({ theme }) => ({
    padding: 10,
    margin: '0 10px',
    borderRadius: 10,
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.standard,
    }),
    '&.ListItem-root': {
      cursor: 'pointer',
    },
    '&.ListItem-root:hover': {
      backgroundColor: theme.palette.background?.paper,
      transition: theme.transitions.create('background-color', {
        duration: theme.transitions.duration.standard,
      }),
    },

    '&.ListItem-root .ListItem-header': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: 18,
      '.listItem-header-left': {
        display: 'flex',
        alignItems: 'center',
      },
      '& .ListItem-icon': {
        display: 'flex',
        alignItems: 'center',
      },

      '& .ListItem-title': {
        fontSize: 16,
        color: theme.palette.text?.primary,
        marginLeft: 10,
      },
    },

    '&.ListItem-root .ListItem-description': {
      marginTop: 10,
      ...ellipsis,
      marginLeft: 26,
      fontSize: 16,
      color: theme.palette.text?.secondary,
    },
  })
);
