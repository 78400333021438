import { createContext } from 'react';

export type Context = {
  collapsed?: boolean;
};

const MenuContext = createContext<Context>({ collapsed: false });

if (process.env.NODE_ENV !== 'production') {
  MenuContext.displayName = 'MenuContext';
}

export default MenuContext;
