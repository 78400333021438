import React from 'react';

import styled from '@emotion/styled';
import clsx from 'clsx';
import { formControlState, useFormControl } from 'src/components/FormControl';

import { composeClasses, shouldForwardProp } from 'src/utils';

export const classNamePrefix = 'Input';

const useUtilityClasses = (props: {
  error?: boolean;
  size?: 'large' | 'middle' | 'small' | undefined;
}) => {
  const { error, size = 'large' } = props;

  const slots = {
    root: ['root', error && 'error', size],
  };

  return composeClasses(classNamePrefix, slots);
};

const InputRoot = styled('input', {
  shouldForwardProp: (prop) => shouldForwardProp(prop) && prop !== 'size',
})<{ size?: any }>(({ theme, size = 'large' }) => ({
  boxSizing: 'border-box',
  position: 'relative',
  display: 'inline-block',

  width: '100%',
  minWidth: 0,
  height: 40,
  color: theme.palette.text?.primary,
  fontSize: 14,
  borderRadius: 0,
  outline: 0,
  border: 'none',
  borderBottom: '1px solid #eee',
  backgroundColor: '#fff',
  transition: theme.transitions.create('all', {
    duration: theme.transitions.duration.standard,
  }),
  ...(size === 'small' && { height: 24, fontSize: 12 }),
  ...(size === 'middle' && { height: 32, fontSize: 14 }),
  '&:placeholder': {
    color: theme.palette.text?.secondary,
  },
  '&:placeholder-shown': {
    textOverflow: 'ellipsis',
  },
  '&:hover': {
    borderColor: theme.palette.primary?.main,
  },

  [`&.${classNamePrefix}-error`]: {
    borderColor: theme.palette.error?.main,
  },
}));

const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { className, size = 'large', ...rest } = props;

  const formControl = useFormControl();

  const fcs = formControlState({
    props,
    formControl: formControl,
    states: ['disabled', 'error', 'required'],
  });

  const classes = useUtilityClasses({ error: fcs.error, size });

  return (
    <InputRoot
      {...rest}
      ref={ref}
      size={size as any}
      aria-invalid={fcs.error}
      disabled={fcs.disabled}
      required={fcs.required}
      className={clsx(classes.root, className)}
    />
  );
});

export default Input;

export interface InputProps {
  autoComplete?: string;
  autoFocus?: boolean;
  className?: string | undefined;
  defaultValue?: string | undefined;
  disabled?: boolean;
  error?: boolean;
  id?: string;
  name?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onKeyDown?: React.KeyboardEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  type?: string;
  maxLength?: number | undefined;
  value?: string | undefined;
  size?: 'large' | 'middle' | 'small' | undefined;
}
