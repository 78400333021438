import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'src/app/store';
import packService, { ShareArgs } from 'src/services/pack';
import { Loading, ResourceType } from 'src/shared/constants';

import { ID, ListData, PackDetail } from 'src/shared/interfaces';
import { getErrorMessage } from 'src/utils';

const name = 'packDetails';

type ThunkApi = { rejectValue: string };

type ShareType = 'receive' | 'send';

/**
 * 资料包详情
 */
export const fetchDetails = createAsyncThunk<
  ListData<PackDetail>,
  { type?: ShareType; id: ID | undefined; keyword?: string },
  ThunkApi
>(`${name}/details`, async ({ id, keyword }, { rejectWithValue }) => {
  try {
    const response = await packService.details({ id, keyword });
    return response.data;
  } catch (err) {
    return rejectWithValue(getErrorMessage(err));
  }
});

type AddToPackArgs = { resource: ResourceType; resourceId: ID };

/**
 * 添加到资料包
 */
export const addToPack = createAsyncThunk<PackDetail, AddToPackArgs, ThunkApi>(
  `${name}/add`,
  async ({ resource, resourceId }, { rejectWithValue }) => {
    try {
      const response = await packService.add(resource, resourceId);
      return response.data;
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

/**
 * 资料包内删除
 */
export const remove = createAsyncThunk<void, ID | undefined, ThunkApi>(
  `${name}/remove`,
  async (id, { rejectWithValue }) => {
    try {
      await packService.remove(id);
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

/**
 * 分享资源/资源包
 */
export const share = createAsyncThunk<void, ShareArgs, ThunkApi>(
  `${name}/share`,
  async (data, { rejectWithValue }) => {
    try {
      await packService.share(data);
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  }
);

interface State {
  wait: PackDetail[];
  receive: PackDetail[];
  send: PackDetail[];
  loading: Loading;
  keyword: {
    receive: string;
    send: string;
  };
}

const initialState: State = {
  wait: [],
  receive: [],
  send: [],
  loading: Loading.idle,
  keyword: {
    receive: '',
    send: '',
  },
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    search(state, action: PayloadAction<{ type: ShareType; keyword: string }>) {
      if (action.payload.type === 'receive') {
        state.keyword.receive = action.payload.keyword;
      } else {
        state.keyword.send = action.payload.keyword;
      }
    },
  },
  extraReducers: (builder) => {
    /**
     * 获取列表
     */
    builder
      .addCase(fetchDetails.pending, (state, action) => {
        const { id } = action.meta.arg;
        if (id) {
          state.loading = Loading.pending;
        }
      })
      .addCase(fetchDetails.fulfilled, (state, action) => {
        const { id, type } = action.meta.arg;

        if (id) {
          if (type === 'receive') {
            state.receive = action.payload.data;
          } else {
            state.send = action.payload.data;
          }
          state.loading = Loading.fulfilled;
        } else {
          state.wait = action.payload.data;
        }
      })
      .addCase(fetchDetails.rejected, (state, action) => {
        const { id, type } = action.meta.arg;

        if (id) {
          if (type === 'receive') {
            state.receive = [];
          } else {
            state.send = [];
          }
          state.loading = Loading.rejected;
        } else {
          state.wait = [];
        }
      });

    /**
     * 删除
     */
    builder.addCase(remove.fulfilled, (state, action) => {
      if (action.meta.arg) {
        state.wait = state.wait.filter((item) => item.id !== action.meta.arg);
      } else {
        state.wait = [];
      }
    });

    /**
     * 添加到资源包
     */
    builder.addCase(addToPack.fulfilled, (state, action) => {
      state.wait.push(action.payload);
    });

    /**
     * 分享
     */
    builder.addCase(share.fulfilled, (state) => {
      state.wait = [];
    });
  },
});

export default slice.reducer;

export const { search } = slice.actions;

/**
 * 总数 （顶部导航）
 * @param state
 * @returns
 */
export const selectCount = (state: AppState) => {
  return state.share.details.wait.length;
};

/**
 * 列表
 * @param cate
 * @returns
 */
export const selectList =
  (cate: 'wait' | 'receive' | 'send') => (state: AppState) => {
    if (cate === 'wait') {
      return state.share.details.wait;
    }
    if (cate === 'receive') {
      return state.share.details.receive;
    }
    return state.share.details.send;
  };

/**
 * Loading
 * @param state
 * @returns
 */
export const selectLoading = (state: AppState) => {
  return state.share.details.loading;
};

/**
 * keyword
 * @param type
 * @returns
 */
export const selectKeyword = (type: ShareType) => (state: AppState) => {
  return state.share.details.keyword[type];
};
