import React, { useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { CloseOutlined } from '@ant-design/icons';

import { toArray } from '../../utils';
import { setDrawerShowTip, UploadContext, UploadStatus } from '../../Provider';
import TableRow from './TableRow';
import TableCell from './TableCell';

const PREFIX = 'TableHead';

const classes = {
  root: `${PREFIX}-root`,
  tip: `${PREFIX}-tip`,
  sticky: `${PREFIX}-sticky`,
};

const Root = styled('thead')({
  [`& .${classes.tip}`]: {
    backgroundColor: '#5a5aee',
    color: '#fff',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  [`& .${classes.sticky}`]: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    backgroundColor: '#fff',
  },
});

const TableHead = React.forwardRef<HTMLTableSectionElement, {}>(
  function TableHead(props, ref) {
    const { state, dispatch } = useContext(UploadContext);

    const uploadFinished = useMemo(() => {
      if (state.tasks) {
        return toArray(state.tasks).filter(
          (item) => item.status === UploadStatus.Success
        );
      }
      return [];
    }, [state.tasks]);

    const handleClose = () => {
      dispatch(setDrawerShowTip(false));
    };

    const renderTableHeadRow = () => {
      if (state.showTip) {
        return (
          <TableRow className={classes.sticky}>
            <TableCell className={classes.tip} colSpan={5}>
              <div>
                <span>有{uploadFinished.length}个文件上传完成</span>
                <span onClick={handleClose} style={{ cursor: 'pointer' }}>
                  <CloseOutlined />
                </span>
              </div>
            </TableCell>
          </TableRow>
        );
      }

      return (
        <TableRow className={classes.sticky}>
          <TableCell component="th">文件名</TableCell>
          <TableCell component="th">大小</TableCell>
          <TableCell component="th">位置</TableCell>
          <TableCell component="th">速度</TableCell>
          <TableCell />
        </TableRow>
      );
    };

    return (
      <Root className={classes.root} ref={ref}>
        {renderTableHeadRow()}
      </Root>
    );
  }
);

export default TableHead;
