import React, { useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import {
  LineOutlined as Minimize,
  BorderOutlined as Maximize,
  CloseOutlined as Close,
} from '@ant-design/icons';
import clsx from 'clsx';

import { Portal } from 'src/components/Portal';
import { IconButton } from 'src/components/IconButton';
import { Scrollbar } from 'src/components/Scrollbar';
import { useDialogContext } from 'src/components/Dialog';

import { toArray } from '../utils';
import {
  UploadContext,
  clearUploadFiles,
  setDrawerOpen,
  UploadDrawerSize,
  setDrawerSize,
  setDrawerShowTip,
  UploadStatus,
} from '../Provider';
import Table from './Table';

enum Title {
  Finished = '全部完成',
  Uploading = '正在上传',
}

const drawerHeight = 532;

const PREFIX = 'FileUploadDrawer';

const classes = {
  root: `${PREFIX}-root`,
  head: `${PREFIX}-head`,
  body: `${PREFIX}-body`,
  minimize: `${PREFIX}-minimize`,
  maximize: `${PREFIX}-maximize`,
  showTip: `${PREFIX}-showTip`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'fixed',
    right: 0,
    bottom: 0,
    height: drawerHeight,
    width: 960,
    background: '#fff',
    zIndex: 1300,
    boxShadow: '0px 0px 30px 0px rgba(0, 0, 0, 0.1)',
    border: '1px solid #eee',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    transition: theme.transitions.create(['transform']),
  },
  [`&.${classes.minimize}`]: {
    transform: `translate3d(0px, ${drawerHeight - 60}px, 0px)`,
    [`&.${classes.showTip}`]: {
      transform: `translate3d(0px, ${drawerHeight - 60 - 54.5}px, 0px)`,
    },
  },
  [`& .${classes.head}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px 20px',
    fontSize: 18,
    borderBottom: '1px solid #eee',
    userSelect: 'none',
    '& > div:last-of-type': {
      display: 'inline-flex',
      alignItems: 'center',
      '& > :not(:last-of-type)': {
        marginRight: 10,
      },
    },
  },
  [`& .${classes.body}`]: {
    flex: 1,
    overflow: 'hidden',
  },
}));

const Drawer: React.FC = () => {
  const { state, dispatch } = useContext(UploadContext);
  const { openDialog } = useDialogContext();

  const unfinished = useMemo(() => {
    return toArray(state.tasks || {}).filter(
      (item) => item.status <= UploadStatus.Uploading
    );
  }, [state.tasks]);

  const title = useMemo(() => {
    if (unfinished.length > 0) {
      return Title.Uploading;
    }
    return Title.Finished;
  }, [unfinished]);

  const handleChangeSize = () => {
    if (state.size === UploadDrawerSize.Maximize) {
      dispatch(setDrawerSize(UploadDrawerSize.Minimize));
    } else {
      dispatch(setDrawerSize(UploadDrawerSize.Maximize));
    }
  };

  const handleClose = () => {
    const onClose = () => {
      dispatch(setDrawerOpen(false));
      dispatch(setDrawerShowTip(false));
      dispatch(setDrawerSize(UploadDrawerSize.Maximize));
      dispatch(clearUploadFiles());
    };

    if (unfinished.length > 0) {
      openDialog({
        variant: 'warning',
        content: '列表中有未上传完成的文件，确定要放弃上传吗？',
        onOk: () => {
          onClose();
        },
      });
    } else {
      onClose();
    }
  };

  const SizeIcon = useMemo(() => {
    if (state.size === UploadDrawerSize.Maximize) {
      return Minimize;
    }
    return Maximize;
  }, [state.size]);

  if (!state.open) return null;

  const classNames = clsx(classes.root, {
    [classes.minimize]: state.open && state.size === UploadDrawerSize.Minimize,
    [classes.maximize]: state.open && state.size === UploadDrawerSize.Maximize,
    [classes.showTip]: state.open && state.showTip,
  });

  return (
    <Portal>
      <Root className={classNames}>
        <div className={classes.head}>
          <div>{title}</div>
          <div>
            <IconButton icon={<SizeIcon />} onClick={handleChangeSize} />
            <IconButton icon={<Close />} onClick={handleClose} />
          </div>
        </div>
        <div className={classes.body}>
          <Scrollbar>
            <Table />
          </Scrollbar>
        </div>
      </Root>
    </Portal>
  );
};

export default React.memo(Drawer);
