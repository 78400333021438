import React from 'react';
import { useSpring, animated } from 'react-spring';

import { BaseTransitionProps } from '../transition';
import { getSpringConfig } from '../utils';

function getScale(value: number) {
  return `scale(${value}, ${value ** 1})`;
}

interface GrowProps extends BaseTransitionProps {}

const Grow = React.forwardRef<HTMLDivElement, GrowProps>((props, ref) => {
  const {
    children,
    in: inProp,
    className,
    onEnter,
    onEntered,
    onExit,
    onExited,
    style,
    config,
    timeout,
  } = props;

  const springConfig = getSpringConfig(config, timeout);

  const styles = useSpring({
    from: { opacity: 0, transform: getScale(0.75) },
    to: {
      opacity: inProp ? 1 : 0,
      transform: inProp ? getScale(1) : getScale(0.75),
    },
    config: springConfig,
    onStart: () => {
      if (inProp && onEnter) {
        onEnter();
      }
      if (!inProp && onExit) {
        onExit();
      }
    },
    onRest: () => {
      if (inProp && onEntered) {
        onEntered();
      }
      if (!inProp && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div
      className={className}
      style={{ ...styles, ...style }}
      ref={ref}
    >
      {children}
    </animated.div>
  );
});

export default Grow;
