import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';

import { Favorites, ID, ListAllData } from 'src/shared/interfaces';
import favoritesService from 'src/services/favorites';
import { Loading } from 'src/shared/constants';
import { AppState } from 'src/app/store';
import { getErrorMessage } from 'src/utils';

const name = 'favorite/folder';

const favoritesAdapter = createEntityAdapter<Favorites>({
  selectId: (favorites) => favorites.id,
});

/**
 * 收藏夹列表
 */
export const list = createAsyncThunk<ListAllData<Favorites>, void>(
  `${name}/list`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await favoritesService.getListOfFavorites();
      return response.data;
    } catch (err) {
      const message = err.isAxiosError ? err.response.data : err.message;
      return rejectWithValue(message);
    }
  }
);

/**
 * 添加/修改收藏夹
 */
export const upsert = createAsyncThunk<
  Partial<Favorites>,
  Partial<Favorites>,
  { rejectValue: string }
>(`${name}/upsert`, async (data, { rejectWithValue }) => {
  try {
    const response = await favoritesService.upsert(data);
    return response.data;
  } catch (err) {
    const message = err.isAxiosError ? err.response.data : err.message;
    return rejectWithValue(message);
  }
});

/**
 * 删除收藏夹
 */
export const removeFolder = createAsyncThunk<void, ID>(
  `${name}/remove`,
  async (id, { rejectWithValue }) => {
    try {
      await favoritesService.removeFolder(id);
    } catch (err) {
      const message = err.isAxiosError ? err.response.data : err.message;
      return rejectWithValue(message);
    }
  }
);

/**
 * 重命名（未来也可能能有其他的内容更新）
 */
export const update = createAsyncThunk<
  Favorites,
  Partial<Favorites>,
  { rejectValue: string }
>(`${name}/update`, async ({ id, name }, { rejectWithValue }) => {
  try {
    const response = await favoritesService.rename(id, name);
    return response.data;
  } catch (err) {
    const message = getErrorMessage(err);
    console.log(message);
    return rejectWithValue(message);
  }
});

const slice = createSlice({
  name,
  initialState: favoritesAdapter.getInitialState<{
    loading: Loading;
    current: ID | null;
  }>({
    loading: Loading.idle,
    current: null,
  }),
  reducers: {
    setCurrent(state, action) {
      state.current = action.payload;
    },
    create(state, action) {
      favoritesAdapter.addOne(state, action.payload);
    },
    rename(state, action) {
      favoritesAdapter.updateOne(state, {
        id: action.payload,
        changes: { type: 'update' },
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(list.pending, (state) => {
      state.loading = Loading.pending;
    });

    builder.addCase(list.fulfilled, (state, action) => {
      state.loading = Loading.fulfilled;
      favoritesAdapter.setAll(state, action.payload.data);
    });

    builder.addCase(list.rejected, (state) => {
      state.loading = Loading.rejected;
      favoritesAdapter.setAll(state, []);
    });

    builder.addCase(upsert.fulfilled, (state, action) => {
      const { id = -1 } = action.meta.arg;
      favoritesAdapter.updateOne(state, {
        id,
        changes: { ...action.payload, type: undefined },
      });
    });

    builder.addCase(removeFolder.fulfilled, (state, action) => {
      favoritesAdapter.removeOne(state, action.meta.arg);
      const current = state.current;
      if (current === action.meta.arg) {
        state.current = null;
      }
    });

    builder.addCase(update.fulfilled, (state, action) => {
      const { id } = action.meta.arg;
      if (id) {
        favoritesAdapter.updateOne(state, {
          id,
          changes: { ...action.payload, type: undefined },
        });
      }
    });
  },
});

export default slice.reducer;

export const { setCurrent, create, rename } = slice.actions;

export const { selectAll, selectById } = favoritesAdapter.getSelectors(
  (state: AppState) => state.favorites.folder
);

export const selectIsLoading = (state: AppState) => {
  return state.favorites.folder.loading;
};

export const selectCount = createSelector(selectAll, (favorites) => {
  return favorites.reduce(
    (acc, item) => (acc += item.action_collects_count),
    0
  );
});

export const selectCurrent = (state: AppState) => {
  return state.favorites.folder.current;
};
