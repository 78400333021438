import { AxiosInstance } from 'axios';
import MockAdapter from 'axios-mock-adapter';

import { login } from './auth';
import { getProfile } from './users';

export const isMockEnabled = () => {
  return (
    process.env.REACT_APP_MOCK_ENABLED === 'true' &&
    process.env.NODE_ENV === 'development'
  );
};

export const initializeAxiosMockAdapter = (instance: AxiosInstance) => {
  const mockAdapter = new MockAdapter(instance, { delayResponse: 3000 });
  mockAdapter.onPost('/login').reply(login);
  mockAdapter.onGet('/profile').reply(getProfile);
};
