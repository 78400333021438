import React from 'react';

export default function useEventCallback<Args extends unknown[], Return>(
  fn: (...args: Args) => Return
): (...args: Args) => Return {
  const ref = React.useRef(fn);

  React.useLayoutEffect(() => {
    ref.current = fn;
  });

  return React.useCallback((...args: Args) => ref.current?.(...args), []);
}
