import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';

import adminService, {
  Department,
  DepartmentParams,
  getDepartmentParams,
  IAddDepartment,
  IDelDepartment,
  IBaseInfo,
  IBaseParams,
  AdminState,
  IDefaultImg,
  ITransferBaseParams,
  IDetailDepartment,
  IDetailDepartmentParams,
  IAddAdminParams,
} from 'src/services/admin';
import { Loading } from 'src/shared/constants';

import { AppState } from 'src/app/store';
import { HttpResponse, ID, ListData } from 'src/shared/interfaces';
import { getErrorMessage } from 'src/utils';

type State = {
  departmentList: Department[];
  withUser: string;
  departmentloading: Loading;
  detailDepartmentList: any;
  adminList: any;
  keyword1: string;
  keyword3: string;
  firstKeyword: string;
  defaultImgList: any;
  defaultList: any;
};

const departmentListAdapter = createEntityAdapter<IDetailDepartment>({
  selectId: (detailDepartment) => detailDepartment.id,
});

const adminAdapter = createEntityAdapter<AdminState>({
  selectId: (item) => item.id,
});

const defaultImgAdapter = createEntityAdapter<IDefaultImg>({
  selectId: (defaultImg) => defaultImg.id,
});

const initialState: State = {
  departmentList: [],
  departmentloading: Loading.idle,
  withUser: '',
  keyword1: '',
  keyword3: '',
  firstKeyword: '',
  detailDepartmentList: departmentListAdapter.getInitialState<{
    loading: Loading;
    departmentName: string;
    sort: string;
    departmentId: ID;
    userId: string;
    keyword: string;
    total: number;
    totalPages: number;
  }>({
    loading: Loading.idle,
    departmentName: '',
    departmentId: '',
    sort: '',
    userId: '',
    keyword: '',
    total: 0,
    totalPages: 0,
  }),
  adminList: adminAdapter.getInitialState<{
    loading: Loading;
    sort: string;
    page: number;
    limit: number;
    total: number;
    keyword: string;
  }>({
    loading: Loading.idle,
    sort: '',
    page: 1,
    limit: 10,
    total: 0,
    keyword: '',
  }),
  defaultImgList: defaultImgAdapter.getInitialState<{
    loading: Loading;
  }>({
    loading: Loading.idle,
  }),
  defaultList: [],
};

// 查询列表
export const getDepartment = createAsyncThunk<ListData<Department>, Partial<getDepartmentParams>>(
  'department/list',
  async ({ ...rest } = {}, { rejectWithValue }) => {
    try {
      const response = await adminService.getDepartment(rest);
      return response.data;
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

// 新建部门
export const createDepartment = createAsyncThunk<
  Partial<Department>,
  DepartmentParams,
  { rejectValue: string }
>('department/create', async (data, { rejectWithValue }) => {
  try {
    const response = await adminService.createDepartment(data);
    return response.data;
  } catch (err) {
    const message = getErrorMessage(err);
    return rejectWithValue(message);
  }
});

// 部门成员添加
export const addDepartment = createAsyncThunk<Partial<IAddDepartment>, Partial<IAddDepartment>>(
  'department/addDepartment',
  async (data, { rejectWithValue }) => {
    try {
      const response = await adminService.addDepartment(data);
      return response.data;
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);
// type Details = { id: ID } & Partial<IDetailDepartmentParams>;
// 部门详情列表
export const detailDepartment = createAsyncThunk<
  ListData<IDetailDepartment>,
  Partial<IDetailDepartmentParams>
>('department/detail', async ({ ...data }, { rejectWithValue }) => {
  try {
    const response = await adminService.detailDepartment(data);
    return response.data;
  } catch (err) {
    const message = getErrorMessage(err);
    return rejectWithValue(message);
  }
});

// 部门删除
export const removeDepartment = createAsyncThunk<void, ID>(
  'department/remove',
  async (id, { rejectWithValue }) => {
    try {
      await adminService.removeDepartment(id);
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

// 部门成员删除
export const delDepartment = createAsyncThunk<HttpResponse<{}>, IDelDepartment>(
  'department/delDepartment',
  async (data, { rejectWithValue }) => {
    try {
      const response = await adminService.delDepartment(data);
      return response;
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);
// 部门成员删除
export const excelAddUser = createAsyncThunk<
  Boolean,
  { departmentId: ID; excel: File },
  { rejectValue: string }
>('department/excelAddUser', async (data, { rejectWithValue }) => {
  try {
    await adminService.excelAddUser(data);
    return true;
  } catch (err) {
    const message = getErrorMessage(err);
    return rejectWithValue(message);
  }
});

// 修改部门
export const updateDepartment = createAsyncThunk<
  Department,
  Partial<Department>,
  { rejectValue: string }
>('department/update', async ({ id, name }, { rejectWithValue }) => {
  try {
    const response = await adminService.updateDepartment(id, name);
    return response.data;
  } catch (err) {
    const message = getErrorMessage(err);
    return rejectWithValue(message);
  }
});

// 新建知识库
export const addBase = createAsyncThunk<Partial<IBaseInfo>, Partial<IBaseParams>>(
  'template/addBase',
  async (data, { rejectWithValue }) => {
    try {
      const response = await adminService.addBase(data);
      return response.data;
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

// 转让知识库
export const transferBase = createAsyncThunk<HttpResponse<{}>, ITransferBaseParams>(
  `transferBase`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await adminService.transferBase(id);
      return response;
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

// 知识库删除
export const delBase = createAsyncThunk<void, ID>(
  'department/delBase',
  async (id, { rejectWithValue }) => {
    try {
      await adminService.delBase(id);
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

// defaultImg默认封面
export const defaultImg = createAsyncThunk<ListData<IDefaultImg>, number>(
  'defaultImg',
  async (id, { rejectWithValue }) => {
    try {
      const response = await adminService.defaultImg(id);
      return response.data;
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

// 高级管理员列表
export const fetchAdmin = createAsyncThunk<
  ListData<AdminState>,
  {
    page?: number;
    limit?: number;
    sort?: string;
    keyword?: string;
    type?: string;
  }
>('admin/list', async (data, { rejectWithValue }) => {
  try {
    const response = await adminService.fetchAdmin(data);
    return response.data;
  } catch (err) {
    const message = getErrorMessage(err);
    return rejectWithValue(message);
  }
});
// 高级管理员删除
export const removeAdmin = createAsyncThunk<void, ID, { rejectValue: string }>(
  'admin/delete',
  async (id, { rejectWithValue }) => {
    try {
      await adminService.removeAdmin(id);
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

// 添加管理员

export const addAdmin = createAsyncThunk<HttpResponse<{}>, Partial<IAddAdminParams>>(
  'base/addAdmin',
  async (data, { rejectWithValue }) => {
    try {
      const response = await adminService.addAdmin(data);
      return response;
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

/**
 * 组织架构成员信息修改
 */
export const updateUser = createAsyncThunk<any, { id: ID; changed: any }, { rejectValue: string }>(
  'department/updateUser',
  async ({ id, changed }, { rejectWithValue }) => {
    try {
      const response = await adminService.updateUser(id, changed);
      return response.data;
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  }
);

export const backendSlice = createSlice({
  name: 'backend',
  initialState,
  reducers: {
    setWithUser(state, action) {
      state.withUser = action.payload;
    },
    setDepartmentName(state, action) {
      state.detailDepartmentList.departmentName = action.payload;
    },
    setDepartmentId(state, action) {
      state.detailDepartmentList.departmentId = action.payload;
    },
    setUserId(state, action) {
      state.detailDepartmentList.userId = action.payload;
    },
    setUserListSort(state, action) {
      state.detailDepartmentList.sort = action.payload;
    },
    create(state, action) {
      const { parent, data } = action.payload;
      if (!parent.departmentId) {
        let index = state.departmentList.findIndex((item) => item.id === parent.id);
        state.departmentList[index].allChildren.unshift(data);
      } else {
        state.departmentList = addTreeNode(state.departmentList, parent.id);
      }
    },
    setUpdateDepartment(state, action) {
      state.departmentList = renameTreeNode(state.departmentList, action.payload.id);
    },
    setRemoveDepartment(state, action) {
      state.departmentList = removeTreeNode(state.departmentList, action.payload.id);
    },
    setAdminListSort(state, action) {
      state.adminList.sort = action.payload;
    },
    setAdminListPage(state, action) {
      state.adminList.page = action.payload;
    },
    setAdminKeyword(state, action) {
      state.adminList.keyword = action.payload;
    },
    setKeyword(state, action) {
      if (action.payload.index === '1') {
        state.keyword1 = action.payload.keyword;
      }
      if (action.payload.index === '3') {
        state.keyword3 = action.payload.keyword;
      }
    },

    setPage(state, action) {
      state.detailDepartmentList.page = action.payload;
    },
    setSort(state, action) {
      state.detailDepartmentList.sort = action.payload;
    },
    setFirstKeyword(state, action) {
      state.firstKeyword = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateUser.fulfilled, (state, action) => {
      departmentListAdapter.updateOne(state.detailDepartmentList, {
        id: action.meta.arg.id,
        changes: action.payload,
      });
    });
    builder.addCase(getDepartment.pending, (state, action) => {
      state.departmentloading = Loading.pending;
    });

    builder.addCase(getDepartment.fulfilled, (state, action) => {
      state.departmentloading = Loading.fulfilled;
      state.departmentList = action.payload.data;

      state.detailDepartmentList.departmentId = action.payload.data[0].id.toString();
    });

    builder.addCase(detailDepartment.pending, (state) => {
      state.detailDepartmentList.loading = Loading.pending;
    });
    builder.addCase(detailDepartment.fulfilled, (state, action) => {
      const { data, meta } = action.payload;
      state.detailDepartmentList.loading = Loading.fulfilled;
      departmentListAdapter.setAll(state.detailDepartmentList, data);
      state.detailDepartmentList.total = meta.pagination.total;
      state.detailDepartmentList.totalPages = meta.pagination.total_pages;
    });
    builder.addCase(detailDepartment.rejected, (state) => {
      state.detailDepartmentList.loading = Loading.rejected;
      state.detailDepartmentList.total = 0;
      state.detailDepartmentList.totalPages = 0;
      departmentListAdapter.setAll(state.detailDepartmentList, []);
    });

    builder.addCase(fetchAdmin.pending, (state) => {
      state.adminList.loading = Loading.pending;
    });
    builder.addCase(fetchAdmin.fulfilled, (state, action) => {
      state.adminList.loading = Loading.fulfilled;
      state.adminList.total = action.payload.meta.pagination.total;
      adminAdapter.setAll(state.adminList, action.payload.data);
    });
    builder.addCase(fetchAdmin.rejected, (state) => {
      state.adminList.loading = Loading.rejected;
      adminAdapter.setAll(state.adminList, []);
    });

    builder.addCase(delDepartment.fulfilled, (state, action) => {
      departmentListAdapter.removeOne(state.detailDepartmentList, action.meta.arg.userId);
    });
    builder.addCase(defaultImg.fulfilled, (state, action) => {
      state.defaultList = action.payload;
    });
  },
});

// reducers
export const {
  setWithUser,
  setDepartmentName,
  setDepartmentId,
  setUserId,
  create,
  setUpdateDepartment,
  setUserListSort,
  setRemoveDepartment,
  setAdminListSort,
  setAdminListPage,
  setKeyword,
  setPage,
  setSort,
  setAdminKeyword,
  setFirstKeyword,
} = backendSlice.actions;

export const selectKeyword = (index: string) => (state: AppState) => {
  if (index === '3') {
    return state.adminBackend.keyword3;
  }
  if (index === '2') {
    return state.adminBackend.keyword1;
  }
  return state.adminBackend.firstKeyword;
};

export const selectAdminKeyword = (state: AppState) => state.adminBackend.adminList.keyword;

export const selectTotal = (state: AppState) => state.adminBackend.detailDepartmentList.total;
export const selectTotalPages = (state: AppState) =>
  state.adminBackend.detailDepartmentList.totalPages;
export const selectPage = (state: AppState) => state.adminBackend.detailDepartmentList.page;
export const selectSort = (state: AppState) => state.adminBackend.detailDepartmentList.sort;

export const selectAdminListSort = (state: AppState) => state.adminBackend.adminList.sort;
export const selectAdminListPage = (state: AppState) => state.adminBackend.adminList.page;
export const selectAdminListLimit = (state: AppState) => state.adminBackend.adminList.limit;
export const selectAdminListTotal = (state: AppState) => state.adminBackend.adminList.total;
export const adminListLoading = createSelector(
  (state: AppState) => state.adminBackend.adminList.loading,
  (loading) => loading === Loading.pending
);

export const defaultList = (state: AppState) => state.adminBackend.defaultList;

export const departmentList = (state: AppState) => state.adminBackend.departmentList;
export const departmentName = (state: AppState) =>
  state.adminBackend.detailDepartmentList.departmentName;
export const departmentId = (state: AppState) =>
  state.adminBackend.detailDepartmentList.departmentId;
export const userid = (state: AppState) => state.adminBackend.detailDepartmentList.userId;

// createSelector
export const detailLoading = createSelector(
  (state: AppState) => state.adminBackend.detailDepartmentList.loading,
  (loading) => loading === Loading.pending
);

export const selectWithUser = (state: AppState) => state.adminBackend.withUser;
export const selectDepartmentLoading = createSelector(
  (state: AppState) => state.adminBackend.departmentloading,
  (loading) => loading === Loading.pending
);

export const { selectAll: selectDepartmentList, selectById: selectUserById } =
  departmentListAdapter.getSelectors((state: AppState) => state.adminBackend.detailDepartmentList);

export const { selectAll: selectAdminList } = adminAdapter.getSelectors(
  (state: AppState) => state.adminBackend.adminList
);

export default backendSlice.reducer;

/**
 * 扁平化树，便于检索
 * @param tree
 * @returns
 */
function flattenTreeData(tree: Department[]) {
  return tree.reduce<Record<string, Department>>((acc, node) => {
    if (node.allChildren) {
      acc = { ...acc, ...flattenTreeData(node.allChildren) };
    }
    return { ...acc, [node.id]: node };
  }, {});
}

/**
 * 查找树子元素索引
 * return 索引数组
 */

// function findTreeChildrenIndex(tree: Department[], source: string[]): number[] {
//   let indexArr: number[] = [];
//   const iteratLoop = (children: Department[]) => {
//     for (let i = 0; i < children.length; i++) {
//       if (Number(source[indexArr.length]) === Number(children[i].id)) {
//         indexArr.push(i);
//         if (children[i].allChildren && indexArr.length !== source.length) {
//           iteratLoop(children[i].allChildren);
//         }
//         return;
//       }
//     }
//   };
//   iteratLoop(tree);
//   return indexArr;
// }

/**
 * 新增树子元素
 */

function addTreeNode(tree: Department[], id: number): Department[] {
  const flatten = flattenTreeData(tree);
  const parent = flatten[id];
  parent.allChildren.unshift({
    departmentId: id,
    id: Date.now(),
    createdAt: '',
    updatedAt: '',
    deletedAt: '',
    allChildren: [],
    name: '默认部门',
    type: 'new',
  });
  return tree;
}
/**
 * 重命名
 */

function renameTreeNode(tree: Department[], id: number): Department[] {
  const flatten = flattenTreeData(tree);
  const parent = flatten[id];
  parent.type = 'update';
  return tree;
}
/**
 * 删除
 */

function removeTreeNode(tree: Department[], id: number | -1): Department[] {
  const iteratLoop = (children: Department[]) => {
    for (let i = 0; i < children.length; i++) {
      if (Number(id) === Number(children[i]?.id)) {
        delete children[i];
        return;
      } else {
        if (children[i]?.allChildren) {
          iteratLoop(children[i]?.allChildren);
        }
      }
    }
  };
  iteratLoop(tree);
  return tree;
}
