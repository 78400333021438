import React from 'react';

import styled from '@emotion/styled';
import clsx from 'clsx';

import { composeClasses } from 'src/utils';
import useFormControl from './useFormControl';

const classNamePrefix = 'FormControlLabel';

const useUtilityClasses = (props: {
  disabled?: boolean;
  row?: boolean;
  required?: boolean;
}) => {
  const { disabled, row, required } = props;

  const slots = {
    root: [
      'root',
      disabled && 'disabled',
      row && 'row',
      required && 'required',
    ],
  };

  return composeClasses(classNamePrefix, slots);
};

function shouldForwardProp(prop: PropertyKey) {
  return (
    prop !== 'theme' &&
    prop !== 'as' &&
    prop !== 'disabled' &&
    prop !== 'required' &&
    prop !== 'row' &&
    prop !== 'colon'
  );
}

const FormControlLabelRoot = styled('label', { shouldForwardProp })<{
  colon?: boolean;
}>(({ colon = false, theme }) => ({
  display: 'inline-flex',
  WebkitTapHighlightColor: 'transparent',
  ...theme.typography.body2,
  marginBottom: 20,
  cursor: 'pointer',
  ...(colon && {
    '::after': {
      content: '":"',
      display: 'inline-block',
      marginLeft: 4,
      marginRight: 6,
    },
  }),

  [`&.${classNamePrefix}-row`]: {
    marginRight: 10,
    marginBottom: 0,
  },

  [`&.${classNamePrefix}-disabled`]: {
    cursor: 'default',
    color: theme.palette.text?.disabled,
  },

  [`&.${classNamePrefix}-required`]: {
    '&::before': {
      content: '"*"',
      marginRight: 6,
      display: 'inline-block',
      color: theme.palette.error?.main,
    },
  },
}));

const FormControlLabel: React.FC<
  FormControlLabelProps & React.HTMLAttributes<HTMLLabelElement>
> = (props) => {
  const { className, id, colon, label, ...rest } = props;

  const { disabled, required, row } = useFormControl() || {};

  const classes = useUtilityClasses({ disabled, row, required });

  return (
    <FormControlLabelRoot
      colon={colon}
      className={clsx(classes.root, className)}
      id={id}
      {...rest}
    >
      {label}
    </FormControlLabelRoot>
  );
};

export default FormControlLabel;

interface FormControlLabelProps {
  className?: string | undefined;
  id?: string | undefined;
  colon?: boolean;
  label?: React.ReactNode;
}
