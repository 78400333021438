import React from 'react';
import { useSpring, animated } from 'react-spring';

import { BaseTransitionProps } from '../transition';
import { getSpringConfig } from '../utils';

export interface FadeProps extends BaseTransitionProps {}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(
  props,
  ref
) {
  const {
    children,
    in: inProp,
    className,
    onEnter,
    onEntered,
    onExit,
    onExited,
    style,
    config,
    timeout,
  } = props;

  const springConfig = getSpringConfig(config, timeout);

  const styles = useSpring({
    from: { opacity: 0 },
    to: { opacity: inProp ? 1 : 0 },
    config: springConfig,
    onStart: () => {
      if (inProp && onEnter) {
        onEnter();
      }
      if (!inProp && onExit) {
        onExit();
      }
    },
    onRest: () => {
      if (inProp && onEntered) {
        onEntered();
      }
      if (!inProp && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div
      className={className}
      ref={ref}
      style={{ ...styles, ...style }}
    >
      {children}
    </animated.div>
  );
});

export default Fade;
