import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { AppState } from 'src/app/store';
import followService from 'src/services/follows';
import { defaultPaginationConfig, Loading } from 'src/shared/constants';
import { ID } from 'src/shared/interfaces';
import { getErrorMessage } from 'src/utils';

import { FollowEntity } from './types';

const name = 'follows' as const;

export interface State {
  entities: FollowEntity[];
  page: number;
  limit: number;
  total: number;
  loading: Loading;
  currentRequestId: string | undefined;
  keyword: string | undefined;
}

const initialState: State = {
  entities: [],
  page: 1,
  limit: defaultPaginationConfig.limit,
  total: 0,
  loading: Loading.idle,
  currentRequestId: undefined,
  keyword: undefined,
};

/**
 * 获取关注列表
 */
export const fetchList = createAsyncThunk<
  { entities: FollowEntity[]; total: number },
  { page?: number; limit?: number; keyword?: string } | undefined,
  { rejectValue: string }
>(
  `${name}/fetchList`,
  async (
    { page = 1, limit = defaultPaginationConfig.limit, keyword } = {},
    { rejectWithValue }
  ) => {
    try {
      const response = await followService.fetchList(page, limit, keyword);
      return {
        entities: response.data.data,
        total: response.data.meta.pagination.total,
      };
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  }
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
    setKeyword: (state, action) => {
      state.keyword = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchList.pending, (state, action) => {
      state.loading = Loading.pending;
      state.currentRequestId = action.meta.requestId;
    });
    builder.addCase(fetchList.fulfilled, (state, action) => {
      if (state.currentRequestId === action.meta.requestId) {
        state.loading = Loading.fulfilled;
        state.currentRequestId = undefined;
        state.entities = action.payload.entities;
        state.total = action.payload.total;
        state.page = action.meta.arg?.page || 1;
        state.limit = action.meta.arg?.limit || defaultPaginationConfig.limit;
      }
    });
    builder.addCase(fetchList.rejected, (state, action) => {
      if (state.currentRequestId === action.meta.requestId) {
        state.loading = Loading.rejected;
        state.currentRequestId = undefined;
      }
    });
  },
});

export const { reset, setKeyword } = slice.actions;

export default slice.reducer;

// Selectors
export const selectFollowById = createSelector(
  [(state: AppState) => state.follows.entities, (_: AppState, id: ID) => id],
  (entities, id) => entities.find((item) => item.id === id)
);

// 最后一页
export const selectIsLastPage = createSelector(
  [
    (state: AppState) => state.follows.page,
    (state: AppState) => state.follows.limit,
    (state: AppState) => state.follows.total,
  ],
  (page, limit, total) => Math.ceil(total / limit) >= page
);
