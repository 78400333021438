import React from 'react';
import styled from '@emotion/styled';
import { shouldForwardProp } from 'src/utils';

const PREFIX = 'Review-Details--Cell';

const classNames = {
  root: `${PREFIX}__root`,
  label: `${PREFIX}__label`,
  content: `${PREFIX}__content`,
};

const CellRoot = styled('div', {
  shouldForwardProp: (prop) => shouldForwardProp(prop) && prop !== 'block',
})<{ block?: boolean }>(({ block }) => ({
  [`&.${classNames.root}`]: {
    position: 'relative',
    display: 'flex',
    ...(block && { flexDirection: 'column' }),
  },
  [`& .${classNames.label}`]: {
    width: 80,
    color: '#666',
  },
  [`& .${classNames.content}`]: {
    ...(block && { marginTop: 8 }),
  },
}));

export interface CellProps {
  label?: string;
  block?: boolean;
}

const Cell: React.FC<CellProps> = (props) => {
  const { label, block = false, children } = props;
  return (
    <CellRoot className={classNames.root} block={block}>
      <div className={classNames.label}>{label}</div>
      <div className={classNames.content}>{children}</div>
    </CellRoot>
  );
};

export default Cell;
