import React, { useEffect } from 'react';
import styled from '@emotion/styled';

import { useMemberDispatch, useMemberSelector } from '../Provider';
import { fetchDepartments, fetchMembers, search } from '../reducers/actions';
import {
  selectCurrentSubdepartment,
  selectSearch,
} from '../reducers/selectors';
import Title from '../common/Title';
import Search from './Search/SearchInput';
import Departments from './Departments';
import Members from './Members';
import SearchResult from './Search';

const ListRoot = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  marginTop: 20,
  overflow: 'hidden',
});

const viewComponent = {
  member: Members,
  department: Departments,
  search: SearchResult,
};

const Views: React.FC<{ title?: string }> = ({ title }) => {
  const dispatch = useMemberDispatch();

  const view = useMemberSelector((state) => state.view);
  const subdepartment = useMemberSelector(selectCurrentSubdepartment);
  const { type, keyword } = useMemberSelector(selectSearch);

  const Component = viewComponent[view];

  useEffect(() => {
    dispatch(fetchDepartments());
  }, [dispatch]);

  useEffect(() => {
    if (subdepartment && subdepartment.id !== null) {
      dispatch(fetchMembers(subdepartment.id));
    }
  }, [dispatch, subdepartment]);

  useEffect(() => {
    if (keyword) {
      dispatch(search(type, keyword));
    }
  }, [dispatch, type, keyword]);

  return (
    <React.Fragment>
      <Title actions={<Search />}>{title}</Title>
      <ListRoot>
        <Component />
      </ListRoot>
    </React.Fragment>
  );
};

export default Views;
