import React, { useEffect } from 'react';
import { message } from 'antd';
import { Navigate, Outlet } from 'react-router-dom';

import { useAppSelector } from 'src/hooks';
import { selectIsManager } from 'src/app/auth.slice';

const Manager: React.FC = () => {
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const isManager = useAppSelector(selectIsManager);

  useEffect(
    () => () => {
      if (!isManager && isLoggedIn) {
        message.warning('没有管理员权限');
      }
    },
    [isLoggedIn, isManager]
  );

  if (!isManager) {
    return <Navigate to="/repository" replace />;
  }

  return <Outlet />;

  // useEffect(() => {
  //   message.warning('alert');
  //   navigate('/repository', { replace: true });
  // }, [navigate]);

  // return null;
};

export default Manager;
