import React from 'react';
import styled from '@emotion/styled';

import { Typography } from 'src/components/Typography';

const PREFIX = 'AppBar-Favorites--ListItem';

const classNames = {
  root: `${PREFIX}__root`,
  head: `${PREFIX}__head`,
  content: `${PREFIX}__content`,
};

const ListItemRoot = styled('div')(({ theme }) => ({
  [`&.${classNames.root}`]: {
    padding: 10,
    margin: '0 10px',
    borderRadius: 10,
    fontSize: 16,
    cursor: 'pointer',
    display: 'block',
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.standard,
    }),
    '&:hover': {
      backgroundColor: theme.palette.background?.paper,
      transition: theme.transitions.create('background-color', {
        duration: theme.transitions.duration.standard,
      }),
    },
  },
  [`& .${classNames.head}`]: {
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      marginLeft: 10,
    },
  },
  [`& .${classNames.content}`]: {
    marginLeft: 26,
    marginTop: 4,
  },
}));

export interface BaseListItemProps<T extends React.ElementType<any>> {
  icon?: React.ReactElement | null;
  title?: string;
  content?: string;
  as?: T;
}

// prettier-ignore
type ListItemProps<T extends React.ElementType<any>> = 
  React.PropsWithChildren<BaseListItemProps<T>> &
  React.ComponentPropsWithRef<T>;

function ListItem<T extends React.ElementType<any>>(props: ListItemProps<T>) {
  const { icon: Icon, title, content, as = 'div', ...rest } = props;
  return (
    <ListItemRoot className={classNames.root} as={as} {...rest}>
      <div className={classNames.head}>
        {Icon}
        <Typography noWrap color="textPrimary">
          {title}
        </Typography>
      </div>
      {content && (
        <Typography noWrap className={classNames.content} color="textSecondary">
          {content}
        </Typography>
      )}
    </ListItemRoot>
  );
}

export default ListItem;
