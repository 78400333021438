import { AxiosRequestConfig } from 'axios';
import Mock from 'mockjs';

import { checkAuthorization } from '../jwt';

export function getProfile(config: AxiosRequestConfig) {
  const authenticated = checkAuthorization(config);
  if (!authenticated) {
    return [401, 'Unauthorized'];
  }

  const user = Mock.mock({
    id: 1,
    name: '@cname',
    avatar:
      'https://coding-net-production-static-ci.codehub.cn/db8dc8e7-d52e-4e54-b183-4c0179348785.png?imageMogr2/auto-orient/format/png/cut/328x328x0x0',
    'age|20-40': 20,
    email: '@email',
    description: '@text',
    address: Mock.Random.city(true),
  });

  return [200, { data: user }];
}
