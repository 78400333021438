import styled from '@emotion/styled';
import clsx from 'clsx';
import { Outlet } from 'react-router-dom';

import { shouldForwardProp } from 'src/utils';
import { Logo } from 'src/components/Logo';

interface AuthLayoutProps {
  className?: string;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ className }) => {
  return (
    <div className={clsx('AuthLayout-root', className)}>
      <div className="AuthLayout-container">
        <div className="AuthLayout-logo">
          <Logo />
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default styled(AuthLayout, { label: 'AuthLayout', shouldForwardProp })(
  () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',

    '&.AuthLayout-root .AuthLayout-logo': {
      width: 198,
      height: 52,
      position: 'absolute',
      top: -84,
      left: '50%',
      transform: 'translate(-50%)',
    },

    '&.AuthLayout-root .AuthLayout-container': {
      width: 560,
      borderRadius: 20,
      border: '1px solid #eee',
      backgroundColor: '#fff',
      position: 'relative',
      padding: '50px 60px',
    },
  })
);
