import styled from '@emotion/styled';

import { List as ListComponent } from 'src/components/List';
import { Scrollbar } from 'src/components/Scrollbar';
import Loading from './Loading';
import Empty from './Empty';

const ListRoot = styled('div')({
  flex: 1,
  overflow: 'hidden',
  marginTop: 20,
});

interface ListProps {
  loading?: boolean;
  isEmpty?: boolean;
}

const List: React.FC<ListProps> = ({ loading, children, isEmpty }) => {
  if (loading) {
    return <Loading />;
  }

  if (isEmpty) {
    return <Empty />;
  }

  return (
    <ListRoot>
      <Scrollbar>
        <ListComponent>{children}</ListComponent>
      </Scrollbar>
    </ListRoot>
  );
};

export default List;
