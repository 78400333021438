import React, { useState } from 'react';
import Tippy from '@tippyjs/react';

import 'tippy.js/dist/tippy.css';

export interface TooltipProps {
  visible?: boolean;
  content?: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = (props) => {
  const { visible: visibleProp = false, content, children } = props;
  const [visible, setVisible] = useState<boolean>(false);

  const handleMouseEnter = () => {
    setVisible(true);
  };

  const handleMouseLeave = () => {
    setVisible(false);
  };

  if (!React.isValidElement(children)) return null;

  return (
    <Tippy content={content} placement="right" visible={visibleProp && visible}>
      {React.cloneElement(children, {
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
      })}
    </Tippy>
  );
};

export default Tooltip;
