import React from 'react';

import styled from '@emotion/styled';
import clsx from 'clsx';

import { composeClasses, lighten } from 'src/utils';

import InputBase, {
  classNamePrefix as inputClassNamePrefix,
  InputProps,
} from './Input';

const classNamePrefix = 'OutlinedInput';

const useUtilityClasses = () => {
  const slots = {
    root: ['root'],
  };

  return composeClasses(classNamePrefix, slots);
};

const OutlinedInputRoot = styled(InputBase)(({ theme }) => ({
  border: '1px solid #eee',
  paddingLeft: 12,
  paddingRight: 12,
  borderRadius: 4,
  [`&.${inputClassNamePrefix}-error`]: {
    '&:focus': {
      borderColor: theme.palette.error?.main,
      boxShadow: `0 0 0 2px ${lighten(
        theme.palette.error?.main || '#ff3b30',
        0.8
      )}`,
    },
  },
  '&:focus': {
    borderColor: theme.palette.primary?.main,
    boxShadow: `0 0 0 2px ${lighten(
      theme.palette.primary?.main || '#5a5aee',
      0.8
    )}`,
  },
}));

const OutlinedInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...rest }, ref) => {
    const classes = useUtilityClasses();

    return (
      <OutlinedInputRoot
        {...rest}
        ref={ref}
        className={clsx(classes.root, className)}
      />
    );
  }
);

export default OutlinedInput;
