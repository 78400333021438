import styled from '@emotion/styled';
import clsx from 'clsx';

import { shouldForwardProp, composeClasses } from 'src/utils';

function capitalize(input: string) {
  return input.charAt(0).toUpperCase() + input.slice(1);
}

const classNamePrefix = 'VariantIcon';

const useUtilityClasses = (props: Pick<VariantIconProps, 'variant'>) => {
  const { variant = 'default' } = props;

  const slots = {
    root: ['root', capitalize(variant)],
  };

  return composeClasses(classNamePrefix, slots);
};

interface VariantIconProps {
  className?: string | undefined;
  variant?: 'default' | 'warning' | 'error' | 'success' | 'info';
  style?: React.CSSProperties;
}

const VariantIconRoot = styled('div', {
  shouldForwardProp: (prop) => shouldForwardProp(prop) && prop !== 'variant',
})<Pick<VariantIconProps, 'variant'>>(({ theme, variant = 'default' }) => ({
  fontSize: 18,
  marginRight: 10,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  top: 4,
  '& > svg': {
    color: 'inherit',
  },
  ...(variant === 'error' && { color: theme.palette.error?.main || '#ff3b30' }),
  ...(variant === 'warning' && {
    color: theme.palette.warning?.main || '#fe805f',
  }),
  ...(variant === 'info' && {
    color: theme.palette.primary?.main || '#5a5aee',
  }),
  ...(variant === 'success' && {
    color: theme.palette.success?.main || '##52c41a',
  }),
}));

const VariantIcon: React.FC<VariantIconProps> = (props) => {
  const { className, variant = 'default', style, children } = props;

  const classes = useUtilityClasses({ variant });

  return (
    <VariantIconRoot
      className={clsx(classes.root, className)}
      variant={variant}
      style={style}
    >
      {children}
    </VariantIconRoot>
  );
};

export default VariantIcon;
