import React from 'react';
import ReactWatermark from 'react-watermark-component';
import { Outlet } from 'react-router-dom';
import styled from '@emotion/styled';
import { useAppSelector } from 'src/hooks';

export interface WatermarkProps {}

const WatermarkRoot = styled(ReactWatermark)({});

const Watermark: React.FC = () => {
  const watermarkText = useAppSelector((state) => (state.auth?.user as any)?.watermark);

  return (
    <WatermarkRoot
      waterMarkText={watermarkText || ''}
      options={{ chunkHeight: 200, chunkWidth: 200, globalAlpha: 0.15, fillStyle: '#999' }}
    >
      <Outlet />
    </WatermarkRoot>
  );
};

export default Watermark;
