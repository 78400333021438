import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import useForkRef, { setRef } from 'src/hooks/useForkRef';

type Container = HTMLElement | (() => HTMLElement | null) | null | undefined;

export interface PortalProps {
  /**
   * 容器
   */
  container?: Container;
  /**
   * @default false
   */
  disablePortal?: boolean;
  children?: React.ReactNode;
}

function getContainer(container?: Container) {
  return typeof container === 'function' ? container() : container;
}

const Portal = React.forwardRef<HTMLElement, PortalProps>(function Portal(
  props,
  ref
) {
  const { children, container, disablePortal = false } = props;
  const [mountNode, setMountNode] = useState<HTMLElement | null>(null);
  const handleRef = useForkRef(
    React.isValidElement(children) ? (children as any).ref : null,
    ref
  );

  useEffect(() => {
    if (!disablePortal) {
      setMountNode(getContainer(container) || document.body);
    }
  }, [container, disablePortal]);

  useEffect(() => {
    if (mountNode && !disablePortal) {
      setRef(ref, mountNode);

      return () => {
        setRef(ref, null);
      };
    }

    return undefined;
  }, [ref, mountNode, disablePortal]);

  if (disablePortal) {
    if (React.isValidElement(children)) {
      return React.cloneElement(children, { ref: handleRef });
    }
  }

  return mountNode ? ReactDOM.createPortal(children, mountNode) : mountNode;
});

export default Portal;
