import styled from '@emotion/styled';

import { shouldForwardProp } from 'src/utils';

export default styled('ul', { label: 'List', shouldForwardProp })(() => ({
  listStyle: 'none',
  position: 'relative',
  margin: 0,
  padding: 0,
}));
