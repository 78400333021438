import {
  DepartmentItem,
  DepartmentSearchResult,
  Member as MemberItem,
  MemberSearchResult,
} from 'src/shared/interfaces';
import { Department, Member } from './interface';
import cache from './cache';

/**
 * 扁平化组织结构
 * @param tree
 * @returns
 */
export function flattenDepartments(
  tree: Department[]
): Record<string, Department> {
  return tree.reduce((acc, item) => {
    if (item.children && item.children.length > 0) {
      acc = { ...acc, ...flattenDepartments(item.children) };
    }

    return { ...acc, [item.id]: item };
  }, {});
}

/**
 * 统一组织架构数据结构
 * @param tree
 * @param parent
 * @param parents
 * @returns
 */
export function resolveDepartments(
  tree: DepartmentItem[],
  parent: DepartmentItem | null = null,
  parents: (string | number)[] = []
): Department[] {
  return tree.reduce<Department[]>((acc, item) => {
    const node: Department = {
      key: item.id,
      id: item.id,
      value: item.id,
      pid: parent ? parent.id : -1,
      title: item.name,
      parents,
      type: 'department',
      children:
        item.allChildren && item.allChildren.length > 0
          ? resolveDepartments(item.allChildren, item, [...parents, item.id])
          : [],
    };

    return [...acc, node];
  }, []);
}

/**
 * 统一部门搜索结果
 * @param data
 * @returns
 */
export function resolveDepartmentSearchResult(
  data: DepartmentSearchResult[]
): Department[] {
  return data.map((item) => ({
    key: item.id,
    id: item.id,
    value: item.id,
    pid: item.departmentId,
    title: item.name,
    parents: item.source
      .split('-')
      .filter((item) => item)
      .map(Number),
    type: 'department',
    children: [],
  }));
}

/**
 * 统一成员数据结构
 * @param data
 * @returns
 */
export function resolveMembers(data: MemberItem[]): Member[] {
  return data.map((item) => {
    let member: Member = {
      id: item.id,
      name: item.name,
      job: item.job,
      pid: item.departmentId,
      department: item.departmentName,
      phone: item.phone,
      parents: [item.departmentId],
      type: 'member',
    };

    const department = getDepartmentById(item.departmentId);
    if (department) {
      member.parents = [...department.parents, item.departmentId];
    }

    return member;
  });
}

/**
 * 统一成员搜索结果
 * @param data
 * @returns
 */
export function resolveMemberSearchResult(
  data: MemberSearchResult[]
): Member[] {
  return data.map((item) => ({
    id: item.id,
    name: item.name,
    job: item.job,
    pid: item.departmentId,
    department: item.departments,
    phone: item.phone,
    parents: item.departmentResource
      .split('-')
      .filter((item) => item)
      .map(Number),
    type: 'member',
  }));
}

/**
 * 从选中的数据中筛选出类型时成员的 id
 * @param checkedList
 * @returns
 */
export function getCheckedMembersId(
  checkedList: (Department | Member)[]
): (string | number)[] {
  return checkedList
    .filter((item): item is Member => item.type === 'member')
    .map((item) => item.id);
}

/**
 * 从选中的数据中筛选出类型是部门的 id
 * @param checkedList
 * @returns
 */
export function getCheckedDepartmentsId(
  checkedList: (Department | Member)[]
): (string | number)[] {
  return checkedList
    .filter((item): item is Department => item.type === 'department')
    .map((item) => item.id);
}

/**
 * 全选按钮状态： indeterminate or checked
 */
export function getStatusOfSelectAll(
  checkedList: (Department | Member)[],
  members: Member[],
  department: Department
): 'indeterminate' | 'checked' | '' {
  const checkedDepartmentsId = getCheckedDepartmentsId(checkedList);
  const parents = [...department.parents, department.id];
  // 某个父级部门选中，则全选为选中状态
  if (checkedDepartmentsId.some((id) => parents.includes(id))) {
    return 'checked';
  }

  const checkedMembersId = getCheckedMembersId(checkedList);
  // 交集
  const intersection = members.filter((item) =>
    checkedMembersId.includes(item.id)
  );
  if (intersection.length > 0) {
    if (intersection.length === members.length) {
      return 'checked';
    } else {
      return 'indeterminate';
    }
  }

  return '';
}

/**
 * 部门选中状态
 *
 * 当前部门或某个父级部门被选中
 * @param department
 * @param checkedList
 * @returns
 */
export function isDepartmentChecked(
  department: Department,
  checkedList: (Department | Member)[]
): boolean {
  const checkedDepartmentsId = getCheckedDepartmentsId(checkedList);
  return checkedDepartmentsId.some((id) =>
    [...department.parents, department.id].includes(id)
  );
}

/**
 * 成员选中状态
 *
 * 考虑两种情况：
 * 1. 成员本身被选中
 * 2. 成员所在部门或其祖先部门被选中
 * @param member
 * @param checkedList
 * @returns
 */
export function isMemberChecked(
  member: Member,
  checkedList: (Department | Member)[]
): boolean {
  const checkedMembersId = getCheckedMembersId(checkedList);
  const checkedDepartmentsId = getCheckedDepartmentsId(checkedList);

  return (
    checkedMembersId.includes(member.id) ||
    checkedDepartmentsId.some((id) => member.parents.includes(id))
  );
}

/**
 * 成员禁用状态
 *
 * 成员所在部门或某个父级部门被选中
 * @param department
 * @param checkedList
 * @returns
 */
export function isMemberDisabled(
  member: Member,
  checkedList: (Department | Member)[]
): boolean {
  const checkedDepartmentsId = getCheckedDepartmentsId(checkedList);
  return checkedDepartmentsId.some((id) => member.parents.includes(id));
}

/**
 * 部门禁用状态
 *
 * 某个父级部门被选中
 * @param department
 * @param checkedList
 * @returns
 */
export function isDepartmentDisabled(
  department: Department,
  checkedList: (Department | Member)[]
): boolean {
  const checkedDepartmentsId = getCheckedDepartmentsId(checkedList);
  return checkedDepartmentsId.some((id) => department.parents.includes(id));
}

/**
 * 根据 id 获取部门
 * @param id
 * @returns
 */
export function getDepartmentById(
  id?: string | number | null
): Department | null {
  if (id == null) return null;
  const cached = cache.get<Record<string, Department>>('department');
  return cached?.[id] || null;
}

/**
 * 获取当前部门所有子部门， 返回列表
 * @param department
 * @returns
 */
export function getChildrenOfDepartment(department: Department): Department[] {
  const departments = Object.values(
    cache.get<Record<string, Department>>('department')
  );
  return departments.filter((item) => item.parents.includes(department.id));
}

/**
 * 获取所属公司
 * @param item
 * @returns
 */
export function getRootDepartment(
  item: Department | Member
): Department | null {
  const root = getDepartmentById(item.parents[0]);
  if (!root) {
    if (item.type === 'member') {
      return getDepartmentById(item.pid);
    }
    return item;
  }

  return root;
}
