import React from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';

import { composeClasses } from 'src/utils';

import RadioGroupContext from './RadioGroupContext';

type Event = React.ChangeEvent<HTMLInputElement>;

type ChangeHandler = (event: Event, value: string) => void;

export interface RadioGroupProps {
  className?: string | undefined;
  row?: boolean;
  name?: string;
  onChange?: ChangeHandler;
  value?: any;
  children?: React.ReactNode;
  disabled?: boolean;
}

const classPrefix = 'RadioGroup';

const useUtilityClasses = (props: { row?: boolean; disabled?: boolean }) => {
  const { row, disabled } = props;

  const slots = {
    root: ['root', row && 'row', disabled && 'disabled'],
  };

  return composeClasses(classPrefix, slots);
};

const RadioGroupRoot = styled('div', {
  label: `${classPrefix}Root`,
  shouldForwardProp: (prop) =>
    prop !== 'theme' && prop !== 'as' && prop !== 'row',
})<Pick<RadioGroupProps, 'row'>>(({ row }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  ...(row && {
    flexDirection: 'row',
  }),
}));

const RadioGroup = React.memo<RadioGroupProps>(function RadioGroup({
  row,
  name,
  onChange,
  value,
  children,
  className,
  disabled,
}) {
  const classes = useUtilityClasses({ row, disabled });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event, event.target.value);
    }
  };

  const childContext = {
    name,
    onChange: handleChange,
    value,
    disabled,
  };

  return (
    <RadioGroupContext.Provider value={childContext}>
      <RadioGroupRoot className={clsx(classes.root, className)} row={row}>
        {children}
      </RadioGroupRoot>
    </RadioGroupContext.Provider>
  );
});

export default RadioGroup;
