import { createSvgIcon } from './utils';

export default createSvgIcon(
  [
    <path
      key="1"
      d="M12.68,12.0460935 C11.6627671,10.7854387 10.2180373,9.87771307 8.56301173,9.53847418 C10.0270903,8.96825233 11.0633917,7.5559976 11.0633917,5.90408416 C11.0633917,3.74791921 9.2978815,2 7.12001703,2 C4.94215256,2 3.1766424,3.74791921 3.1766424,5.90408416 C3.1766424,7.5536378 4.20999934,8.96422678 5.67080056,9.53602762 C2.56261515,10.167882 0.193155253,12.8038849 0,16.02 L11.8380282,16.02 C11.6552659,15.5934831 11.5541227,15.1243857 11.5541227,14.6318812 C11.5541227,13.6131408 11.9868073,12.6945532 12.68,12.0460935 Z"
    />,
    <path
      key="2"
      d="M14.3479668,9.5786251 C15.2827752,9.00844592 15.906045,7.98587172 15.906045,6.8190639 C15.906045,5.0301826 14.4412867,3.58 12.6344255,3.58 C12.2282898,3.58 11.8395445,3.65347652 11.4807335,3.7873867 C11.8785065,4.39627875 12.1095668,5.12193299 12.1095668,5.90104044 C12.1095668,7.2536181 11.5567156,8.61445797 10.5,9.31331632 C10.6259136,9.43189724 11.9540861,9.56485475 13.3692278,11.5304296 C13.9087126,11.2117369 14.4973591,11.0800784 15.1712427,11.0800784 C16.0218813,11.0800784 16.8035709,11.3720272 17.42,11.86 C16.8641221,10.7640532 15.7366906,9.91753165 14.3479668,9.5786251 Z"
    />,
    <path
      key="3"
      d="M15.17,11.78 C13.6512118,11.78 12.42,12.9977804 12.42,14.5 C12.42,16.0022196 13.6512118,17.22 15.17,17.22 C16.6887882,17.22 17.92,16.0022196 17.92,14.5 C17.92,12.9977804 16.6887882,11.78 15.17,11.78 Z M16.8340127,14.9677707 L15.6429299,14.9677707 L15.6429299,16.1458599 C15.6429299,16.4042079 15.4311975,16.6136306 15.17,16.6136306 C14.9088025,16.6136306 14.6970701,16.4042079 14.6970701,16.1458599 L14.6970701,14.9677707 L13.5059873,14.9677707 C13.2447898,14.9677707 13.0330573,14.758348 13.0330573,14.5 C13.0330573,14.241652 13.2447898,14.0322293 13.5059873,14.0322293 L14.6970701,14.0322293 L14.6970701,12.8541401 C14.6970701,12.5957921 14.9088025,12.3863694 15.17,12.3863694 C15.4311975,12.3863694 15.6429299,12.5957921 15.6429299,12.8541401 L15.6429299,14.0322293 L16.8340127,14.0322293 C17.0952102,14.0322293 17.3069427,14.241652 17.3069427,14.5 C17.3069427,14.758348 17.0952102,14.9677707 16.8340127,14.9677707 Z"
    />,
  ],

  'GroupAdd'
);
