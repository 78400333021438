import { createContext } from 'react';

import initialUploadState, { UploadState } from './state';
import { UploadActions } from './actions';

const UploadContext = createContext<{
  state: UploadState;
  dispatch: React.Dispatch<UploadActions>;
}>({
  state: initialUploadState,
  dispatch: () => undefined,
});

if (process.env.NODE_ENV !== 'production') {
  UploadContext.displayName = 'UploadContext';
}

export default UploadContext;
