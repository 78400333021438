import React from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';

import { composeClasses, shouldForwardProp } from 'src/utils';

import { Fade } from '../Transitions';

const classNamePrefix = 'Backdrop';

const useUtilityClasses = () => {
  const slots = {
    root: ['root'],
  };
  return composeClasses(classNamePrefix, slots);
};

const BackdropRoot = styled('div', {
  shouldForwardProp: (prop) => shouldForwardProp(prop) && prop !== 'invisible',
})<{ invisible?: boolean }>(({ invisible = false }) => ({
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  WebkitTapHighlightColor: 'transparent',
  ...(invisible && {
    backgroundColor: 'transparent',
  }),
}));

export interface BackdropProps {
  open?: boolean;
  invisible?: boolean;
  className?: string | undefined;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  style?: React.CSSProperties;
  TransitionComponent?: React.ElementType;
}

const Backdrop = React.forwardRef<HTMLDivElement, BackdropProps>(
  function Backdrop(props, ref) {
    const {
      open,
      invisible = false,
      className,
      children,
      TransitionComponent = Fade,
      onClick,
      ...rest
    } = props;

    const classes = useUtilityClasses();

    return (
      <TransitionComponent in={open} {...rest}>
        <BackdropRoot
          className={clsx(classes.root, className)}
          invisible={invisible}
          onClick={onClick}
          ref={ref}
        >
          {children}
        </BackdropRoot>
      </TransitionComponent>
    );
  }
);

export default Backdrop;
