import React from 'react';
import clsx from 'clsx';

import styled from '@emotion/styled';
import { Popover } from 'src/components/Popover';
import { History as HistoryIcon } from 'src/components/Icons';

import { Tabs, TabPane } from '../common/Tabs';

import Documents from './list';

interface HistoryProps {
  className?: string;
}

const IconRoot = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 18,
});

const TriggerRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  padding: 8,
  borderRadius: 10,
  '&:hover': {
    backgroundColor: theme.palette.background?.paper,
  },
}));

const Paper = styled('div')({
  width: 400,
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflow: 'hidden',
});

const History = React.memo<HistoryProps>(function History({ className }) {
  return (
    <Popover
      className={clsx('History-root', className)}
      trigger="click"
      button={
        <TriggerRoot>
          <IconRoot>
            <HistoryIcon />
          </IconRoot>
        </TriggerRoot>
      }
    >
      <Paper>
        <Tabs centered tabBarGutter={130} animated={false}>
          <TabPane tab="文档" key="1">
            <Documents tabKey="book" />
          </TabPane>
          <TabPane tab="文件" key="2">
            <Documents tabKey="file" />
          </TabPane>
        </Tabs>
      </Paper>
    </Popover>
  );
});

export default History;
