import { createSvgIcon } from './utils';

export default createSvgIcon(
  [
    <path
      key="1"
      d="M3.34844999,12.8566113 L5.15394806,11.0520724 L5.15394809,11.0520725 C4.82104815,10.411936 4.64813314,9.70077227 4.64997249,8.97933093 C4.64997249,6.5408822 6.5833366,4.56526288 8.9671494,4.56526288 L8.96714935,4.56526288 C9.7091523,4.56431141 10.4383126,4.7587274 11.0812505,5.12894313 L12.1723959,4.03837734 L12.1723959,4.03837734 C11.1290831,3.73315468 10.0474172,3.57880127 8.96033039,3.58000701 C3.84422642,3.58032769 2.45486153e-06,7.191127 2.45486153e-06,8.97692712 C-0.00204487669,10.0092005 1.27662924,11.6525611 3.34844999,12.8562699 L3.34844999,12.8566113 Z M14.5858624,5.07100574 L12.7680929,6.88782323 L12.768093,6.88782336 C13.108185,7.53325507 13.2855139,8.25189925 13.2846817,8.98136605 C13.2846817,11.4198011 11.3513175,13.3954341 8.96749107,13.3954341 L8.96749101,13.3954341 C8.21824409,13.3966509 7.48226616,13.1978107 6.83567967,12.8194753 L5.75338933,13.9015185 L5.75338933,13.9015185 C6.79314244,14.2182166 7.87404985,14.379455 8.96101372,14.38 C14.2264656,14.38 17.92,10.7030964 17.92,8.97898957 C17.9179502,7.9364727 16.6743822,6.27438695 14.5858625,5.07066444 L14.5858624,5.07100574 Z"
    />,
    <path
      key="2"
      d="M6.21145272,8.97838111 L6.21145272,8.97753141 C6.21145272,9.27834778 6.25805609,9.57734716 6.34960536,9.86389535 L9.8850321,6.3310028 L9.8850321,6.33100277 C9.58855464,6.2228923 9.27540947,6.1676263 8.95983517,6.16771908 C7.44383255,6.16771908 6.21316315,7.42527048 6.21316315,8.97836338 L6.21145272,8.97838111 Z M8.96016052,11.789039 C10.4782124,11.789039 11.708869,10.5277307 11.708869,8.97838111 L11.708869,8.9784173 C11.7091006,8.67742196 11.6617027,8.37828596 11.5684326,8.09210527 L8.03663996,11.6260771 L8.03663996,11.6260772 C8.33311492,11.7341992 8.64626146,11.7894701 8.9618385,11.7893746 L8.96016052,11.789039 Z M7.9336987,11.1198659 L11.0768316,7.97684842 L15.7086975,3.34686021 L15.7086975,3.34686021 C16.0169038,3.03894526 16.0171292,2.53948907 15.709203,2.23129406 C15.4012767,1.92309905 14.9018022,1.92287363 14.5935959,2.23078855 C14.5935959,2.23078856 14.5935959,2.23078857 14.5935959,2.23078857 L11.9522724,4.87508897 L9.99037942,6.83690995 L6.84519725,9.97992746 L2.21469756,14.6102572 L2.21469755,14.6102572 C1.90470969,14.9161065 1.90136384,15.4153332 2.2072244,15.7253111 C2.51308494,16.0352875 3.01232994,16.0386333 3.32231917,15.7327839 C3.32482919,15.7303074 3.32732256,15.7278141 3.32979912,15.7253042 L6.94815489,12.1070812 L7.9336987,11.1198659 Z"
    />,
  ],

  'Hide'
);
