import React, { useState } from 'react';
import { Dropdown, Menu, message } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';

import { Typography } from 'src/components/Typography';
import { ID } from 'src/shared/interfaces';
import { useDialogContext } from 'src/components/Dialog';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { examineDetail } from 'src/pages/Admin/Review/review.slice';
import ReviewDetails from 'src/pages/Review/components/Details';
import { Loading } from 'src/shared/constants';
import { fetchDetails, selectLoading, review as reviewThunk } from 'src/pages/Review/slice';
import { markRead, updateNotification } from '../slice';
import { NotificationTypes } from '../utils';

const PREFIX = 'AppBar-Notification--ListItemActions';

const classNames = {
  root: `${PREFIX}__root`,
  icon: `${PREFIX}__icon`,
};

const ListItemActionsRoot = styled('div')({
  [`&.${classNames.root}`]: {
    position: 'relative',
    fontSize: 20,
    flexShrink: 0,
  },
  [`& .${classNames.icon}`]: {
    fontSize: 20,
  },
});

const { Item: MenuItem } = Menu;

export interface ListItemActionsProps {
  status?: number;
  authorityId?: ID;
  id: ID;
}

const ListItemActions: React.FC<ListItemActionsProps> = ({ id, authorityId, status }) => {
  const dispatch = useAppDispatch();
  const { openDialog } = useDialogContext();
  const [showReview, setShowReview] = useState<boolean>(false);
  const review = useAppSelector((state) => state.review.details);
  const loading = useAppSelector((state) => selectLoading(state, 'details'));

  const handleClick = async ({ key, domEvent }: any) => {
    domEvent.preventDefault();
    dispatch(markRead(id));
    if (!authorityId) {
      openDialog({
        variant: 'warning',
        type: 'tip',
        content: '该资源不存在或已被删除。',
      });
    } else {
      if (key === 'details') {
        setShowReview(true);
        await dispatch(fetchDetails(authorityId));
      }
      if (key === 'approve') {
        onReview(authorityId, 1);
      }
      if (key === 'refuse') {
        onReview(authorityId, 2);
      }
    }
  };

  /**
   * 处理审核
   */
  const onReview = async (authorityId: ID, status: 1 | 2) => {
    const resultAction = await dispatch(reviewThunk({ id: authorityId, status }));
    if (reviewThunk.fulfilled.match(resultAction)) {
      message.success(resultAction.payload);
      dispatch(
        updateNotification({ type: NotificationTypes.all, changed: { id, changed: { status } } })
      );
      return true;
    }
    message.error(resultAction.payload);
    return false;
  };

  /**
   * 详情弹窗回调
   */
  const handleReview = async (
    status: 1 | 2,
    helper?: { setSubmitting: React.Dispatch<React.SetStateAction<false | 1 | 2>> }
  ) => {
    if (authorityId) {
      const success = await onReview(authorityId, status);
      helper?.setSubmitting(false);
      if (success) {
        setShowReview(false);
      }
    }
  };

  const menus = (
    <Menu onClick={handleClick}>
      <MenuItem key="details">查看详情</MenuItem>
      <MenuItem key="approve">同意</MenuItem>
      <MenuItem key="refuse">拒绝</MenuItem>
    </Menu>
  );

  if (status === 1) {
    return (
      <ListItemActionsRoot className={classNames.root}>
        <Typography noWrap>已同意</Typography>
      </ListItemActionsRoot>
    );
  }

  if (status === 2) {
    return (
      <ListItemActionsRoot className={classNames.root}>
        <Typography>已拒绝</Typography>
      </ListItemActionsRoot>
    );
  }

  return (
    <>
      <Dropdown overlay={menus}>
        <ListItemActionsRoot className={classNames.root}>
          <EllipsisOutlined className={classNames.icon} />
        </ListItemActionsRoot>
      </Dropdown>
      <ReviewDetails
        type={review?.type}
        open={showReview}
        base={(review as any)?.base}
        user={{ avatar: review?.avatar, name: review?.userName, phone: (review as any)?.phone }}
        position={review?.union}
        reason={review?.reason}
        onClose={() => setShowReview(false)}
        loading={loading === Loading.pending}
        onReview={handleReview}
      />
    </>
  );
};

export default ListItemActions;
