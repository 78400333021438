import React from 'react';
import styled from '@emotion/styled';
import { Spin } from 'antd';

const LoadingRoot = styled('div')({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Loading: React.FC = () => {
  return (
    <LoadingRoot>
      <Spin />
    </LoadingRoot>
  );
};

export default Loading;
