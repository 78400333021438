/**
 * 收藏类型
 *
 * 1: 文档
 */
export enum FavoriteType {
  papers = 1,
  folders = 2,
  books = 3,
  repositories = 4,
  qa = 5,
}
