import { withProps, StyledElement } from '@udecode/plate';

import { CSSProp } from 'styled-components';
import styles from 'src/shared/styles/richText';

const OL = withProps(StyledElement, {
  as: 'ol',
  styles: {
    root: styles.ol as CSSProp,
  },
});

export default OL;
