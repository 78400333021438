import React from 'react';

import { useMemberSelector } from '../../Provider';
import { selectSearch } from '../../reducers/selectors';
import Members from './Members';
import Departments from './Departments';

const typeComponents = {
  member: Members,
  department: Departments,
};

const Search: React.FC = () => {
  const { type } = useMemberSelector(selectSearch);

  const Component = typeComponents[type];

  return <Component />;
};

export default Search;
