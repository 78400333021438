import React from 'react';
import styled from '@emotion/styled';
import { Select as AntSelect, SelectProps as AntSelectProps } from 'antd';
import clsx from 'clsx';

type SelectValue = {
  key?: string;
  value: string | number;
  label: React.ReactNode;
};

const Select = React.forwardRef<HTMLSelectElement, AntSelectProps<any>>(
  (props, ref) => {
    const {
      className,
      value,
      onChange,
      placeholder = '请选择',
      mode,
      options = [],
      ...rest
    } = props;

    return (
      <AntSelect<SelectValue>
        ref={ref}
        size="large"
        className={clsx('root', className)}
        placeholder={placeholder}
        allowClear
        showSearch
        optionFilterProp="label"
        mode={mode}
        labelInValue
        value={value}
        onChange={onChange}
        options={options}
        dropdownStyle={{ zIndex: 1301 }}
        {...rest}
      />
    );
  }
);

export default styled(Select, { label: 'Select' })({
  '&.root': {
    width: '100%',
    fontSize: 14,
    height: 40,
  },

  '&.ant-select .ant-select-selector': {
    borderColor: '#eee',
    borderRadius: 4,
  },
  '& .ant-select-selection-placeholder': {
    color: '#999',
  },
});
