import styled from '@emotion/styled';

const classNamePrefix = 'ModalActions';

const ModalActions = styled('div', { label: classNamePrefix })({
  paddingLeft: 60,
  paddingRight: 60,
  paddingBottom: 40,
  flexShrink: 0,
  textAlign: 'right',

  '& > :not(:first-of-type)': {
    marginLeft: 30,
  },
});

export default ModalActions;
