import React from 'react';
import styled from '@emotion/styled';
import SimpleBar, { Props as SimpleBarProps } from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

interface ScrollbarProps extends SimpleBarProps {
  children?: React.ReactNode;
}

const classNamePrefix = 'Scrollbar';

const ScrollbarRoot = styled('div', { label: classNamePrefix })(
  ({ theme }) => ({
    position: 'relative',
    width: '100%',
    height: '100%',
    flex: 1,
    overflow: 'auto',
    '& .simplebar-scrollbar:before': {
      backgroundColor: theme.palette.text?.secondary,
      width: 4,
      borderRadius: 4,
    },
  })
);

const Scrollbar = React.forwardRef<any, ScrollbarProps>(function Scrollbar(
  props,
  ref
) {
  const { children, ...rest } = props;

  return (
    <ScrollbarRoot>
      <SimpleBar ref={ref} style={{ height: '100%' }} {...rest}>
        {children}
      </SimpleBar>
    </ScrollbarRoot>
  );
});

export default Scrollbar;
