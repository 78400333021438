import { combineReducers } from '@reduxjs/toolkit';

import repositoryReducer from 'src/pages/Home/Home/slice';
import favoritesReducer from 'src/pages/Home/Favorites/favorites.slice';
import adminBackendReducer from 'src/pages/Admin/Backend/backend.slice';
import adminReviewReducer from 'src/pages/Admin/Review/review.slice';
import templateReducer from 'src/pages/Admin/Backend/Template/template.slice';
import draftReducer from 'src/pages/Home/Drafts/slice';
import BooksReducer from 'src/pages/Book/slice';
import folderReducer from 'src/pages/Repository/Folder/slice';
import baseTemplateReducer from 'src/pages/Repository/Admin/Templates/slice';
import historyReducer from 'src/pages/Repository/Admin/Operations/slice';
import recycleReducer from 'src/pages/Repository/Admin/Trash/slice';
import appBarReducer from 'src/layouts/MainLayout/AppBar/appBar.slice';
import packReducer from 'src/pages/Pack/slice';
import authBaseReducer from 'src/pages/Admin/KnowledgeBase/slice';
import MemberReducer from 'src/pages/Repository/Admin/Member/slice';
import studyReducer from 'src/pages/Repository/Admin/Study/slice';
import studyRecordReducer from 'src/pages/Study/slice';
import qaReducer from 'src/pages/QA/slice';
import repositoryAdminReducer from 'src/pages/Repository/Admin/slice';
import followsReducer from 'src/pages/Home/Follows/slice';
import managementReducer from 'src/pages/Admin/slice';
import reviewReducer from 'src/pages/Review/slice';

import breadcrumbsReducer from './breadcrumbs.slice';
import authReducer from './auth.slice';
import configReducer from './config.slice';

const rootReducer = combineReducers({
  appBar: appBarReducer,
  breadcrumbs: breadcrumbsReducer,
  auth: authReducer,
  repository: repositoryReducer,
  favorites: favoritesReducer,
  adminBackend: adminBackendReducer,
  adminReview: adminReviewReducer,
  draft: draftReducer,
  templateReducer: templateReducer,
  books: BooksReducer,
  baseTemplateReducer: baseTemplateReducer,
  folder: folderReducer,
  historyReducer: historyReducer,
  recycleReducer: recycleReducer,
  share: packReducer,
  authBaseReducer: authBaseReducer,
  config: configReducer,
  member: MemberReducer,
  study: studyReducer,
  studyRecord: studyRecordReducer,
  QA: qaReducer,
  repositoryAdmin: repositoryAdminReducer,
  follows: followsReducer,
  management: managementReducer,
  review: reviewReducer,
});

export default rootReducer;
