import React from 'react';
import styled from '@emotion/styled';

import { ListItem, ListItemIcon } from 'src/components/List';
import { Person as UserIcon } from 'src/components/Icons';
import { ID } from 'src/shared/interfaces';
import { Typography } from 'src/components/Typography';

import Checkbox from './Checkbox';
import Grid from './Grid';

const StyledCheckbox = styled(Checkbox)({
  alignSelf: 'flex-start',
  '& > span:last-of-type': {
    width: '100%',
    display: 'flex',
  },
});

const StyledListItemIcon = styled(ListItemIcon)({
  alignSelf: 'flex-start',
  position: 'relative',
  top: 3,
});

interface MemberItemProps {
  name?: string;
  department?: string;
  id?: ID;
  job?: string;
  onCheck?: ((id: ID | undefined, checked: boolean) => void) | undefined;
  checked?: boolean;
  disabled?: boolean;
}

const MemberItem: React.FC<MemberItemProps> = (props) => {
  const {
    name,
    department,
    id,
    job,
    onCheck,
    checked = false,
    disabled = false,
  } = props;

  const handleChange = (event: any) => {
    const { checked } = event.target;
    if (onCheck) {
      onCheck(id, checked);
    }
  };

  return (
    <ListItem>
      <StyledCheckbox
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
      />
      <StyledListItemIcon>
        <UserIcon fontSize={16} />
      </StyledListItemIcon>
      <Grid>
        <Grid item style={{ marginBottom: 4 }}>
          <div style={{ flex: 1, minWidth: 0 }}>
            <Typography variant="body2" noWrap>
              {name}
            </Typography>
          </div>
          <div style={{ flex: 3, minWidth: 0 }}>
            <Typography
              align="right"
              noWrap
              variant="body2"
              color="textSecondary"
            >
              {department}
            </Typography>
          </div>
        </Grid>
        <Grid item>
          <Typography variant="body2" color="textSecondary">
            ID: {id}
          </Typography>
          <Typography align="right" variant="body2" color="textSecondary">
            {job}
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default MemberItem;
