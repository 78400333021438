import { AxiosRequestConfig } from 'axios';
import jwt from 'jsonwebtoken';

const secret = 'aBcDEfgHijklmnopqrSTuVWXyz';

export function signToken(payload: string | object | Buffer) {
  return jwt.sign(payload, secret, { expiresIn: '1d' });
}

export function verifyToken(token: string) {
  return jwt.verify(token, secret);
}

export function checkAuthorization(config: AxiosRequestConfig) {
  const authHeader = config.headers['Authorization'];
  const token = authHeader.split(' ')[1];
  if (!token) return false;
  return true;
}
