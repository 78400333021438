import styled from '@emotion/styled';
import { Empty } from 'antd';

export default styled(Empty)({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
});
