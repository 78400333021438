import React from 'react';
import styled from '@emotion/styled';

import MenuContext from './context';
import clsx from 'clsx';

const PREFIX = 'Menu';

const classNames = {
  root: `${PREFIX}__root`,
  collapsed: `${PREFIX}__collapsed`,
};

const MenuRoot = styled('ul')({
  width: '100%',
  position: 'relative',
  listStyle: 'none',
  padding: 0,
  margin: 0,
});

export interface MenuProps {
  className?: string | undefined;
  collapsed?: boolean;
}

const Menu: React.FC<MenuProps> = ({ className, collapsed = false, children }) => {
  return (
    <MenuContext.Provider value={{ collapsed }}>
      <MenuRoot className={clsx(classNames.root, className, { [classNames.collapsed]: collapsed })}>
        {children}
      </MenuRoot>
    </MenuContext.Provider>
  );
};

export default Menu;
