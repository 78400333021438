import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { AppState, AppDispatch } from 'src/app/store';

// type useDispatch
export const useAppDispatch = () => useDispatch<AppDispatch>();

// type useSelector
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export { default as useEventCallback } from './useEventCallback';

export { default as useInterval } from './useInterval';

export { default as usePrevious } from './usePrevious';

export { default as useForkRef } from './useForkRef';

export { default as useMediaQuery } from './useMediaQuery';
