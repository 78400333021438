import React, { useEffect } from 'react';
import styled from '@emotion/styled';

import { Popover } from 'src/components/Popover';
import { useAppDispatch } from 'src/hooks';
import Tabs from '../common/Tabs/Tabs';
import { TabPane } from '../common/Tabs';
import Trigger from './Trigger';
import Extra from './Extra';
import List from './List';
import { fetchUnreadTotal } from './slice';
import { NotificationTypes } from './utils';

const PREFIX = 'AppBar-Notification';

const classNames = {
  root: `${PREFIX}__root`,
};

const NotificationRoot = styled('div')({
  [`&.${classNames.root}`]: {
    position: 'relative',
    width: 500,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
  },
});

const Notification: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const promise = dispatch(fetchUnreadTotal());

    return () => {
      promise.abort();
    };
  }, [dispatch]);

  return (
    <Popover button={<Trigger />} trigger="click">
      <NotificationRoot className={classNames.root}>
        <Tabs tabBarGutter={80} tabBarExtraContent={<Extra />} destroyInactiveTabPane>
          <TabPane tab="全部通知" key="0">
            <List type={NotificationTypes.all} />
          </TabPane>
          <TabPane tab="已读" key="1">
            <List type={NotificationTypes.read} />
          </TabPane>
          <TabPane tab="未读" key="2">
            <List type={NotificationTypes.unread} />
          </TabPane>
        </Tabs>
      </NotificationRoot>
    </Popover>
  );
};

export default Notification;
