import {
  combineReducers,
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import {
  Activity,
  HttpResponse,
  ID,
  ListAllData,
  Repository,
} from 'src/shared/interfaces';
import repositoryService from 'src/services/repository';
import { getErrorMessage } from 'src/utils';
import { Loading, ResourceType } from 'src/shared/constants';
import { AppState } from 'src/app/store';
import favoritesService, { FavoritesParams } from 'src/services/favorites';

import childrenReducer from './Main/Children/slice';
import latestReducer from './Main/Side/Latest/slice';
import commonService from 'src/services/common';

interface State {
  data: Partial<Repository> | undefined | null;
  activity: {
    list: Activity[];
    loading: Loading;
  };
  loading: Loading;
  id: string | number | null;
}

const name = 'repository/details';

const initialState: State = {
  data: null,
  id: null,
  loading: Loading.idle,
  activity: {
    loading: Loading.idle,
    list: [],
  },
};

/**
 * 知识库详情
 */
export const fetchDetails = createAsyncThunk<Partial<Repository>, ID>(
  `${name}/fetch`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await repositoryService.details(id);
      return response.data;
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

/**
 * 添加/取消收藏
 */
export const collect = createAsyncThunk<
  ID,
  Partial<FavoritesParams>,
  { rejectValue: { message: string } }
>(`${name}/collect`, async (params, { rejectWithValue }) => {
  try {
    const response = await favoritesService.favorite(params);
    return response.data.id;
  } catch (err) {
    const message = err.isAxiosError ? err.response.data : err.message;
    return rejectWithValue(message);
  }
});

type UpdateArgs = { id: ID } & {
  _method?: 'POST' | 'PATCH';
} & Partial<Repository>;

/**
 * 知识库更新
 */
export const updateDetails = createAsyncThunk<
  Partial<Repository> | undefined,
  UpdateArgs,
  { rejectValue: string }
>(`${name}/update`, async ({ id, ...rest }, { rejectWithValue }) => {
  try {
    const response = await repositoryService.update(id, rest);
    return response?.data;
  } catch (err) {
    const message = getErrorMessage(err);
    return rejectWithValue(message);
  }
});
/**
 * 知识库动态
 */
export const fetchActivity = createAsyncThunk<ListAllData<Activity>, ID>(
  `${name}/activity`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await repositoryService.fetchActivity(id);
      return response.data;
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

type AddMemberArgs = {
  id: ID;
  members: ID[];
  departments: ID[];
};

/** 添加成员 */
export const addMembers = createAsyncThunk<
  HttpResponse<{}>,
  AddMemberArgs,
  { rejectValue: string }
>(
  `${name}/addMembers`,
  async ({ id, members, departments }, { rejectWithValue }) => {
    try {
      const response = await repositoryService.addMembers(id, {
        members,
        departments,
      });
      return response;
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  }
);

type RequestForAccessArgument = {
  resource: ResourceType;
  resourceId: ID;
  type: 0 | 1 | 2 | 3;
};

/** 申请权限 */
export const requestForAccess = createAsyncThunk<
  {},
  RequestForAccessArgument,
  { rejectValue: string }
>(`${name}/request_for_access`, async (data, { rejectWithValue }) => {
  try {
    const response = await commonService.requestForAccess(data);
    console.log(response);
    return response.data;
  } catch (err) {
    return rejectWithValue(getErrorMessage(err));
  }
});

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset(state) {
      state.data = null;
      state.id = null;
      state.loading = Loading.idle;
      state.activity = {
        loading: Loading.idle,
        list: [],
      };
    },
    setId(state, action) {
      state.id = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDetails.pending, (state) => {
      state.loading = Loading.pending;
    });

    builder.addCase(fetchDetails.fulfilled, (state, action) => {
      state.loading = Loading.fulfilled;
      state.data = action.payload;
    });

    builder.addCase(fetchDetails.rejected, (state) => {
      state.loading = Loading.rejected;
      state.data = null;
    });

    builder.addCase(fetchActivity.pending, (state) => {
      state.activity.loading = Loading.pending;
    });

    builder.addCase(updateDetails.fulfilled, (state, action) => {
      if (state.data) {
        if (action.meta.arg.name) {
          state.data = { ...state.data, ...action.payload };
        } else {
          state.data.notice = action.payload?.notice;
        }
      }
    });

    builder.addCase(fetchActivity.fulfilled, (state, action) => {
      state.activity.loading = Loading.fulfilled;
      state.activity.list = action.payload.data;
    });

    builder.addCase(fetchActivity.rejected, (state) => {
      state.activity.loading = Loading.rejected;
      state.activity.list = [];
    });

    builder.addCase(collect.fulfilled, (state, action) => {
      if (state.data) {
        state.data.collect = action.payload;
      }
    });
  },
});

export const { reset, setId } = slice.actions;

export const selectIsLoading = createSelector(
  (state: AppState) => state.repository.details.data.loading,
  (loading) => loading === Loading.pending || loading === Loading.idle
);
export const selectDetails = (state: AppState) =>
  state.repository.details.data.data || {};

export const selectActivityIsLoading = createSelector(
  (state: AppState) => state.repository.details.data.activity.loading,
  (loading) => loading === Loading.pending || loading === Loading.idle
);
export const selectActivity = (state: AppState) =>
  state.repository.details.data.activity.list;

export default combineReducers({
  data: slice.reducer,
  children: childrenReducer,
  latest: latestReducer,
});
