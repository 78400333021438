import React, { useCallback } from 'react';

import { Company as CompanyIcon } from 'src/components/Icons';
import { ListItemIcon, ListItem } from 'src/components/List';
import { Typography } from 'src/components/Typography';

import List from '../../common/List';
import { useMemberDispatch, useMemberSelector } from '../../Provider';
import { selectDepartmentList, selectLoading } from '../../reducers/selectors';
import { ID } from 'src/shared/interfaces';
import { Department } from '../../interface';
import { showMembers } from '../../reducers/actions';

const Departments: React.FC = () => {
  const dispatch = useMemberDispatch();

  const loading = useMemberSelector(selectLoading('department'));
  const list = useMemberSelector(selectDepartmentList);

  const handleClick = useCallback(
    (id: ID) => {
      return () => {
        const department = list.find((item: Department) => item.id === id);
        let subdepartmentId: string | number | null = 0;
        if (department && department.id !== 0) {
          subdepartmentId = department.children[0]?.id || null;
        }

        dispatch(showMembers({ departmentId: id, subdepartmentId }));
      };
    },
    [list, dispatch]
  );

  return (
    <React.Fragment>
      <Typography variant="body1">组织架构</Typography>
      <List loading={loading} isEmpty={list.length === 0}>
        {list.map((item) => (
          <ListItem key={item.id} onClick={handleClick(item.id)}>
            <ListItemIcon>
              <CompanyIcon fontSize={16} />
            </ListItemIcon>
            <Typography noWrap variant="body2">
              {item.title}
            </Typography>
          </ListItem>
        ))}
      </List>
    </React.Fragment>
  );
};

export default Departments;
