import React, { useCallback, useEffect } from 'react';
import { List } from 'antd';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

import { useAppDispatch, useAppSelector } from 'src/hooks';
import { NotificationTypes } from '../utils';
import {
  getThunkAction,
  selectEntities,
  selectLoading,
  selectHasMore,
  selectCurrentPage,
  clear,
  markRead,
  remove,
} from '../slice';
import ListItem from './ListItem';
import { Loading } from 'src/shared/constants';
import { ID } from 'src/shared/interfaces';

const PREFIX = 'AppBar-Notification--List';

const classNames = {
  root: `${PREFIX}__root`,
  loader: `${PREFIX}__loader`,
};

const NotificationListRoot = styled('div')({
  [`&.${classNames.root}`]: {
    position: 'relative',
  },

  [`& .${classNames.loader}`]: {
    padding: 6,
    color: '#5a5aee',
    fontSize: 14,
    textAlign: 'center',
  },
});

export interface NotificationListProps {
  type: NotificationTypes;
}

const NotificationList: React.FC<NotificationListProps> = ({ type }) => {
  const dispatch = useAppDispatch();

  const entities = useAppSelector((state) => selectEntities(state, type));
  const hasMore = useAppSelector((state) => selectHasMore(state, type));
  const currentPage = useAppSelector((state) => selectCurrentPage(state, type));
  const loading = useAppSelector((state) => selectLoading(state, type));

  const loadData = useCallback(
    ({ page, limit }) => {
      const action = getThunkAction(type);
      return dispatch(action({ page, limit }));
    },
    [dispatch, type]
  );

  /** 标记已读 */
  const handleMark = useCallback(
    (id: ID) => {
      return async (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        await dispatch(markRead(id));
      };
    },
    [dispatch]
  );

  /** 删除消息 */
  const handleRemove = useCallback(
    (id: ID) => {
      return async (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        await dispatch(remove(id));
      };
    },
    [dispatch]
  );

  useEffect(() => {
    const promise = loadData({});

    return () => {
      promise.abort();
      dispatch(clear());
    };
  }, [loadData, dispatch]);

  useEffect(() => {
    const handleScroll = (event: Event) => {
      if (event.currentTarget) {
        const { scrollTop, offsetHeight, scrollHeight } = event.currentTarget as any;
        if (scrollTop + offsetHeight === scrollHeight) {
          if (hasMore && loading !== Loading.pending) {
            loadData({ page: (currentPage || 1) + 1 });
          }
        }
      }
    };

    const target = document.querySelector('.AppBar-Notification__root .ant-tabs-content-holder');
    target?.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      target?.removeEventListener('scroll', handleScroll);
    };
  }, [currentPage, hasMore, loadData, loading]);

  return (
    <NotificationListRoot className={classNames.root}>
      <List
        dataSource={entities}
        loading={loading !== Loading.fulfilled && entities.length === 0}
        renderItem={(item) => {
          const { url = '#', read, ...rest } = item;
          return (
            <ListItem
              key={item.id}
              as={Link}
              to={url}
              read={type === NotificationTypes.all ? read : undefined}
              onMark={handleMark(rest.id)}
              onRemove={handleRemove(rest.id)}
              {...rest}
            />
          );
        }}
      />
      {hasMore && loading === Loading.pending && <div className={classNames.loader}>加载中...</div>}
    </NotificationListRoot>
  );
};

export default NotificationList;
