import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'src/app/store';
import qaService from 'src/services/QA';
import favoritesService from 'src/services/favorites';

import { Loading, ResourceType } from 'src/shared/constants';
import { FavoriteResult, ID } from 'src/shared/interfaces';
import { getErrorMessage } from 'src/utils';
import { AnswerListMode, Approve, Type } from '../constants';
import { Answer } from '../types';
import { answerAdapter } from '../utils';

const name = 'QAAnswers' as const;

export interface State {
  questionId: ID | null;
  data: Answer[];
  loading: Loading;
  page: number;
  hasMore: boolean;
  total: number;
  mode: AnswerListMode;
  answerId: ID | null;
}

const initialState: State = {
  questionId: null,
  data: [],
  loading: Loading.idle,
  page: 0,
  hasMore: true,
  total: 0,
  mode: AnswerListMode.list,
  answerId: null,
};

type FetchAnswerListReturned = {
  data: Answer[];
  hasMore: boolean;
  page: number;
  total: number;
};

type FetchAnswerListParams = {
  questionId: number;
  page?: number;
  limit?: number;
  mode?: 'append' | 'assignment';
};

/**
 * 获取回答列表
 */
export const fetchAnswerList = createAsyncThunk<
  FetchAnswerListReturned,
  FetchAnswerListParams,
  { rejectValue: string; state: AppState }
>(`${name}/fetchAnswerList`, async (data, { rejectWithValue, getState }) => {
  try {
    const { questionId, page, limit = 15 } = data;
    const { QA } = getState();
    const nextPage = page || QA.answers.page + 1;
    const response = await qaService.fetchAnswerList(questionId, {
      page: nextPage,
      limit,
    });
    return {
      page: nextPage,
      data: response.data.data.map((item) => answerAdapter(item)),
      total: response.data.meta.pagination.total,
      hasMore:
        response.data.meta.pagination.current_page < response.data.meta.pagination.total_pages,
    };
  } catch (err) {
    return rejectWithValue(getErrorMessage(err));
  }
});

/**
 * 赞同/取消赞同
 */
export const approve = createAsyncThunk<string, ID, { rejectValue: string; state: AppState }>(
  `${name}/approve`,
  async (id, { rejectWithValue, getState }) => {
    try {
      const { QA } = getState();
      const answer = QA.answers.data.find((item) => item.id === id);
      if (answer) {
        let action = answer.approved ? Approve.disapprove : Approve.approve;
        const response = await qaService.approve(id, { type: Type.answer, action });
        return response.message;
      }
      throw new Error(`Answer ${id} Not Found`);
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  }
);

/**
 * 查看我的回答（回答详情）
 */
export const fetchAnswerDetails = createAsyncThunk<
  Answer,
  ID | undefined,
  { rejectValue: string; state: AppState }
>(`${name}/fetchAnswerDetails`, async (id, { rejectWithValue, getState }) => {
  try {
    const { QA } = getState();
    const answerId = id || QA.answers.answerId;
    if (answerId) {
      const response = await qaService.fetchAnswerDetails(answerId);
      return answerAdapter(response.data.answer);
    }
    throw new Error(`Answer id is required`);
  } catch (err) {
    return rejectWithValue(err);
  }
});

type FavoriteParams = {
  type: 1 | 2;
  folderId?: ID | undefined; // 收藏夹 id
  id: ID; // 资源（回答）id
};

// 收藏
export const favorite = createAsyncThunk<FavoriteResult, FavoriteParams, { rejectValue: string }>(
  `${name}/favorite`,
  async ({ type, folderId, id }, { rejectWithValue }) => {
    try {
      const response = await favoritesService.favorite({
        type,
        id: folderId,
        resourceId: id,
        resource: ResourceType.answer,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  }
);

/** 关闭回答 */
export const close = createAsyncThunk<void, ID, { rejectValue: string }>(
  `${name}/close`,
  async (id, { rejectWithValue }) => {
    try {
      await qaService.closeAnswer(id);
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  }
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
    updateItemById: (state, action: PayloadAction<{ id: ID; changed: Partial<Answer> }>) => {
      state.data = state.data.map((item) => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload.changed };
        }
        return item;
      });
    },
    showTheAnswer: (state, action: PayloadAction<ID | undefined>) => {
      if (action.payload) {
        if (!state.answerId) {
          state.answerId = action.payload;
          state.mode = AnswerListMode.theAnswer;
          state.data = [];
        }
      } else {
        if (state.answerId) {
          state.answerId = null;
          state.mode = AnswerListMode.list;
          state.data = [];
          state.hasMore = true;
        }
      }
    },
    removeAnswerById: (state, action: PayloadAction<ID>) => {
      state.data = state.data.filter((item) => item.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAnswerList.pending, (state) => {
      state.loading = Loading.pending;
    });

    builder.addCase(fetchAnswerList.fulfilled, (state, action) => {
      state.loading = Loading.fulfilled;
      state.total = action.payload.total;
      state.questionId = action.meta.arg.questionId;
      state.page = action.payload.page;
      state.hasMore = action.payload.hasMore;
      if (state.mode === AnswerListMode.list) {
        if (action.meta.arg.mode === 'append') {
          state.data = [...state.data, ...action.payload.data];
        } else {
          state.data = action.payload.data;
        }
      }
    });

    builder.addCase(approve.fulfilled, (state, action) => {
      const id = action.meta.arg;
      state.data = state.data.map((item) => {
        if (item.id === id) {
          return { ...item, approved: !item.approved };
        }
        return item;
      });
    });

    builder.addCase(fetchAnswerDetails.pending, (state) => {
      state.loading = Loading.pending;
    });

    builder.addCase(fetchAnswerDetails.fulfilled, (state, action) => {
      state.loading = Loading.fulfilled;
      state.data = [action.payload];
    });

    builder.addCase(fetchAnswerDetails.rejected, (state) => {
      state.loading = Loading.rejected;
    });

    builder.addCase(favorite.fulfilled, (state, action) => {
      const answerId = action.meta.arg.id;
      state.data = state.data.map((item) => {
        if (item.id === answerId) {
          return { ...item, collectId: action.payload.id };
        }
        return item;
      });
    });
  },
});

export default slice.reducer;

export const { reset, updateItemById, showTheAnswer, removeAnswerById } = slice.actions;

/** Selectors */
export const selectAnswer = createSelector(
  [(state: AppState) => state.QA.answers.data, (_: AppState, id: ID | undefined) => id],
  (answers, id) => answers.find((answer) => answer.id === id)
);
