import styled from '@emotion/styled';
import clsx from 'clsx';

import { composeClasses } from 'src/utils';

import formControlState from './formControlState';
import useFormControl from './useFormControl';

const classNamePrefix = 'FormControlHelperText';

const useUtilityClasses = (props: { error?: boolean; disabled?: boolean }) => {
  const { error, disabled } = props;

  const slots = {
    root: ['root', error && 'error', disabled && 'disabled'],
  };

  return composeClasses(classNamePrefix, slots);
};

function shouldForwardProp(prop: PropertyKey) {
  return (
    prop !== 'theme' && prop !== 'as' && prop !== 'error' && prop !== 'disabled'
  );
}

const FormControlHelperTextRoot = styled('div', { shouldForwardProp })<{
  error?: boolean;
  disabled?: boolean;
}>(({ theme, error, disabled }) => ({
  fontSize: 14,
  height: 24,
  display: 'inline-flex',
  alignItems: 'center',
  textAlign: 'left',
  ...(disabled && { color: theme.palette.text?.disabled }),
  ...(error && { color: theme.palette.error?.main }),
}));

const FormControlHelperText: React.FC<{ className?: string | undefined }> = (
  props
) => {
  const { children, className } = props;

  const formControl = useFormControl();

  const fcs = formControlState({
    props,
    states: ['disabled', 'error'],
    formControl,
  });

  const classes = useUtilityClasses({
    error: fcs.error,
    disabled: fcs.disabled,
  });

  return (
    <FormControlHelperTextRoot
      disabled={fcs.disabled}
      error={fcs.error}
      className={clsx(classes.root, className)}
    >
      {children}
    </FormControlHelperTextRoot>
  );
};

export default FormControlHelperText;
