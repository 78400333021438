import { message } from 'antd';
import React from 'react';
import { useAppDispatch } from 'src/hooks';
import { share } from 'src/pages/Pack/details.slice';
import { ResourceType } from 'src/shared/constants';
import { ID } from 'src/shared/interfaces';
import { omit } from 'src/utils';

import Step1 from './Step1';
import Step2 from './Step2';

export interface ShareModalProp {
  step?: number;
  onStepChange?: (step: number) => void;
  type?: 'resource' | 'pack';
  name?: string;
  resource?: ResourceType;
  resourceId?: ID;
  baseId?: ID;
}

const ShareModal: React.FC<ShareModalProp> = (props) => {
  const {
    step = 0,
    onStepChange,
    type = 'resource',
    name = '',
    resource,
    resourceId,
    baseId,
  } = props;
  const [loading, setLoading] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();

  const data = React.useRef<{ name: string; authType: string }>({
    name: '',
    authType: '1',
  });

  const handleCancel = () => {
    onStepChange?.(0);
  };

  const handleStep1Finished = (values: { name: string; authType: string }) => {
    data.current = { ...data.current, ...values };
    if (onStepChange) {
      onStepChange(2);
    }
  };

  const handleStep2Finished = async ({ members, departments }: any) => {
    setLoading(true);
    const params = {
      resource,
      resourceId,
      authType: data.current.authType,
      name: data.current.name,
      members,
      departments,
    };
    const resultAction = await dispatch(
      share(type === 'pack' ? omit(params, ['resource', 'resourceId', 'authType']) : params)
    );
    if (share.fulfilled.match(resultAction)) {
      message.success('分享成功');
    } else {
      message.error(resultAction.payload);
    }
    setLoading(false);

    if (onStepChange) {
      onStepChange(0);
    }
  };

  return (
    <React.Fragment>
      <Step1
        open={step === 1}
        onCancel={handleCancel}
        onFinish={handleStep1Finished}
        disabledAuth={type === 'pack'}
        initialValues={type === 'pack' ? undefined : { name, authType: '1' }}
        baseId={baseId}
      />
      <Step2
        open={step === 2}
        onCancel={handleCancel}
        onFinish={handleStep2Finished}
        loading={loading}
      />
    </React.Fragment>
  );
};

export default ShareModal;
