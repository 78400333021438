import React from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';

import { composeClasses } from 'src/utils';

const classNamePrefix = 'DialogTitle';

const useUtilityClasses = () => {
  const slots = {
    root: ['root'],
  };
  return composeClasses(classNamePrefix, slots);
};

const DialogTitleRoot = styled('div', { label: `${classNamePrefix}Root` })({
  padding: '40px 40px 20px 40px',
  flex: '0 0 auto',
});

const DialogTitle = React.forwardRef<
  HTMLDivElement,
  { children?: React.ReactNode; className?: string | undefined }
>(function DialogTitle(props, ref) {
  const classes = useUtilityClasses();

  const { className, ...other } = props;

  return (
    <DialogTitleRoot
      ref={ref}
      className={clsx(classes.root, className)}
      {...other}
    />
  );
});

export default DialogTitle;
