import './wdyr';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { configureAppStore } from 'src/app/store';
import httpClient from 'src/utils/httpClient';

import reportWebVitals from './reportWebVitals';
import App from './App';

const store = configureAppStore();

httpClient.setInterceptors(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
