import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { useAppDispatch, useAppSelector } from 'src/hooks';
import { getRecentForSideBar } from 'src/pages/Home/Home/Recent/slice';
import { getResourceBaseUrl, getResourceIcon } from 'src/utils';

import { MenuItem } from '../Menu';

import Extra from './Extra';

const Recent: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const list = useAppSelector((state) => state.repository.recent.sidebar);

  const handleClick = useCallback(
    (path: string) => {
      return () => {
        navigate(path);
      };
    },
    [navigate]
  );

  useEffect(() => {
    dispatch(getRecentForSideBar());
  }, [dispatch]);

  if (!list || list.length === 0) return null;

  return (
    <Extra title="最近">
      {list.map((item) => {
        const { component: Component, color } = getResourceIcon(item.resource);

        const Icon = Component ? <Component color={color} /> : undefined;

        let path = getResourceBaseUrl(item.resource);
        if (typeof path === 'function') {
          path = path(item.baseFolderType);
        }

        if (item.baseFolderId) {
          path += `/${item.baseFolderId}`;
        }

        if (item.resourceId) {
          path += `?pageId=${item.resourceId}`;
        }

        return (
          <MenuItem key={item.id} icon={Icon} to={path} onClick={handleClick(path)}>
            {item.name}
          </MenuItem>
        );
      })}
    </Extra>
  );
};

export default Recent;
