import { Book, Folder, Paper, File, QA } from 'src/components/Icons';
import { SvgIconPropsType } from 'src/components/SvgIcon';

/**
 * LocalStorage
 */
export enum LocalStorageKey {
  accessToken = 'ACCESS_TOKEN',
  expiredAt = 'EXPIRED_AT',
  user = 'USER',
}

/**
 * 正则
 */
export const regexp = {
  phone: /^(?:(?:\+|00)86)?1\d{10}$/,
  password: /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$)[^\s]{6,20}$/, // 6 - 20 位，至少包含数字，字母或符号（空格除外）的 2 中组合。
};

/**
 * 加载状态
 */
export enum Loading {
  idle = 'idle',
  pending = 'pending',
  fulfilled = 'Fulfilled',
  rejected = 'rejected',
}

/**
 * 资源类型
 *
 * 1知识库,2知识本/文件夹,3资源包,4知识页,5文件,6子文件夹
 */
export enum ResourceType {
  repository = 1,
  folder,
  resourcePack,
  paper,
  file,
  subfolder = 6,
  question = 9,
  answer = 10,
}

/**
 * 资源类型 icon
 */
export const ResourceIcon: Record<ResourceType, React.ComponentType<SvgIconPropsType> | undefined> =
  {
    [ResourceType.repository]: Book,
    [ResourceType.folder]: Folder,
    [ResourceType.resourcePack]: undefined,
    [ResourceType.paper]: Paper,
    [ResourceType.file]: File,
    [ResourceType.subfolder]: Folder,
    [ResourceType.question]: QA,
    [ResourceType.answer]: QA,
  };

/**
 * 资源类型 icon color
 * 知识本： <BookOpen color="#c1c5ff" />
 */
export const ResourceIconColor: Record<ResourceType, string | undefined> = {
  [ResourceType.repository]: '#ffd7c1',
  [ResourceType.folder]: '#d5c1ff',
  [ResourceType.resourcePack]: undefined,
  [ResourceType.paper]: '#c1e0ff',
  [ResourceType.file]: '#ffc1c1',
  [ResourceType.subfolder]: '#d5c1ff',
  [ResourceType.question]: '#5a5aee',
  [ResourceType.answer]: '#5a5aee',
};

/**
 * 知识页默认标题
 */
export const DefaultPageTitle = '未命名';

/**
 * 弹窗提示内容
 */
export const Tips = {
  /**
   * 知识库
   */
  repository: {
    remove: {
      variant: 'warning',
      content: '删除知识库后其所有知识本/文件夹将会一并删除，确定吗？',
    },
  },
  /**
   * 知识本
   */
  book: {
    remove: {
      variant: 'warning',
      content: '删除知识本会将知识本内所有内容删除，是否确定？',
    },
  },
  /**
   * 知识页
   */
  page: {
    edit: {
      variant: 'warning',
      content: '当前处于编辑页面，确认退出编辑吗？',
    },
    remove: {
      variant: 'warning',
      content: '删除知识页后其所有子知识页将会一并删除，确定吗？',
    },
    auth: {
      variant: 'error',
      content: '您没有执行此操作的权限，请向管理员申请。',
    },
  },
  /**
   * 草稿箱
   */
  draft: {
    remove: {
      variant: 'warning',
      content: '确定删除该草稿？',
    },
  },
  /**
   * 收藏夹
   */
  favorites: {
    select: {
      variant: 'warning',
      content: '请选择收藏内容',
      type: 'tip',
    },
    unfavorite: {
      variant: 'warning',
      content: '确定取消所选收藏内容？',
    },
    remove: {
      variant: 'warning',
      content: '删除收藏夹将其内所有收藏的内容一起删除，确定吗？',
    },
  },
  /**
   * 文件夹
   */
  folder: {
    remove: {
      variant: 'warning',
      content: '删除文件夹会将文件夹内所有内容删除，是否确定？',
    },
  },
  /**
   * 文件
   */
  file: {
    remove: {
      variant: 'warning',
      content: '确定删除此文件？',
    },
    upload: {
      variant: 'warning',
      content: '列表中有未上传完成的文件，确定要放弃上传吗？',
    },
  },
  /**
   * 部门
   */
  department: {
    remove: {
      variant: 'warning',
      content: '删除会将所有子部门一起删除，是否确定？',
    },
  },

  /**
   * 资料包
   */
  pack: {
    remove: {
      variant: 'warning',
      content: (name: string) => `确定从资料包中删除${name}？`,
    },
    clear: {
      variant: 'warning',
      content: `确定清空资料包？`,
    },
    share: {
      variant: 'warning',
      content: '请添加资料。',
      type: 'tip',
    },
  },
  /**
   * 审核
   */
  review: {
    details: {
      variant: 'warning',
      content: '位置不存在',
      type: 'tip',
    },
  },
} as const;

export const IMAGE_EXTENSIONS: `.${string}`[] = [
  '.png',
  '.jpg',
  '.jpeg',
  '.gif',
  '.webp',
  '.bmp',
  '.tif',
  '.svg',
];

export const VIDEO_EXTENSIONS: `.${string}`[] = ['.mp4'];

export const defaultPaginationConfig = {
  limit: 20,
};

/**
 * 内容区域宽度
 */
export const CONTENT_WIDTH = {
  xs: 960,
  sm: 960,
  md: 1120,
  lg: 1260,
  xl: 1520,
} as const;

/**
 * 侧边导航宽度
 */
export const SIDEBAR_WIDTH = {
  xs: 200,
  sm: 200,
  md: 256,
  lg: 300,
} as const;
