import React from 'react';
import { noop } from 'src/utils';

export type MenuItem = {
  path: string;
  name: string;
  icon?: JSX.Element;
  guard?: (<T>(args: T) => boolean) | undefined;
};

export interface Context {
  menus: MenuItem[]; // 导航目录
  collapsed: boolean; // 折叠
  disableHeaderGutter: boolean;
  toggle: React.Dispatch<React.SetStateAction<boolean>>;
  extra?: React.ReactElement;
}

const LayoutContext = React.createContext<Context>({
  menus: [],
  collapsed: false,
  toggle: noop,
  disableHeaderGutter: false,
  extra: undefined,
});

if (process.env.NODE_ENV !== 'production') {
  LayoutContext.displayName = 'LayoutContext';
}

export default LayoutContext;
