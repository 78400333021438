import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  EntityState,
} from '@reduxjs/toolkit';
import { getErrorMessage } from 'src/utils';

import { AppState } from 'src/app/store';
import { ID, ListData } from 'src/shared/interfaces';
import { Loading } from 'src/shared/constants';

import AuthorityService, { Authority } from 'src/services/admin/review';
import adminService, { ITemplateList } from 'src/services/admin';

const name = 'review';

const authorityAdapter = createEntityAdapter<Authority>({
  selectId: (authorityItem) => authorityItem.id,
});

type State = {
  all: EntityState<Authority> & AdapterState;
  apply: EntityState<Authority> & AdapterState;
  edit: EntityState<Authority> & AdapterState;
  upload: EntityState<Authority> & AdapterState;
  allBase: EntityState<Authority> & AdapterState;
  applyBase: EntityState<Authority> & AdapterState;
  editBase: EntityState<Authority> & AdapterState;
  uploadBase: EntityState<Authority> & AdapterState;
  draftInfo: any;
  keyword: '';
  examineInfo: Partial<Authority>;
  newKnowledge: any;
};
export type TabKey =
  | 'all'
  | 'apply'
  | 'edit'
  | 'upload'
  | 'allBase'
  | 'applyBase'
  | 'editBase'
  | 'uploadBase';

type AdapterState = {
  loading: Loading;
  page: number;
  limit: number;
  total: number;
};
const limit = 10;

const initList = {
  loading: Loading.idle,
  page: 1,
  limit,
  total: 0,
};

const initialState: State = {
  draftInfo: {},
  examineInfo: {},
  keyword: '',
  all: authorityAdapter.getInitialState<AdapterState>({
    ...initList,
  }),
  apply: authorityAdapter.getInitialState<AdapterState>({
    ...initList,
  }),
  edit: authorityAdapter.getInitialState<AdapterState>({
    ...initList,
  }),
  upload: authorityAdapter.getInitialState<AdapterState>({
    ...initList,
  }),
  allBase: authorityAdapter.getInitialState<AdapterState>({
    ...initList,
  }),
  applyBase: authorityAdapter.getInitialState<AdapterState>({
    ...initList,
  }),
  editBase: authorityAdapter.getInitialState<AdapterState>({
    ...initList,
  }),
  uploadBase: authorityAdapter.getInitialState<AdapterState>({
    ...initList,
  }),
  newKnowledge: [],
};

/**
 * 管理列表
 */

export const fetchAuthority = createAsyncThunk<
  ListData<Authority>,
  {
    baseId?: ID;
    page?: number;
    limit?: number;
    type: number;
    keyword?: string;
  },
  { rejectValue: string }
>(`${name}/fetchList`, async (data, { rejectWithValue }) => {
  try {
    const response = await AuthorityService.fetchAuthority(data);
    return response.data;
  } catch (error) {
    const message = getErrorMessage(error);
    return rejectWithValue(message);
  }
});
/**
 * 审核管理
 */
export const examineAuthority = createAsyncThunk<
  Authority,
  { status: ID; id: ID },
  { rejectValue: string }
>(`${name}/examine`, async (data, { rejectWithValue }) => {
  try {
    const response = await AuthorityService.examineAuthority(data);
    return response.data;
  } catch (error) {
    const message = getErrorMessage(error);
    return rejectWithValue(message);
  }
});

/**
 * 草稿箱详情
 */
export const detailTemplate = createAsyncThunk<Partial<ITemplateList>, ID>(
  `${name}/template`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await adminService.detailTemplate(id);
      return response.data;
    } catch (err) {
      const message = err.isAxiosError ? err.response.data : err.message;
      return rejectWithValue(message);
    }
  }
);
/**
 * 审核详情
 */
export const examineDetail = createAsyncThunk<Partial<Authority>, ID>(
  `${name}/examine/detail`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await AuthorityService.examineDetail(id);
      return response.data;
    } catch (err) {
      const message = err.isAxiosError ? err.response.data : err.message;
      return rejectWithValue(message);
    }
  }
);

const reviewReduce = createSlice({
  name,
  initialState,
  reducers: {
    setPage(state, action) {
      state[getTabKey(action.payload.type, action.payload?.id)].page =
        action.payload.page;
    },
    setKeyword(state, action) {
      state.keyword = action.payload;
    },
    setNewKnowledge(state, action) {
      state.newKnowledge = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAuthority.pending, (state, action) => {
      const { type, baseId } = action.meta.arg;
      const tabKey = getTabKey(type, baseId);
      state[tabKey].loading = Loading.pending;
    });
    builder.addCase(fetchAuthority.fulfilled, (state, action) => {
      const { type, baseId } = action.meta.arg;
      const tabKey = getTabKey(type, baseId);
      state[tabKey].loading = Loading.fulfilled;
      state[tabKey].total = action.payload.meta.pagination.total;
      state[tabKey].page = action.payload.meta.pagination.current_page;

      authorityAdapter.setAll(state[tabKey], action.payload.data);
    });
    builder.addCase(fetchAuthority.rejected, (state, action) => {
      const { type, baseId } = action.meta.arg;
      const tabKey = getTabKey(type, baseId);
      state[tabKey].loading = Loading.rejected;
      state[tabKey].total = 0;
      authorityAdapter.setAll(state[tabKey], []);
    });

    builder.addCase(detailTemplate.fulfilled, (state, action) => {
      state.draftInfo = action.payload;
    });
    builder.addCase(examineDetail.fulfilled, (state, action) => {
      state.examineInfo = action.payload;
    });
  },
});

export const { setPage, setKeyword, setNewKnowledge } = reviewReduce.actions;

export default reviewReduce.reducer;

export const selectAll = (tabKey: TabKey) => {
  const { selectAll } = authorityAdapter.getSelectors(
    (state: AppState) => state.adminReview[tabKey]
  );
  return selectAll;
};

export const selectNewKnowledge = (state: AppState) =>
  state.adminReview.newKnowledge;

export const selectPage = (tabKey: TabKey) => (state: AppState) =>
  state.adminReview[tabKey].page;

export const selectKeyword = (state: AppState) => state.adminReview.keyword;
export const selectDraftInfo = (state: AppState) => state.adminReview.draftInfo;
export const selectExamineInfo = (state: AppState) =>
  state.adminReview.examineInfo;

export const selectTotal = (tabKey: TabKey) => (state: AppState) =>
  state.adminReview[tabKey].total;

export const selectLimit = (tabKey: TabKey) => (state: AppState) =>
  state.adminReview[tabKey].limit;

export const selectIsLoading = (tabKey: TabKey) => (state: AppState) =>
  state.adminReview[tabKey].loading === Loading.pending;

/**
 * Tab Key
 * @param type
 * @returns
 */
export function getTabKey(type: any, baseId?: number | string): TabKey {
  switch (type) {
    case 1: {
      if (baseId) {
        return 'applyBase';
      }
      return 'apply';
    }
    case 2: {
      if (baseId) {
        return 'editBase';
      }
      return 'edit';
    }
    case 3: {
      if (baseId) {
        return 'uploadBase';
      }
      return 'upload';
    }
    default: {
      if (baseId) {
        return 'allBase';
      }
      return 'all';
    }
  }
}

export enum tabKeyEnum {
  all = 0,
  apply = 1,
  edit = 2,
  upload = 3,
  allBase = 0,
  applyBase = 1,
  editBase = 2,
  uploadBase = 3,
}
