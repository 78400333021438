import React from 'react';

import styled from '@emotion/styled';

const FooterRoot = styled('div')(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  padding: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const FooterButton = styled('button')(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.primary?.main,
  outline: 0,
  border: 0,
  backgroundColor: 'transparent',
  cursor: 'pointer',
}));

const Footer: React.FC<{ onClick?: React.MouseEventHandler | undefined }> = ({
  onClick,
}) => {
  return (
    <React.Fragment>
      <FooterRoot>
        <FooterButton onClick={onClick}>分享</FooterButton>
      </FooterRoot>
    </React.Fragment>
  );
};

export default Footer;
