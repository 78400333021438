import styled from '@emotion/styled';
import { Avatar as AntAvatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { ID } from 'src/shared/interfaces';

const Avatar = styled(AntAvatar, {
  label: 'Header_Account_Menu_UserInfo_Avatar',
})({
  width: 60,
  height: 60,
  lineHeight: '60px',
  marginRight: 20,
});

export interface UserInfoProps {
  avatar?: string;
  name?: string;
  id?: ID;
  department?: string;
  className?: string;
}

const UserInfoRoot = styled('div')({
  padding: 16,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const Typography = styled('div')<{ color?: 'primary' | 'secondary' }>(
  ({ theme, color = 'primary' }) => ({
    fontSize: 16,
    color: theme.palette.text?.primary,
    fontWeight: 600,
    ...(color === 'secondary' && {
      color: theme.palette.text?.secondary,
      fontWeight: 400,
    }),
  })
);

const UserInfo: React.FC<UserInfoProps> = ({ avatar, name, id, department }) => {
  return (
    <UserInfoRoot>
      {avatar ? <Avatar size={60} src={avatar} /> : <Avatar size={60} icon={<UserOutlined />} />}
      <div>
        <Typography>{name}</Typography>
        <Typography color="secondary">ID: {id}</Typography>
        <Typography color="secondary">{department}</Typography>
      </div>
    </UserInfoRoot>
  );
};

export default UserInfo;
