import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { getErrorMessage } from 'src/utils';

import { AppState } from 'src/app/store';
import { ListData } from 'src/shared/interfaces';
import { Loading } from 'src/shared/constants';

import AppBarService, { ISearch, ISearchParams } from 'src/services/appBar';

const name = 'appBar/search';

const searchAdapter = createEntityAdapter<any>({
  selectId: (item) => item.id,
});
type State = {
  loading: string;
  total: number;
  totalPages: number;
  keyword: string;
  page: number;
  limit: number;
  type: string;
  userName: string;
  createdStartTime: string;
  createdEndTime: string;
  editStartTime: string;
  editEndTime: string;
};
//   搜索
export const globalSearch = createAsyncThunk<
  ListData<ISearch>,
  Partial<ISearchParams>,
  { rejectValue: string }
>(`${name}`, async (data, { rejectWithValue }) => {
  try {
    const response = await AppBarService.globalSearch(data);
    return response.data;
  } catch (error) {
    const message = getErrorMessage(error);
    return rejectWithValue(message);
  }
});
const searchReduce = createSlice({
  name,
  initialState: {
    searchList: searchAdapter.getInitialState<State>({
      loading: Loading.idle,
      total: 0,
      totalPages: 0,
      keyword: '',
      page: 1,
      limit: 5,
      type: '',
      userName: '',
      createdStartTime: '',
      createdEndTime: '',
      editStartTime: '',
      editEndTime: '',
    }),
  },
  reducers: {
    setKeyword(state, action) {
      state.searchList.keyword = action.payload;
    },
    setPage(state, action) {
      state.searchList.page = action.payload;
    },
    setLimit(state, action) {
      state.searchList.limit = action.payload;
    },
    setType(state, action) {
      state.searchList.type = action.payload;
    },
    setUserName(state, action) {
      state.searchList.userName = action.payload;
    },
    setCreatedStartTime(state, action) {
      state.searchList.createdStartTime = action.payload;
    },
    setCreatedEndTime(state, action) {
      state.searchList.createdEndTime = action.payload;
    },
    setEditStartTime(state, action) {
      state.searchList.editStartTime = action.payload;
    },
    setEditEndTime(state, action) {
      state.searchList.editEndTime = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(globalSearch.pending, (state, action) => {
      state.searchList.loading = Loading.pending;
    });
    builder.addCase(globalSearch.fulfilled, (state, action) => {
      state.searchList.loading = Loading.fulfilled;
      const { data, meta } = action.payload;
      searchAdapter.setAll(state.searchList, data);
      state.searchList.total = meta.pagination.total;
      state.searchList.totalPages = meta.pagination.total_pages;
    });
    builder.addCase(globalSearch.rejected, (state, action) => {
      state.searchList.loading = Loading.rejected;
      searchAdapter.setAll(state.searchList, []);
      state.searchList.total = 0;
      state.searchList.totalPages = 0;
    });
  },
});
export default searchReduce.reducer;

export const {
  setKeyword,
  setPage,
  setLimit,
  setType,
  setUserName,
  setCreatedStartTime,
  setCreatedEndTime,
  setEditStartTime,
  setEditEndTime,
} = searchReduce.actions;

export const { selectAll: selectSearchList } = searchAdapter.getSelectors(
  (state: AppState) => state.appBar.search.searchList
);
export const selectLoading = (state: AppState) =>
  state.appBar.search.searchList.loading;
export const selectKeyword = (state: AppState) =>
  state.appBar.search.searchList.keyword;
export const selectTotal = (state: AppState) =>
  state.appBar.search.searchList.total;
export const selectTotalPages = (state: AppState) =>
  state.appBar.search.searchList.totalPages;
export const selectPage = (state: AppState) =>
  state.appBar.search.searchList.page;
export const selectLimit = (state: AppState) =>
  state.appBar.search.searchList.limit;
export const selectType = (state: AppState) =>
  state.appBar.search.searchList.type;
export const selectUserName = (state: AppState) =>
  state.appBar.search.searchList.userName;

export const selectCreatedStartTime = (state: AppState) =>
  state.appBar.search.searchList.createdStartTime;
export const selectCreatedEndTime = (state: AppState) =>
  state.appBar.search.searchList.createdEndTime;
export const selectEditStartTime = (state: AppState) =>
  state.appBar.search.searchList.editStartTime;
export const selectEditEndTime = (state: AppState) =>
  state.appBar.search.searchList.editEndTime;
