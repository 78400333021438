import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';

import Routes from 'src/routes';
import { GlobalStyles } from 'src/components/GlobalStyles';
import { DialogProvider } from 'src/components/Dialog';
import theme from 'src/themes';
import { UploadProvider, UploadDrawer } from 'src/pages/Repository/Folder/Upload';

// import './wdyr';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <DialogProvider>
        <UploadProvider>
          <BrowserRouter>
            <GlobalStyles>
              <Routes />
              <UploadDrawer />
            </GlobalStyles>
          </BrowserRouter>
        </UploadProvider>
      </DialogProvider>
    </ThemeProvider>
  );
}

export default App;
