import departmentService from 'src/services/department';
import adminService from 'src/services/admin';
import {
  RESET,
  DESELECT_ALL,
  DESELECT_DEPARTMENT,
  DESELECT_MEMBER,
  FETCH_DEPARTMENTS_FULFILLED,
  FETCH_DEPARTMENTS_PENDING,
  FETCH_DEPARTMENTS_REJECTED,
  FETCH_MEMBERS_FULFILLED,
  FETCH_MEMBERS_PENDING,
  FETCH_MEMBERS_REJECTED,
  REMOVE,
  SELECT_ALL,
  SELECT_DEPARTMENT,
  SELECT_MEMBER,
  SHOW_DEPARTMENTS,
  SHOW_MEMBERS,
  CLEAR,
  SEARCH_PENDING,
  SEARCH_FULFILLED,
  SEARCH_REJECTED,
  SET_SEARCH,
} from './constants';
import {
  Actions,
  ShowMembersActionPayload,
  ShowMembersAction,
  ShowDepartmentsAction,
  FetchDepartmentsPending,
  FetchDepartmentsFulfilled,
  FetchDepartmentsRejected,
  Department,
  FetchMembersPending,
  FetchMembersFulfilled,
  FetchMembersRejected,
  ResetAction,
  SelectMemberAction,
  DeselectMemberAction,
  SelectAllAction,
  DeselectAllAction,
  SelectDepartmentAction,
  DeselectDepartmentAction,
  RemoveAction,
  ClearAction,
  SearchPendingAction,
  SearchFullFilledAction,
  SearchRejectedAction,
  SetSearchAction,
  Member,
} from '../interface';
import {
  flattenDepartments,
  resolveDepartments,
  resolveDepartmentSearchResult,
  resolveMembers,
  resolveMemberSearchResult,
} from '../utils';
import cache from '../cache';

/**
 * Action creator
 */
export function reset(): ResetAction {
  return { type: RESET };
}

/**
 * 显示子部门成员
 * @param payload
 * @returns
 */
export function showMembers(
  payload: ShowMembersActionPayload
): ShowMembersAction {
  return { type: SHOW_MEMBERS, payload };
}

/**
 * 显示组织架构
 * @returns
 */
export function showDepartments(): ShowDepartmentsAction {
  return { type: SHOW_DEPARTMENTS };
}

/** 获取组织架构 */
function fetchDepartmentsPending(): FetchDepartmentsPending {
  return { type: FETCH_DEPARTMENTS_PENDING };
}

function fetchDepartmentsFulfilled(
  payload: Department[]
): FetchDepartmentsFulfilled {
  return { type: FETCH_DEPARTMENTS_FULFILLED, payload };
}

function fetchDepartmentsRejected(): FetchDepartmentsRejected {
  return { type: FETCH_DEPARTMENTS_REJECTED };
}

export function fetchDepartments() {
  return async (dispatch: React.Dispatch<Actions>) => {
    dispatch(fetchDepartmentsPending());
    try {
      const response = await departmentService.list();
      const resolved = resolveDepartments(response.data.data);
      cache.set('department', flattenDepartments(resolved));
      dispatch(fetchDepartmentsFulfilled(resolved));
    } catch (err) {
      dispatch(fetchDepartmentsRejected());
    }
  };
}

/** 获取成员 */
function fetchMembersPending(): FetchMembersPending {
  return { type: FETCH_MEMBERS_PENDING };
}

function fetchMembersFulfilled(payload: any[]): FetchMembersFulfilled {
  return { type: FETCH_MEMBERS_FULFILLED, payload };
}

function fetchMembersRejected(): FetchMembersRejected {
  return { type: FETCH_MEMBERS_REJECTED };
}

export function fetchMembers(id: string | number) {
  return async (dispatch: React.Dispatch<Actions>) => {
    dispatch(fetchMembersPending());
    try {
      const response = await departmentService.details(id, 10000);
      const resolved = resolveMembers(response.data.data);
      cache.append('member', { [id]: resolved });
      dispatch(fetchMembersFulfilled(resolved));
    } catch (err) {
      dispatch(fetchMembersRejected());
    }
  };
}

/** 选中成员 */
export function selectMember(id: string | number): SelectMemberAction {
  return { type: SELECT_MEMBER, payload: id };
}

/** 取消选中成员 */
export function deselectMember(id: string | number): DeselectMemberAction {
  return { type: DESELECT_MEMBER, payload: id };
}

/**
 * 全选
 * @returns
 */
export function selectAll(): SelectAllAction {
  return { type: SELECT_ALL };
}

/**
 * 取消全选
 * @returns
 */
export function deselectAll(): DeselectAllAction {
  return { type: DESELECT_ALL };
}

/**
 * 选择部门
 * @returns
 */
export function selectDepartment(id: string | number): SelectDepartmentAction {
  return { type: SELECT_DEPARTMENT, payload: id };
}

/**
 * 取消选择部门
 * @returns
 */
export function deselectDepartment(
  id: string | number
): DeselectDepartmentAction {
  return { type: DESELECT_DEPARTMENT, payload: id };
}

/**
 * 移除已添加
 * @param payload
 * @returns
 */
export function remove(payload: {
  id: string | number;
  type: 'department' | 'member';
}): RemoveAction {
  return { type: REMOVE, payload };
}

/**
 * 清空已添加
 * @returns
 */
export function clear(): ClearAction {
  return { type: CLEAR };
}

/** 搜索 */
export function searchPending(): SearchPendingAction {
  return { type: SEARCH_PENDING };
}

export function searchFulfilled(
  payload: (Department | Member)[]
): SearchFullFilledAction {
  return { type: SEARCH_FULFILLED, payload };
}

export function searchRejected(): SearchRejectedAction {
  return { type: SEARCH_REJECTED };
}

export function search(type: 'department' | 'member', keyword: string) {
  return async (dispatch: React.Dispatch<Actions>) => {
    try {
      dispatch(searchPending());
      const response = await adminService.memberSearch({
        type: type === 'member' ? 1 : 2,
        keyword,
      });
      let resolved = [];
      if (type === 'department') {
        resolved = resolveDepartmentSearchResult(response.data.data);
      } else {
        resolved = resolveMemberSearchResult(response.data.data as any);
      }
      dispatch(searchFulfilled(resolved));
    } catch (err) {
      dispatch(searchRejected());
    }
  };
}

export function setSearch(payload: {
  type: 'department' | 'member';
  keyword: string;
}): SetSearchAction {
  return { type: SET_SEARCH, payload };
}
