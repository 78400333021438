import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  EntityState,
} from '@reduxjs/toolkit';
import { getErrorMessage } from 'src/utils';

import { AppState } from 'src/app/store';
import { ID, ListAllData } from 'src/shared/interfaces';
import { Loading } from 'src/shared/constants';

import AppBarService, { HistoryState } from 'src/services/appBar';

const name = 'appBar/history';

const histroyAdapter = createEntityAdapter<HistoryState>({
  selectId: (item) => item.id,
});

type State = {
  file: EntityState<HistoryState> & AdapterState;
  book: EntityState<HistoryState> & AdapterState;
};
export type TabKey = 'file' | 'book';
export enum TabKeyEnum {
  file = 2,
  book = 1,
}

type AdapterState = {
  loading: Loading;
  page: number;
  limit: number;
  total: number;
};

const limit = 20;
const initList = {
  loading: Loading.idle,
  page: 1,
  limit,
  total: 0,
};

const initialState: State = {
  file: histroyAdapter.getInitialState<AdapterState>({
    ...initList,
  }),
  book: histroyAdapter.getInitialState<AdapterState>({
    ...initList,
  }),
};

/**
 * 最近列表
 */

export const fetchHistory = createAsyncThunk<
  ListAllData<HistoryState>,
  { baseId?: ID; type: number },
  { rejectValue: string }
>(`${name}/fetchHistory`, async (data, { rejectWithValue }) => {
  try {
    const response = await AppBarService.fetchHistory(data);
    return response.data;
  } catch (error) {
    const message = getErrorMessage(error);
    return rejectWithValue(message);
  }
});

const historyReduce = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchHistory.pending, (state, action) => {
      const { type } = action.meta.arg;
      const tabKey = getTabKey(type);
      state[tabKey].loading = Loading.pending;
    });
    builder.addCase(fetchHistory.fulfilled, (state, action) => {
      const { type } = action.meta.arg;
      const tabKey = getTabKey(type);
      state[tabKey].loading = Loading.fulfilled;
      histroyAdapter.setAll(state[tabKey], action.payload.data);
    });
    builder.addCase(fetchHistory.rejected, (state, action) => {
      const { type } = action.meta.arg;
      const tabKey = getTabKey(type);
      state[tabKey].loading = Loading.rejected;
      histroyAdapter.setAll(state[tabKey], []);
    });
  },
});

export default historyReduce.reducer;

export const selectAll = (tabKey: TabKey) => {
  const { selectAll } = histroyAdapter.getSelectors(
    (state: AppState) => state.appBar.history[tabKey]
  );
  return selectAll;
};

export const selectPage = (tabKey: TabKey) => (state: AppState) =>
  state.appBar.history[tabKey].page;

export const selectTotal = (tabKey: TabKey) => (state: AppState) =>
  state.appBar.history[tabKey].total;

export const selectLimit = (tabKey: TabKey) => (state: AppState) =>
  state.appBar.history[tabKey].limit;

export const selectIsLoading = (tabKey: TabKey) => (state: AppState) =>
  state.appBar.history[tabKey].loading === Loading.pending;

/**
 * Tab Key
 * @param type
 * @returns
 */
export function getTabKey(type: number | undefined): TabKey {
  switch (type) {
    case 1: {
      return 'book';
    }
    default: {
      return 'file';
    }
  }
}
