import { withProps, StyledElement } from '@udecode/plate';

import styles from 'src/shared/styles/richText';
import { CSSProp } from 'styled-components';

const Blockquote = withProps(StyledElement, {
  as: 'blockquote',
  styles: {
    root: styles.blockquote as CSSProp,
  },
});

export default Blockquote;
