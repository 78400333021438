import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { Button, Spin } from 'antd';

import { ID, Union } from 'src/shared/interfaces';
import {
  Modal,
  ModalPaper,
  ModalBody,
  ModalActions,
  ModalTitle,
  ModalProps,
} from 'src/components/unstable_Modal';
import { Grow } from 'src/components/Transitions';

import Cell from './Cell';
import UserInfo from './UserInfo';
import Base from './Base';
import Authority from './Authority';
import Position from './Position';
import Reason from './Reason';

const PREFIX = 'Review-Details';

const classNames = {
  root: `${PREFIX}__root`,
  loading: `${PREFIX}__loading`,
};

const DetailsRoot = styled('div')({
  [`&.${classNames.root}`]: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      marginBottom: 16,
    },
  },
  [`& .${classNames.loading}`]: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
});

export interface DetailsProps {
  open?: boolean;
  user?: { avatar?: string; name?: string; phone?: string } | undefined;
  base?: { name: string; id: ID } | undefined;
  position?: Union | undefined;
  type?: number;
  reason?: string;
  onClose?: ModalProps['onClose'];
  loading?: boolean;
  onReview?: (
    status: 1 | 2,
    helper?: { setSubmitting: React.Dispatch<React.SetStateAction<false | 1 | 2>> }
  ) => Promise<void>;
}

export const Details: React.FC<DetailsProps> = (props) => {
  const {
    open = false,
    user,
    base,
    type,
    position,
    reason,
    onClose,
    loading = false,
    onReview,
  } = props;
  const [submitting, setSubmitting] = useState<1 | 2 | false>(false);

  const handleReview = useCallback(
    (status: 1 | 2) => {
      return () => {
        if (onReview) {
          setSubmitting(status);
          onReview(status, { setSubmitting });
        }
      };
    },
    [onReview]
  );

  return (
    <Modal center open={open} onClose={onClose}>
      <Grow in={open}>
        <ModalPaper>
          <ModalTitle>审批详情</ModalTitle>
          <ModalBody style={{ display: 'flex', flexDirection: 'column' }}>
            <DetailsRoot className={classNames.root}>
              {loading ? (
                <div className={classNames.loading}>
                  <Spin />
                </div>
              ) : (
                <>
                  <Cell label="申请人员" block>
                    <UserInfo {...user} />
                  </Cell>
                  <Cell label="知识库">
                    <Base {...base} />
                  </Cell>
                  <Cell label="编辑类型">
                    <Authority authority={type} />
                  </Cell>
                  <Cell label="位置" block>
                    <Position type={type} {...position} />
                  </Cell>
                  <Cell label="理由" block>
                    <Reason>{reason}</Reason>
                  </Cell>
                </>
              )}
            </DetailsRoot>
          </ModalBody>
          <ModalActions>
            <Button type="primary" onClick={handleReview(1)} loading={submitting === 1}>
              同意
            </Button>
            <Button type="primary" danger onClick={handleReview(2)} loading={submitting === 2}>
              拒绝
            </Button>
          </ModalActions>
        </ModalPaper>
      </Grow>
    </Modal>
  );
};

export default Details;
