import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from 'src/app/store';
import reportService from 'src/services/report';
import { Loading } from 'src/shared/constants';
import { ID } from 'src/shared/interfaces';
import { getErrorMessage } from 'src/utils';

import { ReportEntity, Report } from './types';

const name = 'RepositoryAdminReport';

export type TabKey = 'pending' | 'processed' | 'all';

export interface State {
  baseId: ID | undefined;
  data: Report[];
  loading: Loading; // 表格数据加载状态
  handling: Loading; // 举报处理状态
  total: number;
  tab: TabKey;
  currentRequestId: string | undefined;
  page: number;
  currentReportId: ID | undefined;
  keyword: string | undefined;
}

const initialState: State = {
  baseId: undefined,
  data: [],
  loading: Loading.idle,
  handling: Loading.idle,
  total: 0,
  tab: 'pending',
  page: 1,
  currentRequestId: undefined,
  currentReportId: undefined,
  keyword: undefined,
};

type FetchListMeta = {
  page?: number;
  keyword?: string;
};

/**
 * 举报管理列表
 */
export const fetchList = createAsyncThunk<
  { entities: Report[]; total: number },
  { baseId: ID; status: 1 | 2 | undefined; meta?: FetchListMeta },
  { rejectValue: string; state: AppState }
>(`${name}/fetchList`, async ({ baseId, status, meta = {} }, { rejectWithValue }) => {
  try {
    const { page = 1, keyword } = meta;
    const response = await reportService.fetchListByBase(baseId, status, { page }, { keyword });
    return {
      entities: response.data.data.map((item) => reportAdapter(item)),
      total: response.data.meta.pagination.total,
    };
  } catch (err) {
    return rejectWithValue(getErrorMessage(err));
  }
});

/**
 * 举报处理
 */
export const handle = createAsyncThunk<
  void,
  { status: 1 | 2; id: ID },
  { rejectValue: string; state: AppState }
>(`${name}/handle`, async ({ status, id }, { rejectWithValue, getState }) => {
  try {
    if (id) {
      await reportService.handle(id, status);
    }
  } catch (err) {
    return rejectWithValue(getErrorMessage(err));
  }
});

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
    switchTab: (state, action: PayloadAction<TabKey>) => {
      state.tab = action.payload;
    },
    setBaseId: (state, action: PayloadAction<ID | undefined>) => {
      state.baseId = action.payload;
    },
    setCurrentReportId: (state, action: PayloadAction<ID | undefined>) => {
      state.currentReportId = action.payload;
    },
    search: (state, action: PayloadAction<string | undefined>) => {
      state.keyword = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchList.pending, (state, action) => {
      state.loading = Loading.pending;
      state.currentRequestId = action.meta.requestId;
    });
    builder.addCase(fetchList.fulfilled, (state, action) => {
      if (state.currentRequestId === action.meta.requestId) {
        state.currentRequestId = undefined;
        state.loading = Loading.fulfilled;
        state.data = action.payload.entities;
        state.total = action.payload.total;
        state.page = action.meta.arg.meta?.page || 1;
        const baseId = action.meta.arg.baseId;
        if (baseId) {
          state.baseId = Number(baseId);
        }
      }
    });
    builder.addCase(fetchList.rejected, (state, action) => {
      if (state.currentRequestId === action.meta.requestId) {
        state.loading = Loading.rejected;
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(handle.pending, (state, action) => {
      state.handling = Loading.pending;
      state.currentRequestId = action.meta.requestId;
    });
    builder.addCase(handle.fulfilled, (state, action) => {
      if (state.currentRequestId === action.meta.requestId) {
        state.handling = Loading.fulfilled;
        state.currentRequestId = undefined;
        // if (state.tab === 'pending') {
        //   state.data = state.data.filter((item) => item.id !== action.meta.arg.id);
        // }
      }
    });
    builder.addCase(handle.rejected, (state, action) => {
      if (state.currentRequestId === action.meta.requestId) {
        state.handling = Loading.rejected;
        state.currentReportId = undefined;
      }
    });
  },
});

export const { reset, switchTab, setBaseId, setCurrentReportId, search } = slice.actions;

export default slice.reducer;

/** selectors */
export const selectCurrentReport = createSelector(
  [
    (state: AppState) => state.repositoryAdmin.report.data,
    (state: AppState) => state.repositoryAdmin.report.currentReportId,
  ],
  (reports, id) => reports.find((item) => item.id === id)
);

export const selectCurrentDataLength = createSelector(
  (state: AppState) => state.repositoryAdmin.report.data,
  (reports) => reports.length
);

/**
 * 后端数据结构与前端数据结构映射
 * @param entity
 */
export function reportAdapter(entity: ReportEntity): Report {
  const {
    type: originType,
    position,
    status: originStatus,
    baseId,
    createdAt,
    detail,
    id,
    rId,
    reasonStr,
    tipUserName,
    userName,
    title,
    updatedAt,
  } = entity;
  let type: 'question' | 'answer' = 'question';
  if (originType === 2) {
    type = 'answer';
  }

  let status = originStatus as 0 | 1;
  if (status > 1) {
    status = 1; // 已处理
  }

  const result = getResultFromStatus(originStatus);

  const href = getResourceLink(entity);

  return {
    type,
    baseId,
    status,
    result,
    createdAt,
    detail,
    id,
    rId,
    position,
    updatedAt,
    href,
    title,
    from: userName,
    to: tipUserName,
    reason: reasonStr,
  };
}

function getResultFromStatus(status: ReportEntity['status']): Report['result'] {
  if (status > 0) {
    return status as 1 | 2 | undefined;
  }

  return undefined;
}

function getResourceLink(entity: ReportEntity) {
  let result = `/repository/${entity.baseId}/questions/${entity.qId}`;
  if (entity.type === 2) {
    result += `?answer_id=${entity.aId}`;
  }
  return result;
}
