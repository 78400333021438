import styled from '@emotion/styled';
import { Outlet, matchPath, useLocation, useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';

import Box from 'src/components/Box';
import { Typography } from 'src/components/Typography';
import { Image } from 'src/components/Image';
import { FavoriteFill } from 'src/components/Icons';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import {
  fetchDetails,
  selectDetails,
} from 'src/pages/Repository/Details/slice';
import { parseImageSrc } from 'src/utils';

const Root = styled('div', { label: 'RepositoryAdminLayout' })({
  paddingTop: 64,
  paddingLeft: 32,
  paddingBottom: 64,
});

type RepositoryAdminLayoutProps = {
  className?: string;
};

const RepositoryAdminLayout: React.FC<RepositoryAdminLayoutProps> = ({
  className,
}) => {
  const { pathname } = useLocation();

  const showHeader = useMemo(() => {
    const whitelist = [
      '/repository/:id/admin/settings',
      '/repository/:id/admin/study',
    ];
    return whitelist.every((path) => !matchPath({ path, end: true }, pathname));
  }, [pathname]);

  const dispatch = useAppDispatch();
  const { id } = useParams();

  //  知识库详情
  useEffect(() => {
    if (id) {
      dispatch(fetchDetails(id));
    }
  }, [id, dispatch]);

  const detail: any = useAppSelector(selectDetails);

  return (
    <Root className={className}>
      {showHeader && (
        <div className="header">
          <Image
            src={parseImageSrc(detail?.img)}
            className="cover"
            objectFit="cover"
          />
          <div className="info">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h5" color="textPrimary" className="title">
                {detail?.name}
              </Typography>
              <Box sx={{ marginLeft: 10, display: 'flex' }}>
                <FavoriteFill
                  color={detail?.collect ? '#FE805F' : ''}
                  fontSize="18px"
                />
              </Box>
            </Box>
            <Typography variant="body1" color="textSecondary" className="title">
              {detail?.introduction}
            </Typography>
          </div>
        </div>
      )}

      <Outlet />
    </Root>
  );
};

export default styled(RepositoryAdminLayout)({
  '& .header': {
    display: 'flex',
    marginBottom: 32,
  },
  '& .cover': {
    width: 187,
    height: 115,
    borderRadius: 8,
  },
  '& .info': {
    padding: '18px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: 30,
    overflow: 'hidden',
    '& .title': {
      width: 'auto',
      maxWidth: 500,
    },
  },
});
