import { withProps, StyledElement } from '@udecode/plate';

import { CSSProp } from 'styled-components';
import styles from 'src/shared/styles/richText';

const Strong = withProps(StyledElement, {
  as: 'strong',
  styles: {
    root: styles.strong as CSSProp,
  },
});

export default Strong;
