import {
  createPlugins,
  createPlateUI,
  createParagraphPlugin,
  createBoldPlugin,
  createStrikethroughPlugin,
  createUnderlinePlugin,
  createItalicPlugin,
  createHeadingPlugin,
  createBlockquotePlugin,
  createListPlugin,
  createDeserializeHtmlPlugin,
  createAutoformatPlugin,
  createSoftBreakPlugin,
  createExitBreakPlugin,
  createResetNodePlugin,
  createLinkPlugin,
  createCodeBlockPlugin,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_PARAGRAPH,
  MARK_BOLD,
  ELEMENT_BLOCKQUOTE,
  ELEMENT_UL,
  ELEMENT_OL,
  ELEMENT_LI,
  ELEMENT_CODE_BLOCK,
  ELEMENT_LINK,
} from '@udecode/plate';
import { autoformatOptions } from '../configs/autoformatRules';
import { exitBreakOptions } from '../configs/exitBreakOptions';
import { resetNodeOptions } from '../configs/resetNodeOptions';
import { softBreakOptions } from '../configs/softBreakOptions';
import Blockquote from '../Elements/Blockquote';
import Codeblock from '../Elements/CodeBlock';
import H1 from '../Elements/H1';
import H2 from '../Elements/H2';
import H3 from '../Elements/H3';
import H4 from '../Elements/H4';
import H5 from '../Elements/H5';
import LI from '../Elements/LI';
import OL from '../Elements/OL';
import Paragraph from '../Elements/Paragraph';
import Strong from '../Elements/Strong';
import UL from '../Elements/UL';
import Link from '../Elements/Link';

export const plugins = createPlugins(
  [
    createParagraphPlugin(),
    createBoldPlugin(),
    createStrikethroughPlugin(),
    createUnderlinePlugin(),
    createItalicPlugin(),
    createHeadingPlugin({ options: { levels: 6 } }),
    createBlockquotePlugin(),
    createListPlugin(),
    createLinkPlugin(),
    createCodeBlockPlugin({ options: { syntax: false } }),
    createDeserializeHtmlPlugin(),
    createAutoformatPlugin({ options: autoformatOptions }),
    createSoftBreakPlugin({ options: softBreakOptions }),
    createExitBreakPlugin({ options: exitBreakOptions }),
    createResetNodePlugin({ options: resetNodeOptions }),
  ],
  {
    components: createPlateUI({
      [ELEMENT_H1]: H1,
      [ELEMENT_H2]: H2,
      [ELEMENT_H3]: H3,
      [ELEMENT_H4]: H4,
      [ELEMENT_H5]: H5,
      [ELEMENT_PARAGRAPH]: Paragraph,
      [MARK_BOLD]: Strong,
      [ELEMENT_BLOCKQUOTE]: Blockquote,
      [ELEMENT_UL]: UL,
      [ELEMENT_OL]: OL,
      [ELEMENT_LI]: LI,
      [ELEMENT_CODE_BLOCK]: Codeblock,
      [ELEMENT_LINK]: Link,
    }),
  }
);
