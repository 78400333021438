import { intervalToDuration, format, isAfter, startOfDay } from 'date-fns';

export function fromNow(date: Date) {
  const duration = intervalToDuration({
    start: startOfDay(date),
    end: startOfDay(new Date()),
  });
  const time = format(date, 'HH:mm');

  let prefix = format(date, 'yyyy-MM-dd');
  if (duration.days === 0) {
    prefix = '今天';
  }

  if (duration.days === 1) {
    if (isAfter(date, new Date())) {
      prefix = '明天';
    } else {
      prefix = '昨天';
    }
  }

  return `${prefix} ${time}`;
}

/**
 * 解析时间字符串
 * @param date
 */
export function resolveDate(input: string) {
  const [day, time] = input.split(' ');
  const [year, month, date] = day.split('-').map((item) => parseInt(item, 10));
  const [hour, minute, second] = time
    .split(':')
    .map((item) => parseInt(item, 10));

  return new Date(year, month - 1, date, hour, minute, second);
}
