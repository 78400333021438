import React from 'react';

import styled from '@emotion/styled';
import { Popover as AntPopover, PopoverProps as AntPopoverProps } from 'antd';

import {
  composeClasses,
  // getScrollbarSize,
  // ownerDocument,
  // ownerWindow,
} from 'src/utils';

import clsx from 'clsx';

export type PopoverProps = {
  button?: React.ReactNode;
  hideArrow?: boolean;
} & Omit<AntPopoverProps, 'content'>;

type StyleProps = Pick<PopoverProps, 'hideArrow'>;

const classNamePrefix = 'Popover';

const useUtilityClasses = (props: StyleProps) => {
  const { hideArrow } = props;
  const slots = {
    root: ['root', hideArrow && 'hideArrow'],
    paper: ['paper'],
  };
  return composeClasses(classNamePrefix, slots);
};

const Popover: React.FC<PopoverProps> = (props) => {
  const {
    button,
    hideArrow = true,
    destroyTooltipOnHide = true,
    placement = 'bottom',
    className,
    children,
    ...rest
  } = props;
  const classes = useUtilityClasses({ hideArrow });

  // let restore: null | (() => void);
  // const handleVisibleChange = (visible: any) => {
  //   if (visible) {
  //     restore = lockScrollBar(document.getElementsByTagName('body')[0]);
  //   } else {
  //     restore?.();
  //   }
  // };

  return (
    <AntPopover
      destroyTooltipOnHide={destroyTooltipOnHide}
      placement={placement}
      // onVisibleChange={handleVisibleChange}
      {...rest}
      content={children}
      overlayClassName={clsx(classes.root, className)}
    >
      {button}
    </AntPopover>
  );
};

export default styled(Popover)<PopoverProps>(({ hideArrow = true }) => ({
  zIndex: 1300,
  position: 'fixed',
  '& .ant-popover-inner': {
    maxHeight: 550,
    backgroundColor: '#fff',
    border: '1px solid #eee',
    boxShadow: '0 0 30px 0 rgba(0, 0, 0, 0.1)',
    borderRadius: 10,
    position: 'relative',
    top: 12,
    display: 'flex',
    flexDirection: 'column',

    '& > .ant-popover-inner-content': {
      flex: 1,
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
  },
  ...(hideArrow && {
    '& .ant-popover-arrow': {
      display: 'none !important',
    },
  }),
}));

/**
 * 弹窗打开，锁定页面滚动
 * @param containerInfo
 */
// function lockScrollBar(container: HTMLElement) {
//   function isOverflowing(container: Element): boolean {
//     const doc = ownerDocument(container);
//     if (doc.body === container) {
//       return (
//         ownerWindow(container).innerWidth > doc.documentElement.clientWidth
//       );
//     }

//     return container.scrollHeight > container.clientHeight;
//   }

//   function getPaddingRight(element: Element): number {
//     return (
//       parseInt(
//         ownerWindow(element).getComputedStyle(element).paddingRight,
//         10
//       ) || 0
//     );
//   }

//   const restoreStyle: {
//     property: string;
//     el: HTMLElement | SVGElement;
//     value: string;
//   }[] = [];

//   if (isOverflowing(container)) {
//     const scrollbarSize = getScrollbarSize(ownerDocument(container));
//     restoreStyle.push({
//       value: container.style.paddingRight,
//       property: 'padding-right',
//       el: container,
//     });
//     container.style.paddingRight = `${
//       getPaddingRight(container) + scrollbarSize
//     }px`;

//     // position: fixed
//     const fixedElements =
//       ownerDocument(container).querySelectorAll('.kwb-fixed');

//     [].forEach.call(fixedElements, (element: HTMLElement | SVGElement) => {
//       restoreStyle.push({
//         value: element.style.paddingRight,
//         property: 'padding-right',
//         el: element,
//       });
//       element.style.paddingRight = `${
//         getPaddingRight(element) + scrollbarSize
//       }px`;
//     });

//     const parent = container.parentElement;
//     const containerWindow = ownerWindow(container);
//     const scrollContainer =
//       parent?.nodeName === 'HTML' &&
//       containerWindow.getComputedStyle(parent).overflowY === 'scroll'
//         ? parent
//         : container;

//     restoreStyle.push({
//       value: scrollContainer.style.overflow,
//       property: 'overflow',
//       el: scrollContainer,
//     });
//     scrollContainer.style.overflow = 'hidden';
//   }

//   return () => {
//     restoreStyle.forEach(({ value, el, property }) => {
//       if (value) {
//         el.style.setProperty(property, value);
//       } else {
//         el.style.removeProperty(property);
//       }
//     });
//   };
// }
