import React from 'react';
import styled from '@emotion/styled';
import { Dropdown, Menu } from 'antd';
import { EllipsisOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useAppDispatch } from 'src/hooks';

import { markAll, removeAll } from './slice';

const { Item: MenuItem } = Menu;

const PREFIX = 'AppBar-Notification--TabBarExtra';

const classNames = {
  root: `${PREFIX}__root`,
};

const ExtraRoot = styled('div')({
  [`&.${classNames.root}`]: {
    fontSize: 24,
    cursor: 'pointer',
  },
});

const Extra: React.FC = () => {
  const dispatch = useAppDispatch();

  const handleClick = ({ key }: any) => {
    if (key === 'mark_all') {
      dispatch(markAll());
    }
    if (key === 'remove') {
      dispatch(removeAll());
    }
  };

  const menus = (
    <Menu onClick={handleClick}>
      <MenuItem key="mark_all">
        <CheckOutlined />
        <span>标记全部已读</span>
      </MenuItem>
      <MenuItem key="remove">
        <CloseOutlined />
        <span>删除全部已读</span>
      </MenuItem>
    </Menu>
  );

  return (
    <Dropdown overlay={menus}>
      <ExtraRoot className={classNames.root}>
        <EllipsisOutlined />
      </ExtraRoot>
    </Dropdown>
  );
};

export default Extra;
