import React from 'react';
import styled from '@emotion/styled';
import { message } from 'antd';
import { useNavigate } from 'react-router';

import { Union } from 'src/shared/interfaces';
import { ResourceType } from 'src/shared/constants';
import { getResourceLink } from 'src/utils/resource';
import { Typography } from 'src/components/Typography';

const PREFIX = 'Review-Details--Position';

const classNames = {
  root: `${PREFIX}__root`,
};

const PositionRoot = styled('div')({
  [`&.${classNames.root}`]: {
    position: 'relative',
    border: '1px solid #eee',
    borderRadius: 4,
    height: 40,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: 6,
  },
});

const Position: React.FC<Partial<Union> & { type?: number }> = (props) => {
  const { type, ...rest } = props;
  const navigate = useNavigate();

  const name = type === 5 || type === 1 ? rest.name : rest.resourceName;

  const handleClick = () => {
    if (!rest.resource) {
      message.error('位置不存在');
    } else {
      // TODO: 想办法提取一个公共函数，这个地址用的地方太多了
      let href = getResourceLink(
        rest.resource,
        { id: rest.baseFolderId },
        { pageId: rest.resourceId }
      );

      if (rest.resource === ResourceType.repository) {
        href = getResourceLink(ResourceType.repository, { id: rest.resourceId });
      }
      if (rest.resource === ResourceType.folder) {
        // 知识本
        let baseURL = 'book';
        if (rest.baseFolderType === 2) {
          // 文件夹
          baseURL = 'folder';
        }
        href = getResourceLink(ResourceType.folder, { baseURL, id: rest.baseFolderId });
      }

      console.log(href);
      navigate(href);
    }
  };

  return (
    <PositionRoot className={classNames.root} onClick={handleClick}>
      <Typography noWrap>{name}</Typography>
    </PositionRoot>
  );
};

export default Position;
