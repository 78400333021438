import styled from '@emotion/styled';
import { lighten } from 'src/utils';

export default styled('button')(({ theme }) => ({
  outline: 0,
  border: 0,
  backgroundColor: 'transparent',
  cursor: 'pointer',
  color: theme.palette.primary?.main,
  '&:hover': {
    color: lighten(theme.palette.primary?.main || '#5a5aee', 0.2),
  },
  ...theme.typography.body1,
}));
