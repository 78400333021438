import React from 'react';
import styled from '@emotion/styled';
import { Divider as AntDivider } from 'antd';

import { Modal, ModalPaper } from 'src/components/unstable_Modal';
import { Grow } from 'src/components/Transitions';

import Provider from './Provider';
import Views from './Views';
import Added from './Added';
import Footer from './Footer';

const Panel = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  flex: 1,
  flexShrink: 0,
});

const Wrapper = styled('div')({
  padding: '40px 60px',
  display: 'flex',
  height: 640,
  width: '100%',
  minWidth: 0,
  '@media screen and (max-width: 1280px)': {
    padding: '20px 30px',
    height: 560,
  },
  '@media screen and (max-width: 960px)': {
    padding: '20px 30px',
    height: 480,
  },
});

const Divider = styled(AntDivider)({
  marginLeft: 60,
  marginRight: 60,
  justifySelf: 'stretch',
  height: 'auto',
  '@media (min-width: 0px)': {
    marginLeft: 24,
    marginRight: 24,
  },
  '@media (min-width: 960px)': {
    marginLeft: 24,
    marginRight: 24,
  },
  '@media (min-width: 1280px)': {
    marginLeft: 30,
    marginRight: 30,
  },
  '@media (min-width: 1600px)': {
    marginLeft: 30,
    marginRight: 30,
  },
  '@media (min-width: 1920px)': {
    marginLeft: 30,
    marginRight: 30,
  },
});

const StyledModalPaper = styled(ModalPaper)({
  '@media (min-width: 0px)': {
    width: 620,
  },
  '@media (min-width: 960px)': {
    width: 768,
  },
  '@media (min-width: 1280px)': {
    width: 1024,
  },
  '@media (min-width: 1600px)': {
    width: 1200,
  },
  '@media (min-width: 1920px)': {
    width: 1200,
  },
});

interface MemberProps {
  open: boolean;
  title?: string;
  onClose?: (() => void) | undefined;
  onFinish?:
    | ((data: {
        departments: (string | number)[];
        members: (string | number)[];
      }) => void)
    | undefined;
  loading?: boolean;
}

const Member: React.FC<MemberProps> = (props) => {
  const { open, title, onClose, onFinish, loading = false } = props;

  return (
    <Modal center open={open} onClose={onClose}>
      <Grow in={open}>
        <StyledModalPaper>
          <Provider>
            <Wrapper>
              <Panel>
                <Views title={title} />
              </Panel>
              <Divider type="vertical" />
              <Panel>
                <Added />
                <Footer
                  onCancel={onClose}
                  onFinish={onFinish}
                  loading={loading}
                />
              </Panel>
            </Wrapper>
          </Provider>
        </StyledModalPaper>
      </Grow>
    </Modal>
  );
};

export default Member;
