import React from 'react';
import styled from '@emotion/styled';
import { Badge } from 'antd';
import clsx from 'clsx';

import { Bell as NotificationIcon } from 'src/components/Icons';
import { useAppSelector } from 'src/hooks';

const PREFIX = 'AppBar-Notification--Trigger';

const classNames = {
  root: `${PREFIX}__root`,
  badge: `${PREFIX}__badge`,
};

const TriggerRoot = styled('div')(({ theme }) => ({
  [`&.${classNames.root}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    padding: 8,
    borderRadius: 10,
    '&:hover': {
      backgroundColor: theme.palette.background?.paper,
    },
  },
  [`& .${classNames.badge}`]: {
    height: 18,
  },
}));

export interface TriggerProps {
  className?: string | undefined;
  [propName: string]: any;
}

const Trigger: React.FC<TriggerProps> = ({ className, ...rest }) => {
  const unread = useAppSelector((state) => state.appBar.notification.unread);

  return (
    <TriggerRoot className={clsx(classNames.root, className)} {...rest}>
      <Badge className={classNames.badge} count={unread}>
        <NotificationIcon fontSize={18} />
      </Badge>
    </TriggerRoot>
  );
};

export default Trigger;
