import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';
import { isBefore, isAfter } from 'date-fns';

import DatePicker from '../DatePicker/DatePicker';
import DefaultSeparator from './Separator';

type DateType = Date | null | undefined;

export type DateRangeValue = [DateType, DateType] | undefined;

type Classes = {
  start?: string;
  end?: string;
  separator?: string;
};

type DatePickerFieldProps = {
  value?: DateRangeValue;
  placeholder?: [string, string];
  onChange?: (value?: DateRangeValue) => void;
  separator?: React.ReactElement;
  className?: string;
  classes?: Classes;
  disabled?: boolean;
};

function disableDate(
  compareFn: (start: Date, end: Date) => boolean,
  date?: Date | null
) {
  return (current?: Date) => {
    if (current && date) {
      return compareFn(current, date);
    }
    return false;
  };
}

const DateRangePicker = React.forwardRef<HTMLDivElement, DatePickerFieldProps>(
  (
    {
      className,
      classes,
      value = [],
      placeholder = ['开始时间', '结束时间'],
      onChange,
      separator = <DefaultSeparator />,
      disabled = false,
    },
    ref
  ) => {
    const [startPlaceholder, endPlaceholder] = placeholder;

    const [start, end] = value;

    const handleChange = useCallback(
      (field: 'start' | 'end') => {
        return (date?: Date | null) => {
          if (field === 'start') {
            onChange?.([date, end]);
          }
          if (field === 'end') {
            onChange?.([start, date]);
          }
        };
      },
      [onChange, end, start]
    );

    return (
      <div className={className} ref={ref}>
        <DatePicker
          size="large"
          placeholder={startPlaceholder}
          className={clsx('start', classes?.start)}
          value={start}
          onChange={handleChange('start')}
          disabledDate={disableDate(isAfter, end)}
          disabled={disabled}
        />
        {React.cloneElement(separator, {
          className: clsx('separator', classes?.separator),
        })}
        <DatePicker
          size="large"
          placeholder={endPlaceholder}
          className={clsx('end', classes?.end)}
          value={end}
          onChange={handleChange('end')}
          disabledDate={disableDate(isBefore, start)}
          disabled={disabled}
        />
      </div>
    );
  }
);

export default styled(DateRangePicker, { label: 'DateRangePicker' })({
  display: 'flex',
  alignItems: 'center',

  '& > .start, & > .end': {
    flex: 1,
  },

  '& > .separator': {
    flexShrink: 0,
  },

  '& input::placeholder': {
    color: '#999',
  },
});
