import React from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';

import { shouldForwardProp } from 'src/utils';
import { Tabs as StyledTabs, TabsProps } from 'src/components/Tabs';

const Tabs = React.memo<TabsProps>(function Tabs({ className, ...rest }) {
  return <StyledTabs className={clsx('Tabs-root', className)} {...rest} />;
});

export default styled(Tabs, { label: 'Tabs', shouldForwardProp })(({ theme, tabBarGutter }) => ({
  '&.Tabs-root.ant-tabs': {
    overflow: 'hidden',
    flex: 1,
  },

  '&.Tabs-root.ant-tabs .ant-tabs-nav': {
    margin: '0 20px',
  },

  '&.Tabs-root.ant-tabs .ant-tabs-nav .ant-tabs-tab': {
    padding: '20px 0',
  },

  '&.Tabs-root.ant-tabs .ant-tabs-content-holder': {
    overflow: 'auto',
    margin: 10,

    '&::-webkit-scrollbar': {
      width: 4,
    },

    '&::-webkit-scrollbar-thumb': {
      boxShadow: 'inset 0 0 6px #999',
      borderRadius: 4,
    },

    '&::-webkit-scrollbar-track': {
      margin: 4,
    },
  },
}));
