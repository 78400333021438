import React, { useMemo } from 'react';
import { TreeSelect } from 'antd';
import styled from '@emotion/styled';
import { useMemberDispatch, useMemberSelector } from '../../Provider';
import {
  selectCurrentSubdepartment,
  selectSubdepartmentList,
  selectCurrentDepartment,
} from '../../reducers/selectors';
import { showMembers } from '../../reducers/actions';

const SubdepartmentsRoot = styled('div')({
  padding: '0 2px',
  width: '100%',
  marginTop: 20,
});

const Subdepartments: React.FC = () => {
  const dispatch = useMemberDispatch();

  const list = useMemberSelector(selectSubdepartmentList);
  const subdepartment = useMemberSelector(selectCurrentSubdepartment);
  const department = useMemberSelector(selectCurrentDepartment);

  const value = useMemo(() => {
    if (subdepartment) {
      return { label: subdepartment.title, value: subdepartment.id };
    }
    return undefined;
  }, [subdepartment]);

  const handleChange = ({ value }: any) => {
    dispatch(
      showMembers({
        departmentId: department?.id || null,
        subdepartmentId: value,
      })
    );
  };

  if (!subdepartment || subdepartment.id === 0) return null;

  return (
    <SubdepartmentsRoot>
      <TreeSelect
        style={{ width: '100%' }}
        placeholder="请选择部门"
        treeData={list}
        value={value}
        labelInValue
        onChange={handleChange}
      />
    </SubdepartmentsRoot>
  );
};

export default Subdepartments;
