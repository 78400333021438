import React from 'react';
import styled from '@emotion/styled';
import { LeftOutlined } from '@ant-design/icons';

import { useMemberDispatch, useMemberSelector } from '../../Provider';
import { selectCurrentDepartment } from '../../reducers/selectors';
import { showDepartments } from '../../reducers/actions';

const TitleRoot = styled('div')(({ theme }) => ({
  flex: 0,
  display: 'inline-flex',
  alignItems: 'center',
  cursor: 'pointer',
  width: 'max-content',
  '& > span:hover': {
    color: theme.palette.primary?.main,
  },
  ...theme.typography.body1,
}));

const Title: React.FC = () => {
  const dispatch = useMemberDispatch();
  const department = useMemberSelector(selectCurrentDepartment);

  const handleClick = () => {
    dispatch(showDepartments());
  };

  return (
    <TitleRoot>
      <span onClick={handleClick}>
        <LeftOutlined style={{ marginRight: 10 }} />
        {department?.title}
      </span>
    </TitleRoot>
  );
};

export default Title;
