const defaultFontFamily = [
  '-apple-system',
  'BlinkMacSystemFont',
  "'Segoe UI'",
  'Helvetica',
  'Arial',
  'sans-serif',
  "'Apple Color Emoji'",
  "'Segoe UI Emoji'",
].join(', ');

export type Variant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'caption'
  | 'button'
  | 'overline';

export interface FontStyle {
  fontFamily: React.CSSProperties['fontFamily'];
  htmlFontSize: number;
  fontWeightLight: React.CSSProperties['fontWeight'];
  fontWeightRegular: React.CSSProperties['fontWeight'];
  fontWeightMedium: React.CSSProperties['fontWeight'];
  fontWeightBold: React.CSSProperties['fontWeight'];
}

export interface TypographyUtils {
  pxToRem: (px: number) => string;
}

export interface TypographyOptions
  extends Partial<FontStyle & TypographyUtils & Record<Variant, React.CSSProperties>> {}

export interface Typography
  extends Record<Variant, React.CSSProperties>,
    FontStyle,
    TypographyUtils {}

export default function createTypography(typography: TypographyOptions = {}): Typography {
  const {
    fontFamily = defaultFontFamily,
    htmlFontSize = 16,
    fontWeightLight = 300,
    fontWeightRegular = 400,
    fontWeightMedium = 500,
    fontWeightBold = 600,
    pxToRem: pxToRem2,
    ...other
  } = typography;

  const pxToRem = pxToRem2 || ((size: number) => `${size / htmlFontSize}rem`);

  const buildVariant = (
    fontWeight: React.CSSProperties['fontWeight'],
    size: number,
    lineHeight: number
  ) => ({
    fontFamily,
    fontWeight,
    fontSize: pxToRem(size),
    lineHeight,
  });

  const variants = {
    h1: buildVariant(fontWeightLight, 60, 1.167),
    h2: buildVariant(fontWeightLight, 48, 1.2),
    h3: buildVariant(fontWeightRegular, 34, 1.167),
    h4: buildVariant(fontWeightRegular, 22, 1.235),
    h5: buildVariant(fontWeightRegular, 20, 1.334),
    h6: buildVariant(fontWeightMedium, 18, 1.6),
    subtitle1: buildVariant(fontWeightRegular, 16, 1.75),
    subtitle2: buildVariant(fontWeightMedium, 14, 1.57),
    body1: buildVariant(fontWeightRegular, 16, 1.5715),
    body2: buildVariant(fontWeightRegular, 14, 1.5715),
    button: buildVariant(fontWeightMedium, 14, 1.75),
    caption: buildVariant(fontWeightRegular, 12, 1.66),
    overline: buildVariant(fontWeightRegular, 12, 2.66),
  };

  return {
    fontFamily,
    htmlFontSize,
    fontWeightLight,
    fontWeightRegular,
    fontWeightMedium,
    fontWeightBold,
    pxToRem,
    ...variants,
    ...other,
  };
}
