import { useEffect, useState } from 'react';

import styled from '@emotion/styled';
import clsx from 'clsx';

import { Image } from 'src/components/Image';
import { composeClasses } from 'src/utils';

interface PreviewProps {
  image?: File | string | undefined;
  className?: string | undefined;
}

const classPrefix = 'Preview';

const useUtilityClasses = () => {
  const slots = {
    root: ['root'],
  };
  return composeClasses(classPrefix, slots);
};

function readAsDataUrl(image: File): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = reject;
    fileReader.readAsDataURL(image);
  });
}

const PreviewRoot = styled(Image)({
  width: 234,
  height: 144,
  borderRadius: 10,
});

const Preview: React.FC<PreviewProps> = ({ image, className }) => {
  const classes = useUtilityClasses();
  const [src, setSrc] = useState<string>();

  useEffect(() => {
    const init = async () => {
      if (image instanceof File) {
        const result = await readAsDataUrl(image);
        setSrc(result as string);
      } else {
        setSrc(image);
      }
    };

    init();
  }, [image]);

  return (
    <PreviewRoot
      className={clsx(classes.root, className)}
      src={src}
      objectFit="cover"
    />
  );
};

export default Preview;
