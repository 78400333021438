import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { Loading } from 'src/shared/constants';
import { getErrorMessage } from 'src/utils';
import AppBarService from 'src/services/appBar';
import { AppState } from 'src/app/store';
import { defineNotificationItem, NotificationTypes } from './utils';
import { ID } from 'src/shared/interfaces';

const name = 'appBar/notifications' as const;

type ListItemType = {
  loading: Loading;
  entities: any[];
  page: number | undefined;
  limit: number | undefined;
  hasMore: boolean;
};

interface State {
  list: {
    all: ListItemType;
    unread: ListItemType;
    read: ListItemType;
  };
  unread: number;
  type: NotificationTypes;
}

const initialListItem: ListItemType = {
  loading: Loading.idle,
  entities: [],
  page: undefined,
  limit: undefined,
  hasMore: true,
};

const initialState: State = {
  list: {
    all: initialListItem,
    unread: initialListItem,
    read: initialListItem,
  },
  unread: 0,
  type: NotificationTypes.all,
};

/**
 * 获取未读消息计数
 */
export const fetchUnreadTotal = createAsyncThunk<number, void, { rejectValue: string }>(
  `${name}/fetchUnreadTotal`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await AppBarService.getMessage({ type: NotificationTypes.unread });
      return response.data.meta.pagination.total || 0;
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  }
);

type FetchNotificationListReturned = {
  hasMore: boolean;
  entities: any[];
  type: NotificationTypes;
  total: number;
};

type FetchNotificationListParams = {
  page?: number | undefined;
  limit?: number | undefined;
};

/**
 * 获取消息列表工厂函数
 * @param name
 * @param type
 * @returns
 */
function fetchNotificationListFactory(name: string, type: NotificationTypes) {
  return createAsyncThunk<
    FetchNotificationListReturned,
    FetchNotificationListParams,
    { state: AppState; rejectValue: string }
  >(name, async ({ page, limit }, { rejectWithValue }) => {
    try {
      const notificationType = type === NotificationTypes.all ? undefined : type;
      const response = await AppBarService.getMessage({ page, limit, type: notificationType });
      return {
        total: response.data.meta.pagination.total,
        entities: response.data.data.map((item) => defineNotificationItem(item)),
        hasMore: (page || 1) < response.data.meta.pagination.total_pages,
        type,
      };
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  });
}

/**
 * 获取全部消息
 */
export const fetchAllNotifications = fetchNotificationListFactory(
  `${name}/fetchAllNotifications`,
  NotificationTypes.all
);

/**
 * 获取已读消息
 */
export const fetchReadNotifications = fetchNotificationListFactory(
  `${name}/fetchReadNotifications`,
  NotificationTypes.read
);

/**
 * 获取未读消息
 */
export const fetchUnreadNotifications = fetchNotificationListFactory(
  `${name}/fetchUnreadNotifications`,
  NotificationTypes.unread
);

/**
 * 标记已读
 */
export const markRead = createAsyncThunk<void, ID, { rejectValue: string }>(
  `${name}/markRead`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await AppBarService.setMessage({ id: String(id) });
      console.log(response);
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  }
);

/**
 * 全部标记未已读
 */
export const markAll = createAsyncThunk<void, void, { rejectValue: string }>(
  `${name}/markAll`,
  async (_, { rejectWithValue }) => {
    try {
      await AppBarService.setMessage({ id: '0' });
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  }
);

/**
 * 删除消息
 */
export const remove = createAsyncThunk<void, ID, { rejectValue: string }>(
  `${name}/remove`,
  async (id, { rejectWithValue }) => {
    try {
      await AppBarService.removeMessage({ id } as any);
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  }
);

/**
 * 删除全部已读
 */
export const removeAll = createAsyncThunk<void, void, { rejectValue: string }>(
  `${name}/removeAll`,
  async (_, { rejectWithValue }) => {
    try {
      await AppBarService.removeMessage({ id: '0', all: '1' });
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  }
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
    clear: (state) => {
      state.list = {
        all: initialListItem,
        unread: initialListItem,
        read: initialListItem,
      };
      state.type = NotificationTypes.all;
    },
    updateNotification: (
      state,
      action: PayloadAction<{ type: NotificationTypes; changed: { id: ID; changed: any } }>
    ) => {
      const entity = getEntity(action.payload.type, state);
      entity.entities = entity.entities.map((item) => {
        if (item.id === action.payload.changed.id) {
          return { ...item, ...action.payload.changed.changed };
        }
        return item;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUnreadTotal.fulfilled, (state, action) => {
      state.unread = action.payload;
    });

    builder.addCase(markRead.fulfilled, (state, action) => {
      state.list.all.entities = state.list.all.entities.map((item) => {
        if (item.id === action.meta.arg) {
          return { ...item, read: 1 };
        }
        return item;
      });
      state.unread -= 1;
    });

    builder.addCase(remove.fulfilled, (state, action) => {
      const entity = getEntity(state.type, state);
      const notification = entity.entities.find((item) => item.id === action.meta.arg);
      if (notification && notification.read === 0) {
        // 被删除的消息处于未读状态
        state.unread -= 1;
      }
      entity.entities = entity.entities.filter((item) => item.id !== action.meta.arg);
    });

    builder.addCase(markAll.fulfilled, (state, action) => {
      const entity = getEntity(state.type, state);
      entity.entities = entity.entities.map((item) => ({ ...item, read: 1 }));
      state.unread = 0;
    });

    builder.addCase(removeAll.fulfilled, (state, action) => {
      const entity = getEntity(state.type, state);
      entity.entities = entity.entities.filter((item) => item.read === 0);
    });

    fetchListReducers(builder, NotificationTypes.all);
    fetchListReducers(builder, NotificationTypes.read);
    fetchListReducers(builder, NotificationTypes.unread);
  },
});

export const { reset, clear, updateNotification } = slice.actions;

export default slice.reducer;

/** Selectors */
export const selectEntities = createSelector(
  [
    (state: AppState) => state.appBar.notification,
    (state: AppState, type: NotificationTypes) => type,
  ],
  (notifications, type) => {
    const state = getEntity(type, notifications);
    return state.entities;
  }
);

export const selectHasMore = createSelector(
  [
    (state: AppState) => state.appBar.notification,
    (state: AppState, type: NotificationTypes) => type,
  ],
  (notifications, type) => {
    const state = getEntity(type, notifications);
    return state.hasMore;
  }
);

export const selectCurrentPage = createSelector(
  [
    (state: AppState) => state.appBar.notification,
    (state: AppState, type: NotificationTypes) => type,
  ],
  (notifications, type) => {
    const state = getEntity(type, notifications);
    return state.page;
  }
);

export const selectLoading = createSelector(
  [
    (state: AppState) => state.appBar.notification,
    (state: AppState, type: NotificationTypes) => type,
  ],
  (notifications, type) => {
    const state = getEntity(type, notifications);
    return state.loading;
  }
);

export function getThunkAction(type: NotificationTypes) {
  switch (type) {
    case 1: {
      return fetchReadNotifications;
    }
    case 2: {
      return fetchUnreadNotifications;
    }
    default:
      return fetchAllNotifications;
  }
}

function getEntity(type: NotificationTypes, state: State) {
  switch (type) {
    case 1: {
      return state.list.read;
    }
    case 2: {
      return state.list.unread;
    }
    default:
      return state.list.all;
  }
}

function fetchListReducers(builder: ActionReducerMapBuilder<State>, type: NotificationTypes) {
  const thunk = getThunkAction(type);

  builder.addCase(thunk.pending, (state, action) => {
    const entity = getEntity(type, state);
    entity.loading = Loading.pending;
  });

  builder.addCase(thunk.fulfilled, (state, action) => {
    const entity = getEntity(type, state);
    entity.loading = Loading.fulfilled;
    entity.entities.push(...action.payload.entities);
    entity.hasMore = action.payload.hasMore;
    entity.page = action.meta.arg.page;
    entity.limit = action.meta.arg.limit;
    state.type = action.payload.type;
    if (type === NotificationTypes.unread) {
      state.unread = action.payload.total;
    }
  });

  builder.addCase(thunk.rejected, (state, action) => {
    const entity = getEntity(type, state);
    entity.loading = Loading.rejected;
  });
}
