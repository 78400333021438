import React, {
  useContext,
  useMemo,
  useReducer,
  useRef,
  useCallback,
} from 'react';

import MemberContext from './Context';
import { initialState, reducer } from '../reducers';
import { Store, Selector, State, Actions } from '../interface';
import { useEffect } from 'react';
import { reset } from '../reducers/actions';

// 直接放到 redux 中会更方便
const Provider: React.FC = ({ children }) => {
  const prevState = useRef<State>(initialState);
  const queue = useRef<{ action: Actions; state: State }[]>([]);

  const [state, dispatch] = useReducer(
    (state: State, action: Actions) =>
      (prevState.current = reducer(state, action)),
    initialState,
    () => reducer(initialState, { type: '@@member/init' })
  );

  const getState = useCallback(() => prevState.current, []);

  const enhancedDispatch = useCallback(
    (action: Actions | ((dispatch: React.Dispatch<Actions>) => void)) => {
      if (typeof action === 'function') {
        action(enhancedDispatch);
      } else {
        queue.current.push({ action, state: getState() });
        dispatch(action);
      }
    },
    [getState]
  );

  useMemo(() => {
    if (process.env.NODE_ENV !== 'production') {
      while (queue.current.length > 0) {
        const item = queue.current.shift();
        if (item) {
          console.group(item.action.type);
          console.log('%c prev state', 'color: red', item.state);
          console.log('%c action: ', 'color: blue', item.action);
          console.log('%c next state: ', 'color: green', state);
          console.groupEnd();
        }
      }
    }
  }, [state]);

  useEffect(() => {
    return () => {
      enhancedDispatch(reset());
    };
  }, [enhancedDispatch]);

  const store = useMemo<Store>(
    () => [state, enhancedDispatch],
    [state, enhancedDispatch]
  );

  return (
    <MemberContext.Provider value={store}>{children}</MemberContext.Provider>
  );
};

export default Provider;

export function useMemberStore() {
  return useContext(MemberContext);
}

export function useMemberDispatch() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, dispatch] = useMemberStore();
  return dispatch;
}

export function useMemberSelector<TSelected>(
  selector: Selector<State, TSelected>
) {
  const [state] = useMemberStore();
  return selector(state);
}
