import {
  createAsyncThunk,
  createSelector,
  createSlice,
  nanoid,
  PayloadAction,
} from '@reduxjs/toolkit';
import { AppState } from 'src/app/store';
import FileSaver from 'file-saver';

import bookService from 'src/services/book';
import draftService from 'src/services/draft';
import favoriteService from 'src/services/favorites';
import { DefaultPageTitle, Loading, ResourceType } from 'src/shared/constants';
import { BookOrFolder, HttpResponse, ID, Page, PageMenu, Template } from 'src/shared/interfaces';
import { getErrorMessage, omit } from 'src/utils';

type Draft = {
  id?: ID | undefined; // 草稿 id
  title?: string | undefined;
  content?: string | undefined;
  pageId?: ID | undefined; // 所属知识页 id
  resourcePageId?: ID | undefined; // 上级知识页 id
  menuKey?: string | number | undefined;
};

interface State {
  from: 'book' | 'draft'; // 入口标识， book: 从知识本进入， draft: 从草稿箱进入，从草稿箱进入时，其他目录不可点击
  menus: TreeNodeType[];
  loading: Loading;
  status: 'view' | 'edit' | 'needPermission' | 'create';
  templates: Template[];
  baseId: ID | undefined; // 知识页 id
  baseFolderId: ID | undefined; // 知识本 id
  current: ID | undefined; // 当前预览的知识页 id
  page: Partial<Page> | null; // 预览页面显示的内容
  draft: Draft | null; // 草稿
  keyword: string; // 关键字搜索
  authority: ID | undefined; //是否有权限 0 无权限  1 有权限
  saving?: boolean;
  downloading?: boolean;
}

const initialState: State = {
  from: 'book',
  menus: [],
  loading: Loading.idle,
  status: 'view',
  current: undefined,
  templates: [],
  baseId: undefined,
  baseFolderId: undefined,
  page: null,
  draft: null,
  keyword: '',
  authority: undefined,
  saving: false,
  downloading: false,
};

type ThunkApi = { state: AppState; rejectValue: string };

const name = 'books';

/**
 * 详情
 */
export const fetchDetails = createAsyncThunk<BookOrFolder, ID, ThunkApi>(
  `${name}/details`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await bookService.fetchDetails(id);
      return response.data;
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

/**
 * 知识本目录
 */
export const fetchMenus = createAsyncThunk<
  { page: TreeNodeType[]; template: Template[]; authority: number },
  { id: ID; keyword?: string },
  ThunkApi
>(`${name}/menus`, async (data, { rejectWithValue }) => {
  try {
    const response = await bookService.fetchMenus(data);
    const { page, template, authority } = response.data;
    return { page: formatTreeData(page), template, authority };
  } catch (err) {
    const message = getErrorMessage(err);
    return rejectWithValue(message);
  }
});

/**
 * 知识页/知识页草稿详情
 */
export const fetchPage = createAsyncThunk<Page, ID, ThunkApi>(
  `${name}/page`,
  async (id, { rejectWithValue, getState }) => {
    try {
      const {
        books: { from },
      } = getState();
      let response: HttpResponse<Page>;
      if (from === 'draft') {
        response = await draftService.fetchPage(id);
      } else {
        response = await bookService.fetchPage(id);
      }

      return response.data;
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

type UpdateArgs = { id: ID; name: string };

/**
 * 重命名
 */
export const renamePage = createAsyncThunk<void, UpdateArgs, ThunkApi>(
  `${name}/update`,
  async ({ id, name }, { rejectWithValue }) => {
    try {
      await bookService.renamePage(id, name);
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

/**
 * 保存到草稿
 */
export const saveToDraft = createAsyncThunk<Partial<Page> | null, void, ThunkApi>(
  `${name}/saveToDraft`,
  async (_, { rejectWithValue, getState }) => {
    try {
      const {
        books: { baseFolderId, baseId, draft },
      } = getState();

      if (draft) {
        const data = {
          ...omit(draft, ['id', 'menuKey']),
          baseFolderId,
          baseId,
        };
        let response: HttpResponse<Page>;
        if (draft.id) {
          response = await draftService.updatePage(draft.id, data);
        } else {
          response = await draftService.createPage(data);
        }
        return response.data;
      }

      return null;
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

/**
 * 保存并提交
 */
export const submit = createAsyncThunk<Page | null, void, ThunkApi>(
  `${name}/submit`,
  async (_, { getState, rejectWithValue }) => {
    try {
      const {
        books: { baseFolderId, baseId, draft },
      } = getState();
      if (draft) {
        console.log(draft);
        let draftId = draft.id;
        const data = {
          ...omit(draft, ['id', 'menuKey']),
          baseFolderId,
          baseId,
        };
        if (!draftId) {
          // 还没有保存到草稿，则先保存到草稿
          const response = await draftService.createPage(data);
          draftId = response.data.id;
        }

        const result = await draftService.updatePage(draftId, data, true);
        return result.data;
      }

      return null;
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

/**
 * 保存为模板
 */
export const savePageAsTemplate = createAsyncThunk<
  Template,
  { title: string; file: File },
  ThunkApi
>(`${name}/saveAsTemplate`, async ({ title, file }, { getState, rejectWithValue }) => {
  try {
    const {
      books: { draft, page },
    } = getState();

    const content = draft?.content ?? page?.content;
    if (!content) {
      throw new Error('请填写模板内容');
    }

    const response = await bookService.savePageAsTemplate({
      title,
      file,
      content: draft?.content ?? page?.content ?? '',
    });
    return response.data;
  } catch (err) {
    const message = getErrorMessage(err);
    return rejectWithValue(message);
  }
});

type FavoriteArgs = { type: 1 | 2; id?: ID | undefined };

/**
 * 收藏
 */
export const favorite = createAsyncThunk<ID, FavoriteArgs, ThunkApi>(
  `${name}/favorite`,
  async ({ type, id }, { getState, rejectWithValue }) => {
    const state = getState();
    const { current: resourceId } = state.books;
    try {
      const response = await favoriteService.favorite({
        type,
        id,
        resourceId,
        resource: ResourceType.paper,
      });
      return response.data.id;
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

/**
 * 删除知识页
 */
export const removePage = createAsyncThunk<{}, ID, ThunkApi>(
  `${name}/remove`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await bookService.removePage(id);
      return response.data;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

type CopyArgs = { id: ID; to?: ID; keep?: boolean };

/**
 * 创建副本/复制到
 */
export const copy = createAsyncThunk<Page | null, CopyArgs, ThunkApi>(
  `${name}/copy`,
  async ({ id, to, keep }, { rejectWithValue }) => {
    try {
      const response = await bookService.copy(id, { to, keep });
      return response.data;
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

export const downloadAsPDF = createAsyncThunk<void, { title: string; content: string }, ThunkApi>(
  `${name}/download`,
  async (page, { rejectWithValue }) => {
    try {
      const blob = await bookService.downloadAsPDF(page.content);
      FileSaver.saveAs(blob, `${page.title}.pdf`);
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  }
);

export const findPageDraft = createAsyncThunk<Page | null, void, ThunkApi>(
  `${name}/findPageDraft`,
  async (_, { rejectWithValue, getState }) => {
    try {
      const {
        books: { page },
      } = getState();
      if (page) {
        const pageId = page.pageId || page.id;
        if (pageId) {
          const response = await bookService.findDraft(pageId);
          return response.data;
        }
        return null;
      }
      return null;
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  }
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    /**
     * 初始化
     */
    init(state, action: PayloadAction<{ baseFolderId: ID; from: 'book' | 'draft' }>) {
      state.baseFolderId = action.payload.baseFolderId;
      state.from = action.payload.from;
    },
    /**
     * 重置状态
     */
    reset(state) {
      Object.assign(state, initialState);
    },
    /**
     * 重命名按钮
     */
    rename(state, action: PayloadAction<ID>) {
      state.menus = updateTreeNode(state.menus, action.payload, {
        mode: 'edit',
      });
    },
    /**
     * 添加按钮，跳转至新建页面
     */
    add(state, action: PayloadAction<{ pid: ID }>) {
      // state.resourcePageId = action.payload.resourcePageId;
      // 草稿暂存，主要为了 resourcePageId, 后面创建目录需要
      state.draft = { resourcePageId: action.payload.pid };
      state.status = 'create';
    },

    /**
     * 新建知识页，点击空白模板或自定义模板，新建一个 draft
     */
    create: {
      reducer: (
        state,
        action: PayloadAction<{
          menuKey: string;
          title: string;
          content: string;
        }>
      ) => {
        if (state.draft !== null) {
          const { menuKey, title, content } = action.payload;
          const { resourcePageId = 0 } = state.draft;
          const menu: TreeNodeType = {
            id: menuKey,
            key: menuKey,
            pid: resourcePageId,
            title: title || DefaultPageTitle,
            isLeaf: true,
            mode: 'new',
          };
          state.menus = insertTreeNode(state.menus, resourcePageId, menu);
          state.page = null;
          state.draft = { ...state.draft, content, title, menuKey };
          state.current = menuKey;
          state.status = 'edit';
        }
      },
      prepare: ({ title, content }) => {
        const id = nanoid();
        return { payload: { menuKey: id, title, content } };
      },
    },
    /**
     * 编辑，从 state.page 复制一个 draft,
     *
     * 预览莫个知识页，点击编辑按钮，此时需要从 state.page 中复制 draft
     */
    edit(state) {
      if (state.page) {
        const { id, pageId, ...rest } = state.page;
        if (state.from === 'draft') {
          // 知识页来自草稿，此时 id 为草稿箱 id
          state.draft = { ...rest, id, pageId };
        }
      }
      state.status = 'edit';
    },
    /**
     * 退出编辑
     *
     * 清除 state.draft
     */
    quitEdit(state) {
      if (state.draft) {
        const menu = findMenuByKey(state.menus, state.draft.menuKey || -1);
        if (menu && menu.mode === 'new') {
          state.menus = removeTreeNode(state.menus, menu.key);
          state.current = state.menus[0]?.id;
        }
        state.draft = null;
      }
    },
    /**
     * 预览知识页
     */
    view(state, action: PayloadAction<ID | undefined>) {
      if (action.payload) {
        state.current = action.payload;
      }
      state.status = 'view';
    },

    /**
     * 更新草稿
     */
    update(state, action: PayloadAction<Partial<Page>>) {
      state.draft = { ...state.draft, ...action.payload };
    },

    /**
     * 关键字搜索
     */
    search(state, action) {
      state.keyword = action.payload;
    },

    /**
     *  显示申请权限页面
     * @param state
     */
    requestForAccess(state) {
      state.status = 'needPermission';
    },
    /**
     * 复制不保留原知识页时，删除目录，并重置当前预览
     * @param state
     */
    copyWithoutKeep(state, action) {
      if (!action.payload.keep) {
        state.menus = removeTreeNode(state.menus, action.payload.id);
        if (state.current === action.payload.id) {
          state.current = state.menus[0]?.id;
        }
      }
    },
  },

  extraReducers: (builder) => {
    /**
     * 知识本详情
     */
    builder
      .addCase(fetchDetails.pending, (state) => {
        state.loading = Loading.pending;
      })
      .addCase(fetchDetails.fulfilled, (state, action) => {
        state.loading = Loading.fulfilled;
        state.baseId = action.payload.baseId;
      })
      .addCase(fetchDetails.rejected, (state) => {
        state.loading = Loading.rejected;
        state.baseId = undefined;
      });

    /**
     * 知识本目录结构， 即知识页
     */
    builder
      .addCase(fetchMenus.fulfilled, (state, action) => {
        if (state.from === 'draft') {
          const flatten = flattenTreeData(action.payload.page);
          Object.keys(flatten).forEach((key) => {
            flatten[key].disabled = true;
          });
        }
        state.authority = action.payload.authority;
        state.menus = action.payload.page;
        state.templates = action.payload.template;
      })
      .addCase(fetchMenus.rejected, (state) => {
        state.menus = [];
        state.templates = [];
        state.current = undefined;
      });

    /**
     * 知识页/知识页草稿详情
     */
    builder
      .addCase(fetchPage.fulfilled, (state, action) => {
        const { id, title, resourcePageId, pageId } = action.payload;

        if (state.from === 'draft') {
          const menu: TreeNodeType = {
            id,
            key: id,
            title,
            pid: resourcePageId,
            isLeaf: true,
            disabled: false,
          };
          if (pageId) {
            // 曾经审核通过，知识页目录中已经存在
            state.menus = updateTreeNode(state.menus, pageId, menu);
          } else {
            state.menus = insertTreeNode(state.menus, resourcePageId, menu);
          }
          state.page = action.payload;
        } else {
          state.page = action.payload;
        }
      })
      .addCase(fetchPage.rejected, (state) => {
        state.page = null;
        state.draft = null;
      });

    /**
     * 删除知识页
     */
    builder.addCase(removePage.fulfilled, (state, action) => {
      const id = action.meta.arg;
      const flatten = flattenTreeData(state.menus);
      const menu = flatten[id];
      if (menu) {
        state.menus = removeTreeNode(state.menus, id);
        state.current = state.menus[0].id;
      }
    });

    /**
     * 重命名
     */
    builder.addCase(renamePage.fulfilled, (state, action) => {
      const { id, name } = action.meta.arg;
      state.menus = updateTreeNode(state.menus, id, {
        mode: 'view',
        title: name,
      });
    });

    /**
     * 创建副本
     */
    builder.addCase(copy.fulfilled, (state, action) => {
      if (action.payload) {
        const { resourcePageId, id, title, baseFolderId } = action.payload;
        if (baseFolderId === state.baseFolderId) {
          const menu = {
            id,
            title,
            key: id,
            pid: resourcePageId,
            isLeaf: true,
          };
          state.menus = insertTreeNode(state.menus, resourcePageId, menu);
        }
      }
    });

    /**
     * 保存并提交
     */
    builder.addCase(submit.fulfilled, (state, action) => {
      const submitted = action.payload;
      if (submitted) {
        if (submitted.status === 2) {
          // 用户有权限，无须审核，直接创建，需要修改目录
          const { pageId, title } = submitted;
          const updated: Partial<TreeNodeType> = {
            title,
            key: pageId,
            id: pageId,
            mode: 'view',
          };
          state.menus = updateTreeNode(state.menus, state.current!, updated);
          state.page = submitted;
          state.current = pageId;
        } else if (state.page === null) {
          state.menus = removeTreeNode(state.menus, state.current!);
          state.current = state.menus[0]?.id;
        }
      }
    });

    /** 保存到草稿 */
    builder
      .addCase(saveToDraft.pending, (state) => {
        state.saving = true;
      })
      .addCase(saveToDraft.fulfilled, (state) => {
        state.saving = false;
      })
      .addCase(saveToDraft.rejected, (state) => {
        state.saving = false;
      });

    /** 下载为 PDF */
    builder
      .addCase(downloadAsPDF.pending, (state) => {
        state.downloading = true;
      })
      .addCase(downloadAsPDF.fulfilled, (state) => {
        state.downloading = false;
      })
      .addCase(downloadAsPDF.rejected, (state) => {
        state.downloading = false;
      });

    builder.addCase(findPageDraft.fulfilled, (state, action) => {
      if (action.payload && action.payload.id) {
        state.draft = action.payload;
      } else {
        if (state.page) {
          const { id, pageId, ...rest } = state.page;
          state.draft = { ...rest, pageId: pageId || id };
        }
      }
    });
  },
});

export default slice.reducer;

export const {
  init,
  create,
  add,
  view,
  edit,
  update,
  reset,
  rename,
  search,
  quitEdit,
  requestForAccess,
  copyWithoutKeep,
} = slice.actions;

/**
 * selectors
 */
export const selectIsLoading = (state: AppState) => {
  return state.books.loading === Loading.pending;
};

export const selectStatus = (state: AppState) => {
  return state.books.status;
};

export const selectCurrent = (state: AppState) => {
  return state.books.current;
};

export const selectTemplates = (state: AppState) => {
  return state.books.templates;
};

export const selectMenus = (state: AppState) => {
  return state.books.menus;
};

export const selectFlattenMenus = (state: AppState) => {
  return flattenTreeData(state.books.menus);
};

export const selectDraft = (state: AppState) => {
  return state.books.draft;
};

export const selectPage = (state: AppState) => {
  return state.books.page;
};

export const selectKeyword = (state: AppState) => {
  return state.books.keyword;
};

export const selectFrom = (state: AppState) => {
  return state.books.from;
};

export const selectAuthority = (state: AppState) => {
  return state.books.authority;
};

export const selectFilterByKeyword = createSelector(
  selectKeyword,
  selectFlattenMenus,
  (keyword, menus) => {
    if (!keyword) return [];
    return Object.keys(menus)
      .filter((key) => {
        const menu = menus[key];
        return menu.title.match(keyword);
      })
      .map((key) => menus[key]);
  }
);

/**
 * 当前选中目录的祖先
 */
export const selectAncestorsOfCurrent = createSelector(
  selectMenus,
  selectCurrent,
  (menus, current) => parentNodes(menus, current || 0)
);

export interface TreeNodeType {
  pid: ID;
  id: ID;
  key: string | number;
  title: string;
  children?: TreeNodeType[];
  disabled?: boolean;
  selectable?: boolean;
  mode?: 'view' | 'edit' | 'new';
  isLeaf?: boolean;
  level?: number;
}

/**
 * 格式化
 * @param treeData
 * @param parent
 * @returns
 */
function formatTreeData(
  treeData: PageMenu[] = [],
  parent: PageMenu | null = null,
  depth: number = 0
): TreeNodeType[] {
  return treeData.reduce<TreeNodeType[]>((acc, node) => {
    const treeNode: TreeNodeType = {
      pid: parent ? parent.id : 0,
      id: node.id,
      key: node.id,
      title: node.name,
      mode: 'view',
      disabled: false,
      isLeaf: !node.children || node.children.length === 0,
      level: depth,
      children: formatTreeData(node.children, node, depth + 1),
    };

    return acc.concat(treeNode);
  }, []);
}

/**
 * 根据可查询目录
 * @param tree
 * @param key
 * @returns
 */
function findMenuByKey(tree: TreeNodeType[], key: string | number) {
  const flatten = flattenTreeData(tree);
  return flatten[key] || null;
}

/**
 * 扁平化树，便于检索
 * @param tree
 * @returns
 */
function flattenTreeData(tree: TreeNodeType[]) {
  return tree.reduce<Record<string, TreeNodeType>>((acc, node) => {
    if (node.children) {
      acc = { ...acc, ...flattenTreeData(node.children) };
    }
    return { ...acc, [node.id]: node };
  }, {});
}

/**
 * 是否存在
 * @param tree
 * @param node
 * @returns
 */
function isExistInTree(tree: Record<string, TreeNodeType>, node: TreeNodeType): boolean {
  return Boolean(tree[node.id]);
}

/**
 * 树中指定位置插入节点
 * @param tree
 * @param position 父级节点 id
 * @param node
 * @returns
 */
function insertTreeNode(tree: TreeNodeType[], position: ID, node: TreeNodeType): TreeNodeType[] {
  const flatten = flattenTreeData(tree);
  if (isExistInTree(flatten, node)) return tree;

  const parent = flatten[position];

  if (parent) {
    if (parent.children && parent.children.length > 0) {
      parent.children.push({ ...node, level: (parent.level || 0) + 1 });
    } else {
      parent.isLeaf = false;
      parent.children = [{ ...node, level: (parent.level || 0) + 1 }];
    }
    return tree;
  }
  return tree.concat({ ...node, level: 0 });
}

/**
 * 移除节点
 * @param tree
 * @param node
 * @returns
 */
function removeTreeNode(tree: TreeNodeType[], key: string | number): TreeNodeType[] {
  ('');
  const flatten = flattenTreeData(tree);
  const node = flatten[key];
  const parent = flatten[node.pid];
  if (parent && parent.children && parent.children.length > 0) {
    parent.children = parent.children.filter((t) => t.key !== node.key);
    parent.isLeaf = parent.children.length === 0;
    return tree;
  }
  return tree.filter((t) => t.key !== node.key);
}

/**
 * 更新 [id] 对应的节点
 * @param tree
 * @param id
 * @param changes
 * @returns
 */
export function updateTreeNode(
  tree: TreeNodeType[],
  id: ID,
  changes: Partial<TreeNodeType>
): TreeNodeType[] {
  const flatten = flattenTreeData(tree);
  const node = flatten[id];
  if (node) {
    Object.assign(node, changes);
  }
  return tree;
}

/**
 * 获取当前节点的所有父节点
 * @param tree
 * @param position
 */
function parentNodes(tree: TreeNodeType[], position: ID) {
  const flatten = flattenTreeData(tree);
  let node = flatten[position];
  const output: TreeNodeType[] = [];
  while (node) {
    const parent = flatten[node.pid];
    if (parent) {
      output.push(parent);
    }
    node = parent;
  }
  return output.reverse();
}
