import { AxiosRequestConfig, AxiosResponse } from 'axios';

import { LocalStorageKey } from 'src/shared/constants';
import { localStorage } from 'src/utils';

export function setToken(config: AxiosRequestConfig) {
  const token = localStorage.get(LocalStorageKey.accessToken);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}

export function refreshToken(response: AxiosResponse) {
  const token = response.headers['Set_Token'];
  const expiredAt = response.headers['Set_Token_Expire_Time'];
  if (token) {
    localStorage.set(LocalStorageKey.accessToken, token);
    localStorage.set(LocalStorageKey.expiredAt, expiredAt);
    response.config.headers.Authorization = `Bearer ${token}`;
    console.log('refresh token: ', token);
    console.log(response);
  }
  return response;
}
