import React from 'react';

import Member from 'src/pages/components/Member';

interface Step1Props {
  open?: boolean;
  onCancel?: () => void;
  onFinish?: (values: any) => void;
  loading?: boolean;
}

const Step2: React.FC<Step1Props> = (props) => {
  const { open = false, onCancel, onFinish, loading = false } = props;

  return (
    <Member
      open={open}
      onClose={onCancel}
      onFinish={onFinish}
      loading={loading}
      title="分享浏览"
    />
  );
};

export default Step2;
