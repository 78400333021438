import { UploadState } from './state';
import { UploadActions, ActionTypes } from './actions';
import { modifyFiles } from './utils';
import { UploadStatus } from './constants';

export default function reducer(state: UploadState, action: UploadActions) {
  switch (action.type) {
    case ActionTypes.SetUploadFiles: {
      const { files, owner } = action.payload;
      return {
        ...state,
        tasks: {
          ...state.tasks,
          ...modifyFiles(files, owner, state.tasks),
        },
      };
    }

    case ActionTypes.ClearUploadFiles: {
      return {
        ...state,
        tasks: undefined,
      };
    }

    case ActionTypes.SetUploadProgress: {
      if (!state.tasks || !state.tasks[action.payload.id]) return state;

      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.payload.id]: {
            ...state.tasks[action.payload.id],
            progress: action.payload.progress,
          },
        },
      };
    }

    case ActionTypes.SetUploadStatus: {
      if (!state.tasks || !state.tasks[action.payload.id]) return state;

      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.payload.id]: {
            ...state.tasks[action.payload.id],
            status: action.payload.status,
          },
        },
      };
    }

    case ActionTypes.SetUploadSpeed: {
      if (!state.tasks || !state.tasks[action.payload.id]) return state;

      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.payload.id]: {
            ...state.tasks[action.payload.id],
            speed: action.payload.speed,
          },
        },
      };
    }

    case ActionTypes.RetryUploadFile: {
      if (!state.tasks || !state.tasks[action.payload.id]) return state;

      return {
        ...state,
        tasks: {
          ...state.tasks,
          [action.payload.id]: {
            ...state.tasks[action.payload.id],
            status: UploadStatus.Pending,
            progress: 0,
            speed: 0,
          },
        },
      };
    }

    case ActionTypes.CancelUploadFile: {
      if (!state.tasks || !state.tasks[action.payload.id]) return state;

      return {
        ...state,
        tasks: Object.entries(state.tasks).reduce((acc, item) => {
          const [id, value] = item;
          if (Number(id) !== action.payload.id) {
            return { ...acc, [id]: value };
          }
          return acc;
        }, {}),
      };
    }

    case ActionTypes.SetDrawerOpen: {
      return {
        ...state,
        open: action.payload,
      };
    }

    case ActionTypes.SetDrawerSize: {
      return {
        ...state,
        size: action.payload,
      };
    }

    case ActionTypes.SetDrawerShowTip: {
      return {
        ...state,
        showTip: action.payload,
      };
    }

    default:
      return state;
  }
}
