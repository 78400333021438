import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  current,
} from '@reduxjs/toolkit';

import { ListData, Repository, ID } from 'src/shared/interfaces';
import repositoryService, { MemberListParams, Order } from 'src/services/repository';
import favoritesService, { FavoritesParams } from 'src/services/favorites';
import { Loading } from 'src/shared/constants';
import { AppState } from 'src/app/store';
import { resolveDate } from 'src/utils';

const name = 'repository/member';

const repositoryAdapter = createEntityAdapter<Repository>({
  selectId: (repository) => repository.id,
});

/**
 * 知识库列表
 */
export const getListOfMember = createAsyncThunk<ListData<Repository>, Partial<MemberListParams>>(
  `${name}/list`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await repositoryService.getListOfMember(data);
      return response.data;
    } catch (err) {
      const message = err.isAxiosError ? err.response.data : err.message;
      return rejectWithValue(message);
    }
  }
);

/**
 * 知识库删除
 */
export const removeItemOfMember = createAsyncThunk<ID, ID>(
  `${name}/remove`,
  async (id, { rejectWithValue }) => {
    try {
      await repositoryService.removeItemOfMember(id);
      return id;
    } catch (err) {
      const message = err.isAxiosError ? err.response.data : err.message;
      return rejectWithValue(message);
    }
  }
);

/**
 * 置顶
 */
export const markTop = createAsyncThunk<
  string,
  { id: ID; order: Order },
  { rejectValue: { message: string } }
>(`${name}/markTop`, async ({ id }, { rejectWithValue }) => {
  try {
    const response = await repositoryService.markTop(id);
    return response.message;
  } catch (err) {
    const message = err.isAxiosError ? err.response.data : err.message;
    return rejectWithValue(message);
  }
});

/**
 * 添加/取消收藏
 */
export const collect = createAsyncThunk<
  ID,
  Partial<FavoritesParams>,
  { rejectValue: { message: string } }
>(`${name}/collect`, async (params, { rejectWithValue }) => {
  try {
    const response = await favoritesService.favorite(params);
    return response.data.id;
  } catch (err) {
    const message = err.isAxiosError ? err.response.data : err.message;
    return rejectWithValue(message);
  }
});

type OtherMemberState = {
  total: number;
  loading: Loading;
  totalPages: number;
  page: number;
  order: Order | undefined;
};

const slice = createSlice({
  name,
  initialState: repositoryAdapter.getInitialState<OtherMemberState>({
    total: 0,
    loading: Loading.idle,
    totalPages: 0,
    page: 1,
    order: undefined,
  }),
  reducers: {
    setPage(state, action) {
      state.page = action.payload;
    },
    setOrder(state, action) {
      state.order = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListOfMember.pending, (state) => {
      state.loading = Loading.pending;
    });

    builder.addCase(getListOfMember.fulfilled, (state, action) => {
      state.loading = Loading.fulfilled;
      const { data, meta } = action.payload;
      state.total = meta.pagination.total;
      state.totalPages = meta.pagination.total_pages;

      if (state.page !== 1) {
        repositoryAdapter.upsertMany(state, data);
      } else {
        repositoryAdapter.setAll(state, data);
      }
    });

    builder.addCase(getListOfMember.rejected, (state) => {
      state.loading = Loading.rejected;
      repositoryAdapter.setAll(state, []);
      state.total = 0;
      state.totalPages = 0;
    });

    builder.addCase(removeItemOfMember.fulfilled, (state, action) => {
      repositoryAdapter.removeOne(state, action.payload);
    });

    builder.addCase(markTop.fulfilled, (state, action) => {
      const { id, order } = action.meta.arg;
      const old = state.entities[id];
      if (old) {
        repositoryAdapter.updateOne(state, {
          id,
          changes: { top: old.top === 1 ? 2 : 1 },
        });
        const top = filter<Repository>(
          state.ids,
          current(state.entities),
          (item) => item.top === 1
        );
        const others = filter<Repository>(
          state.ids,
          current(state.entities),
          (item) => item?.top === 2
        );
        repositoryAdapter.setAll(state, [
          ...top.sort(sortBy(order)),
          ...others.sort(sortBy(order)),
        ]);
      }
    });

    builder.addCase(collect.fulfilled, (state, action) => {
      const { resourceId: id } = action.meta.arg;

      if (id) {
        repositoryAdapter.updateOne(state, {
          id,
          changes: {
            collect: action.payload ?? 0,
          },
        });
      }
    });
  },
});

export const { setPage, setOrder } = slice.actions;
export default slice.reducer;

// repository selectors
export const { selectAll } = repositoryAdapter.getSelectors(
  (state: AppState) => state.repository.member
);

export const selectIsLoading = (state: AppState) =>
  state.repository.member.loading === Loading.pending;

export const selectPage = (state: AppState) => state.repository.member.page;

export const selectTotalPages = (state: AppState) => state.repository.member.totalPages;

export const selectOrder = (state: AppState) => state.repository.member.order;

export const selectHasMore = createSelector(
  selectPage,
  selectTotalPages,
  (page, totalPages) => page < totalPages
);

/**
 * 排序
 *
 * @param order
 */
function sortBy<T extends Repository>(order: Order) {
  return (a: T, b: T) => {
    switch (order) {
      case 'p_asc': {
        return a.name.localeCompare(b.name);
      }
      case 'p_desc': {
        return b.name.localeCompare(a.name);
      }
      case 't_asc': {
        return resolveDate(a.updatedAt).getTime() - resolveDate(b.updatedAt).getTime();
      }
      case 't_desc':
      default: {
        return resolveDate(b.updatedAt).getTime() - resolveDate(a.updatedAt).getTime();
      }
    }
  };
}

/**
 * entities adapter 过滤
 * @param ids
 * @param entities
 * @param compareFn
 * @returns
 */
function filter<T>(
  ids: ID[],
  entities: Record<string, T | undefined>,
  compareFn: (item: T) => boolean
) {
  return ids.reduce<T[]>((acc, id) => {
    const item = entities[id];
    if (!item) return acc;

    if (compareFn(item)) {
      return [...acc, item];
    }

    return acc;
  }, []);
}
