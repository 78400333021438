import styled from '@emotion/styled';

import { Popover } from 'src/components/Popover';
import Trigger from './Trigger';
import PlatformItem from './PlatformItem';

const PREFIX = 'AppBar-Entry';

const classNames = {
  root: `${PREFIX}__root`,
};

const platforms = [
  {
    key: 'ZD_JOB',
    name: '招聘交付',
    appId: '100002',
    disabled: false,
    hidden: false,
  },
  {
    key: 'HRM',
    name: 'HRM',
    appId: '100007',
    disabled: false,
    hidden: false,
  },
  {
    key: 'HRO',
    name: 'HRO',
    appId: '',
    disabled: true,
    hidden: false,
  },
  {
    key: 'ZD_MALL',
    name: '商场邻工',
    appId: '',
    disabled: true,
    hidden: true,
  },
];

const EntryPanelRoot = styled('div')({
  [`&.${classNames.root}`]: {
    width: 500,
    height: 200,
    padding: 20,
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'repeat(2, 1fr)',
    gridGap: 20,
    '& > div': {
      justifySelf: 'center',
      alignSelf: 'center',
    },
  },
});

export default function Entry() {
  return (
    <Popover trigger="click" button={<Trigger />}>
      <EntryPanelRoot className={classNames.root}>
        {platforms.map((item) => (
          <PlatformItem disabled={item.disabled} appId={item.appId} hidden={item.hidden}>
            {item.name}
          </PlatformItem>
        ))}
      </EntryPanelRoot>
    </Popover>
  );
}
