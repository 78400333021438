import { createContext } from 'react';

import { Store, State } from '../interface';

const Context = createContext<Store>([{} as State, () => {}]);

if (process.env.NODE_ENV !== 'production') {
  Context.displayName = 'MemberContext';
}

export default Context;
