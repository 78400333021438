import { AxiosRequestConfig } from 'axios';

function isFile(input: unknown): input is File {
  return input instanceof File;
}

function isBlob(input: unknown): input is Blob {
  return input instanceof Blob;
}

function resolveArray(
  formData: FormData,
  key: string,
  arr: (string | number | File)[]
) {
  arr.forEach((item) => {
    if (item == null) return;

    if (isFile(item) || isBlob(item)) {
      formData.append(key, item);
    } else {
      formData.append(key, String(item));
    }
  });
}

function createFormData(data: any) {
  const formData = new FormData();
  if (!data) return formData;

  Object.keys(data).forEach((key) => {
    const value = data[key];
    if (value == null) return;

    if (Array.isArray(value)) {
      resolveArray(formData, key, value);
    }
    if (isFile(value) || isBlob(value)) {
      formData.append(key, value);
    } else {
      formData.append(key, String(value));
    }
  });

  return formData;
}

export default function formData(config: AxiosRequestConfig) {
  const { headers, data } = config;
  const contentType = headers['Content-Type'];
  if (contentType === 'multiple/form-data') {
    if (data && !(data instanceof FormData)) {
      const formData = createFormData(data);
      config.data = formData;
    }
    return config;
  }
  return config;
}
