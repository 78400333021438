import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { Loading } from 'src/shared/constants';

import { FavoritesItem, HttpResponse, ID } from 'src/shared/interfaces';
import { getErrorMessage } from 'src/utils';
import favoritesService, { collectMoveParams } from 'src/services/favorites';
import { AppState } from 'src/app/store';

const name = 'favorite/details';

const detailsAdapter = createEntityAdapter<FavoritesItem>({
  selectId: (detail) => detail.id,
});

/**
 * 收藏详情列表
 */
export const list = createAsyncThunk<
  FavoritesItem[],
  { id: ID; keyword?: string },
  { rejectValue: string }
>(`${name}/list`, async (data, { rejectWithValue }) => {
  try {
    const response = await favoritesService.details(data);
    return response.data.collects;
  } catch (err) {
    const message = getErrorMessage(err);
    return rejectWithValue(message);
  }
});

/**
 * 批量取消收藏
 */
export const unfavoriteMany = createAsyncThunk<
  void,
  ID[],
  { rejectValue: string }
>(`${name}/unfavoriteMany`, async (ids, { rejectWithValue }) => {
  try {
    await favoritesService.unfavoriteMany(ids);
  } catch (err) {
    const message = getErrorMessage(err);
    return rejectWithValue(message);
  }
});

// 移动
export const collectMove = createAsyncThunk<
  HttpResponse<{}>,
  Partial<collectMoveParams>,
  { rejectValue: string }
>(`${name}/collectMove`, async (data, { rejectWithValue }) => {
  try {
    const response = await favoritesService.collectMove(data);
    return response;
  } catch (err) {
    const message = getErrorMessage(err);
    return rejectWithValue(message);
  }
});

const slice = createSlice({
  name,
  initialState: detailsAdapter.getInitialState({
    loading: Loading.idle,
    keyword: '',
    selected: [],
  }),
  reducers: {
    setKeyword(state, action) {
      state.keyword = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(list.pending, (state) => {
      state.loading = Loading.pending;
    });

    builder.addCase(list.fulfilled, (state, action) => {
      state.loading = Loading.fulfilled;
      detailsAdapter.setAll(state, action.payload);
    });

    builder.addCase(list.rejected, (state) => {
      state.loading = Loading.rejected;
      detailsAdapter.setAll(state, []);
    });

    builder.addCase(unfavoriteMany.fulfilled, (state, action) => {
      const { arg: ids } = action.meta;
      detailsAdapter.removeMany(state, ids);
    });

    builder.addCase(collectMove.fulfilled, (state, action) => {
      const id = action.meta.arg.id;

      if (typeof id === 'string') {
        const ids = (id as string).split(',');
        detailsAdapter.removeMany(state, ids);
      } else if (typeof id === 'number') {
        detailsAdapter.removeOne(state, id);
      }
    });
  },
});

export default slice.reducer;

export const { setSelected, setKeyword } = slice.actions;

export const { selectAll } = detailsAdapter.getSelectors(
  (state: AppState) => state.favorites.details
);

export const selectIsLoading = createSelector(
  (state: AppState) => state.favorites.details.loading,
  (loading) => loading === Loading.pending
);

export const selectSelected = (state: AppState) =>
  state.favorites.details.selected;

export const selectKeyword = (state: AppState) =>
  state.favorites.details.keyword;
