import React from 'react';
import styled from '@emotion/styled';

import { Upload } from 'src/components/Icons';

const PREFIX = 'Review-Details--Authority';

const classNames = {
  root: `${PREFIX}__root`,
  icon: `${PREFIX}__icon`,
};

const AuthorityRoot = styled('div')({
  [`&.${classNames.root}`]: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classNames.icon}`]: {
    marginRight: 10,
  },
});

export interface AuthorityProps {
  authority?: number;
  resource?: number;
}

function title(type?: number, resource?: number) {
  let title = '浏览权限';

  if (type === 2) {
    title = '编辑权限';
  }
  if (resource === 8) {
    title = '成员权限';
  }
  if (type === 4) {
    if (resource === 4) {
      title = '编辑';
    }
    if (resource === 5) {
      title = '上传';
    }
  }
  return title;
}

const Authority: React.FC<AuthorityProps> = ({ authority, resource }) => {
  return (
    <AuthorityRoot className={classNames.root}>
      <Upload className={classNames.icon} />
      <div>{title(authority, resource)}</div>
    </AuthorityRoot>
  );
};

export default Authority;
