import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit';
import FileSaver from 'file-saver';
import OSS from 'ali-oss';

import {
  ListData,
  PaginationParams,
  ID,
  BookOrFolder,
  FolderDirectory,
  DirectoryBreak,
  DirectoryFile,
  ListAllData,
  HttpResponse,
} from 'src/shared/interfaces';
import repositoryService from 'src/services/repository';
import favoritesService, { FavoritesParams } from 'src/services/favorites';

import { Loading } from 'src/shared/constants';
import { AppState } from 'src/app/store';
import { getErrorMessage } from 'src/utils';

interface State {
  detail: Partial<BookOrFolder>;
  bread: Partial<DirectoryBreak[]>;
  loading: Loading;
  list: any;
  newList: FolderDirectory[];
  fileList: any;
  downloading: boolean;
  selected: (string | number)[];
}

type SortState = 'nameAsc' | 'nameDesc' | 'sizeAsc' | 'sizeDesc' | 'timeAsc' | 'timeDesc';

type FetchArgs = {
  folderId: ID;
  directoryId?: ID;
  keyword?: string;
  page?: number;
  limit?: number;
  sort: SortState;
} & PaginationParams;

type ListState = {
  keyword?: string;
  total: number;
  loading: Loading;
  totalPages: number;
  pageSize: number;
  page: number;
};

const name = 'repository/folder';

const folderAdapter = createEntityAdapter<FolderDirectory>({
  selectId: (folder) => folder.id,
});

const fileAdapter = createEntityAdapter<DirectoryFile>({
  selectId: (file) => file.id,
});

const initialState: State = {
  selected: [],
  detail: {},
  bread: [],
  loading: Loading.idle,
  downloading: false,
  newList: [],
  fileList: fileAdapter.getInitialState(),
  list: folderAdapter.getInitialState<ListState>({
    total: 0,
    keyword: '',
    loading: Loading.idle,
    totalPages: 0,
    pageSize: 50, // TODO: size changer
    page: 1,
  }),
};

/**
 * 知识本/文件夹详情
 */
export const detailBookOrFolder = createAsyncThunk<BookOrFolder, ID>(
  `${name}/detail`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await repositoryService.fetchBookOrFolderDetail(id);
      return response.data;
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

// 下载文件记录
export const fileDownload = createAsyncThunk<HttpResponse<{}>, ID>(
  `${name}/fileDownload`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await repositoryService.fileDownload(id);

      const { fileUrl, name } = response.data as any;
      if (fileUrl) {
        const result = await fetch(fileUrl);
        const blob = await result.blob();
        FileSaver.saveAs(blob, name);
      }

      return response;
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

/**
 * 面包屑
 */
export const fetchDirectoryBread = createAsyncThunk<ListAllData<DirectoryBreak>, ID>(
  `${name}/break`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await repositoryService.fetchDirectoryBread(id);
      return response.data;
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

/**
 * 子文件夹列表
 */
export const fetchFolder = createAsyncThunk<ListData<FolderDirectory>, Partial<FetchArgs>>(
  `${name}/fetch`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await repositoryService.fetchFolder(data);
      return response.data;
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

/**
 * 子文件夹删除
 */
export const removeDirectory = createAsyncThunk<ID, ID>(
  `${name}/delete`,
  async (id, { rejectWithValue }) => {
    try {
      await repositoryService.removeDirectory(id);
      return id;
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

// 删除文件

export const removeFile = createAsyncThunk<ID, ID>(
  `${name}/removeFile`,
  async (id, { rejectWithValue }) => {
    try {
      await repositoryService.removeFile(id);
      return id;
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

/**
 * 子文件夹创建
 */
export const createDitectory = createAsyncThunk<
  Partial<FolderDirectory>,
  {
    baseFolderId: ID;
    name: string;
    resourceDirectoryId?: ID;
  },
  { rejectValue: string }
>(`${name}/create`, async (data, { rejectWithValue }) => {
  try {
    const response = await repositoryService.createDitectory(data);
    return response.data;
  } catch (err) {
    const message = getErrorMessage(err);
    return rejectWithValue(message);
  }
});

/**
 * 子文件修改
 */
export const updateDitectory = createAsyncThunk<
  Partial<FolderDirectory>,
  {
    id: ID;
    type: number; // isFolder 别名， 0： 文件， 1： 文件夹
    name: string;
  },
  { rejectValue: string }
>(`${name}/update`, async ({ type, id, name }, { rejectWithValue }) => {
  try {
    let response;
    if (type === 1) {
      response = await repositoryService.updateDitectory({ id, name });
    } else {
      response = await repositoryService.renameFile(name, id);
    }
    return response.data as any;
  } catch (err) {
    const message = getErrorMessage(err);
    return rejectWithValue(message);
  }
});

/**
 * 添加/取消收藏
 */
export const collect = createAsyncThunk<
  ID,
  Partial<FavoritesParams>,
  { rejectValue: { message: string } }
>(`${name}/collect`, async (params, { rejectWithValue }) => {
  try {
    const response = await favoritesService.favorite(params);
    return response.data.id;
  } catch (err) {
    const message = err.isAxiosError ? err.response.data : err.message;
    return rejectWithValue(message);
  }
});

/**
 * 批量删除
 */
export const patchRemove = createAsyncThunk<string, ID[], { rejectValue: string }>(
  `${name}/patch_remove`,
  async (ids, { rejectWithValue }) => {
    try {
      const response = await repositoryService.patchRemoveFiles(ids);
      return response.message;
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  }
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    select(state, action) {
      state.selected = action.payload;
    },
    reset(state) {
      Object.assign(state, initialState);
    },
    setBread(state, action) {
      state.bread = action.payload;
    },
    setListSort(state, action) {
      state.list.sort = action.payload;
    },
    setListPage(state, action) {
      state.list.page = action.payload;
    },
    setListKeyword(state, action) {
      state.list.keyword = action.payload;
    },
    setNewList(state, action) {
      state.newList = action.payload;
    },
    setListRename(state, action) {
      console.log(action.payload);
      folderAdapter.updateOne(state.list, {
        id: action.payload.id,
        changes: { ctype: 'update' },
      });
    },
    /** file处理 */
    setFileProgressAndSpeed(state, action) {
      fileAdapter.updateOne(state.fileList, {
        id: action.payload.id,
        changes: {
          progress: action.payload.data.progress,
          speed: action.payload.data.speed,
        },
      });
    },
    setFileList(
      state,
      action: PayloadAction<{
        type: 'update' | 'remove' | 'clear';
        [prop: string]: any;
      }>
    ) {
      const { type, ...rest } = action.payload;
      if (type === 'update') {
        fileAdapter.upsertOne(state.fileList, rest as any);
      }
      if (type === 'remove') {
        fileAdapter.removeOne(state.fileList, rest.id);
      }
      if (action.payload.type === 'clear') {
        fileAdapter.removeAll(state.fileList);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(detailBookOrFolder.pending, (state, action) => {
      state.loading = Loading.pending;
    });
    builder.addCase(detailBookOrFolder.fulfilled, (state, action) => {
      state.loading = Loading.fulfilled;
      state.detail = action.payload;
    });
    builder.addCase(detailBookOrFolder.rejected, (state, action) => {
      state.loading = Loading.rejected;
      state.detail = {};
    });
    /* 修改子文件夹 */
    builder.addCase(updateDitectory.fulfilled, (state, action) => {
      console.log(action.payload);
      folderAdapter.updateOne(state.list, {
        id: action.meta.arg.id,
        changes: {
          ...action.payload,
          ctype: undefined,
        },
      });
    });

    /* 列表 */
    builder.addCase(fetchFolder.pending, (state, action) => {
      state.list.loading = Loading.pending;
    });
    builder.addCase(fetchFolder.fulfilled, (state, action) => {
      const { data, meta } = action.payload;
      folderAdapter.setAll(
        state.list,
        data.map((item) => ({ ...item, type: item.isFolder }))
      );
      state.list.loading = Loading.fulfilled;
      state.list.total = meta.pagination.total;
      state.list.totalPages = meta.pagination.total_pages;
      state.list.page = meta.pagination.current_page;
    });
    builder.addCase(fetchFolder.rejected, (state, action) => {
      state.list.loading = Loading.rejected;
    });

    builder.addCase(removeDirectory.fulfilled, (state, action) => {
      folderAdapter.removeOne(state.list, action.payload);
    });
    builder.addCase(fetchDirectoryBread.fulfilled, (state, action) => {
      state.bread = action.payload.data;
    });

    builder.addCase(removeFile.fulfilled, (state, action) => {
      folderAdapter.removeOne(state.list, action.payload);
    });

    builder.addCase(fileDownload.pending, (state) => {
      state.downloading = true;
    });

    builder.addCase(fileDownload.fulfilled, (state) => {
      state.downloading = false;
    });

    builder.addCase(fileDownload.rejected, (state) => {
      state.downloading = false;
    });
  },
});

export const {
  setListPage,
  setListSort,
  setListKeyword,
  setBread,
  setListRename,
  setNewList,
  setFileList,
  setFileProgressAndSpeed,
  reset,
  select,
} = slice.actions;
export default slice.reducer;

export const selectIsLoading = createSelector(
  (state: AppState) => state.folder.loading,
  (state: AppState) => state.folder.detail,
  (loading, detail) => loading === Loading.pending || !detail
);

export const selectDetail = (state: AppState) => {
  return state.folder.detail;
};
export const selectBread = (state: AppState) => {
  return state.folder.bread;
};
export const { selectAll: selectfileList, selectById: selectFileListById } =
  fileAdapter.getSelectors((state: AppState) => state.folder.fileList);

/** 列表select */
export const { selectAll: selectList, selectById: selectFileById } = folderAdapter.getSelectors(
  (state: AppState) => state.folder.list
);
export const selectNewList = (state: AppState) => {
  return state.folder.newList;
};

export const selectListLoading = createSelector(
  (state: AppState) => state.folder.list.loading,
  (loading) => loading === Loading.pending
);

export const selectListSort = (state: AppState) => state.folder.list.sort;
export const selectListKeyword = (state: AppState) => state.folder.list.keyword;
export const selectListPage = (state: AppState) => state.folder.list.page;
export const selectListPageSize = (state: AppState) => state.folder.list.pageSize;
export const selectListTotal = (state: AppState) => state.folder.list.total;

export const selectImages = createSelector(selectList, (list) => {
  return list
    .filter((item) => item.type === 2)
    .filter((item) => /.(png|jpg|jpeg|gif|bmp|svg|tif)$/.test(item.name));
});
