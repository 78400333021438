/**
 * 收藏夹
 */
import { httpClient } from 'src/utils';

import {
  HttpResponse,
  Favorites,
  ID,
  FavoriteResult,
  ListHttpDataResponse,
} from 'src/shared/interfaces';
import { ResourceType } from 'src/shared/constants';

export type FavoritesParams = {
  type: 1 | 2;
  id: ID; // 收藏夹 id
  resourceId: ID; // 资源 id
  resource: ResourceType; // 资源类型
};

class FavoritesService {
  async getListOfFavorites() {
    const response = await httpClient.get<ListHttpDataResponse<Favorites>>(
      '/cateCollect'
    );
    return response.data;
  }

  /**
   * 添加/取消收藏
   */
  async favorite(params: Partial<FavoritesParams>) {
    const response = await httpClient.post<HttpResponse<FavoriteResult>>(
      '/collect',
      params
    );
    return response.data;
  }

  /**
   * 批量取消收藏，理论上可以和收藏接口合并
   * @param ids
   */
  async unfavoriteMany(ids: ID[]) {
    const response = await httpClient.post<HttpResponse<{}>>('/delCollects', {
      ids: ids.join(','),
    });
    return response.data;
  }

  // 移动
  async collectMove(data: Partial<collectMoveParams>) {
    const response = await httpClient.post<HttpResponse<{}>>(
      '/collectMove',
      data
    );
    return response.data;
  }
  /**
   * 添加/修改收藏夹
   */
  async upsert(data: Partial<Favorites>) {
    const response = await httpClient.post<HttpResponse<Partial<Favorites>>>(
      '/cateCollect',
      data
    );
    return response.data;
  }

  /**
   * 删除收藏夹
   *
   * @param id
   * @returns
   */
  async removeFolder(id: ID) {
    const response = await httpClient.delete<HttpResponse<{}>>(
      `/cateCollect/${id}`
    );
    return response.data;
  }

  /**
   * 重命名
   *
   * @param id
   * @param name
   * @returns
   */
  async rename(id?: ID, name?: string) {
    const response = await httpClient.patch<HttpResponse<Favorites>>(
      `/cateCollect/${id}`,
      { name }
    );
    return response.data;
  }

  /**
   * 收藏夹详情
   *
   * @param id
   * @returns
   */
  async details(params: { id: ID; keyword?: string }) {
    const response = await httpClient.get<HttpResponse<Favorites>>(
      `/cateCollect/${params.id}`,
      {
        params,
      }
    );
    return response.data;
  }
}
export interface collectMoveParams {
  id: ID;
  cateId: ID;
}

export default new FavoritesService();
