export enum UploadStatus {
  Pending,
  Prepare,
  Pause,
  Cancel,
  Uploading,
  Success,
  Failed,
}

export enum UploadDrawerSize {
  Minimize,
  Maximize,
}
