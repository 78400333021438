import React from 'react';
import {
  CodeBlockInsertOptions,
  ELEMENT_CODE_BLOCK,
  insertEmptyCodeBlock,
  usePlateEditorState,
  someNode,
  unwrapCodeBlock,
  setNodes,
  ELEMENT_PARAGRAPH,
  toggleNodeType,
  wrapNodes,
  toggleCodeBlock,
  ELEMENT_CODE_LINE,
  insertNodes,
  unwrapNodes,
} from '@udecode/plate';

import ToolbarButton from '../ToolbarButton';
import { CodeBlock } from '../../icons';
import { Transforms, Node } from 'slate';

export interface CodeBlockToolbarButtonProps {
  options?: CodeBlockInsertOptions;
}

const CodeBlockToolbarButton: React.FC<CodeBlockToolbarButtonProps> = (props) => {
  const { options } = props;
  const editor = usePlateEditorState();

  const isCodeBlockActive =
    !!editor?.selection && someNode(editor, { match: { type: ELEMENT_CODE_BLOCK } });

  const handleInsertEmptyCodeBlock = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!editor) return;

    event.preventDefault();
    event.stopPropagation();
    if (isCodeBlockActive) {
      unwrapNodes(editor, { match: (node) => node.type === ELEMENT_CODE_LINE });
      wrapNodes(editor, { type: ELEMENT_PARAGRAPH, children: [] });
      unwrapCodeBlock(editor);
    } else {
      insertEmptyCodeBlock(editor, {
        insertNodesOptions: { select: false },
        ...options,
      });
    }
  };

  return (
    <ToolbarButton active={isCodeBlockActive} onMouseDown={handleInsertEmptyCodeBlock}>
      <CodeBlock color="inherit" />
    </ToolbarButton>
  );
};

export default CodeBlockToolbarButton;
