import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, Outlet, Navigate, useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/hooks';
import { getProfile } from 'src/app/auth.slice';
import { fetchCovers } from 'src/app/config.slice';
import PageLoading from 'src/components/PageLoading';
import { sleep } from 'src/utils';

const AuthGuard: React.FC = () => {
  const { isLoggedIn } = useAppSelector((state) => state.auth);
  const userId = useAppSelector((state) => state.auth.user?.id);
  const needUpdatePassword = useAppSelector((state) => state.auth.needUpdatePassword);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(true);

  const mounted = useRef<boolean>(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    // const { pathname, search } = window.location;
    const init = async () => {
      setLoading(true);
      if (isLoggedIn) {
        if (!userId) {
          await Promise.all([dispatch(getProfile()), sleep(2000)]);
        }
        if (mounted.current) {
          setLoading(false);
        }
        // navigate(
        //   {
        //     pathname,
        //     search,
        //   },
        //   { replace: true }
        // );
      } else {
        navigate('/auth/login', { replace: true });
      }
    };

    init();
  }, [isLoggedIn, dispatch, navigate, userId]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchCovers());
    }
  }, [dispatch, isLoggedIn]);

  if (loading) {
    return <PageLoading />;
  }

  if (needUpdatePassword && location.pathname !== '/update-password') {
    return <Navigate to="/update-password" />;
  }

  return <Outlet />;
};

export default AuthGuard;
