import axios, { AxiosRequestConfig } from 'axios';

import { hash } from 'src/utils';

const pendingRequest = new Map();

function formDataResolver(formData: FormData) {
  return Array.from(formData.entries()).reduce((acc, item) => {
    const [key, value] = item;
    if (value instanceof File) {
      return {
        ...acc,
        [key]: value.name + Math.random().toString(36).substring(0, 6),
      };
    }
    return {
      ...acc,
      [key]: value,
    };
  }, {});
}

function generateRequestKey(config: AxiosRequestConfig) {
  const { method, url, params } = config;
  let data = config.data;
  if (data instanceof FormData) {
    data = formDataResolver(data);
  }

  const str = [method, url, JSON.stringify(params), JSON.stringify(data)].join(
    '&'
  );
  return hash(str);
}

export function addPendingRequest(config: AxiosRequestConfig) {
  const requestKey = generateRequestKey(config);
  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken((cancel) => {
      if (!pendingRequest.has(requestKey)) {
        pendingRequest.set(requestKey, cancel);
      }
    });

  return config;
}

export function removePendingRequest(config: AxiosRequestConfig) {
  const requestKey = generateRequestKey(config);

  if (pendingRequest.has(requestKey)) {
    const cancel = pendingRequest.get(requestKey);
    cancel(requestKey);
    pendingRequest.delete(requestKey);
  }

  return config;
}
