import React from 'react';
import {
  ELEMENT_LINK,
  getAndUpsertLink,
  getPluginType,
  someNode,
  usePlateEditorState,
} from '@udecode/plate';

import { Link } from '../../icons';
import ToolbarButton from '../ToolbarButton';

export interface LinkToolbarProps {
  getLinkUrl?: (prevUrl: string | null) => Promise<string | null>;
}

const LinkToolbarButton: React.FC<LinkToolbarProps> = (props) => {
  const { getLinkUrl } = props;
  const editor = usePlateEditorState()!;

  const type = getPluginType(editor, ELEMENT_LINK);
  const isLinkActive = !!editor?.selection && someNode(editor, { match: { type } });

  const handleGetAndUpsertLink = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!editor) return;

    event.preventDefault();
    event.stopPropagation();
    getAndUpsertLink(editor, getLinkUrl);
  };

  return (
    <ToolbarButton active={isLinkActive} onMouseDown={handleGetAndUpsertLink}>
      <Link color="inherit" />
    </ToolbarButton>
  );
};

export default LinkToolbarButton;
