import React, { useState } from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';
import { AutoComplete } from 'antd';
import { useNavigate } from 'react-router-dom';

import { SearchInput } from 'src/components/SearchInput';
import store from 'src/utils/localStorage';
import { setKeyword, selectKeyword } from 'src/pages/Search/slice';
import { useAppDispatch, useAppSelector } from 'src/hooks';

const PREFIX = 'MainLayout-AppBar--Search';

const classNames = {
  root: `${PREFIX}__root`,
};

const SearchRoot = styled('div')({
  [`&.${classNames.root}`]: {
    position: 'relative',
  },
});

export interface SearchProps {
  className?: string | undefined;
}

function getHistory() {
  return (store.get<string[]>('history') || []).map((item) => ({ value: item }));
}

const KeyCode = {
  ENTER: 13,
};

const Search: React.FC<SearchProps> = ({ className }) => {
  const [history, setHistory] = useState<{ value: string }[]>(getHistory());
  const dispatch = useAppDispatch();
  const keyword = useAppSelector(selectKeyword);
  const navigate = useNavigate();

  const handleSearch = (value: string) => {
    dispatch(setKeyword(value));
  };

  const updateHistory = (value: string) => {
    const index = history.findIndex((item) => item.value === value);
    let nextHistory = [...history];
    if (index > -1) {
      nextHistory.splice(index, 1);
    }
    nextHistory.unshift({ value: value });
    nextHistory = nextHistory.slice(0, Math.min(5, nextHistory.length));
    setHistory(nextHistory);
    store.set(
      'history',
      nextHistory.map((item) => item.value)
    );
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const which = event.which; // event.key
    if (which === KeyCode.ENTER && keyword) {
      // 更新历史记录
      updateHistory(keyword);
      navigate('/search');
    }
  };

  const handleSelect = (value: string) => {
    updateHistory(value);
    dispatch(setKeyword(value));
    navigate('/search');
  };

  return (
    <SearchRoot className={clsx(classNames.root, className)}>
      <AutoComplete
        options={history}
        onSearch={handleSearch}
        onSelect={handleSelect}
        value={keyword}
      >
        <SearchInput placeholder="搜索知识/文件/知识库" onKeyDown={handleKeyDown} />
      </AutoComplete>
    </SearchRoot>
  );
};

export default Search;
