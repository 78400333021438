import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'src/app/store';
import qaService from 'src/services/QA';
import { Loading } from 'src/shared/constants';
import { ID } from 'src/shared/interfaces';
import { getErrorMessage } from 'src/utils';
import { Question } from '../types';
import { questionAdapter } from '../utils';

export interface State {
  baseId: ID | null;
  questions: Question[];
  loading: Loading;
  hasMore: boolean;
  page: number;
  error?: string | undefined;
}

const name = 'QAList' as const;

const initialState: State = {
  baseId: null,
  questions: [],
  loading: Loading.idle,
  hasMore: true,
  page: 0,
  error: undefined,
};

type FetchQAListParams = {
  baseId?: ID;
  page?: number;
  limit?: number;
  keyword?: string;
  operation?: 'append' | 'replace';
};

type FetchQAListReturned = {
  data: Question[];
  page: number;
  hasMore: boolean;
};

export const fetchQAList = createAsyncThunk<
  FetchQAListReturned,
  FetchQAListParams | undefined,
  { rejectValue: string; state: AppState }
>(`${name}/list`, async (data = {}, { rejectWithValue, getState }) => {
  try {
    const { baseId, page, ...rest } = data;
    const { QA } = getState();
    const nextPage = page || QA.list.page + 1;
    const response = await qaService.fetchQAList(baseId!, { ...rest, page: nextPage });
    return {
      page: nextPage,
      data: response.data.data.map((item) => questionAdapter(item)),
      hasMore:
        response.data.meta.pagination.current_page < response.data.meta.pagination.total_pages,
    };
  } catch (err) {
    return rejectWithValue(getErrorMessage(err));
  }
});

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
    updateQAItem: (state: State, action: PayloadAction<{ id: ID; changed: Partial<Question> }>) => {
      state.questions = state.questions.map((item) => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload.changed };
        }
        return item;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQAList.pending, (state) => {
        state.loading = Loading.pending;
        state.error = '';
      })
      .addCase(fetchQAList.fulfilled, (state, action) => {
        state.loading = Loading.fulfilled;
        state.hasMore = action.payload.hasMore;
        state.page = action.payload.page;
        if (action.meta.arg?.operation === 'append') {
          state.questions = [...state.questions, ...action.payload.data];
        } else {
          state.questions = action.payload.data;
        }
        state.baseId = action.meta.arg?.baseId || null;
      })
      .addCase(fetchQAList.rejected, (state, action) => {
        state.questions = [];
        state.loading = Loading.rejected;
        state.error = action.payload;
      });
  },
});

export const { reset, updateQAItem } = slice.actions;

export default slice.reducer;
