import React from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';

import { Flash as FlashIcon } from 'src/components/Icons';
import { useMediaQuery } from 'src/hooks';

const PREFIX = 'AppBar-Entry--Trigger';

const classNames = {
  root: `${PREFIX}__root`,
  icon: `${PREFIX}__icon`,
  label: `${PREFIX}__label`,
};

const TriggerRoot = styled('div')(({ theme }) => ({
  [`&.${classNames.root}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    paddingTop: 3,
    paddingBottom: 3,
    paddingRight: 6,
    paddingLeft: 6,
    borderRadius: 10,
    userSelect: 'none',
    '&:hover': {
      backgroundColor: theme.palette.background?.paper,
    },
    '& > *': {
      flexShrink: 0,
    },
  },
  [`& .${classNames.icon}`]: {
    [`&+.${classNames.label}`]: {
      marginLeft: 10,
    },
  },
}));

interface TriggerProps {
  className?: string | undefined;
  [propName: string]: any;
}

const Trigger: React.FC<TriggerProps> = ({ className, ...rest }) => {
  const isSmall = useMediaQuery('screen and (max-width: 960px)');

  return (
    <TriggerRoot className={clsx(classNames.root, className)} {...rest}>
      <FlashIcon className={classNames.icon} />
      {!isSmall && <span className={classNames.label}>快速入口</span>}
    </TriggerRoot>
  );
};

export default Trigger;
