import styled from '@emotion/styled';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import generatePicker from 'antd/es/date-picker/generatePicker';

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);

export default styled(DatePicker, { label: 'DatePicker' })({
  border: '1px solid #eee',
  height: 40,
  '& .ant-picker-input > input': {
    fontSize: 14,
  },
});
