import { withProps, StyledElement } from '@udecode/plate';

import { CSSProp } from 'styled-components';
import styles from 'src/shared/styles/richText';

const Paragraph = withProps(StyledElement, {
  as: 'a',
  styles: {
    root: styles.a as CSSProp,
  },
});

export default Paragraph;
