import React from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';

import { composeClasses } from 'src/utils';

const classNamePrefix = 'DialogActions';

const useUtilityClasses = () => {
  const slots = {
    root: ['root'],
  };
  return composeClasses(classNamePrefix, slots);
};

const DialogActionsRoot = styled('div', { label: `${classNamePrefix}Root` })({
  padding: '20px 40px 40px 40px',
  flex: '0 0 auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',

  '& > :not(:first-of-type)': {
    marginLeft: 20,
  },

  '.DialogContent-root + &': {
    paddingTop: 0,
  },
});

const DialogActions = React.forwardRef<
  HTMLDivElement,
  { children?: React.ReactNode; className?: string | undefined }
>(function DialogActions(props, ref) {
  const classes = useUtilityClasses();

  const { className, ...other } = props;

  return (
    <DialogActionsRoot
      ref={ref}
      className={clsx(classes.root, className)}
      {...other}
    />
  );
});

export default DialogActions;
