import { AxiosRequestConfig } from 'axios';

export function login(config: AxiosRequestConfig) {
  try {
    const { phone, password } = JSON.parse(config.data) || {};

    if (!phone || !password) {
      return [400, 'Username or password is required'];
    }
    if (phone === '18919608864' && password === 'wm123456') {
      return [
        200,
        {
          data: {
            apiToken:
              'XoA7lUY6TnZ8Xv9iNYBs7clsuEMcNDCr2f689b5c23f14f125af9bfdc9c01a297',
            expireAt: '1623037295',
          },
        },
      ];
    }
    return [403, 'Username or password in incorrect'];
  } catch (e) {
    return [500, 'Internal Server Error'];
  }
}
