const prefix = '@@member';

export const INIT = `${prefix}/init` as const;

export const RESET = `${prefix}/reset` as const;

export const SHOW_DEPARTMENTS = `${prefix}/show_department` as const;

export const SHOW_MEMBERS = `${prefix}/show_members` as const;

export const FETCH_DEPARTMENTS_PENDING =
  `${prefix}/fetch_departments_pending` as const;

export const FETCH_DEPARTMENTS_FULFILLED =
  `${prefix}/fetch_departments_fulfilled` as const;

export const FETCH_DEPARTMENTS_REJECTED =
  `${prefix}/fetch_departments_rejected` as const;

export const FETCH_MEMBERS_PENDING = `${prefix}/fetch_members_pending` as const;

export const FETCH_MEMBERS_FULFILLED =
  `${prefix}/fetch_members_fulfilled` as const;

export const FETCH_MEMBERS_REJECTED =
  `${prefix}/fetch_members_rejected` as const;

export const SELECT_MEMBER = `${prefix}/select_member` as const;

export const DESELECT_MEMBER = `${prefix}/deselect_member` as const;

export const SELECT_ALL = `${prefix}/select_all` as const;

export const DESELECT_ALL = `${prefix}/deselect_all` as const;

export const SELECT_DEPARTMENT = `${prefix}/select_department` as const;

export const DESELECT_DEPARTMENT = `${prefix}/deselect_department` as const;

export const REMOVE = `${prefix}/remove` as const;

export const CLEAR = `${prefix}/clear` as const;

export const SET_SEARCH = `${prefix}/set_search` as const;

export const SEARCH_PENDING = `${prefix}/search_pending` as const;

export const SEARCH_FULFILLED = `${prefix}/search_fulfilled` as const;

export const SEARCH_REJECTED = `${prefix}/search_rejected` as const;
