import { combineReducers, createSlice } from '@reduxjs/toolkit';
import { AppState } from 'src/app/store';

import detailsReducer from 'src/pages/Repository/Details/slice';

import memberReducer from './Member/slice';
import recentReducer from './Recent/slice';

const name = 'repository/common';

const slice = createSlice({
  name,
  initialState: { keyword: '' },
  reducers: {
    setKeyword(state, action) {
      state.keyword = action.payload;
    },
  },
});

export const { setKeyword } = slice.actions;

export const selectKeyword = (state: AppState) =>
  state.repository.common.keyword;

export default combineReducers({
  common: slice.reducer,
  member: memberReducer,
  recent: recentReducer,
  details: detailsReducer,
});
