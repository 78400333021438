import { createSvgIcon } from './utils';

export default createSvgIcon(
  [
    <path
      key="1"
      d="M5.22100088,0 L15.6789991,0 C17.1729991,0 17.92,0.747000875 17.92,2.24100088 L17.92,12.6989991 C17.92,14.1929991 17.1729991,14.94 15.6789991,14.94 L5.22100088,14.94 C3.72700088,14.94 2.98,14.1929991 2.98,12.6989991 L2.98,2.24100088 C2.98,0.747000875 3.72700088,0 5.22100088,0 Z"
    />,
    <path
      key="2"
      d="M2.24100088,2.98 L12.6989991,2.98 C14.1929991,2.98 14.94,3.72700088 14.94,5.22100088 L14.94,15.6789991 C14.94,17.1729991 14.1929991,17.92 12.6989991,17.92 L2.24100088,17.92 C0.747000875,17.92 0,17.1729991 0,15.6789991 L0,5.22100088 C0,3.72700088 0.747000875,2.98 2.24100088,2.98 Z"
    />,
    <path
      key="3"
      color="#fff"
      d="M10.4540912,8.95499825 L4.48590874,8.95499825 L4.48590872,8.95499825 C4.07389216,8.95493504 3.74,8.62021779 3.74,8.20738437 C3.74,7.79464206 4.07398133,7.46006307 4.48590872,7.46 L10.4540912,7.46 L10.4540913,7.46 C10.8661078,7.46006322 11.2,7.79478221 11.2,8.20761388 C11.2,8.62035619 10.8660187,8.95493518 10.4540913,8.95499825 L10.4540912,8.95499825 Z M4.48590874,11.9450018 L10.4540912,11.9450018 L10.4540913,11.9450018 C10.8661078,11.945065 11.2,12.279784 11.2,12.6926156 C11.2,13.1053579 10.8660187,13.4399369 10.4540913,13.44 L4.48590877,13.44 L4.48590876,13.44 C4.0738922,13.4399368 3.74000004,13.1052195 3.74000004,12.6923861 C3.74000004,12.2796438 4.07398137,11.9450648 4.48590876,11.9450018 L4.48590874,11.9450018 Z"
    />,
  ],
  'Copy'
);
