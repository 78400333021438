import { UploadDrawerSize, UploadStatus } from './constants';

export enum ActionTypes {
  SetUploadFiles = 'SET_UPLOAD_FILES',
  ClearUploadFiles = 'CLEAR_UPLOAD_FILES',
  SetUploadProgress = 'SET_UPLOAD_PROGRESS',
  SetUploadStatus = 'SET_UPLOAD_STATUS',
  SetUploadSpeed = 'SET_UPLOAD_SPEED',
  RetryUploadFile = 'RETRY_UPLOAD_FILE',
  CancelUploadFile = 'CANCEL_UPLOAD_FILE',
  SetDrawerOpen = 'SET_DRAWER_OPEN',
  SetDrawerSize = 'SET_DRAWER_SIZE',
  SetDrawerShowTip = 'SET_DRAWER_SHOW_TIP',
}

export interface SetUploadFilesAction {
  type: ActionTypes.SetUploadFiles;
  payload: { files: File[] | null; owner: any };
}

export interface ClearUploadFilesAction {
  type: ActionTypes.ClearUploadFiles;
}

export interface SetUploadProgressAction {
  type: ActionTypes.SetUploadProgress;
  payload: { id: number; progress: number };
}

export interface SetUploadStatusAction {
  type: ActionTypes.SetUploadStatus;
  payload: { id: number; status: UploadStatus };
}

export interface SetUploadSpeedAction {
  type: ActionTypes.SetUploadSpeed;
  payload: { id: number; speed: number };
}

export interface RetryUploadFileAction {
  type: ActionTypes.RetryUploadFile;
  payload: { id: number };
}

export interface CancelUploadFileAction {
  type: ActionTypes.CancelUploadFile;
  payload: { id: number };
}

export interface SetDrawerOpenAction {
  type: ActionTypes.SetDrawerOpen;
  payload: boolean;
}

export interface SetDrawerSizeAction {
  type: ActionTypes.SetDrawerSize;
  payload: UploadDrawerSize;
}

export interface SetDrawerShowTipAction {
  type: ActionTypes.SetDrawerShowTip;
  payload: boolean;
}

export type UploadActions =
  | SetUploadFilesAction
  | ClearUploadFilesAction
  | SetUploadProgressAction
  | SetUploadStatusAction
  | SetUploadSpeedAction
  | RetryUploadFileAction
  | CancelUploadFileAction
  | SetDrawerOpenAction
  | SetDrawerSizeAction
  | SetDrawerShowTipAction;

/** Action creator */

/**
 * 上传文件
 * @param files
 * @returns
 */
export function setUploadFiles(files: File[] | null, owner: any): SetUploadFilesAction {
  return {
    type: ActionTypes.SetUploadFiles,
    payload: { files, owner },
  };
}

/**
 * 清空上传文件
 * @returns
 */
export function clearUploadFiles(): ClearUploadFilesAction {
  return {
    type: ActionTypes.ClearUploadFiles,
  };
}

/**
 * 更新文件上传进度
 * @param payload
 * @returns
 */
export function setUploadProgress(id: number, progress: number): SetUploadProgressAction {
  return {
    type: ActionTypes.SetUploadProgress,
    payload: { id, progress },
  };
}

/**
 * 更新文件上传状态
 * @param id
 * @param status
 * @returns
 */
export function setUploadStatus(id: number, status: UploadStatus): SetUploadStatusAction {
  return {
    type: ActionTypes.SetUploadStatus,
    payload: { id, status },
  };
}

/**
 * 更新文件上传速度
 * @param id
 * @param speed
 * @returns
 */
export function setUploadSpeed(id: number, speed: number): SetUploadSpeedAction {
  return {
    type: ActionTypes.SetUploadSpeed,
    payload: { id, speed },
  };
}

/**
 * 重新上传
 * @param id
 * @returns
 */
export function retryUploadFile(id: number): RetryUploadFileAction {
  return {
    type: ActionTypes.RetryUploadFile,
    payload: { id },
  };
}

/**
 * 取消上传
 * @param id
 * @returns
 */
export function cancelUploadFile(id: number): CancelUploadFileAction {
  return {
    type: ActionTypes.CancelUploadFile,
    payload: { id },
  };
}

/**
 * 打开/关闭上传窗口
 * @param isOpen
 * @returns
 */
export function setDrawerOpen(isOpen: boolean): SetDrawerOpenAction {
  return {
    type: ActionTypes.SetDrawerOpen,
    payload: isOpen,
  };
}

/**
 * 最小化/最大化
 * @param size
 * @returns
 */
export function setDrawerSize(size: UploadDrawerSize): SetDrawerSizeAction {
  return {
    type: ActionTypes.SetDrawerSize,
    payload: size,
  };
}

/**
 * 是否显示上传完成提示横幅
 * @param show
 * @returns
 */
export function setDrawerShowTip(show: boolean): SetDrawerShowTipAction {
  return {
    type: ActionTypes.SetDrawerShowTip,
    payload: show,
  };
}
