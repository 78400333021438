import React from 'react';

import styled from '@emotion/styled';
import clsx from 'clsx';

import { formControlState, useFormControl } from 'src/components/FormControl';
import { composeClasses, lighten } from 'src/utils';

import { InputProps } from './Input';

const classNamePrefix = 'TextArea';

const useUtilityClasses = (props: Pick<InputProps, 'error'>) => {
  const { error } = props;

  const slots = {
    root: ['root', error && 'error'],
  };

  return composeClasses(classNamePrefix, slots);
};

const TextAreaRoot = styled('textarea')(({ theme }) => ({
  boxSizing: 'border-box',
  position: 'relative',
  display: 'inline-block',
  paddingLeft: 12,
  paddingRight: 12,
  paddingTop: 8,
  paddingBottom: 8,
  width: '100%',
  minWidth: 0,
  color: theme.palette.text?.primary,
  fontSize: 14,
  borderRadius: 4,
  outline: 0,
  border: '1px solid #eee',
  backgroundColor: '#fff',
  resize: 'none',
  transition: theme.transitions.create('all', {
    duration: theme.transitions.duration.standard,
  }),
  '&:placeholder': {
    color: theme.palette.text?.secondary,
  },
  '&:placeholder-shown': {
    textOverflow: 'ellipsis',
  },
  '&:focus': {
    borderColor: theme.palette.primary?.main,
    boxShadow: `0 0 0 2px ${lighten(
      theme.palette.primary?.main || '#5a5aee',
      0.8
    )}`,
  },
  '&:hover': {
    borderColor: theme.palette.primary?.main,
  },

  [`&.${classNamePrefix}-error`]: {
    borderColor: theme.palette.error?.main,
    '&:focus': {
      borderColor: theme.palette.error?.main,
      boxShadow: `0 0 0 2px ${lighten(
        theme.palette.error?.main || '#ff3b30',
        0.8
      )}`,
    },
  },
}));

type Props = InputProps & { rows?: number | undefined };

const TextArea = React.forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const { className, ...rest } = props;

  const formControl = useFormControl();

  const fcs = formControlState({
    props,
    formControl: formControl,
    states: ['disabled', 'error', 'required'],
  });

  const classes = useUtilityClasses({ error: fcs.error });

  return (
    <TextAreaRoot
      {...rest}
      ref={ref}
      className={clsx(classes.root, className)}
      aria-invalid={fcs.error}
      disabled={fcs.disabled}
      required={fcs.required}
    />
  );
});

export default TextArea;
