import React from 'react';
import styled from '@emotion/styled';

type Props = {
  className?: string;
  as?: React.ElementType;
};

const BoxInner = React.forwardRef<{}, Props>((props, ref) => {
  const { className, children, as: Component = 'div' } = props;

  return (
    <Component ref={ref} className={className}>
      {children}
    </Component>
  );
});

const BoxRoot = styled(BoxInner)<{ sx?: React.CSSProperties }>(({ sx }) => ({
  ...sx,
}));

interface BoxProps extends Props {
  sx?: React.CSSProperties;
  children?: React.ReactNode; // 不加这个类型， ts 会报错
}

const Box = React.forwardRef<{}, BoxProps>(function Box(props, ref) {
  const { sx, ...rest } = props;
  return <BoxRoot ref={ref} sx={sx} {...rest} />;
});

export default React.memo(Box);
