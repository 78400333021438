import { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';

import { Platform as PlatformIcon } from 'src/components/Icons';

const PREFIX = 'Platform-Item';

const classNames = {
  root: `${PREFIX}_root`,
  icon: `${PREFIX}_icon`,
  disabled: `${PREFIX}_disabled`,
};

const PlatformItemRoot = styled('a')<{ disabled?: boolean; hidden?: boolean }>(
  ({ theme, disabled = false, hidden = false }) => ({
    [`&.${classNames.root}`]: {
      justifySelf: 'center',
      alignSelf: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      border: '1px solid #eee',
      borderRadius: 8,
      fontSize: 16,
      color: '#333',
      userSelect: 'none',
      ...(hidden && {
        display: 'none',
      }),
      ...(disabled && {
        color: '#999',
        pointerEvents: 'none',
      }),
      '&:hover': {
        backgroundColor: '#f6f7fB',
        ...(disabled && {
          backgroundColor: '#fff',
        }),
      },
    },
    [`& .${classNames.icon}`]: {
      fontSize: 24,
      marginRight: 10,
      color: theme.palette.primary?.main,
      ...(disabled && {
        color: '#ccc',
      }),
    },
  })
);

interface PlatformItemProps {
  disabled?: boolean | undefined;
  appId: string;
  hidden?: boolean | undefined;
}

export default function PlatformItem(props: PropsWithChildren<PlatformItemProps>) {
  const { children, disabled = false, appId, hidden = false } = props;

  return (
    <PlatformItemRoot
      className={clsx(classNames.root, { [classNames.disabled]: disabled })}
      disabled={disabled}
      hidden={hidden}
      href={`/redirect?toAppId=${appId}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <PlatformIcon viewBox="0 0 24 24" className={classNames.icon} />
      {children}
    </PlatformItemRoot>
  );
}
