import React from 'react';
import styled from '@emotion/styled';

import {
  Company as CompanyIcon,
  Person as UserIcon,
} from 'src/components/Icons';
import { Typography } from 'src/components/Typography';

import Grid from '../common/Grid';
import Button from '../common/Button';

interface ItemProps {
  type: 'department' | 'member';
  name?: string;
  root?: string;
  onRemove?: ((event: React.MouseEvent<HTMLButtonElement>) => void) | undefined;
}

const IconRoot = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: 10,
  fontSize: 18,
});

const StyledButton = styled(Button)({
  fontSize: 14,
  flexShrink: 0,
});

const iconComponent = {
  department: CompanyIcon,
  member: UserIcon,
};

const Item: React.FC<ItemProps> = (props) => {
  const { type, name, root, onRemove } = props;

  const Icon = iconComponent[type];

  return (
    <Grid>
      <Grid item>
        <IconRoot>
          <Icon fontSize={16} />
        </IconRoot>
        <div style={{ flex: 2, minWidth: 0 }}>
          <Typography noWrap variant="body2">
            {name}
          </Typography>
        </div>
        <div style={{ flex: 4, minWidth: 0 }}>
          <Typography
            align="center"
            noWrap
            variant="body2"
            color="textSecondary"
          >
            {root}
          </Typography>
        </div>
        <div style={{ flex: 2, textAlign: 'right' }}>
          <StyledButton onClick={onRemove}>删除</StyledButton>
        </div>
      </Grid>
    </Grid>
  );
};

export default Item;
