import React from 'react';
import styled from '@emotion/styled';

import { Typography } from 'src/components/Typography';

const TitleRoot = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  marginBottom: 10,
  '@media screen and (max-width: 1280px)': {
    '& > div': {
      marginTop: 20,
    },
  },
});

interface TitleProps {
  actions?: React.ReactNode | undefined;
}

const Title: React.FC<TitleProps> = ({ children, actions }) => {
  return (
    <TitleRoot>
      <Typography variant="h5" style={{ flexShrink: 0 }}>
        {children}
      </Typography>
      <div>{actions}</div>
    </TitleRoot>
  );
};

export default Title;
