import React, { useCallback } from 'react';
import { ID } from 'src/shared/interfaces';

import MemberItem from '../../common/MemberItem';
import List from '../../common/List';
import { Member } from '../../interface';
import { useMemberDispatch, useMemberSelector } from '../../Provider';
import { deselectMember, selectMember } from '../../reducers/actions';
import {
  selectSearch,
  selectSelectedList,
  selectLoading,
} from '../../reducers/selectors';
import { isMemberChecked, isMemberDisabled } from '../../utils';

const Search: React.FC = () => {
  const dispatch = useMemberDispatch();

  const loading = useMemberSelector(selectLoading('search'));
  const { result: list } = useMemberSelector(selectSearch);
  const selectedList = useMemberSelector(selectSelectedList);

  const handleCheck = useCallback(
    (id: ID | undefined, checked: boolean) => {
      if (id) {
        if (checked) {
          dispatch(selectMember(id));
        } else {
          dispatch(deselectMember(id));
        }
      }
    },
    [dispatch]
  );

  return (
    <List loading={loading} isEmpty={list.length === 0}>
      {(list as Member[]).map((item) => {
        const checked = isMemberChecked(item, selectedList);
        const disabled = isMemberDisabled(item, selectedList);
        return (
          <MemberItem
            key={item.id}
            id={item.id}
            name={item.name}
            department={item.department}
            job={item.job}
            onCheck={handleCheck}
            checked={checked}
            disabled={disabled}
          />
        );
      })}
    </List>
  );
};

export default Search;
