const toString = Object.prototype.toString;

/**
 * 是否为字符串
 *
 * @param input
 * @returns
 */
export function isString(input: unknown): input is string {
  return typeof input === 'string';
}

/**
 * 是否为数字
 *
 * @param input
 * @returns
 */
export function isNumber(input: unknown): input is number {
  return !isNaN(parseFloat(input as string));
}

/**
 * 是否为对象
 * @param input
 * @returns
 */
export function isObject(input: unknown): input is object {
  return toString.call(input) === '[object Object]';
}

/**
 * 是否为空对象
 * @param obj
 * @returns
 */
export function isEmptyObject(obj: Record<string, unknown>) {
  return Object.keys(obj).length === 0;
}
