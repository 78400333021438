/**
 * 后台管理
 */
import { httpClient } from 'src/utils';

import {
  HttpResponse,
  ID,
  ListHttpDataResponse,
  ListHttpResponse,
  Timestamp,
  Repository,
  PaginationParams,
} from 'src/shared/interfaces';

export interface Department extends Timestamp {
  id: ID;
  departmentId: ID;
  name: string;
  allChildren: Department[];
  _method?: string;
  type?: 'new' | 'update';
}

export interface DepartmentParams {
  departmentId: ID;
  name: string;
  _method?: string;
}

export interface IUsers extends Timestamp {
  id: ID;
  departmentId: ID;
  name: string;
  phone: string;
  job: string;
  departmentName?: string;
  avatar: string;
}

export interface IDetailDepartment extends Timestamp {
  id: ID;
  departmentId: ID;
  name: string;
  departmentName: string;
  phone: string;
  job: string;
}
export interface getDepartmentParams {
  withUser: string;
  keyword: string;
}

export interface IDelDepartment {
  userId: string | number;
  type: string; // 1 移除 2 删除
}

export interface IDepartment extends Timestamp {
  id: ID;
  departmentId: ID;
  name: string;
  source: string;
}
export interface IAddDepartment extends Timestamp {
  id: ID;
  name: string;
  phone: string;
  job: string;
  Token: string;
  departmentId: ID;
  messageCount: ID;
  department: IDepartment;
}

export interface ITemplateList {
  default: string;
  id: ID;
  img: string;
  title: string;
  content: string;
  type: string;
}

export interface ITemplateParams {
  keyword: string;
  baseId?: ID;
}

export interface IDetailDepartmentParams {
  id: ID;
  page: ID;
  limit: ID;
  sort: string;
  keyword: string;
}

export interface IAddAdminParams {
  users: string;
}
class adminService {
  // 部门列表
  async getDepartment({ keyword }: Partial<getDepartmentParams>) {
    const response = await httpClient.get<ListHttpResponse<Department>>('/department', {
      params: { keyword },
    });
    return response.data;
  }

  // 新增部门
  async createDepartment(data: Partial<DepartmentParams>) {
    const response = await httpClient.post<HttpResponse<Department>>('/department', data);
    return response.data;
  }

  // 部门详情
  async detailDepartment(data: Partial<IDetailDepartmentParams>) {
    const response = await httpClient.get<ListHttpResponse<IDetailDepartment>>(
      `/department/${data.id}`,
      { params: { ...data } }
    );
    return response.data;
  }

  // 部门修改
  async updateDepartment(id?: ID, name?: string) {
    const response = await httpClient.patch<HttpResponse<Department>>(`/department/${id}`, {
      name,
    });
    return response.data;
  }

  // 部门删除
  async removeDepartment(id: ID) {
    const response = await httpClient.delete(`/department/${id}`);
    return response.data;
  }

  // 部门成员删除
  async delDepartment(data: Partial<IDelDepartment>) {
    const response = await httpClient.post<HttpResponse<{}>>(`/delDepartment`, data);
    return response.data;
  }

  // 部门添加成员
  async addDepartment(data: Partial<IAddDepartment>) {
    const response = await httpClient.post<HttpResponse<IAddDepartment>>(`/addDepartment`, data);
    return response.data;
  }
  /**
   * 部门excel新增成员
   * @param data
   * @returns
   */
  async excelAddUser(data: { departmentId: ID; excel: File }) {
    const response = await httpClient.post<HttpResponse<any>>(`/excelAddUser`, data, {
      headers: { 'Content-Type': 'multiple/form-data' },
    });
    return response.data;
  }

  // 模板列表
  async getTemplate({ keyword }: Partial<ITemplateParams>) {
    const response = await httpClient.get<ListHttpResponse<ITemplateList>>('/template', {
      params: { keyword },
    });
    return response.data;
  }

  // 知识库知识页模板列表
  async getBaseTemplate({ keyword, baseId }: Partial<ITemplateParams>) {
    const response = await httpClient.get<ListHttpResponse<ITemplateList>>('/baseTemplate', {
      params: { keyword, baseId },
    });
    return response.data;
  }

  // 知识库知识页模板删除
  async delBaseTemplate(id: ID) {
    const response = await httpClient.delete<HttpResponse<{}>>(`/baseTemplate/${id}`);
    return response.data;
  }

  // 模板删除
  async delTemplate(id: ID) {
    const response = await httpClient.delete<HttpResponse<{}>>(`/template/${id}`);
    return response.data;
  }

  // 模板详情
  async detailTemplate(id: ID) {
    const response = await httpClient.get<HttpResponse<ITemplateList>>(`/template/${id}`);
    return response.data;
  }

  // 知识库知识页模板详情
  async detailBaseTemplate(id: ID) {
    const response = await httpClient.get<HttpResponse<ITemplateList>>(`/baseTemplate/${id}`);
    return response.data;
  }

  // 模板新增
  async addTemplate(data: Partial<IAddTemplateParams>) {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    const response = await httpClient.post<HttpResponse<IAddTemplate>>(`/template`, formData, {
      headers: { 'content-type': 'multiple/form-data' },
    });
    return response.data;
  }

  // 知识库知识页模板新增
  async addBaseTemplate(data: Partial<IAddTemplateParams>) {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    const response = await httpClient.post<HttpResponse<IAddTemplate>>(`/baseTemplate`, formData, {
      headers: { 'content-type': 'multiple/form-data' },
    });
    return response.data;
  }

  // 知识库知识页模板修改
  async updateBaseTemplate(id: ID, data: Partial<IAddTemplateParams>) {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    const response = await httpClient.post<HttpResponse<Partial<IAddTemplate>>>(
      `/baseTemplate/${id}`,
      formData,
      { headers: { 'content-type': 'multiple/form-data' } }
    );
    return response.data;
  }

  // 模板修改
  async updateTemplate(id: ID, data: Partial<IAddTemplateParams>) {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    const response = await httpClient.post<HttpResponse<Partial<IAddTemplate>>>(
      `/template/${id}`,
      formData,
      { headers: { 'content-type': 'multiple/form-data' } }
    );
    return response.data;
  }

  // 模板默认操作
  async defaultTemplate(data?: Partial<IDefaultParams>) {
    const response = await httpClient.post<HttpResponse<{}>>(`/templates/default`, data);
    return response.data;
  }

  // 新建知识库
  async addBase(data: Partial<IBaseParams>) {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    const response = await httpClient.post<HttpResponse<IBaseInfo>>(`/base`, formData, {
      headers: { 'content-type': 'multiple/form-data' },
    });
    return response.data;
  }

  // 知识库删除
  async delBase(id: ID) {
    const response = await httpClient.delete<HttpResponse<{}>>(`/base/${id}`);
    return response.data;
  }

  // 知识库操作记录
  async getHistory(data: Partial<IHistoryParams>) {
    const response = await httpClient.get<ListHttpResponse<IHistory>>(`/history/${data.id}`, {
      params: { ...data },
    });
    return response.data;
  }

  // 导出
  async exportHistory(id: ID, data: Partial<IHistoryParams>) {
    const response = await httpClient.get<ListHttpResponse<IHistory>>(`/historyToExcel/${id}`, {
      params: { ...data },
      responseType: 'arraybuffer',
    });
    return response.data;
  }

  // 回收站列表
  async getRecycle(data: Partial<IRecycleParams>) {
    const response = await httpClient.get<ListHttpResponse<IRecycle>>(`/recycle`, {
      params: { limit: 10, ...data },
    });
    return response.data;
  }

  // 回收站删除
  async delRecycle(id: ID) {
    const response = await httpClient.delete<HttpResponse<{}>>(`/recycle/${id}`);
    return response.data;
  }

  // 回收站恢复
  async recoveryRecycle(id: ID) {
    const response = await httpClient.get<HttpResponse<{}>>(`/recycle/${id}`);
    return response.data;
  }

  // 知识库添加成员
  async addBaseMember(data: Partial<IAddBaseMemberParams>) {
    const { departmentsId } = data;
    const response = await httpClient.post<HttpResponse<{}>>(`/base/add`, data);
    return response.data;
  }

  // 知识库搜索
  async memberSearch(data: Partial<IMemberSearchParams>) {
    const response = await httpClient.get<ListHttpDataResponse<IMemberSearch>>(`/memberSearch`, {
      params: { ...data },
    });
    return response.data;
  }
  // 高级管理员列表
  async fetchAdmin(params: {
    sort?: string;
    keyword?: string;
    page?: number;
    limit?: number;
    type?: string;
  }) {
    const response = await httpClient.get<ListHttpResponse<AdminState>>(`/admin`, {
      params,
    });
    return response.data;
  }

  // 移除高级管理员
  async removeAdmin(id: ID) {
    const response = await httpClient.delete<HttpResponse>(`/admin/${id}`);
    return response.data;
  }

  // 添加管理员
  async addAdmin(data: Partial<IAddAdminParams>) {
    const response = await httpClient.post<HttpResponse<{}>>(`/admin`, data);
    return response.data;
  }

  // 知识库部门
  async baseMember(data: Partial<IBaseMemberParams>) {
    const response = await httpClient.get<HttpResponse<IBaseMemberList[]>>(`/baseMember`, {
      params: { ...data },
    });
    return response.data;
  }

  // 部门成员
  async getBaseMember(data: Partial<IGetBaseMember>) {
    const response = await httpClient.get<ListHttpResponse<IUsers>>(`/baseMember/${data.id}`, {
      params: { ...data },
    });
    return response.data;
  }

  // 知识库成员部门删除
  async removeBaseMember(data: Partial<IRemoveBaseMemberParams>) {
    const { id, userId, ...rest } = data;
    const response = await httpClient.delete<HttpResponse<{}>>(`/baseMember/${id}`, {
      params: {
        ...rest,
        userId: Array.isArray(userId) ? userId.join(',') : userId,
      },
    });
    return response.data;
  }

  // 新增取消管理员
  async baseMemberAdmin(data: Partial<IBaseMemberAdminParams>) {
    const { userId, ...rest } = data;
    const response = await httpClient.post<HttpResponse<{}>>(`/baseMember`, {
      ...rest,
      userId: userId?.join(', '),
    });
    return response.data;
  }

  // 管理权知识库
  async authBase({ order, limit, page = 1, keyword }: Partial<MemberListParams>) {
    const response = await httpClient.get<ListHttpResponse<Repository>>(`/authBase`, {
      params: { order, limit, page, keyword },
    });
    return response.data;
  }

  /**
   * 知识库权限管理列表
   * @param params
   * @returns
   */
  async fetchBaseAuthority(params: {
    baseId: ID;
    limit?: number;
    page?: number;
    sort?: string;
    keyword?: string;
  }) {
    const response = await httpClient.get<ListHttpResponse<BaseAuthority>>(`/baseAuthority`, {
      params,
    });
    return response.data;
  }

  async removeBaseAuthority(params: { baseId: ID; ids: string }) {
    const response = await httpClient.delete<HttpResponse<any>>(`/baseAuthority/${params.baseId}`, {
      params: {
        ids: params.ids,
      },
    });
    return response.data;
  }

  // 默认封面
  async defaultImg(id: number) {
    const response = await httpClient.get<ListHttpResponse<IDefaultImg>>(`/defaultImg/${id}`);
    return response.data;
  }

  // 知识库转让
  async transferBase(data: Partial<ITransferBaseParams>) {
    const response = await httpClient.post<HttpResponse<{}>>(`/transferBase`, data);
    return response.data;
  }

  // 下载文件浏览记录
  async fileDownload(id: ID) {
    const response = await httpClient.get<HttpResponse<{}>>(`/file/download/${id}`);
    return response.data;
  }

  /**
   * 组织架构成员信息修改
   * @param id
   * @param changed
   */
  async updateUser(id: ID, changed: any) {
    const response = await httpClient.post<HttpResponse<{}>>(`/user/${id}`, changed);
    return response.data;
  }
}

export interface ITransferBaseParams {
  receiveId: ID;
  baseId: ID;
}

export interface IDefaultImg {
  id: ID;
  type: ID;
  img: string;
  imgUrl: string;
}

export interface BaseAuthority {
  id: ID;
  userId: ID;
  joinId: ID;
  baseId: ID;
  resource: number;
  resourceId: ID;
  resourceDirectoryId: ID;
  type: number;
  authAt: string;
  userName: string;
  joinName: string;
  phone: string;
  resourceName: string;
  baseFolderType: number;
  baseFolderId: number;
}

export type Order = 't_asc' | 't_desc' | 'p_asc' | 'p_desc';
export type MemberListParams = {
  order: Order | undefined;
  keyword: string | null;
} & PaginationParams;
export interface IBaseMemberAdminParams {
  baseId: ID;
  userId: ID[];
  type: ID;
}
export interface IRemoveBaseMemberParams {
  id: ID;
  userId: ID;
  departmentId: ID;
  type: ID; // 是否黑名单
}
export interface IGetBaseMember {
  id: ID; // 部门 id
  baseId: ID; // 知识库 id
  type: number; // 是否为黑名单
  keyword: string;
  page: ID;
  sort: string;
}
export interface IBaseMemberList extends Timestamp {
  id: ID;
  departmentId: ID;
  name: string;
  source: string;
}
export interface IBaseMemberParams {
  id: ID;
}
export interface IMemberSearch extends Timestamp {
  id: ID;
  departmentId: ID;
  source: string;
  name: string;
  departments: string;
  phone: ID;
  job: string;
}
export interface IMemberSearchParams {
  type: ID;
  keyword: string;
}
export interface IAddBaseMemberParams {
  baseId: ID;
  usersId: ID;
  departmentsId: ID;
}
export interface IRecycle extends Timestamp {
  id: ID;
  resource: ID;
  resourceId: ID;
  name: string;
  parentName?: string;
  user: any;
}
export interface IRecycleParams {
  baseId?: ID;
  keyword?: string;
  sort?: string;
  page?: ID;
}
export interface IHistoryParams {
  startTime?: string;
  endTime?: string;
  type?: string;
  userName?: string;
  resourceName?: string;
  limit?: ID;
  page?: ID;
  id: ID;
  sort?: string;
}

export interface IHistory extends Timestamp {
  id: ID;
  userId: ID;
  baseId: ID;
  resource: ID;
  resourceId: ID;
  type: ID;
  name: string;
  userName: string;
  baseName: string;
}

export interface IDefaultParams {
  id: ID;
  default: ID;
}

export interface IDefaultInfo {}

export interface IBaseParams {
  name: string;
  introduction: string;
  file: any;
  isOpen: ID;
  defaultImgId: string;
}
export interface IBaseInfo extends Timestamp {
  name: string;
  introduction: string;
  img: any;
  isOpen: ID;
  id: ID;
}
export interface IAddTemplateParams {
  title?: string;
  content?: string;
  baseId?: string;
  file?: any;
  id?: ID;
  _method?: string;
}

export interface IAddTemplate {
  baseId?: ID;
  userId?: ID;
  title?: string;
  content?: any;
  img?: any;
  id?: ID;
}

export interface AdminState {
  adminType: 1 | 2; //1、超级，2、高级
  authAt: string;
  createdAt: string;
  departmentId: ID;
  departmentResource: string;
  departments: string;
  id: ID;
  job: string;
  name: string;
  phone: string;
  updatedAt: string;
  avatar: string;
}

export default new adminService();
