import React from 'react';
import { Select, Input } from 'antd';
import styled from '@emotion/styled';
import { useMemberDispatch, useMemberSelector } from '../../Provider';
import { selectSearch } from '../../reducers/selectors';
import { setSearch, showDepartments } from '../../reducers/actions';
import { useDebounceCallback } from '@react-hook/debounce';

const { Group: InputGroup } = Input;
const { Option } = Select;

const StyledSelect = styled(Select, { label: 'SearchInput' })({
  width: '30%',
  '&.ant-select:not(.ant-select-customize-input) .ant-select-selector': {
    border: 0,
  },
  '&.ant-select-focused:not(.ant-select-disabled)': {
    '&.ant-select:not(.ant-select-customize-input)': {
      '& .ant-select-selector': {
        boxShadow: 'none',
      },
    },
  },
});

const StyledInput = styled(Input)({
  width: '70% !important',
  border: 0,
  '&:focus': {
    boxShadow: 'none',
  },
});

const SearchInputRoot = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const Search: React.FC = () => {
  const dispatch = useMemberDispatch();

  const { type, keyword } = useMemberSelector(selectSearch);

  const [currentType, setCurrentType] = React.useState<'member' | 'department'>(
    type
  );
  const [currentKeyword, setCurrentKeyword] = React.useState<string>(keyword);

  const onSearch = useDebounceCallback((value: string) => {
    if (value === '') {
      dispatch(showDepartments());
    }
    dispatch(setSearch({ type, keyword: value }));
  }, 250);

  const handleSelect = (type: unknown) => {
    setCurrentType(type as 'member' | 'department');
    dispatch(setSearch({ type: type as 'member' | 'department', keyword }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setCurrentKeyword(value);
    onSearch(value);
  };

  return (
    <SearchInputRoot>
      <InputGroup compact>
        <StyledSelect value={currentType} onChange={handleSelect}>
          <Option value="member">人员</Option>
          <Option value="department">部门</Option>
        </StyledSelect>
        <StyledInput
          placeholder="搜索"
          value={currentKeyword}
          onChange={handleChange}
        />
      </InputGroup>
    </SearchInputRoot>
  );
};

export default Search;
