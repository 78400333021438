import React from 'react';
import { RadioGroupProps } from './RadioGroup';

type Context = Pick<
  RadioGroupProps,
  'name' | 'value' | 'onChange' | 'disabled'
>;

const RadioGroupContext = React.createContext<Context>({});

if (process.env.NODE_ENV !== 'production') {
  RadioGroupContext.displayName = 'RadioGroupContext';
}

export default RadioGroupContext;
