import React, { SyntheticEvent } from 'react';

import clsx from 'clsx';
import styled from '@emotion/styled';
import { useField } from 'formik';

import { composeClasses } from 'src/utils';
import {
  FormControl,
  FormControlLabel,
  FormControlHelperText,
} from 'src/components/FormControl';

const classNamePrefix = 'Field';

const useUtilityClasses = (props: Pick<FieldProps, 'classes'>) => {
  const { classes } = props;

  const slots = {
    root: ['root'],
    label: ['label'],
    control: ['control'],
    helperText: ['helperText'],
  };

  return composeClasses(
    classNamePrefix,
    slots,
    classes as Record<string, string>
  );
};

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

function resolveFileInput(event: React.ChangeEvent<HTMLInputElement>) {
  const { multiple, files } = event.currentTarget;
  if (files) {
    const final = multiple ? Array.from(files) : files[0];
    return final;
  }
  return undefined;
}

function isChangeEvent(
  input: unknown
): input is React.ChangeEvent<HTMLInputElement> {
  return typeof (input as SyntheticEvent).target !== 'undefined';
}

const Field: React.FC<FieldProps> = (props) => {
  const {
    name,
    label,
    required,
    children,
    className,
    classes: classesProp,
    helperText: helperTextProp,
    row,
    custom = false,
  } = props;
  const [field, meta, helper] = useField(name);

  const classes = useUtilityClasses({ classes: classesProp });

  const error = Boolean(meta.touched && meta.error);

  const helperText = error ? meta.error : helperTextProp || ' ';

  const handleChange = (event: unknown) => {
    if (isChangeEvent(event)) {
      const { type } = event.currentTarget;
      if (type === 'file') {
        const file = resolveFileInput(event);
        helper.setValue(file);
      } else {
        field.onChange(event);
      }
    } else {
      helper.setValue(event);
    }
  };

  const renderControl = () => {
    if (React.Children.only(children) && React.isValidElement(children)) {
      if (custom) {
        return React.cloneElement(children, {
          className: clsx(children.props.className, classes.control),
        });
      }

      return React.cloneElement(children, {
        ...field,
        onChange: handleChange,
        className: clsx(children.props.className, classes.control),
      });
    }
  };

  return (
    <FormControl
      required={required}
      error={error}
      className={clsx(classes.root, className)}
      row={row}
    >
      {label && (
        <FormControlLabel colon className={classes.label} label={label} />
      )}
      <Wrapper>
        {renderControl()}
        {helperText && (
          <FormControlHelperText className={classes.helperText}>
            {helperText}
          </FormControlHelperText>
        )}
      </Wrapper>
    </FormControl>
  );
};

export default Field;

interface ClassesKey {
  root?: string | undefined;
  label?: string | undefined;
  control?: string | undefined;
  helperText?: string | undefined;
}

interface FieldProps {
  className?: string | undefined;
  classes?: ClassesKey | undefined;
  name: string;
  label?: string | undefined;
  required?: boolean;
  helperText?: string | undefined;
  row?: boolean;
  custom?: boolean; // 自定义`change`事件
}
