/**
 * 问答根级组件
 *
 * 主要是问答模块全局需要的内容，目前只有`面包屑`
 */
import React, { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/hooks';
import { fetchDetails as fetchBaseDetails } from 'src/pages/Repository/Details/slice';
import { setBreadcrumbs } from 'src/app/breadcrumbs.slice';
import { reset } from './Details/slice';
import useCanViewPage from './useCanViewPage';

const QA: React.FC = () => {
  const { id: baseId } = useParams();
  const dispatch = useAppDispatch();
  const baseName = useAppSelector((state) => state.repository.details.data.data?.name);
  const isQuestionMember = useAppSelector((state) => state.QA.details.data?.isMember);
  const { canViewPage, handler } = useCanViewPage({ redirect: '..', isQuestionMember });

  useEffect(() => {
    if (!canViewPage && baseName && typeof isQuestionMember !== 'undefined') {
      handler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canViewPage, baseName, isQuestionMember]);

  useEffect(() => {
    if (!baseName) {
      if (baseId) {
        dispatch(fetchBaseDetails(baseId));
      }
    } else {
      dispatch(
        setBreadcrumbs([
          { path: `/repository/${baseId}`, label: baseName },
          { path: '', label: '问答' },
        ])
      );
    }

    return () => {
      dispatch(setBreadcrumbs([]));
    };
  }, [baseId, baseName, dispatch]);

  useEffect(
    () => () => {
      dispatch(reset());
    },
    [dispatch]
  );

  if (!canViewPage && baseName && typeof isQuestionMember !== 'undefined') {
    return null;
  }

  return <Outlet />;
};

export default QA;
