import store from 'src/utils/localStorage';
import { LocalStorageKey } from 'src/shared/constants';
import { httpClient } from 'src/utils';
import { HttpResponse, User } from 'src/shared/interfaces';

type LoginResponse = HttpResponse<{
  apiToken: string;
  expireTime: number;
}>;

type ProfileResponse = HttpResponse<User>;

class AuthService {
  getAccessToken(): string | null {
    return store.get<string>(LocalStorageKey.accessToken);
  }

  getExpiredAt(): string | null {
    return store.get<string>(LocalStorageKey.expiredAt);
  }

  isValidAccessToken(token?: string | null): boolean {
    if (!token) {
      return false;
    }

    const exp = Number(this.getExpiredAt());
    const currentTime = Date.now() / 1000;

    return (exp || 0) > currentTime;
  }

  isAuthenticated(): boolean {
    return !!this.getAccessToken();
  }

  setSession(accessToken?: string | null, expiredAt?: string | number | null): void {
    if (accessToken) {
      store.set(LocalStorageKey.accessToken, accessToken);
      store.set(LocalStorageKey.expiredAt, expiredAt);
    } else {
      store.remove(LocalStorageKey.accessToken);
      store.remove(LocalStorageKey.expiredAt);
    }
  }

  authentication() {
    const accessToken = this.getAccessToken();
    const expiredAt = this.getExpiredAt();
    if (!accessToken) {
      return;
    }
    if (this.isValidAccessToken(accessToken)) {
      this.setSession(accessToken, expiredAt);
    } else {
      this.setSession(null);
    }
  }

  async login(phone: string, password: string) {
    const response = await httpClient.post<LoginResponse>('/login', {
      phone,
      password,
    });
    const { apiToken, expireTime } = response.data.data;
    this.setSession(apiToken, expireTime);
    return response.data;
  }

  logout() {
    this.setSession(null);
  }

  async getProfile() {
    const response = await httpClient.get<ProfileResponse>('/user');
    return response.data;
  }
  /**
   * 修改密码
   * @param phone
   * @param password
   * @returns
   */
  async changePassword(phone: string, password: string) {
    const response = await httpClient.post<ProfileResponse>('/user/changePassword', {
      phone,
      password,
    });
    return response.data;
  }

  // 获取验证码
  async sendCode(phone: string) {
    const response = await httpClient.post<ProfileResponse>('sendCode', {
      phone,
    });
    return response.data;
  }

  // 验证码登录
  async codeLogin(phone: string, code: string) {
    const response = await httpClient.post<LoginResponse>('codeLogin', {
      phone,
      code,
    });
    const { apiToken, expireTime } = response.data.data;
    this.setSession(apiToken, expireTime);
    return response.data;
  }

  // 忘记密码
  async passwordReset(phone: string, code: string, password: string, repassword: string) {
    const response = await httpClient.post<LoginResponse>('passwordReset', {
      phone,
      code,
      password,
      repassword,
    });
    // const { apiToken, expireTime } = response.data.data;
    // this.setSession(apiToken, expireTime);
    return response.data;
  }

  // 修改头像
  async changeImage(data: Partial<IChangeImageParams>) {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    const response = await httpClient.post<ProfileResponse>('changeImage', formData, {
      headers: { 'content-type': 'multiple/form-data' },
    });

    return response.data;
  }
}
export interface IChangeImageParams {
  file: any;
  imgUrl: string;
}

export default new AuthService();
