import { State } from '../interface';

const initialState: State = {
  view: 'department',
  current: {
    department: null,
    subdepartment: null,
  },
  list: {
    department: [],
    member: [],
  },
  selected: [],
  loading: {
    department: false,
    member: false,
    search: false,
  },
  search: {
    type: 'member',
    result: [],
    keyword: '',
  },
};

export default initialState;
