import styled from '@emotion/styled';
import clsx from 'clsx';

import { composeClasses, lighten } from 'src/utils';

import { useRadioGroup } from '../RadioGroup';
import Radio, { RadioProps } from './Radio';

const classNamePrefix = 'FilledRadio';

const useUtilityClasses = (props: {
  checked?: boolean;
  disabled?: boolean;
}) => {
  const { disabled, checked } = props;

  const slots = {
    root: ['root', disabled && 'disabled', checked && 'checked'],
    icon: ['icon'],
    label: ['label'],
    description: ['description'],
  };

  return composeClasses(classNamePrefix, slots);
};

const FilledRadioRoot = styled('label')<{
  checked?: boolean;
  disabled?: boolean;
}>(({ theme, checked, disabled }) => ({
  padding: '18px 20px',
  borderRadius: 10,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  ':hover': {
    backgroundColor: lighten(theme.palette.background?.paper || '#f6f7fb', 0.4),
  },
  ...(checked && {
    backgroundColor: theme.palette.background?.paper || '#f6ffb',
  }),
  ...(disabled && { pointerEvents: 'none' }),
}));

const FilledRadioCol = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
});

const FilledRadioIcon = styled('div')<{ disabled?: boolean }>(
  ({ theme, disabled }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 50,
    marginRight: 20,
    ...(disabled && {
      '& > svg': {
        color: theme.palette.action?.disabled,
      },
    }),
  })
);

const FilledRadioText = styled('div')<{
  disabled?: boolean;
  variant?: 'primary' | 'secondary';
}>(({ theme, disabled, variant = 'primary' }) => ({
  ...theme.typography.body1,
  color: theme.palette.text?.primary,
  ...(variant === 'secondary' && {
    ...theme.typography.body2,
    color: theme.palette.text?.secondary,
  }),
  ...(disabled && { color: theme.palette.text?.disabled }),
}));

const RadioInput = styled(Radio)({
  display: 'none',
});

export type FilledRadioProps = RadioProps & {
  icon?: React.ReactElement;
  label?: string;
  description?: string;
};

const FilledRadio: React.FC<FilledRadioProps> = (props) => {
  const {
    icon: Icon,
    label,
    description,
    className,
    disabled: disabledProp,
    checked: checkedProp,
    ...rest
  } = props;

  const radioGroup = useRadioGroup();
  let disabled = disabledProp;
  let checked = checkedProp;

  if (radioGroup) {
    if (typeof disabled === 'undefined') {
      disabled = radioGroup.disabled;
    }
    if (typeof checked === 'undefined') {
      checked = radioGroup.value === rest.value;
    }
  }

  const classes = useUtilityClasses({ disabled, checked });

  return (
    <FilledRadioRoot
      className={clsx(classes.root, className)}
      checked={checked}
      disabled={disabled}
    >
      <RadioInput {...rest} disabled={disabled} checked={checked} />
      {Icon && (
        <FilledRadioCol>
          <FilledRadioIcon disabled={disabled} className={classes.icon}>
            {Icon}
          </FilledRadioIcon>
        </FilledRadioCol>
      )}
      <FilledRadioCol>
        <FilledRadioText
          disabled={disabled}
          className={classes.label}
          style={{ marginBottom: 4 }}
        >
          {label}
        </FilledRadioText>
        <FilledRadioText
          disabled={disabled}
          variant="secondary"
          className={classes.description}
        >
          {description}
        </FilledRadioText>
      </FilledRadioCol>
    </FilledRadioRoot>
  );
};

export default FilledRadio;
