import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { AppState } from 'src/app/store';

import packService from 'src/services/pack';
import { Loading } from 'src/shared/constants';
import { ID, ListData, Pack } from 'src/shared/interfaces';
import { getErrorMessage } from 'src/utils';

const name = 'pack';

type ThunkApi = { rejectValue: string };
type FetchListArgs = { type: ShareType; keyword: string };

/**
 * 资源包列表
 */
export const fetchList = createAsyncThunk<
  ListData<Pack>,
  FetchListArgs,
  ThunkApi
>(`${name}/list`, async ({ type, keyword }, { rejectWithValue }) => {
  try {
    const response = await packService.list({ type, keyword });
    console.log(response);
    return response.data;
  } catch (err) {
    const message = getErrorMessage(err);
    return rejectWithValue(message);
  }
});

type ShareType = 'receive' | 'send';

// TODO refactor state
interface State {
  receive: {
    list: Pack[];
    current: ID | null;
    keyword: string;
  };
  send: {
    list: Pack[];
    current: ID | null;
    keyword: string;
  };
  loading: Loading;
}

const initialState: State = {
  receive: {
    list: [],
    current: null,
    keyword: '',
  },
  send: {
    list: [],
    current: null,
    keyword: '',
  },
  loading: Loading.idle,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    view(state, action: PayloadAction<{ type: ShareType; id: ID | null }>) {
      if (action.payload.type === 'receive') {
        state.receive.current = action.payload.id;
      } else {
        state.send.current = action.payload.id;
      }
    },

    search(state, action: PayloadAction<{ type: ShareType; keyword: string }>) {
      if (action.payload.type === 'receive') {
        state.receive.keyword = action.payload.keyword;
      } else {
        state.send.keyword = action.payload.keyword;
      }
    },
  },
  extraReducers: (builder) => {
    /**
     * 资料包列表
     */
    builder
      .addCase(fetchList.pending, (state) => {
        state.loading = Loading.pending;
      })
      .addCase(fetchList.fulfilled, (state, action) => {
        const { type } = action.meta.arg;
        if (type === 'receive') {
          state.receive.list = action.payload.data;
          state.receive.current = action.payload.data[0]?.id || null;
        } else {
          state.send.list = action.payload.data;
          state.send.current = action.payload.data[0]?.id || null;
        }
        state.loading = Loading.fulfilled;
      })
      .addCase(fetchList.rejected, (state, action) => {
        const { type } = action.meta.arg;
        if (type === 'receive') {
          state.receive.list = [];
          state.receive.current = null;
        } else {
          state.send.list = [];
          state.send.current = null;
        }
        state.loading = Loading.rejected;
      });
  },
});

export default slice.reducer;

export const { view, search } = slice.actions;

/**
 * Selectors
 */
/**
 * 资料包列表
 * @param type
 * @returns
 */
export const selectList = (type: ShareType) => (state: AppState) => {
  if (type === 'receive') {
    return state.share.pack.receive.list;
  }
  return state.share.pack.send.list;
};

/**
 * 当前选中的资料包
 * @param type
 * @returns
 */
export const selectCurrentPack = (type: ShareType) => {
  return createSelector(
    (state: AppState) => state.share.pack,
    (pack) => {
      const { current, list } = pack[type];

      return list.find((item) => item.id === current);
    }
  );
};

/**
 * loading
 * @param state
 * @returns
 */
export const selectLoading = (state: AppState) => {
  return state.share.pack.loading;
};

/**
 * keyword
 * @param type
 * @returns
 */
export const selectKeyword = (type: ShareType) => (state: AppState) => {
  if (type === 'receive') {
    return state.share.pack.receive.keyword;
  }

  return state.share.pack.send.keyword;
};
