import { UploadStatus } from './constants';
import { UploadTask } from './state';

type MaybeFileList = File[] | null;

export function modifyFiles(
  files: MaybeFileList,
  owner: any,
  existingFiles: object = {}
) {
  if (!files) return undefined;

  let fileToUpload: Record<number, UploadTask> = {};
  for (let i = 0; i < files.length; i++) {
    const lastExistingId = Number(Object.keys(existingFiles).pop()) || 0;
    const id = lastExistingId + i + 1;

    fileToUpload = {
      ...fileToUpload,
      [id]: {
        id,
        owner,
        file: files[i],
        status: UploadStatus.Pending,
        progress: 0,
        speed: 0,
      },
    };
  }

  return fileToUpload;
}
