import { getScrollbarSize, ownerDocument, ownerWindow } from 'src/utils';

interface Modal {
  moundNode: HTMLElement;
  modalRef: HTMLElement;
}

interface Container {
  container: HTMLElement;
  modals: Modal[];
  restore: null | (() => void);
}

/**
 * https://sourcegraph.com/github.com/mui-org/material-ui/-/blob/packages/material-ui-unstyled/src/ModalUnstyled/ModalManager.ts
 */
export default class ModalManager {
  private containers: Container[];

  private modals: Modal[];

  constructor() {
    this.containers = [];
    this.modals = [];
  }

  add(modal: Modal, container: HTMLElement): number {
    let modalIndex = this.modals.indexOf(modal);
    if (modalIndex !== -1) {
      return modalIndex;
    }

    modalIndex = this.modals.length;
    this.modals.push(modal);

    const containerInfo = this.containers.find((item) => item.container === container);
    if (containerInfo) {
      containerInfo.modals.push(modal);
      return modalIndex;
    }

    this.containers.push({
      container,
      modals: [modal],
      restore: null,
    });

    return modalIndex;
  }

  mount(modal: Modal): void {
    const containerInfo = this.containers.find((item) => item.modals.indexOf(modal) !== -1);
    if (containerInfo && !containerInfo.restore) {
      containerInfo.restore = lockScrollBar(containerInfo);
    }
  }

  remove(modal: Modal): number {
    const modalIndex = this.modals.indexOf(modal);
    if (modalIndex === -1) {
      return modalIndex;
    }

    const containerIndex = this.containers.findIndex((item) => item.modals.indexOf(modal) !== -1);
    const containerInfo = this.containers[containerIndex];
    if (containerInfo) {
      containerInfo.modals.splice(containerInfo.modals.indexOf(modal), 1);
      this.modals.splice(modalIndex, 1);
      if (containerInfo.modals.length === 0) {
        containerInfo.restore?.();
        this.containers.splice(containerIndex, 1);
      }
    }

    return modalIndex;
  }

  isTopModal(modal: Modal): boolean {
    return this.modals.length > 0 && this.modals[this.modals.length - 1] === modal;
  }
}

function isOverflowing(container: Element): boolean {
  const doc = ownerDocument(container);
  if (doc.body === container) {
    return ownerWindow(container).innerWidth > doc.documentElement.clientWidth;
  }

  return container.scrollHeight > container.clientHeight;
}

function getPaddingRight(element: Element): number {
  return parseInt(ownerWindow(element).getComputedStyle(element).paddingRight, 10) || 0;
}

/**
 * 弹窗打开，锁定页面滚动
 * @param containerInfo
 */
function lockScrollBar(containerInfo: Container) {
  const restoreStyle: {
    property: string;
    el: HTMLElement | SVGElement;
    value: string;
  }[] = [];

  const container = containerInfo.container;
  if (isOverflowing(container)) {
    const scrollbarSize = getScrollbarSize(ownerDocument(container));
    restoreStyle.push({
      value: container.style.paddingRight,
      property: 'padding-right',
      el: container,
    });
    container.style.paddingRight = `${getPaddingRight(container) + scrollbarSize}px`;

    // position: fixed
    const fixedElements = ownerDocument(container).querySelectorAll('.kwb-fixed');

    [].forEach.call(fixedElements, (element: HTMLElement | SVGElement) => {
      restoreStyle.push({
        value: element.style.paddingRight,
        property: 'padding-right',
        el: element,
      });
      element.style.paddingRight = `${getPaddingRight(element) + scrollbarSize}px`;
    });

    const parent = container.parentElement;
    const containerWindow = ownerWindow(container);
    const scrollContainer =
      parent?.nodeName === 'HTML' && containerWindow.getComputedStyle(parent).overflowY === 'scroll'
        ? parent
        : container;

    restoreStyle.push({
      value: scrollContainer.style.overflow,
      property: 'overflow',
      el: scrollContainer,
    });
    scrollContainer.style.overflow = 'hidden';
  }

  return () => {
    restoreStyle.forEach(({ value, el, property }) => {
      if (value) {
        el.style.setProperty(property, value);
      } else {
        el.style.removeProperty(property);
      }
    });
  };
}
