import { combineReducers } from 'redux';

import QAListReducer from './List/slice';
import QADetailsReducer from './Details/slice';
import QAAnswersReducer from './Answers/slice';
import QAWaitingForYouReducer from './WaitingForYou/slice';
import QAReportReducer from './Report/slice';
import QAInvitationReducer from './Invitation/slice';

export default combineReducers({
  list: QAListReducer,
  details: QADetailsReducer,
  answers: QAAnswersReducer,
  waitingForYou: QAWaitingForYouReducer,
  report: QAReportReducer,
  invitation: QAInvitationReducer,
});
