/**
 * 富文本编辑器样式
 */
import { css } from '@emotion/react';

const richTextStyles = {
  img: {
    maxWidth: '100%',
    height: 'auto !important',
    margin: '8px 0',
  },
  video: {
    margin: '8px 0',
    lineHeight: 1.65,
    maxWidth: '100%',
    maxHeight: 420,
  },
  h1: {
    // color: 'rgb(222, 120, 2)',
    fontSize: 26,
    lineHeight: 1.4,
    marginBottom: 10,
    marginTop: '1em',
    fontWeight: 500,
    letterSpacing: '-0.02em',
    display: 'block',
  },
  h2: {
    // color: 'rgb(222, 120, 2)',
    fontSize: 22,
    lineHeight: 1.4,
    marginBottom: 8,
    marginTop: '1em',
    fontWeight: 500,
    letterSpacing: '-0.02em',
    display: 'block',
  },
  h3: {
    // color: 'rgb(222, 120, 2)',
    fontSize: 20,
    lineHeight: 1.4,
    marginBottom: 8,
    marginTop: '1em',
    fontWeight: 500,
    letterSpacing: '-0.02em',
    display: 'block',
  },
  h4: {
    // color: 'rgb(222, 120, 2)',
    fontSize: 18,
    lineHeight: 1.4,
    marginBottom: 8,
    marginTop: '1em',
    fontWeight: 500,
    letterSpacing: '-0.02em',
    display: 'block',
  },
  h5: {
    // color: 'rgb(222, 120, 2)',
    fontSize: 16,
    lineHeight: 1.4,
    marginBottom: 8,
    marginTop: '1em',
    fontWeight: 500,
    letterSpacing: '-0.02em',
    display: 'block',
  },
  p: {
    margin: '8px 0',
    color: 'rgb(31, 35, 41)',
    fontSize: 16,
    position: 'relative',
    lineHeight: 1.65,
    letterSpacing: '0.02em',
  },
  strong: {
    fontWeight: 599,
    letterSpacing: '0.02em',
  },
  a: {
    color: 'rgb(51, 112, 255)',
    letterSpacing: '0.02em',
  },
  blockquote: {
    boxSizing: 'border-box',
    color: 'rgba(31, 35, 41, 0.7)',
    display: 'block',
    fontSize: 16,
    lineHeight: 1.65,
    margin: '8px 0',
    paddingLeft: 14,
    position: 'relative',
    borderLeft: '2px solid rgb(51, 112, 255)',
    letterSpacing: '0.02em',
  },
  ol: {
    margin: 0,
    paddingLeft: 18,
    fontSize: 16,
    position: 'relative',
    lineHeight: 1.65,
    color: 'rgb(31, 35, 41)',
    letterSpacing: '0.02em',
  },
  ul: {
    margin: 0,
    paddingLeft: 18,
    color: 'rgb(31, 35, 41)',
    fontSize: 16,
    position: 'relative',
    lineHeight: 1.65,
    letterSpacing: '0.02em',
  },
  li: {
    marginTop: 4,
    marginBottom: 4,
    color: 'rgb(31, 35, 41)',
    lineHeight: 1.65,
    fontSize: 16,
    letterSpacing: '0.02em',
    '&::marker': {
      color: 'rgb(51, 112, 255)',
    },
  },
  pre: {
    boxSizing: 'border-box',
    color: 'rgb(31, 35, 41)',
    display: 'block',
    fontSize: 16,
    lineHeight: 1.65,
    margin: '8px 0',
    position: 'relative',
    backgroundColor: 'rgb(255, 245, 235)',
    border: '1px solid rgb(254, 212, 164)',
    borderRadius: 4,
    whiteSpace: 'pre-wrap',
    padding: '0.75rem 1rem',
    // fontFamily: "SFMono-Regular,Consolas,Monaco,'Liberation Mono',Menlo,Courier,monospace",
    letterSpacing: '0.02em',
  },
};

export default richTextStyles;

console.log(css(richTextStyles['li']));

export function getInlineStyles(tag: string) {
  const style = (richTextStyles as any)[tag];
  const cssObject = css(style);

  return cssObject.styles;
}
