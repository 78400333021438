import React from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';

import { composeClasses, lighten } from 'src/utils';

import { useRadioGroup } from '../RadioGroup';

const classPrefix = 'Radio';

const useUtilityClasses = (props: Partial<RadioProps>) => {
  const { disabled, checked } = props;

  const slots = {
    root: ['root', disabled && 'disabled'],
    input: ['input', checked && 'checked'],
    control: ['control'],
    label: ['label'],
  };

  return composeClasses(classPrefix, slots);
};

const RadioRoot = styled('label', {
  label: `${classPrefix}Root`,
  shouldForwardProp: (prop) =>
    prop !== 'theme' && prop !== 'as' && prop !== 'disabled',
})(({ theme }) => ({
  margin: 0,
  padding: 0,
  color: theme.palette.text?.primary,
  fontSize: 14,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',

  [`& .${classPrefix}-input`]: {
    '& > input': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 1,
      cursor: 'pointer',
      opacity: 0,

      [`&:focus+.${classPrefix}-control`]: {
        borderColor: theme.palette.primary?.main,
        boxShadow: `0 0 0 3px ${lighten(
          theme.palette.primary?.main || '#5a5aee',
          0.92
        )}`,
      },
    },

    [`&.${classPrefix}-checked .${classPrefix}-control`]: {
      borderColor: theme.palette.primary?.main,
    },

    [`&.${classPrefix}-checked .${classPrefix}-control::after`]: {
      transform: `scale(1)`,
      opacity: 1,
      transition: theme.transitions.create('all', {
        duration: theme.transitions.duration.standard,
      }),
    },
  },

  [`& .${classPrefix}-control`]: {
    position: 'relative',
    top: 0,
    left: 0,
    display: 'block',
    width: 16,
    height: 16,
    backgroundColor: '#fff',
    border: '1px solid #eee',
    borderRadius: '50%',
    transition: 'all 0.3s',

    '&:hover': {
      borderColor: theme.palette.primary?.main,
    },

    '&::after': {
      content: '""',
      position: 'absolute',
      top: 3,
      left: 3,
      display: 'block',
      width: 8,
      height: 8,
      backgroundColor: theme.palette.primary?.main,
      borderTop: 0,
      borderLeft: 0,
      borderRadius: '50%',
      transform: `scale(0)`,
      opacity: 0,
      transition: theme.transitions.create('all', {
        duration: theme.transitions.duration.standard,
      }),
    },
  },

  [`& .${classPrefix}-label`]: {
    paddingLeft: 8,
    paddingRight: 8,
  },
}));

export interface RadioProps {
  className?: string | undefined;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  children?: React.ReactNode;
  name?: string | undefined;
  value?: any;
}

export default React.memo<RadioProps>(function Radio(props) {
  const {
    className,
    disabled: disabledProp,
    children,
    name: nameProp,
    checked: checkedProp,
    onChange: onChangeProp,
    ...rest
  } = props;

  const radioGroup = useRadioGroup();

  let checked = checkedProp;
  let name = nameProp;
  let disabled = disabledProp;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeProp?.(event);
    radioGroup?.onChange?.(event, event.target.value);
  };

  if (radioGroup) {
    if (typeof checked === 'undefined') {
      checked = radioGroup.value === props.value;
    }
    if (typeof name === 'undefined') {
      name = radioGroup.name;
    }
    if (typeof disabled === 'undefined') {
      disabled = radioGroup.disabled;
    }
  }

  const classes = useUtilityClasses({ disabled, checked });

  return (
    <RadioRoot className={clsx(classes.root, className)}>
      <span className={classes.input}>
        <input
          {...rest}
          type="radio"
          name={name}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
        />
        <span className={classes.control} />
      </span>
      <span className={classes.label}>{children}</span>
    </RadioRoot>
  );
});
