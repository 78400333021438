import React from 'react';
import {
  MARK_BOLD,
  MARK_STRIKETHROUGH,
  MARK_UNDERLINE,
  MARK_ITALIC,
  usePlateEditorState,
  toggleMark,
  isMarkActive,
  getPluginType,
} from '@udecode/plate';

import { Bold, Strikethrough, Underline, Italic } from '../../icons';
import ToolbarButton from '../ToolbarButton';

const MarkTypes = [MARK_BOLD, MARK_STRIKETHROUGH, MARK_UNDERLINE, MARK_ITALIC] as const;

type MarkType = typeof MarkTypes[number];

const MarkTypeIcons: Record<MarkType, React.ComponentType<any>> = {
  [MARK_BOLD]: Bold,
  [MARK_STRIKETHROUGH]: Strikethrough,
  [MARK_UNDERLINE]: Underline,
  [MARK_ITALIC]: Italic,
};

export interface MarkToolbarButtonProps {
  type: MarkType;
  clear?: string | string[];
}

const MarkToolbarButton: React.FC<MarkToolbarButtonProps> = (props) => {
  const { type, clear } = props;
  const editor = usePlateEditorState();

  const Icon = MarkTypeIcons[type];

  const handleToggleMark = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (editor) {
      toggleMark(editor, { key: type, clear });
    }
  };

  return (
    <ToolbarButton
      active={!!editor?.selection && isMarkActive(editor, getPluginType(editor, type))}
      onMouseDown={handleToggleMark}
    >
      <Icon color="inherit" />
    </ToolbarButton>
  );
};

export default MarkToolbarButton;
