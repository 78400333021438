import React from 'react';
import styled from '@emotion/styled';

const PREFIX = 'SideBarExtra';

const classes = {
  root: `${PREFIX}-root`,
  head: `${PREFIX}-head`,
  list: `${PREFIX}-list`,
};

const Root = styled('div')({
  [`& .${classes.head}`]: {
    fontSize: 18,
    paddingLeft: 20,
    whiteSpace: 'nowrap',
  },

  [`& .${classes.list}`]: {
    listStyle: 'none',
    padding: 0,
    marginTop: 16,
  },
});

const Extra: React.FC<{ title?: string }> = ({ children, title }) => {
  return (
    <Root className={classes.root}>
      <div className={classes.head}>{title}</div>
      <ul className={classes.list}>{children}</ul>
    </Root>
  );
};

export default Extra;
