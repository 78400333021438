import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  unwrapResult,
} from '@reduxjs/toolkit';

import { ListData, Recent } from 'src/shared/interfaces';
import repositoryService, { RecentListParams } from 'src/services/repository';
import { Loading } from 'src/shared/constants';
import { AppState } from 'src/app/store';
import { getErrorMessage } from 'src/utils';

const name = 'repository/recent';

const recentAdapter = createEntityAdapter<Recent>({
  selectId: (recent) => recent.id,
});

/**
 * 最近
 */
export const getListOfRecent = createAsyncThunk<
  ListData<Recent>,
  Partial<RecentListParams>
>(`${name}/list`, async (params, { rejectWithValue }) => {
  try {
    const response = await repositoryService.getListOfRecent(params);
    return response.data as ListData<Recent>;
  } catch (err) {
    const message = err.isAxiosError ? err.response.data : err.message;
    return rejectWithValue(message);
  }
});

export const getRecentForSideBar = createAsyncThunk<ListData<Recent>, void>(
  `${name}/sidebar`,
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const resultAction = await dispatch(
        getListOfRecent({ page: 1, limit: 5 })
      );
      unwrapResult(resultAction);
      return resultAction.payload as ListData<Recent>;
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  }
);

const slice = createSlice({
  name,
  initialState: recentAdapter.getInitialState<{
    loading: Loading;
    page: number;
    total: number;
    sidebar: Recent[];
  }>({
    loading: Loading.idle,
    page: 1,
    total: 0,
    sidebar: [], // 导航栏显示
  }),
  reducers: {
    setPage(state, action) {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListOfRecent.pending, (state) => {
      state.loading = Loading.pending;
    });

    builder.addCase(getListOfRecent.fulfilled, (state, action) => {
      state.loading = Loading.fulfilled;
      recentAdapter.setAll(state, action.payload.data);
      state.total = action.payload.meta.pagination.total;
    });

    builder.addCase(getRecentForSideBar.fulfilled, (state, action) => {
      state.sidebar = action.payload.data;
    });

    builder.addCase(getRecentForSideBar.rejected, (state) => {
      state.sidebar = [];
    });
  },
});

export const { setPage } = slice.actions;
export default slice.reducer;

// recent selector
export const { selectAll } = recentAdapter.getSelectors(
  (state: AppState) => state.repository.recent
);

export const selectIsLoading = (state: AppState) =>
  state.repository.recent.loading;

export const selectPage = (state: AppState) => state.repository.recent.page;

export const selectTotal = (state: AppState) => state.repository.recent.total;
