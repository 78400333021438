import React from 'react';
import { List } from 'antd';
import { Link } from 'react-router-dom';

import { Book as BookIcon } from 'src/components/Icons';

import useFavorites from '../useFavorites';
import ListItem from '../ListItem';
import { FavoriteType } from '../constants';
import { getBookLink } from '../utils';

const Book: React.FC = () => {
  const { loading, entities } = useFavorites(FavoriteType.books);

  return (
    <List
      loading={loading}
      dataSource={entities}
      renderItem={(item) => {
        const href = getBookLink(item);
        return (
          <ListItem
            key={item.id}
            icon={<BookIcon color="#d5c1ff" />}
            title={item.name}
            content={item.introduction}
            as={Link}
            to={href}
          />
        );
      }}
    />
  );
};

export default Book;
