/**
 * 关注
 */

import { httpClient } from 'src/utils';
import { FollowEntity } from 'src/pages/Home/Follows/types';
import { ListHttpResponse } from 'src/shared/interfaces';
import { defaultPaginationConfig } from 'src/shared/constants';

class FollowService {
  /**
   * 关注列表
   */
  async fetchList(
    page: number = 1,
    limit: number = defaultPaginationConfig.limit,
    keyword?: string
  ) {
    const response = await httpClient.get<ListHttpResponse<FollowEntity>>('/attention', {
      params: { page, limit, keyword },
    });
    return response.data;
  }
}

const followService = new FollowService();

export default followService;
