import React from 'react';

import InputBase, { InputProps as InputBaseProps } from './Input';
import OutlinedInput from './OutlinedInput';
import TextArea from './TextArea';

const variantComponent = {
  standard: InputBase,
  outlined: OutlinedInput,
};

type Variant = 'standard' | 'outlined';

type Props = InputBaseProps & { variant?: Variant; rows?: number };

const Input = React.forwardRef<any, Props>((props, ref) => {
  const { variant = 'standard', rows, ...rest } = props;

  let InputComponent = variantComponent[variant];

  if (typeof rows === 'number') {
    return <TextArea {...rest} rows={rows} ref={ref} />;
  }

  return <InputComponent {...rest} ref={ref} />;
});

export default Input;

export type { InputBaseProps, Props as InputProps };
