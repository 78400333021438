import { AxiosError } from 'axios';
import { ResourceIcon, ResourceIconColor, ResourceType } from 'src/shared/constants';
import httpClient from './httpClient';
import localStorage from './localStorage';

export { httpClient, localStorage };

export * from './checkType';
export * from './date';
export * from './color';

/**
 * 延时
 * @param delay
 * @returns
 */
export function sleep(delay: number) {
  return new Promise((resolve) => {
    window.setTimeout(resolve, delay);
  });
}

/**
 * 空函数
 */
export function noop() {}

/**
 * emotion shouldForwardProp
 * @param prop
 * @returns
 */
export function shouldForwardProp(prop: string | number | symbol) {
  return prop !== 'theme' && prop !== 'as';
}

/**
 * 随机数
 * @param max
 * @param min
 * @returns
 */
export function random(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * hash
 *
 * @param string
 * @returns
 */
export function hash(string: string) {
  let hash = 0;
  if (string.length === 0) return hash;

  for (let i = 0; i < string.length; i++) {
    let char = string.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }

  return hash;
}

/**
 * axios error
 * @param error
 * @returns
 */
export function isAxiosError(error: unknown): error is AxiosError {
  return (error as AxiosError).isAxiosError === true;
}

/**
 * 错误信息
 * @param error
 * @returns
 */
export function getErrorMessage(error: AxiosError | Error): string {
  if (isAxiosError(error)) {
    return error.response?.data?.message || '未知错误';
  }
  return error.message;
}

/**
 * 资源图标
 * @param resourceType
 * @returns
 */
export function getResourceIcon(resourceType: ResourceType) {
  const component = ResourceIcon[resourceType];
  const color = ResourceIconColor[resourceType];

  return {
    component,
    color,
  };
}

/**
 * 构建 css class
 * @param prefix
 * @param slot
 * @param classes
 */
export function composeClasses<ClassKey extends string>(
  prefix: string,
  slots: Record<ClassKey, ReadonlyArray<string | false | undefined | null>>,
  classes?: Record<string, string> | undefined
) {
  const output: Record<ClassKey, string> = {} as any;

  // @ts-expect-error
  Object.keys(slots).forEach((slot: ClassKey) => {
    output[slot] = slots[slot]
      .reduce<string[]>((acc, key) => {
        if (key) {
          if (classes && classes[key]) {
            acc.push(classes[key]);
          }
          acc.push(`${prefix}-${key}`);
        }
        return acc;
      }, [])
      .join(' ');
  });

  return output;
}
/**
 * 根据 num 获取资源类型
 * @param type
 * @param foldType 文件夹和知识本分类
 * @returns
 */
export function getResourceType(type: number, foldType?: number | undefined) {
  switch (type) {
    case 1:
      return ResourceType.repository;
    case 2:
      if (foldType && foldType === 1) {
        return ResourceType.paper;
      }
      return ResourceType.folder;
    case 3:
      return ResourceType.resourcePack;
    case 4:
      return ResourceType.paper;
    case 5:
      return ResourceType.file;
    default:
      return ResourceType.subfolder;
  }
}
/**
 * 根据 num 获取资源类型文字
 *  1知识库,2知识本文件夹,3资源包,4知识页,5文件,6子文件夹
 * @param type
 * @param foldType 文件夹和知识本分类
 * @returns
 */
export function getResourceTypeText(type: number, foldType?: number | undefined) {
  switch (type) {
    case 1:
      return '知识库';
    case 2:
      if (foldType && foldType === 1) {
        return '知识本';
      }
      return '文件夹';
    case 3:
      return '资源包';
    case 4:
      return '知识页';
    case 5:
      return '文件';
    default:
      return '子文件夹';
  }
}

/**
 * 根据资源类型获取 url 前缀
 * @param type
 * @returns
 */
export function getResourceBaseUrl(type: ResourceType | undefined) {
  switch (type) {
    case ResourceType.repository: {
      return '/repository';
    }
    case ResourceType.paper: {
      return '/book';
    }
    case ResourceType.file:
    case ResourceType.subfolder: {
      return '/folder';
    }
    // 知识本 or 文件夹
    case ResourceType.folder: {
      return (baseFolderType: 1 | 2) => {
        return baseFolderType === 1 ? '/book' : '/folder';
      };
    }
    case ResourceType.answer: {
      return '/repository';
    }
    default: {
      throw new Error(`Unknown resource type: ${type}`);
    }
  }
}

/**
 * omit
 * @param input
 * @param fields
 * @returns
 */
export function omit<T extends Record<string, any>, K extends keyof T>(
  input: T,
  fields: K[]
): Omit<T, K> {
  const output = {} as T;

  (Object.keys(input) as Array<keyof T>).forEach((key) => {
    if (fields.indexOf(key as K) === -1) {
      output[key] = input[key];
    }
  });

  return output;
}

/**
 * 返回给定范围内的值
 * @param value
 * @param min
 * @param max
 */
export function clamp(value: number, min: number = 0, max: number = 1) {
  if (process.env.NODE_ENV !== 'production') {
    if (value < min || value > max) {
      console.error(`Them value provided ${value} is out of range [${min}, ${max}]`);
    }
  }
  return Math.min(Math.max(min, value), max);
}

/**
 * 转换数据
 * @param obj
 * @param filterKeys
 * @param transformer
 * @returns
 */
export function transform<T extends Record<string, any>, C extends (arg: any) => any>(
  obj: T | undefined | null,
  filterKeys: string[],
  transformer: C
) {
  if (!obj) return undefined;

  return filterKeys.reduce((acc, key) => {
    if (typeof obj[key] !== 'undefined') {
      return { ...acc, [key]: transformer(obj[key]) };
    }
    return acc;
  }, obj);
}
/**
 *
 * @param str
 * @returns [名称,后缀]
 */

export function getFileName(str: string) {
  //正则表达式获取文件名，不带后缀
  // eslint-disable-next-line no-useless-escape
  const strFileName = str.replace(/^.+?\\([^\\]+?)(\.[^\.\\]*?)?$/gi, '$1');
  //正则表达式获取后缀
  const FileExt = str.replace(/.+\./, '');
  return [strFileName, FileExt];
}

/**
 * 格式化文件大小, 输出成带单位的字符串
 * @param {Number} size 文件大小
 * @param {Number} [pointLength=2] 精确到的小数点数。
 * @param {Array} [units=[ 'B', 'Kb', 'Mb', 'G', 'T' ]]
 *    如果单位数组里面只指定了到了K(千字节)，同时文件大小大于M, 此方法的输出将还是显示成多少K.
 * @returns
 */
export function formatSize(
  size: number,
  pointLength?: number | undefined,
  units?: string[] | undefined
) {
  let unit: string = '';
  units = units || ['B', 'Kb', 'Mb', 'G', 'T'];

  while ((unit = units.shift() as string) && size > 1024) {
    size = size / 1024;
  }
  return (unit === 'B' ? size : size.toFixed(pointLength === undefined ? 1 : pointLength)) + unit;
}

/**
 * 对象中值的变化
 *
 * 变化前后对象键没有新增/删除
 * @param origin
 * @param changed
 * @returns
 */
export function changes<T extends Record<string, unknown>>(origin: T, changed: T): Partial<T> {
  return Object.keys(changed).reduce((acc, key) => {
    if (origin[key] !== changed[key]) {
      return { ...acc, [key]: changed[key] };
    }
    return acc;
  }, {});
}

/**
 * owner document
 * @link https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/utils/ownerDocument.js
 * @param node
 * @returns
 */
export function ownerDocument(node: Node | null | undefined): Document {
  return (node && node.ownerDocument) || document;
}

/**
 * owner window
 * @link https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/utils/ownerWindow.js
 * @param node
 * @returns
 */
export function ownerWindow(node: Node | null | undefined): Window {
  const doc = ownerDocument(node);
  return doc.defaultView || window;
}

export type ChainedFunction = ((...args: any[]) => void) | undefined | null;

/**
 * chain function
 * @link https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/utils/createChainedFunction.js
 * @param funcs
 * @returns
 */
export function createChainedFunction(...funcs: ChainedFunction[]): (...args: any[]) => void {
  return funcs.reduce<(...args: any[]) => void>(
    (acc, func) => {
      if (func !== null && func !== undefined) {
        return function chainedFunction(this: any, ...args) {
          acc.apply(this, args);
          func.apply(this, args);
        };
      }
      return acc;
    },
    () => {}
  );
}

/**
 * 当前滚动条宽度
 *
 * 为 0 时，页面没有滚动条
 * @link https://sourcegraph.com/github.com/mui-org/material-ui/-/blob/packages/material-ui-utils/src/getScrollbarSize.ts
 * @param doc
 * @returns
 */
export function getScrollbarSize(doc: Document) {
  const documentWidth = doc.documentElement.clientWidth;
  return Math.abs(window.innerWidth - documentWidth);
}

/**
 * 检测不同浏览器的滚动条宽度
 * @returns
 */
export function detectScrollbarWidth(): number {
  const dummy = document.createElement('div');
  dummy.style.width = '100px';
  dummy.style.height = '100px';
  dummy.style.overflow = 'scroll';
  dummy.style.position = 'absolute';
  dummy.style.top = '-9999px';

  document.body.appendChild(dummy);
  const scrollbarWidth = dummy.offsetWidth - dummy.clientWidth;
  document.body.removeChild(dummy);
  return scrollbarWidth;
}

type AnimateConfig = {
  timing?: (fraction: number) => number;
  callback?: (progress: number) => void;
  duration?: number;
};

export function animate(config: AnimateConfig | undefined = {}) {
  const { timing = easeInOutSine, callback = noop, duration = 500 } = config;

  let start = performance.now();

  window.requestAnimationFrame(function animated(time) {
    // timeFraction goes from 0 to 1
    let timeFraction = (time - start) / duration;
    if (timeFraction >= 1) timeFraction = 1;

    // calculate the current animation state;
    const progress = timing(timeFraction);

    callback(progress);

    if (timeFraction < 1) {
      requestAnimationFrame(animated);
    }
  });
}

function easeInOutSine(x: number): number {
  return -(Math.cos(Math.PI * x) - 1) / 2;
}

/**
 * 随即字符串（英文）
 *
 * 用于删除时，4位验证码
 * @param length
 * @returns
 */
export function randomString(length: number): string {
  const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

  let result = '';
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return result;
}
/**
 * 首字母大写
 * @param input
 * @returns
 */
export function capitalize(input: string) {
  return input.charAt(0).toUpperCase() + input.slice(1);
}

/**
 * 图片链接兼容
 * @param src
 */
export function parseImageSrc(src: string = ''): string {
  if (!src) return '';
  if (src.startsWith('https://') || src.startsWith('http://') || src.startsWith('/storage')) {
    return src;
  }

  return '/storage/' + src;
}

/**
 * 字符串模板
 *
 * const templated = template`Hello, ${'name'}`;
 * console.log(templated({ name: 'world' })); => 'Hello, world'
 * @param strings
 * @param keys
 * @returns
 */
export function template<T extends string>(strings: TemplateStringsArray, ...keys: T[]) {
  return function templated(args: Record<T, string | number>) {
    const result = keys.reduce(
      (acc, key, index) => {
        return [...acc, String(args[key]), strings[index + 1]];
      },
      [strings[0]]
    );

    return result.join('');
  };
}
