import { format, getDate, getTime, getYear } from 'date-fns';
import { deserialize, getPlainText, parseHtmlDocument } from './components/Editor/TextEditor';
import {
  Answer,
  AnswerEntity,
  Question,
  Content,
  QuestionEntity,
  ReportEntity,
  Report,
  InviteUserEntity,
  InviteUser,
} from './types';

const MILLISECONDS_IN_MINUTE = 1000 * 60;
const MINUTES_IN_DAY = 60 * 24;
const MINUTES_IN_YEAR = MINUTES_IN_DAY * 365;

/**
 * 1. 0-24 小时内，直接显示时、分，例：11:32；
 * 2. 24-48 小时内，显示昨日、时、分，例：昨日 11:32；
 * 3. 本年度内，显示月、日、时、分，例：1月29日 11:32；
 * 4. 本年度以外，超过48小时，显示年、月、日，例：2020年12月31日。
 * @param date
 * @returns
 */
export function formatDistanceToNow(date: Date | number): string {
  const today = new Date();

  const milliseconds = getTime(today) - getTime(date);
  const minutes = milliseconds / MILLISECONDS_IN_MINUTE;

  let result = '';

  // 0 - 24 小时
  if (minutes < MINUTES_IN_DAY) {
    if (getDate(today) === getDate(date)) {
      result = format(date, 'HH:mm');
    } else {
      //日期不同
      result = `昨天 ${format(date, 'HH:mm')}`;
    }
  } else if (minutes < MINUTES_IN_DAY * 2) {
    // 24 - 48 小时
    result = `昨天 ${format(date, 'HH:mm')}`;
  } else if (minutes < MINUTES_IN_YEAR) {
    if (getYear(today) === getYear(date)) {
      result = format(date, 'M月d日 HH:mm');
    } else {
      result = format(date, 'yyyy年M月d日 HH:mm');
    }
  } else {
    result = format(date, 'yyyy年M月d日 HH:mm');
  }

  return result;
}

/**
 * 从html字符串中获取纯文本
 * @param html
 * @returns
 */
export function getPlainTextFromHtmlString(html: string): string {
  const document = parseHtmlDocument(html);

  return getPlainText(deserialize(document.body));
}

/**
 * 纯文本输出问题描述
 * @param content
 * @returns
 */
export function getPlainTextOfQuestionDescription(content: Content[] = []) {
  return content.reduce((acc, item) => {
    switch (item.type) {
      case 'text': {
        return (acc += getPlainTextFromHtmlString(item?.content || ''));
      }
      case 'image': {
        return (acc += '[图片]');
      }
      case 'video': {
        return (acc += '[视频]');
      }
      default:
        return acc;
    }
  }, '');
}

/**
 * 服务端数据结构映射前端数据结构
 * @param entity
 * @returns
 */
export function questionAdapter(entity: QuestionEntity): Question {
  const {
    id,
    baseId,
    title,
    content,
    answerNum,
    myAnswerId,
    attentionBtn,
    attentionNum,
    views,
    isAnonymous,
    userId,
    createdAt,
    updatedAt,
    hasAuthority,
    isMember,
  } = entity;

  return {
    id,
    baseId,
    userId,
    title,
    content,
    hasAuthority,
    answerId: myAnswerId,
    updatedAt: updatedAt || '',
    createdAt: createdAt || '',
    answered: !!myAnswerId,
    followed: attentionBtn,
    answers: answerNum,
    follows: attentionNum,
    read: views,
    isIncognito: isAnonymous === 1,
    isMember,
  };
}

export function answerAdapter(entity: AnswerEntity): Answer {
  const {
    baseId,
    content,
    createdAt,
    id,
    isAgree,
    isCollect,
    qId,
    updatedAt,
    userAvatar,
    userName,
    userId,
    collectId,
  } = entity;

  return {
    baseId,
    content,
    id,
    user: {
      id: userId,
      name: userName,
      avatar: userAvatar,
    },
    collectId,
    questionId: qId,
    approved: isAgree,
    favorited: isCollect,
    createdAt: createdAt || '',
    updatedAt: updatedAt || '',
  };
}

export function reportAdapter(entity: ReportEntity): Report {
  const { id, content, needContent: needReason } = entity;

  return { id, content, needReason: needReason === 1 };
}

export function inviteUserAdapter(entity: InviteUserEntity): InviteUser {
  const { userId, departmentName, count, ...rest } = entity;

  return { ...rest, department: departmentName, answered: count, id: userId };
}
