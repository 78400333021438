import styled from '@emotion/styled';
import { Checkbox } from 'antd';

export default styled(Checkbox)({
  marginRight: 10,

  '& .ant-checkbox-inner': {
    borderRadius: '50%',
    width: 18,
    height: 18,
  },

  '& .ant-checkbox-checked::after': {
    borderRadius: '50%',
    border: 'none',
  },
  '& .css-hhoriw .ant-checkbox-inner': {
    top: 0,
    borderRadius: '50%',
  },

  '& .ant-checkbox-indeterminate .ant-checkbox-inner::after': {
    borderRadius: '50%',
  },
});

export const { Group: CheckboxGroup } = Checkbox;
