import React from 'react';
import { SvgIcon, SvgIconPropsType } from '../../SvgIcon';

export default function createSvgIcon(
  path: React.ReactNode,
  displayName?: string,
  viewBox?: string
): typeof SvgIcon {
  const Component = (props: React.PropsWithChildren<SvgIconPropsType>) => {
    const { viewBox: viewBoxProp = viewBox, ...rest } = props;
    return (
      <SvgIcon
        data-testid={`${displayName}Icon`}
        {...rest}
        viewBox={viewBoxProp}
      >
        {path}
      </SvgIcon>
    );
  };

  Component.displayName = `${displayName}Icon`;

  return React.memo(Component);
}
