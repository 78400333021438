import styled from '@emotion/styled';

const classNamePrefix = 'ModalBody';

const ModalBody = styled('div', { label: classNamePrefix })({
  paddingTop: 20,
  paddingBottom: 20,
  paddingLeft: 60,
  paddingRight: 60,
  flex: 1,
  overflow: 'auto',
  minHeight: 240,
});

export default ModalBody;
