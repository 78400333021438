import { HttpResponse, ID, ListHttpResponse } from 'src/shared/interfaces';
import { httpClient } from 'src/utils';
import { AnswerEntity, InviteUserEntity, QuestionEntity, ReportEntity } from 'src/pages/QA/types';

import { Approve, Type } from 'src/pages/QA/constants';

type FetchListParams = {
  page?: number;
  limit?: number;
  keyword?: string;
};

class QAService {
  /**
   * 提问
   * @param baseId 知识库 id
   * @param title 标题
   * @param content 内容
   */
  async createQuestion(baseId: ID, question: Partial<QuestionEntity>) {
    const { title, content } = question;
    const response = await httpClient.post<HttpResponse<QuestionEntity>>('/question', {
      baseId,
      title,
      content,
    });
    return response.data;
  }

  /**
   * 编辑问题
   * @param questionId
   * @param question
   */
  async updateQuestion(questionId: ID, entity: Partial<Omit<QuestionEntity, 'isMember'>>) {
    const response = await httpClient.patch<HttpResponse<QuestionEntity>>(
      `/question/${questionId}`,
      entity
    );
    return response.data;
  }

  /**
   * 问答首页列表
   * @param params
   * @returns
   */
  async fetchQAList(baseId: ID, params: FetchListParams = {}) {
    const { page = 1, limit = 15 } = params;
    const response = await httpClient.get<ListHttpResponse<QuestionEntity>>('/question', {
      params: { baseId, page, limit },
    });
    return response.data;
  }

  /**
   * 提问详情
   * @param id
   * @returns
   */
  async fetchQADetails(id: ID) {
    const response = await httpClient.get<HttpResponse<QuestionEntity>>(`/question/${id}`);
    return response.data;
  }

  /**
   * 回答列表
   * @param questionId
   * @param meta
   * @returns
   */
  async fetchAnswerList(questionId: ID, meta: Omit<FetchListParams, 'keyword'> = {}) {
    const response = await httpClient.get<ListHttpResponse<AnswerEntity>>(
      `/question/${questionId}/answers`,
      { params: meta }
    );
    return response.data;
  }

  /**
   * 回答问题
   * @param questionId
   * @param content
   * @returns
   */
  async answerQuestion(questionId: ID, content: QuestionEntity['content']) {
    const response = await httpClient.post<HttpResponse<any>>('/answer', {
      qId: questionId,
      content,
    });
    return response.data;
  }

  /**
   * 等你来答列表
   */
  async fetchWaitingForYouList(baseId: ID) {
    const response = await httpClient.get<HttpResponse<any[]>>('/waitingAnswer', {
      params: { baseId },
    });
    return response.data;
  }

  /**
   * 关闭问题
   * @param questionId
   */
  async close(questionId: ID) {
    const response = await httpClient.post<HttpResponse<any>>(`/question/close/${questionId}`);
    return response.data;
  }

  /**
   * 删除问题
   * @param questionId
   */
  async remove(questionId: ID) {
    const response = await httpClient.delete<HttpResponse<{}>>(`/question/${questionId}`);
    return response.data;
  }

  /**
   * 浏览记录
   * @param questionId
   */
  async view(questionId: ID) {
    const response = await httpClient.get<HttpResponse<QuestionEntity>>(
      `/question/view/${questionId}`
    );
    return response.data;
  }

  /**
   * 关注/取消关注
   * @param questionId
   * @param type 1: 关注 2: 取消关注
   * @returns
   */
  async follow(questionId: ID, type: 1 | 2) {
    const response = await httpClient.post<HttpResponse<{}>>(`/question/attention/${questionId}`, {
      type,
    });
    return response.data;
  }

  /**
   * 获取举报理由列表
   */
  async fetchReportList() {
    const response = await httpClient.get<HttpResponse<ReportEntity[]>>('/tips');
    return response.data;
  }

  /**
   * 举报问题/答案
   * @param id
   * @param data
   * @returns
   */
  async report(id: ID, data: { type: Type; rId: ID; detail?: string }) {
    const response = await httpClient.post<HttpResponse<{}>>(`/tip/${id}`, data);
    return response.data;
  }

  /**
   * 赞同/取消赞同问题/答案
   * @param id
   * @param data
   * @returns
   */
  async approve(
    id: ID,
    data: { type: Type; action: Approve } = { type: Type.question, action: Approve.approve }
  ) {
    const response = await httpClient.post<HttpResponse<{}>>(`/agree/${id}`, data);
    return response.data;
  }

  /**
   * 获取回答详情
   * @param id
   */
  async fetchAnswerDetails(id: ID) {
    const response = await httpClient.get<
      HttpResponse<{ answer: AnswerEntity; question: QuestionEntity }>
    >(`/answer/${id}`);
    return response.data;
  }

  /**
   * 编辑回答
   * @param id
   * @param entity
   */
  async updateAnswer(id: ID, entity: Partial<AnswerEntity>) {
    const response = await httpClient.patch(`/answer/${id}`, entity);
    return response.data;
  }

  /**
   * 邀请回答人员列表
   * @param questionId
   * @returns
   */
  async fetchInviteUserList(questionId: ID, filter: { keyword?: string } = {}) {
    const { keyword: name } = filter;
    const response = await httpClient.get<HttpResponse<InviteUserEntity[]>>('/invitation', {
      params: { qId: questionId, name },
    });
    return response.data;
  }

  /**
   * 邀请回答
   * @param questionId
   * @param ids
   * @returns
   */
  async invite(questionId: ID, ids: ID[]) {
    const response = await httpClient.post<HttpResponse<{}>>('/invite', {
      qId: questionId,
      ids: ids.join(','),
    });
    return response.data;
  }

  /**
   * 关闭回答
   * @param answerId
   * @returns
   */
  async closeAnswer(answerId: ID) {
    const response = await httpClient.post<HttpResponse<any>>(`/answer/close/${answerId}`);
    return response.data;
  }
}

const qaService = new QAService();

export default qaService;
