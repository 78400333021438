import {
  Book,
  Share,
  FavoriteFill,
  Draft,
  Setting,
  Edit,
  Management,
  ShowChart,
  Trash,
  Person,
  Template,
  Operate,
  Record,
} from 'src/components/Icons';

import { MenuItem } from 'src/layouts/MainLayout';
import { User } from 'src/shared/interfaces';

export const MAIN_MENUS: MenuItem<User>[] = [
  {
    path: '/repository',
    name: '知识库',
    icon: <Book />,
  },
  {
    path: '/share',
    name: '分享',
    icon: <Share />,
  },
  {
    path: '/favorites',
    name: '收藏夹',
    icon: <FavoriteFill />,
  },
  {
    path: '/follows',
    name: '我的关注',
    icon: <FavoriteFill />,
  },
  {
    path: '/draft',
    name: '草稿箱',
    icon: <Draft />,
  },
  {
    path: '/management',
    name: '管理',
    icon: <Setting />,
    guard: (user: User) => user && user.manage < 3,
  },
];

export const MANAGEMENT_MENUS: MenuItem[] = [
  {
    path: '',
    name: '审核',
    icon: <Edit />,
  },
  {
    path: 'knowledge-base',
    name: '知识库管理',
    icon: <Management />,
  },
  {
    path: 'qa',
    name: '问答管理',
    icon: <Management />,
  },
  {
    path: 'backend',
    name: '后台管理',
    icon: <ShowChart />,
  },
];

export const KNOWLEDGE_MENUS: MenuItem[] = [
  {
    path: `admin`,
    name: '审核',
    icon: <Edit />,
  },
  {
    path: `admin/member`,
    name: '成员管理',
    icon: <Person />,
  },
  {
    path: `admin/share`,
    name: '权限管理',
    icon: <Share />,
  },
  {
    path: `admin/template`,
    name: '模板管理',
    icon: <Template />,
  },
  {
    path: 'admin/report',
    name: '举报管理',
    icon: <Template />,
  },
  {
    path: `admin/study`,
    name: '学习记录',
    icon: <Record />,
  },
  {
    path: `admin/operate`,
    name: '操作记录',
    icon: <Operate />,
  },
  {
    path: `admin/settings`,
    name: '知识库设置',
    icon: <Book />,
  },
  {
    path: `admin/recycle`,
    name: '回收站',
    icon: <Trash />,
  },
];
