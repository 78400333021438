import React from 'react';

import styled from '@emotion/styled';
import { composeClasses } from 'src/utils';
import clsx from 'clsx';

const classNamePrefix = 'ShareTitle';

const useUtilityClasses = () => {
  const slots = {
    root: ['root'],
  };

  return composeClasses(classNamePrefix, slots);
};

const TitleRoot = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: 20,
});

const TitleText = styled('div')(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.text?.primary,
}));

const TitleAction = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 14,
  color: theme.palette.primary?.main,
  cursor: 'pointer',
}));

const Title: React.FC<{
  className?: string | undefined;
  onClear?: () => void;
}> = ({ className, onClear }) => {
  const classes = useUtilityClasses();

  return (
    <TitleRoot className={clsx(classes.root, className)}>
      <TitleText>分享资料包</TitleText>
      <TitleAction onClick={onClear}>清空</TitleAction>
    </TitleRoot>
  );
};

export default Title;
