import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { AppState } from 'src/app/store';

import draftService from 'src/services/draft';
import { Loading, ResourceType } from 'src/shared/constants';
import { Draft, HttpResponse, ID, ListData } from 'src/shared/interfaces';
import { getErrorMessage } from 'src/utils';

const name = 'draft';

const entityAdapter = createEntityAdapter<Draft>({
  selectId: (draft) => draft.id,
});

interface State {
  page: number;
  total: number;
  loading: Loading;
  limit: number;
}

type FetchListArg = {
  type?: 1 | 2 | undefined;
  page?: number | undefined;
  limit?: number | undefined;
  keyword?: string | undefined;
};

type ThunkApi = { rejectValue: string };

/**
 * 草稿箱列表
 */
export const fetchList = createAsyncThunk<
  ListData<Draft>,
  FetchListArg,
  ThunkApi
>(`${name}/list`, async (data, { rejectWithValue }) => {
  try {
    const response = await draftService.getList(data);
    return response.data;
  } catch (error) {
    const message = getErrorMessage(error);
    return rejectWithValue(message);
  }
});

type RemoveArgs = { id: ID; resourceType: ResourceType };

/**
 * 删除草稿
 */
export const remove = createAsyncThunk<{}, RemoveArgs, ThunkApi>(
  `${name}/remove`,
  async ({ id, resourceType }, { rejectWithValue }) => {
    try {
      let response: HttpResponse<{}>;
      if (resourceType === ResourceType.paper) {
        response = await draftService.removePage(id);
      } else {
        response = await draftService.removeFile(id);
      }
      return response.data;
    } catch (error) {
      console.log(error);
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

// 撤回
export const withdraw = createAsyncThunk<HttpResponse<{}>, any>(
  `${name}/withdraw`,
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await draftService.withdraw(id);
      return response;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

const slice = createSlice({
  name,
  initialState: {
    all: getInitialStateWithAdapter(),
    pages: getInitialStateWithAdapter(),
    folders: getInitialStateWithAdapter(),
    keyword: '',
  },
  reducers: {
    setKeyword(state, action) {
      state.keyword = action.payload;
    },
    setPage(
      state,
      action: PayloadAction<['all' | 'pages' | 'folders', number]>
    ) {
      const [path, page] = action.payload;
      state[path].page = page;
    },
  },
  extraReducers: (builder) => {
    // Fetch List
    builder
      .addCase(fetchList.pending, (state, action) => {
        const { type } = action.meta.arg;
        const pathKey = getPathKey(type);
        state[pathKey].loading = Loading.pending;
      })
      .addCase(fetchList.fulfilled, (state, action) => {
        const { type } = action.meta.arg;
        const pathKey = getPathKey(type);
        state[pathKey].loading = Loading.fulfilled;
        entityAdapter.setAll(state[pathKey], action.payload.data);
        const { total } = action.payload.meta.pagination;
        state[pathKey].total = total;
      })
      .addCase(fetchList.rejected, (state, action) => {
        const { type } = action.meta.arg;
        const pathKey = getPathKey(type);
        state[pathKey].loading = Loading.rejected;
        entityAdapter.setAll(state[pathKey], []);
        state[pathKey].total = 0;
      });

    // Remove item
    builder.addCase(remove.fulfilled, (state, action) => {
      const { id } = action.meta.arg;
      (['all', 'pages', 'folders'] as const).forEach((pathKey) => {
        entityAdapter.removeOne(state[pathKey], id);
      });
    });

    builder.addCase(withdraw.fulfilled, (state, action) => {
      const { id } = action.meta.arg;
      (['all', 'pages', 'folders'] as const).forEach((pathKey) => {
        entityAdapter.removeOne(state[pathKey], id);
      });
    });
  },
});

export default slice.reducer;

export const { setKeyword, setPage } = slice.actions;

/***
 * selectors
 */
const selectDraft = (state: AppState) => state.draft;

export const selectAll = (type?: 1 | 2 | undefined) =>
  createSelector(
    () => getPathKey(type),
    (state: AppState) => state,
    (pathKey, state) => {
      const { selectAll: selectAllEntity } = entityAdapter.getSelectors(
        (state: AppState) => state.draft[pathKey]
      );
      return selectAllEntity(state);
    }
  );

export const selectPage = (type?: 1 | 2 | undefined) =>
  createSelector(
    selectDraft,
    () => getPathKey(type),
    (draft, pathKey) => draft[pathKey].page
  );

export const selectLimit = (type?: 1 | 2 | undefined) =>
  createSelector(
    selectDraft,
    () => getPathKey(type),
    (draft, pathKey) => draft[pathKey].limit
  );

export const selectTotal = (type?: 1 | 2 | undefined) =>
  createSelector(
    selectDraft,
    () => getPathKey(type),
    (draft, pathKey) => draft[pathKey].total
  );

export const selectLoading = (type?: 1 | 2 | undefined) =>
  createSelector(
    selectDraft,
    () => getPathKey(type),
    (draft, pathKey) => draft[pathKey].loading
  );

export const selectKeyword = (state: AppState) => state.draft.keyword;

/**
 * initial state with entity adapter
 * @returns
 */
function getInitialStateWithAdapter() {
  return entityAdapter.getInitialState<State>({
    page: 1,
    total: 0,
    loading: Loading.idle,
    limit: 16,
  });
}

/**
 * 获取类型对应的 state
 * @param type
 * @returns
 */
export function getPathKey(type?: 1 | 2 | undefined) {
  switch (type) {
    case 1: {
      return 'pages';
    }
    case 2: {
      return 'folders';
    }
    default:
      return 'all';
  }
}
