import { useCallback, useMemo } from 'react';
import { useAppSelector } from 'src/hooks';
import { useDialogContext } from 'src/components/Dialog';
import { useNavigate } from 'react-router';

type UseCanViewPageArgs = {
  redirect?: string | false;
  content?: string;
  isQuestionMember?: boolean;
};

export default function useCanViewPage({
  redirect = false,
  content = '您没有权限浏览问答',
  isQuestionMember,
}: UseCanViewPageArgs = {}) {
  const baseId = useAppSelector((state) => state.repository.details.data.data?.id);
  const isBasePrivate = useAppSelector((state) => state.repository.details.data.data?.isOpen);
  const isBaseMember = useAppSelector((state) => state.repository.details.data.data?.isMember);

  const { openDialog } = useDialogContext();
  const navigate = useNavigate();

  const canViewPage = useMemo(() => {
    if (typeof isQuestionMember !== 'undefined') {
      return isQuestionMember;
    }
    return baseId && !(isBasePrivate && !isBaseMember);
  }, [baseId, isBaseMember, isBasePrivate, isQuestionMember]);

  const handler = useCallback(() => {
    openDialog({
      type: 'tip',
      variant: 'warning',
      content,
      onOk: () => {
        if (redirect) {
          navigate(redirect);
        }
      },
    });
  }, [content, navigate, openDialog, redirect]);

  return useMemo(() => ({ canViewPage, handler }), [canViewPage, handler]);
}
