import { Context } from './FormControlContext';

export type Parameters<T> = {
  props: T;
  states: (keyof Context)[];
  formControl: Context | null;
};

export default function formControlState<T extends Record<string, any> = {}>({
  props,
  states,
  formControl,
}: Parameters<T>) {
  return states.reduce<Record<string, any>>((acc, state) => {
    acc[state] = props[state];

    if (formControl) {
      if (typeof props[state] === 'undefined') {
        acc[state] = formControl[state];
      }
    }

    return acc;
  }, {});
}
