import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { AppState } from 'src/app/store';
import qaService from 'src/services/QA';
import { Loading } from 'src/shared/constants';
import { ID } from 'src/shared/interfaces';
import { getErrorMessage } from 'src/utils';
import { InviteUser } from '../types';
import { inviteUserAdapter } from '../utils';

const name = 'QA-Invitation' as const;

export interface State {
  data: InviteUser[];
  questionId: ID | undefined;
  loading: Loading;
}

const initialState: State = {
  data: [],
  questionId: undefined,
  loading: Loading.idle,
};

/**
 * 邀请回答人员列表
 */
export const fetchUsers = createAsyncThunk<
  InviteUser[],
  { id: ID; keyword?: string | undefined },
  { rejectValue: string }
>(`${name}/fetchUsers`, async ({ id, keyword }, { rejectWithValue }) => {
  try {
    const response = await qaService.fetchInviteUserList(id, { keyword });
    return response.data.map((item) => inviteUserAdapter(item));
  } catch (err) {
    return rejectWithValue(getErrorMessage(err));
  }
});

/**
 * 邀请回答
 */
export const invite = createAsyncThunk<void, ID, { rejectValue: string; state: AppState }>(
  `${name}/invite`,
  async (id, { rejectWithValue, getState }) => {
    try {
      const { QA } = getState();
      const { questionId } = QA.invitation;
      if (questionId) {
        await qaService.invite(questionId, [id]);
      }
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  }
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state) => {
      state.loading = Loading.pending;
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.loading = Loading.fulfilled;
      state.data = action.payload;
      state.questionId = Number(action.meta.arg.id);
    });
    builder.addCase(fetchUsers.rejected, (state) => {
      state.loading = Loading.rejected;
    });
    builder.addCase(invite.fulfilled, (state, action) => {
      const userId = action.meta.arg;
      state.data = state.data.map((item) => {
        if (item.id === userId) {
          return { ...item, isInvited: true };
        }
        return item;
      });
    });
  },
});

export default slice.reducer;

export const { reset } = slice.actions;

/** selectors */
export const selectHasInvited = createSelector(
  [(state: AppState) => state.QA.invitation.data],
  (users) => users.filter((user) => user.isInvited)
);
