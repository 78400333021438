import React from 'react';
import styled from 'styled-components';
import {
  ELEMENT_BLOCKQUOTE,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_OL,
  ELEMENT_PARAGRAPH,
  ELEMENT_UL,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_UNDERLINE,
  ToolbarBase,
  ToolbarProps as PlateToolbarProps,
} from '@udecode/plate';
import clsx from 'clsx';

// import HeadingToolbarButton from '../HeadingToolbarButton';
import MarkToolbarButton from '../MarkToolbarButton';
import ListToolbarButton from '../ListToolbarButton';
import BlockToolbarButton from '../BlockToolbarButton';
import LinkToolbarButton from '../LinkToolbarButton';
import CodeBlockToolbarButton from '../CodeBlockToolbarButton';

const PREFIX = 'TextEditorToolbar';

const classNames = {
  root: `${PREFIX}-root`,
};

const ToolbarRoot = styled(ToolbarBase)({
  [`&.${classNames.root}`]: {
    padding: 8,
    borderBottom: '1px solid #dadada',
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: '#f9f9f9',
    borderRadius: '4px 4px 0 0',
  },
});

export interface ToolbarProps extends PlateToolbarProps {}

const Toolbar: React.FC<ToolbarProps> = (props) => {
  const { className } = props;

  return (
    <ToolbarRoot className={clsx(classNames.root, className)}>
      {/* <HeadingToolbarButton /> */}
      <BlockToolbarButton type={ELEMENT_H1} inactiveType={ELEMENT_PARAGRAPH} />
      <BlockToolbarButton type={ELEMENT_H2} inactiveType={ELEMENT_PARAGRAPH} />
      <BlockToolbarButton type={ELEMENT_H3} inactiveType={ELEMENT_PARAGRAPH} />
      <BlockToolbarButton type={ELEMENT_H4} inactiveType={ELEMENT_PARAGRAPH} />
      <MarkToolbarButton type={MARK_BOLD} />
      <MarkToolbarButton type={MARK_STRIKETHROUGH} />
      <MarkToolbarButton type={MARK_UNDERLINE} />
      <MarkToolbarButton type={MARK_ITALIC} />
      <ListToolbarButton type={ELEMENT_OL} />
      <ListToolbarButton type={ELEMENT_UL} />
      <BlockToolbarButton type={ELEMENT_BLOCKQUOTE} />
      <CodeBlockToolbarButton />
      <LinkToolbarButton />
    </ToolbarRoot>
  );
};

export default Toolbar;
