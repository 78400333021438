import React from 'react';

import styled from '@emotion/styled';
import { Badge, message } from 'antd';

import { Popover } from 'src/components/Popover';
import { Message as ShareIcon } from 'src/components/Icons';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { fetchDetails, remove, selectCount, selectList } from 'src/pages/Pack/details.slice';
import ShareModal from 'src/pages/components/Share/Modal';
import { useDialogContext } from 'src/components/Dialog';
import { Tips } from 'src/shared/constants';

import Title from './Title';
import List from './List';
import Footer from './Footer';

const IconRoot = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 18,
});

const TriggerRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  padding: 8,
  borderRadius: 10,
  '&:hover': {
    backgroundColor: theme.palette.background?.paper,
  },
}));

const Paper = styled('div')({
  width: 400,
});

const Share: React.FC = () => {
  const dispatch = useAppDispatch();
  const count = useAppSelector(selectCount);
  const list = useAppSelector(selectList('wait'));

  const { openDialog } = useDialogContext();

  React.useEffect(() => {
    dispatch(fetchDetails({ id: undefined }));
  }, [dispatch]);

  const [step, setStep] = React.useState<number>(0);

  const openModal = () => {
    if (list.length === 0) {
      openDialog(Tips.pack.share);
    } else {
      setStep(1);
    }
  };

  const handleChange = (step: number) => {
    setStep(step);
  };

  const handleClear = () => {
    const { variant, content } = Tips.pack.clear;
    openDialog({
      variant,
      content,
      onOk: async () => {
        const resultAction = await dispatch(remove());
        if (remove.fulfilled.match(resultAction)) {
          message.success('已清空');
        } else {
          message.error(resultAction.payload);
        }
      },
    });
  };

  const Trigger = (
    <TriggerRoot>
      <Badge count={count}>
        <IconRoot>
          <ShareIcon />
        </IconRoot>
      </Badge>
    </TriggerRoot>
  );

  return (
    <React.Fragment>
      <Popover button={Trigger} trigger="click">
        <Paper>
          <Title onClear={handleClear} />
          <List />
          <Footer onClick={openModal} />
        </Paper>
      </Popover>
      <ShareModal step={step} onStepChange={handleChange} type="pack" />
    </React.Fragment>
  );
};

export default Share;
