import React from 'react';
import styled from '@emotion/styled';

const PREFIX = 'Review-Details--Reason';

const classNames = {
  root: `${PREFIX}__root`,
};

const ReasonRoot = styled('div')({
  [`&.${classNames.root}`]: {
    position: 'relative',
    border: '1px solid #eee',
    borderRadius: 4,
    minHeight: 100,
    padding: 6,
  },
});

const Reason: React.FC = ({ children }) => {
  return <ReasonRoot className={classNames.root}>{children}</ReasonRoot>;
};

export default Reason;
