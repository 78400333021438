import styled from '@emotion/styled';

const classNamePrefix = 'ModalTitle';

const ModalTitle = styled('div', { label: classNamePrefix })(({ theme }) => ({
  ...theme.typography.h6,
  paddingTop: 40,
  paddingLeft: 60,
  paddingRight: 60,
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
}));

export default ModalTitle;
