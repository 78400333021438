import {
  ELEMENT_BLOCKQUOTE,
  ELEMENT_CODE_BLOCK,
  ELEMENT_CODE_LINE,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_LI,
  ELEMENT_LIC,
  ELEMENT_LINK,
  ELEMENT_OL,
  ELEMENT_PARAGRAPH,
  ELEMENT_UL,
} from '@udecode/plate';
import { jsx } from 'slate-hyperscript';
import escapeHtml from 'escape-html';
import { Text, Node } from 'slate';
import { getInlineStyles } from 'src/shared/styles/richText';

const styles = {
  codeBlock:
    'white-space: pre-wrap;' +
    'padding-top: 0.75rem;' +
    'padding-bottom: 0.75rem;' +
    'padding-left: 1rem;' +
    'padding-right: 1rem;' +
    'font-size: 16px;' +
    "font-family: SFMono-Regular,Consolas,Monaco,'Liberation Mono',Menlo,Courier,monospace;" +
    'tab-size: 2;' +
    'line-height: 1.5;' +
    'border-radius: 3px;' +
    'background-color: rgb(247,246,243);',
  paragraph:
    'margin: 0px;' +
    'padding-top: 0.25rem;' +
    'padding-bottom: 0.25rem;' +
    'padding-left: 0px;' +
    'padding-right: 0px;',
  blockquote:
    'margin-top: 0.5rem;' +
    'margin-bottom: 0.5rem;' +
    'margin-left: 0px;' +
    'margin-right: 0px;' +
    'border-left: 2px solid #ddd;' +
    'padding: 10px 20px 10px 16px;' +
    'color: #aaa;',
  list: 'margin: 0; padding-inline-start: 24px;',
  h1: 'margin: 2em 0 4px;' + 'font-size: 1.875em;' + 'font-weight: 500;' + 'line-height: 1.3;',
  h2: 'margin: 1.4em 0 1px;' + 'font-size: 1.5em;' + 'font-weight: 500;' + 'line-height: 1.3;',
  h3:
    'margin: 1em 0 1px;' +
    'font-size: 1.25em;' +
    'font-weight: 500;' +
    'line-height: 1.3;' +
    'color: #434343;',
  h4:
    'margin: 0.75em 0 0;' +
    'font-size: 1.1em;' +
    'font-weight: 500;' +
    'line-height: 1.3;' +
    'color: #666;',
  h5:
    'margin: 0.75em 0 0;' +
    'font-size: 1.1em;' +
    'font-weight: 500;' +
    'line-height: 1.3;' +
    'color: #666;',
  h6:
    'margin: 0.75em 0 0;' +
    'font-size: 1.1em;' +
    'font-weight: 500;' +
    'line-height: 1.3;' +
    'color: #666;',
};

export function parseHtmlDocument(html: string) {
  return new DOMParser().parseFromString(html, 'text/html');
}

/**
 * 反序列化
 * @param el
 * @returns
 */
export function deserialize(el: any) {
  // console.dir(el);
  // console.dir(`element: ${el} ---> nodeType: ${el.nodeType}`);
  if (el.nodeType === 3) {
    return el.textContent;
  } else if (el.nodeType !== 1) {
    return null;
  }

  let children: any = Array.from(el.childNodes).map((node) => deserialize(node));

  if (children.length === 0) {
    children = [{ text: '' }];
  }

  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children);
    case 'BR':
      return '\n';
    case 'BLOCKQUOTE':
      return jsx('element', { type: ELEMENT_BLOCKQUOTE }, children);
    case 'P':
      return jsx('element', { type: ELEMENT_PARAGRAPH }, children);
    case 'A':
      return jsx('element', { type: ELEMENT_LINK, url: el.getAttribute('href') }, children);
    case 'H1': {
      return jsx('element', { type: ELEMENT_H1 }, children);
    }
    case 'H2': {
      return jsx('element', { type: ELEMENT_H2 }, children);
    }
    case 'H3': {
      return jsx('element', { type: ELEMENT_H3 }, children);
    }
    case 'H4': {
      return jsx('element', { type: ELEMENT_H4 }, children);
    }
    case 'H5': {
      return jsx('element', { type: ELEMENT_H5 }, children);
    }
    case 'H6': {
      return jsx('element', { type: ELEMENT_H6 }, children);
    }
    case 'OL': {
      return jsx('element', { type: ELEMENT_OL }, children);
    }
    case 'UL': {
      return jsx('element', { type: ELEMENT_UL }, children);
    }
    case 'LI': {
      return jsx('element', { type: ELEMENT_LI }, jsx('element', { type: ELEMENT_LIC }, children));
    }
    case 'PRE': {
      if (el.firstChild.nodeName === 'CODE') {
        return jsx('element', { type: ELEMENT_CODE_BLOCK }, children);
      }
      return children;
    }
    case 'CODE': {
      return children;
    }
    case 'DIV': {
      return jsx('element', { type: ELEMENT_CODE_LINE }, children);
    }
    case 'STRONG':
      return jsx('text', { bold: true }, children);
    case 'S':
      return jsx('text', { strikethrough: true }, children);
    case 'U':
      return jsx('text', { underline: true }, children);
    case 'EM':
      return jsx('text', { italic: true }, children);
    default:
      return el.textContent;
  }
}

/**
 * 序列化
 * @param node
 * @returns
 */
export function serialize(node: any) {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if ((node as any).bold) {
      string = `<strong>${string}</strong>`;
    }
    if ((node as any).strikethrough) {
      string = `<s>${string}</s>`;
    }
    if ((node as any).underline) {
      string = `<u>${string}</u>`;
    }
    if ((node as any).italic) {
      string = `<em>${string}</em>`;
    }
    return string.replace(/\n/g, '<br />');
  }

  const children = node.children?.map((n: any) => serialize(n)).join('');

  switch (node.type) {
    case ELEMENT_BLOCKQUOTE:
      return `<blockquote>${children}</blockquote>`;
    case ELEMENT_PARAGRAPH:
      return `<p>${children}</p>`;
    case ELEMENT_LINK:
      return `<a href="${escapeHtml(node.url)}">${children}</a>`;
    case ELEMENT_OL:
      return `<ol>${children}</ol>`;
    case ELEMENT_UL:
      return `<ul>${children}</ul>`;
    case ELEMENT_LI:
      return `<li>${children}</li>`;
    case ELEMENT_CODE_BLOCK:
      return `<pre><code>${children}</code></pre>`;
    case ELEMENT_CODE_LINE: {
      return `<div class="element_code_line">${children}</div>`;
    }
    case ELEMENT_H1:
      return `<h1>${children}</h1>`;
    case ELEMENT_H2:
      return `<h2>${children}</h2>`;
    case ELEMENT_H3:
      return `<h3>${children}</h3>`;
    case ELEMENT_H4:
      return `<h4>${children}</h4>`;
    case ELEMENT_H5:
      return `<h5>${children}</h5>`;
    case ELEMENT_H6:
      return `<h6>${children}</h6>`;
    default:
      return children;
  }
}

export function getFormattedNode(node: any[]) {
  return node.reduce((acc, item) => {
    const temp = { ...item };
    if ('text' in temp && temp.text === undefined) {
      temp.text = '';
    }
    if (temp.children) {
      temp.children = getFormattedNode(temp.children);
    }

    return [...acc, temp];
  }, []);
}

/**
 * 获取纯文本
 * @param node
 * @returns
 */
export function getPlainText(node: any[] = [{ type: 'p', children: [{ text: '' }] }]): string {
  return Node.string({ children: getFormattedNode(node) });
}
