import {
  RESET,
  DESELECT_ALL,
  DESELECT_DEPARTMENT,
  DESELECT_MEMBER,
  FETCH_DEPARTMENTS_FULFILLED,
  FETCH_DEPARTMENTS_PENDING,
  FETCH_DEPARTMENTS_REJECTED,
  FETCH_MEMBERS_FULFILLED,
  FETCH_MEMBERS_PENDING,
  FETCH_MEMBERS_REJECTED,
  SELECT_ALL,
  SELECT_DEPARTMENT,
  SELECT_MEMBER,
  SHOW_DEPARTMENTS,
  SHOW_MEMBERS,
  REMOVE,
  CLEAR,
  SEARCH_PENDING,
  SEARCH_FULFILLED,
  SEARCH_REJECTED,
  SET_SEARCH,
} from './constants';
import { State, Actions } from '../interface';
import initialState from './state';
import {
  getCheckedMembersId,
  getChildrenOfDepartment,
  getDepartmentById,
} from '../utils';

export default function reducer(state: State, action: Actions): State {
  switch (action.type) {
    case SHOW_MEMBERS: {
      let next = { ...state.current };
      const { departmentId, subdepartmentId } = action.payload;
      const department = state.list.department.find(
        (item) => item.id === departmentId
      );
      if (!department) {
        next = { department: null, subdepartment: null };
      } else {
        next = { department: departmentId, subdepartment: subdepartmentId };
      }
      return {
        ...state,
        view: 'member',
        current: { ...next },
      };
    }
    case SHOW_DEPARTMENTS: {
      return {
        ...state,
        current: { department: null, subdepartment: null },
        view: 'department',
      };
    }
    case FETCH_DEPARTMENTS_PENDING: {
      return {
        ...state,
        loading: { ...state.loading, department: true },
      };
    }
    case FETCH_DEPARTMENTS_FULFILLED: {
      return {
        ...state,
        loading: { ...state.loading, department: false },
        list: { ...state.list, department: action.payload },
      };
    }
    case FETCH_DEPARTMENTS_REJECTED: {
      return {
        ...state,
        loading: { ...state.loading, department: false },
        list: { ...state.list, department: [] },
      };
    }
    case FETCH_MEMBERS_PENDING: {
      return {
        ...state,
        loading: { ...state.loading, member: true },
      };
    }
    case FETCH_MEMBERS_FULFILLED: {
      return {
        ...state,
        loading: { ...state.loading, member: false },
        list: { ...state.list, member: action.payload },
      };
    }

    case FETCH_MEMBERS_REJECTED: {
      return {
        ...state,
        loading: { ...state.loading, member: false },
        list: { ...state.list, member: [] },
      };
    }
    case SELECT_MEMBER: {
      let next = [...state.selected];
      const list =
        state.view === 'member'
          ? [...state.list.member]
          : [...state.search.result];

      const member = list.find((item) => item.id === action.payload);

      if (member) {
        next = [...next, member];
      }

      return {
        ...state,
        selected: [...next],
      };
    }
    case DESELECT_MEMBER: {
      const next = state.selected.filter((item) => item.id !== action.payload);
      return {
        ...state,
        selected: [...next],
      };
    }
    case SELECT_ALL: {
      const checkedMemberIds = getCheckedMembersId(state.selected);
      // 差集
      const subtraction = state.list.member.filter(
        (item) => !checkedMemberIds.includes(item.id)
      );
      const next = [...state.selected, ...subtraction];
      return {
        ...state,
        selected: [...next],
      };
    }
    case DESELECT_ALL: {
      const membersId = state.list.member.map((item) => item.id);
      const next = state.selected
        .filter((item) => item.type === 'member')
        .filter((item) => !membersId.includes(item.id));
      return {
        ...state,
        selected: [...next],
      };
    }
    case SELECT_DEPARTMENT: {
      const department = getDepartmentById(action.payload);
      if (department) {
        const children = getChildrenOfDepartment(department);
        const childrenId = children.map((item) => item.id);
        // 过滤子级部门及子级部门中选中人员
        const next = state.selected.filter((item) => {
          if (item.type === 'member') {
            return !item.parents.includes(department.id);
          } else {
            return !childrenId.includes(item.id);
          }
        });
        return {
          ...state,
          selected: [...next, department],
        };
      }
      return state;
    }
    case DESELECT_DEPARTMENT: {
      const next = state.selected.filter((item) => {
        if (item.type === 'department') {
          return item.id !== action.payload;
        }
        return true;
      });
      return {
        ...state,
        selected: [...next],
      };
    }
    case REMOVE: {
      const next = state.selected.filter(
        (item) =>
          !(item.type === action.payload.type && item.id === action.payload.id)
      );

      return {
        ...state,
        selected: [...next],
      };
    }
    case SET_SEARCH: {
      return {
        ...state,
        search: { ...state.search, ...action.payload },
      };
    }
    case SEARCH_PENDING: {
      return {
        ...state,
        view: 'search',
        loading: { ...state.loading, search: true },
      };
    }
    case SEARCH_FULFILLED: {
      return {
        ...state,
        loading: { ...state.loading, search: false },
        search: { ...state.search, result: action.payload },
      };
    }
    case SEARCH_REJECTED: {
      return {
        ...state,
        loading: { ...state.loading, search: false },
        search: { ...state.search, result: [] },
      };
    }
    case CLEAR: {
      return {
        ...state,
        selected: [],
      };
    }
    case RESET: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
