import React, { useMemo } from 'react';
import styled from '@emotion/styled';

import Checkbox from '../../common/Checkbox';
import { useMemberDispatch, useMemberSelector } from '../../Provider';
import {
  selectCurrentSubdepartment,
  selectIfCanSelectAll,
  selectLoading,
  selectSelectedList,
  selectStatusOfSelectAll,
} from '../../reducers/selectors';
import {
  deselectAll,
  deselectDepartment,
  selectAll,
  selectDepartment,
} from '../../reducers/actions';
import { isDepartmentChecked, isDepartmentDisabled } from '../../utils';

const ToolbarRoot = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: 20,
});

const StyledCheckbox = styled(Checkbox)({
  marginRight: 0,

  '& .ant-checkbox + span': {
    paddingRight: 0,
  },
});

const Toolbar: React.FC = () => {
  const dispatch = useMemberDispatch();

  const status = useMemberSelector(selectStatusOfSelectAll);
  const subdepartment = useMemberSelector(selectCurrentSubdepartment);
  const selectedList = useMemberSelector(selectSelectedList);
  const canSelectAll = useMemberSelector(selectIfCanSelectAll);
  const loading = useMemberSelector(selectLoading('member'));
  const { department } = useMemberSelector((state) => state.current);

  // TODO useMemo 必要吗？
  const departmentChecked = useMemo<boolean>(() => {
    if (subdepartment) {
      return isDepartmentChecked(subdepartment, selectedList);
    }
    return false;
  }, [selectedList, subdepartment]);

  const departmentDisabled = useMemo<boolean>(() => {
    if (subdepartment) {
      return isDepartmentDisabled(subdepartment, selectedList);
    }
    return false;
  }, [selectedList, subdepartment]);

  const handleSelectAll = (event: any) => {
    const { checked } = event.target;
    if (checked) {
      dispatch(selectAll());
    } else {
      dispatch(deselectAll());
    }
  };

  const handleSelectDepartment = (event: any) => {
    const { checked } = event.target;
    if (subdepartment) {
      if (checked) {
        dispatch(selectDepartment(subdepartment.id));
      } else {
        dispatch(deselectDepartment(subdepartment.id));
      }
    }
  };
  return (
    <ToolbarRoot>
      <div>
        {canSelectAll && !loading && (
          <StyledCheckbox
            indeterminate={status === 'indeterminate'}
            checked={status === 'checked'}
            onChange={handleSelectAll}
            disabled={departmentChecked}
          >
            全选
          </StyledCheckbox>
        )}
      </div>
      {department !== null && department !== 0 && (
        <div>
          <StyledCheckbox
            checked={departmentChecked}
            onChange={handleSelectDepartment}
            disabled={departmentDisabled}
          >
            添加部门
          </StyledCheckbox>
        </div>
      )}
    </ToolbarRoot>
  );
};

export default Toolbar;
