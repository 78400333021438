import React from 'react';
import styled from '@emotion/styled';
import { Outlet } from 'react-router-dom';

import { SIDEBAR_WIDTH } from 'src/shared/constants';

import Content from './Content';
import AppBar from './AppBar';
import SideBar from './SideBar';
import MenuProvider from './Provider';

export type MenuItem<T = any> = {
  path: string;
  name: string;
  icon?: JSX.Element;
  guard?: (args: T) => boolean;
};

const StyledContent = styled(Content)(({ theme }) => ({
  '& .outlet': {
    transition: theme.transitions.create(['margin-left'], {
      duration: theme.transitions.duration.shortest,
    }),
  },
  '& .side-bar+.outlet': {
    [theme.breakpoints.up('xs')]: {
      marginLeft: SIDEBAR_WIDTH['xs'],
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: SIDEBAR_WIDTH['md'],
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: SIDEBAR_WIDTH['lg'],
    },
  },
  '& .side-bar.MainLayout-SideBar__collapsed+.outlet': {
    marginLeft: 56,
  },
}));

export interface MainLayoutProps {
  menus?: MenuItem[];
  disableHeaderGutter?: boolean;
  extra?: JSX.Element;
}

const MainLayout: React.FC<MainLayoutProps> = (props) => {
  const { menus = [], disableHeaderGutter = false, extra } = props;
  return (
    <MenuProvider menus={menus} disableHeaderGutter={disableHeaderGutter} extra={extra}>
      <StyledContent>
        <SideBar className="side-bar" />
        <div className="outlet">
          <Outlet />
        </div>
      </StyledContent>
      <AppBar />
    </MenuProvider>
  );
};

export default MainLayout;
