import BaseRadio, { RadioProps as BaseRadioPropsWithoutVariant } from './Radio';
import OutlinedRadio, {
  OutlinedRadioProps as OutlinedRadioPropsWithoutVariant,
} from './OutlinedRadio';
import FilledRadio, {
  FilledRadioProps as FilledRadioPropWithoutVariant,
} from './FilledRadio';

interface BaseProps {
  variant?: 'standard' | 'outlined' | 'filled';
}

interface StandardRadioProps extends BaseRadioPropsWithoutVariant, BaseProps {
  variant?: 'standard';
}

interface OutlinedRadioProps
  extends OutlinedRadioPropsWithoutVariant,
    BaseProps {
  variant?: 'outlined';
}

interface FilledRadioProps extends FilledRadioPropWithoutVariant, BaseProps {
  variant?: 'filled';
}

const variantComponents = {
  standard: BaseRadio,
  outlined: OutlinedRadio,
  filled: FilledRadio,
};

export default function Radio(
  props: React.PropsWithChildren<
    StandardRadioProps | OutlinedRadioProps | FilledRadioProps
  >
) {
  const { variant = 'standard', ...rest } = props;

  const RadioComponent = variantComponents[variant];

  return <RadioComponent {...rest} />;
}
