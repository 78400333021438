import React from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';
import { shouldForwardProp } from 'src/utils';

const PREFIX = 'Container';

const classNames = {
  root: `${PREFIX}__root`,
};

const ContainerRoot = styled('div', {
  shouldForwardProp: (prop) => shouldForwardProp(prop) && prop !== 'disableGutter',
})<{ disableGutter: boolean }>(({ disableGutter }) => ({
  [`&.${classNames.root}`]: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    boxSizing: 'border-box',
    display: 'block',
    paddingLeft: 24,
    paddingRight: 24,
    ...(!disableGutter && { paddingTop: 64, paddingBottom: 64 }),
  },
}));

export interface ContainerProps {
  className?: string;
  disableGutter?: boolean;
}

const Container: React.FC<ContainerProps> = ({ className, children, disableGutter = false }) => {
  return (
    <ContainerRoot disableGutter={disableGutter} className={clsx(classNames.root, className)}>
      {children}
    </ContainerRoot>
  );
};

export default Container;
