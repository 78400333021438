import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { Breadcrumbs as BreadcrumbsComponent } from 'src/components/Breadcrumbs';
import { Typography } from 'src/components/Typography';
import { useAppSelector } from 'src/hooks';

const PREFIX = 'MainLayout-AppBar--Breadcrumbs';

const classNames = {
  root: `${PREFIX}__root`,
};

const BreadcrumbsRoot = styled('div')({
  [`&.${classNames.root}`]: {
    position: 'relative',
    display: 'flex',
    overflow: 'hidden',
    width: 'fit-content',
  },
});

export interface BreadcrumbsProps {
  className?: string | undefined;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ className }) => {
  const breadcrumbs = useAppSelector((state) => state.breadcrumbs.items);

  return (
    <BreadcrumbsRoot className={clsx(classNames.root, className)}>
      <BreadcrumbsComponent>
        {breadcrumbs.map((item, index) => {
          const isLast = index === breadcrumbs.length - 1;
          if (isLast) {
            return (
              <Typography
                style={{ flex: '0 1 auto' }}
                noWrap
                color="textPrimary"
                key={item.path || index}
              >
                {item.label}
              </Typography>
            );
          }
          return (
            <Link to={item.path} key={item.path}>
              <Typography style={{ flex: '0 1 auto' }} noWrap color="textSecondary">
                {item.label}
              </Typography>
            </Link>
          );
        })}
      </BreadcrumbsComponent>
    </BreadcrumbsRoot>
  );
};

export default Breadcrumbs;
