import React from 'react';
import {
  usePlateEditorState,
  ELEMENT_OL,
  ELEMENT_UL,
  toggleList,
  getListItemEntry,
} from '@udecode/plate';

import ToolbarButton from '../ToolbarButton';
import { ListOrdered as OL, ListUnordered as UL } from '../../icons';

interface ListToolbarButtonProps {
  type?: typeof ELEMENT_OL | typeof ELEMENT_UL;
}

const Icons = {
  [ELEMENT_OL]: OL,
  [ELEMENT_UL]: UL,
};

const ListToolbarButton: React.FC<ListToolbarButtonProps> = (props) => {
  const { type = ELEMENT_UL } = props;
  const Icon = Icons[type];
  const editor = usePlateEditorState();

  const res = !!editor?.selection && getListItemEntry(editor);

  const toggleListElement = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (editor) {
      toggleList(editor, { type });
    }
  };

  return (
    <ToolbarButton onMouseDown={toggleListElement} active={!!res && res.list[0].type === type}>
      <Icon color="inherit" />
    </ToolbarButton>
  );
};

export default ListToolbarButton;
