import React from 'react';
import { useField } from 'formik';

import Select, { SelectProps } from 'src/components/Fields/Select';

interface AuthFieldProps extends SelectProps<string> {}

const AuthField: React.FC<AuthFieldProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, _, helper] = useField('authType');

  const handleChange = (value: any) => {
    helper.setValue(value);
  };

  return (
    <Select
      allowClear={false}
      showSearch={false}
      value={field.value}
      onChange={handleChange}
      placeholder="请选择分享权限"
      labelInValue={false}
      {...props}
    />
  );
};

export default AuthField;
