import { createContext } from 'react';

import { noop } from 'src/utils';

export type DialogInstance = {
  title?: React.ReactNode;
  content?: React.ReactNode;
  onOk?: (() => Promise<any>) | (() => void);
  onCancel?: () => void;
  showActions?: boolean;
  variant?: 'default' | 'warning' | 'error' | 'success' | 'info';
  type?: 'confirm' | 'tip';
};

type Context = {
  openDialog: (instance: DialogInstance) => void;
  closeDialog: () => void;
};

const DialogContext = createContext<Context>({
  openDialog: noop,
  closeDialog: noop,
});

export default DialogContext;
