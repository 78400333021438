import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from '@emotion/react';

import { useMediaQuery } from 'src/hooks';

import LayoutContext, { Context } from './LayoutContext';

let CACHED_COLLAPSED: boolean | null = null;

function getInitialCollapsed(collapsed: boolean) {
  if (CACHED_COLLAPSED === null) {
    return collapsed;
  }

  return CACHED_COLLAPSED;
}

interface ProviderProps {
  menus?: Context['menus'];
  extra?: JSX.Element;
  disableHeaderGutter?: boolean;
}

function MenuProvider(props: React.PropsWithChildren<ProviderProps>) {
  const { menus = [], children, disableHeaderGutter = false, extra } = props;
  const theme = useTheme();
  const isMiddleScreen = useMediaQuery(theme.breakpoints.down('md').replace('@media ', ''));
  const [collapsed, setCollapsed] = useState<boolean>(getInitialCollapsed(isMiddleScreen));
  const mounted = useRef<boolean>(false);

  useEffect(() => {
    CACHED_COLLAPSED = collapsed;
  }, [collapsed]);

  useEffect(() => {
    if (mounted.current) {
      setCollapsed(isMiddleScreen);
    }
  }, [isMiddleScreen]);

  useEffect(() => {
    if (!mounted.current) {
      if (CACHED_COLLAPSED !== null) {
        setCollapsed(CACHED_COLLAPSED);
      }
      mounted.current = true;
    }
    return () => {
      mounted.current = false;
    };
  }, []);

  const childProps = useMemo<Context>(
    () => ({
      menus,
      extra,
      collapsed,
      toggle: setCollapsed,
      disableHeaderGutter,
    }),
    [collapsed, disableHeaderGutter, extra, menus]
  );

  return <LayoutContext.Provider value={childProps}>{children}</LayoutContext.Provider>;
}

export default MenuProvider;
