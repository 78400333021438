import React from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';

import { composeClasses, shouldForwardProp, createChainedFunction } from 'src/utils';
import { useEventCallback, useForkRef } from 'src/hooks';

import { Backdrop, BackdropProps } from '../Backdrop';
import { Portal, PortalProps } from '../Portal';
import ModalManager from './ModalManager';
import { useTheme } from '@emotion/react';

const classNamePrefix = 'unstable_Modal';

function getContainer(container: any) {
  return typeof container === 'function' ? container() : container;
}

const useUtilityClasses = (props: { center?: boolean }) => {
  const { center } = props;
  const slots = {
    root: ['root', center && 'center'],
    backdrop: ['backdrop'],
  };
  return composeClasses(classNamePrefix, slots);
};

const ModalRoot = styled('div', {
  shouldForwardProp: (prop) =>
    shouldForwardProp(prop) && prop !== 'open' && prop !== 'exited' && prop !== 'center',
})<{ open?: boolean; exited?: boolean; center?: boolean }>(
  ({ open = false, exited = false, center = false }) => ({
    position: 'fixed',
    zIndex: 1300,
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    outline: 'none',
    ...(center && {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    ...(!open &&
      exited && {
        visibility: 'hidden',
      }),
  })
);

const ModalBackdrop = styled(Backdrop)({ zIndex: -1 });

export interface ModalProps {
  open: boolean;
  onClose?:
    | ((
        event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
        reason: 'backdropClick' | 'escapeKeyDown'
      ) => void)
    | undefined;
  onBackdropClick?: React.MouseEventHandler<HTMLDivElement>;
  hideBackdrop?: boolean;
  container?: PortalProps['container'];
  disablePortal?: PortalProps['disablePortal'];
  children?: React.ReactElement;
  className?: string | undefined;
  style?: React.CSSProperties;
  BackdropProps?: BackdropProps;
  center?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const modalManager = new ModalManager();

/**
 * TODO Auto trap focus component like material-ui
 */
const Modal = React.forwardRef<HTMLDivElement, ModalProps>(function Modal(props, ref) {
  const {
    open,
    container,
    onClose,
    onBackdropClick,
    hideBackdrop = false,
    disablePortal = false,
    children,
    className,
    center = false,
    BackdropProps = { invisible: true },
    onClick = (event) => event.stopPropagation(),
    ...rest
  } = props;

  const theme = useTheme();

  const [exited, setExited] = React.useState<boolean>(true);
  const hasTransition = getHasTransition(props);
  const modal = React.useRef<any>({});
  const modalRef = React.useRef<HTMLDivElement>(null);
  const mountNodeRef = React.useRef<HTMLDivElement>();
  const handleRef = useForkRef(modalRef, ref);

  const classes = useUtilityClasses({ center });

  const getModal = () => {
    modal.current.modalRef = modalRef.current;
    modal.current.mountNode = mountNodeRef.current;
    return modal.current;
  };

  const isTopModal = React.useCallback(() => modalManager.isTopModal(getModal()), []);

  const handleMounted = () => {
    modalManager.mount(getModal());
  };

  const handleOpen = useEventCallback(() => {
    const resolvedContainer = getContainer(container) || document.body;
    modalManager.add(getModal(), resolvedContainer);
    if (modalRef.current) {
      handleMounted();
    }
  });

  const handleClose = React.useCallback(() => {
    modalManager.remove(getModal());
  }, []);

  const handlePortalRef = useEventCallback((node: HTMLDivElement) => {
    mountNodeRef.current = node;
    if (!node) return;

    if (open && isTopModal()) {
      handleMounted();
    }
  });

  React.useEffect(() => {
    if (open) {
      handleOpen();
    } else if (!hasTransition) {
      handleClose();
    }
  }, [open, hasTransition, handleOpen, handleClose]);

  React.useEffect(() => {
    if (modalRef.current && open && !exited) {
      modalRef.current.focus();
    }
  }, [open, exited]);

  if (!open && (!hasTransition || exited)) {
    return null;
  }

  const handleEnter = () => {
    setExited(false);
  };

  const handleExited = () => {
    handleClose();
    setExited(true);
  };

  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target !== event.currentTarget) {
      return;
    }

    if (onBackdropClick) {
      onBackdropClick(event);
    }

    if (onClose) {
      onClose(event, 'backdropClick');
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') {
      if (onClose) {
        onClose(event, 'escapeKeyDown');
      }
    }
  };

  const childProps: any = {
    timeout: theme.transitions.duration.enteringScreen,
  };
  if (React.isValidElement(children)) {
    if (hasTransition) {
      childProps.onEnter = createChainedFunction(handleEnter, children.props.onEnter);
      childProps.onExited = createChainedFunction(handleExited, children.props.onExited);
    }
  }

  return (
    <Portal ref={handlePortalRef} container={container} disablePortal={disablePortal}>
      <ModalRoot
        role="presentation"
        className={clsx(classes.root, className)}
        ref={handleRef}
        tabIndex={0}
        onKeyDown={handleKeyDown}
        open={open}
        exited={exited}
        center={center}
        onClick={onClick}
        {...rest}
      >
        {!hideBackdrop && (
          <ModalBackdrop open={open} onClick={handleBackdropClick} {...BackdropProps} />
        )}
        {React.isValidElement(children) && React.cloneElement(children, childProps)}
      </ModalRoot>
    </Portal>
  );
});

export default Modal;

function getHasTransition(props: ModalProps) {
  return props.children ? props.children.props.hasOwnProperty('in') : false;
}
