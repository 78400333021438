import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { AppState } from 'src/app/store';
import reviewService from 'src/services/review';
import { Loading } from 'src/shared/constants';
import { ID, Review } from 'src/shared/interfaces';
import { getErrorMessage } from 'src/utils';

const name = 'review' as const;

export interface State {
  baseId: ID | undefined;
  details: Review | undefined;
  loading: {
    list: Loading;
    details: Loading;
  };
  currentRequestId: string | undefined;
}

const initialState: State = {
  baseId: undefined,
  details: undefined,
  loading: {
    list: Loading.idle,
    details: Loading.idle,
  },
  currentRequestId: undefined,
};

/**
 * 审核详情
 */
export const fetchDetails = createAsyncThunk<Review, ID, { rejectValue: string }>(
  `${name}/fetchDetails`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await reviewService.fetchDetails(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  }
);

/**
 * 处理审核
 */
export const review = createAsyncThunk<string, { id: ID; status: 1 | 2 }, { rejectValue: string }>(
  `${name}/review`,
  async ({ id, status }, { rejectWithValue }) => {
    try {
      const response = await reviewService.resolve(id, status);
      return response.message;
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  }
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDetails.pending, (state, action) => {
      state.loading.details = Loading.pending;
      state.currentRequestId = action.meta.requestId;
    });
    builder.addCase(fetchDetails.fulfilled, (state, action) => {
      if (state.currentRequestId === action.meta.requestId) {
        state.loading.details = Loading.fulfilled;
        state.details = action.payload;
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(fetchDetails.rejected, (state, action) => {
      if (state.currentRequestId === action.meta.requestId) {
        state.loading.details = Loading.rejected;
        state.currentRequestId = undefined;
      }
    });
  },
});

export default slice.reducer;

/** Selectors */
export const selectLoading = createSelector(
  [
    (state: AppState) => state.review.loading,
    (state: AppState, type: keyof State['loading']) => type,
  ],
  (loading, type) => loading[type]
);
