import { httpClient } from 'src/utils';
import {
  Draft,
  HttpResponse,
  ID,
  ListHttpResponse,
  Page,
  PaginationParams,
} from 'src/shared/interfaces';
import { AxiosRequestConfig } from 'axios';

export type ListParams = { type?: 1 | 2; keyword: string } & PaginationParams;

export type SliceUploadData = {
  name: string;
  file: Blob;
  hash: string;
  blobNum: number; // 当前分片索引
  totalBlobNum: number; // 总分片块数
  partSize: number; // 分片大小
  totalSize: number; // 文件大小
};

class DraftService {
  /**
   * 草稿箱列表
   * @param param0
   * @returns
   */
  async getList({ type, page, limit, keyword }: Partial<ListParams>) {
    const response = await httpClient.get<ListHttpResponse<Draft>>(
      '/fileDraft',
      {
        params: { type, page, limit, keyword },
      }
    );
    return response.data;
  }

  /**
   * 获取知识页草稿
   * @param id
   */
  async fetchPage(id: ID) {
    const response = await httpClient.get<HttpResponse<Page>>(
      `/pageDraft/${id}`
    );
    return response.data;
  }

  /**
   * 新建知识页草稿
   * @param data
   */
  async createPage(data: Partial<Page & { pageId: ID }>) {
    const response = await httpClient.post<HttpResponse<Page>>(
      '/pageDraft',
      data
    );
    return response.data;
  }

  /**
   * 更新知识页草稿
   * @param id 草稿 id
   * @param data
   * @param isSubmit 保存并提交时，传 1
   */
  async updatePage(id: ID, data: Partial<Page>, isSubmit?: boolean) {
    const response = await httpClient.patch<HttpResponse<Page>>(
      `/pageDraft/${id}`,
      { ...data, isSubmit: isSubmit ? 1 : undefined }
    );
    return response.data;
  }

  /**
   * 删除知识页
   * @param id
   */
  async removePage(id: ID) {
    const response = await httpClient.delete<HttpResponse<{}>>(
      `/pageDraft/${id}`
    );
    return response.data;
  }

  /**
   * 删除文件
   * @param id
   * @returns
   */
  async removeFile(id: ID) {
    const response = await httpClient.delete<HttpResponse<{}>>(
      `/fileDraft/${id}`
    );
    return response.data;
  }

  /**
   * 上传文件
   * @param data
   * @param config
   * @returns
   */
  async uploadFile(
    data: {
      file: File;
      baseId: ID;
      baseFolderId: ID;
      fileHash: string;
      resourceDirectoryId?: ID | undefined;
    },
    config: AxiosRequestConfig
  ) {
    const response = await httpClient.post<ListHttpResponse<Draft>>(
      '/fileDraft',
      data,
      {
        headers: { 'Content-Type': 'multiple/form-data' },
        timeout: 0,
        ...config,
      }
    );
    return response.data;
  }

  // 分片上传
  async sliceUpload(data: SliceUploadData, config: AxiosRequestConfig) {
    const requestConfig: AxiosRequestConfig = {
      headers: { 'Content-Type': 'multiple/form-data' },
      timeout: 0,
      ...config,
    };

    const response = await httpClient.post<HttpResponse<any>>(
      '/slice/upload',
      data,
      requestConfig
    );

    return response.data;
  }

  // 撤回
  async withdraw(id: any) {
    const response = await httpClient.patch<HttpResponse<{}>>(
      `/pageDraft/${id}`,
      { unSubmit: 1 }
    );
    return response.data;
  }
}

const draftService = new DraftService();

export default draftService;
