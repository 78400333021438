import React, { useContext } from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';
import { Divider } from 'antd';

import { CONTENT_WIDTH } from 'src/shared/constants';
import Entry from './Entry';

import { LayoutContext } from '../Provider';
import Logo from './Logo';
import Breadcrumbs from './Breadcrumbs';
import Search from './Search';
import Favorites from './Favorites';
import Share from './Share';
import Notification from './Notification';
import Account from './Account';
import History from './History';
import { shouldForwardProp } from 'src/utils';

const PREFIX = 'MainLayout-AppBar';

const classNames = {
  root: `${PREFIX}__root`,
  toolbar: `${PREFIX}__toolbar`,
  gutter: `${PREFIX}__gutter`,
  expand: `${PREFIX}__expand`,
  divider: `${PREFIX}__divider`,
};

type StyledProps = {
  disableGutter?: boolean;
};

const AppBarRoot = styled('header', {
  shouldForwardProp: (prop) => shouldForwardProp(prop) && prop !== 'disableGutter',
})<StyledProps>(({ theme, disableGutter = false }) => ({
  [`&.${classNames.root}`]: {
    backgroundImage: 'none',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    boxSizing: 'border-box',
    flexShrink: 0,
    position: 'fixed',
    zIndex: 1201,
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    boxShadow: '0px -6px 16px 0px rgba(0, 0, 0, 0.1)',
  },
  [`& .${classNames.toolbar}`]: {
    minHeight: 64,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    left: 0,
    paddingLeft: 16,
    paddingRight: 16,
    marginLeft: 'auto',
    marginRight: 'auto',
    ...(disableGutter && { width: '100%' }),
    ...(!disableGutter && {
      [theme.breakpoints.up('xs')]: {
        width: CONTENT_WIDTH['xs'],
      },
      [theme.breakpoints.up('md')]: {
        width: CONTENT_WIDTH['md'],
      },
      [theme.breakpoints.up('lg')]: {
        width: CONTENT_WIDTH['lg'],
      },
      [theme.breakpoints.up('xl')]: {
        width: CONTENT_WIDTH['xl'],
      },
    }),
  },
  [`& .${classNames.gutter}`]: {
    width: 30,
    [theme.breakpoints.down('md')]: {
      width: 16,
    },
  },
  [`& .${classNames.expand}`]: {
    flex: 1,
  },
  [`& .${classNames.divider}`]: {
    height: 45,
    marginLeft: 30,
    marginRight: 30,
    [theme.breakpoints.down('md')]: {
      marginLeft: 16,
      marginRight: 16,
    },
  },
}));

export interface AppBarProps {
  className?: string | undefined;
}

const AppBar: React.FC<AppBarProps> = ({ className }) => {
  const { disableHeaderGutter: disableGutter } = useContext(LayoutContext);

  return (
    <AppBarRoot
      className={clsx(classNames.root, 'kwb-fixed', className)}
      disableGutter={disableGutter}
    >
      <div className={classNames.toolbar}>
        <Logo />
        <div className={classNames.gutter} />
        <Breadcrumbs />
        <div className={classNames.expand} />
        <Search />
        <div className={classNames.gutter} />
        <Entry />
        <div className={classNames.gutter} />
        <Favorites />
        <Divider type="vertical" className={classNames.divider} />
        <Share />
        <div className={classNames.gutter} />
        <History />
        <div className={classNames.gutter} />
        <Notification />
        <div className={classNames.gutter} />
        <Account />
      </div>
    </AppBarRoot>
  );
};

export default AppBar;
