import { withProps, StyledElement } from '@udecode/plate';

import { CSSProp } from 'styled-components';
import styles from 'src/shared/styles/richText';

const H2 = withProps(StyledElement, {
  as: 'h2',
  styles: {
    root: styles.h2 as CSSProp,
  },
});

export default H2;
