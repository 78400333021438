import { ResourceType } from 'src/shared/constants';
import { template } from './index';

export const ResourceLinkTemplate: Record<ResourceType, ReturnType<typeof template>> = {
  [ResourceType.repository]: template`/repository/${'id'}`,
  [ResourceType.folder]: template`/${'baseURL'}/${'id'}`,
  [ResourceType.resourcePack]: template`/share`,
  [ResourceType.paper]: template`/book/${'id'}`,
  [ResourceType.file]: template`/folder/${'id'}`,
  [ResourceType.subfolder]: template`/folder/${'id'}?pageId=${'subfolderId'}`,
  [ResourceType.question]: template`/repository/${'id'}/questions/${'qId'}`,
  [ResourceType.answer]: template`/repository/${'id'}/questions/${'qId'}?answer_id=${'aId'}`,
};

export function getResourceLink(
  resource: ResourceType,
  params: Record<string, any>,
  query?: Record<string, any> | undefined
) {
  const templated = ResourceLinkTemplate[resource];
  const href = templated(params);
  if (query) {
    const search = new URLSearchParams(query);
    return href + href.includes('?') ? `&${search.toString()}` : `?${search.toString()}`;
  }

  return href;
}
