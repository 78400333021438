import { withProps, StyledElement } from '@udecode/plate';

import { CSSProp } from 'styled-components';
import styles from 'src/shared/styles/richText';

const H5 = withProps(StyledElement, {
  as: 'h5',
  styles: {
    root: styles.h5 as CSSProp,
  },
});

export default H5;
