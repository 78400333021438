import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
} from '@reduxjs/toolkit';

import { AppState } from 'src/app/store';
import adminService, { IHistory, IHistoryParams } from 'src/services/admin';
import { Loading } from 'src/shared/constants';
import { HttpResponse, ID, ListData } from 'src/shared/interfaces';
import { getErrorMessage } from 'src/utils';

// 操作记录
export const getHistory = createAsyncThunk<
  ListData<IHistory>,
  Partial<IHistoryParams>
>('template/getHistory', async (data, { rejectWithValue }) => {
  try {
    const response = await adminService.getHistory(data);
    return response.data;
  } catch (err) {
    const message = getErrorMessage(err);
    return rejectWithValue(message);
  }
});
type ExportArgs = { id: ID } & Partial<IHistoryParams>;
// 导出
export const exportHistory = createAsyncThunk<HttpResponse<{}>, ExportArgs>(
  'template/exportHistory',
  async ({ id, ...data }, { rejectWithValue }) => {
    try {
      const response = await adminService.exportHistory(id, data);
      return response;
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);
export const fileDownload = createAsyncThunk<void, ID>(
  'template/fileDownload',
  async (id, { rejectWithValue }) => {
    try {
      await adminService.fileDownload(id);
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

const historyAdapter = createEntityAdapter<IHistory>({
  selectId: (history) => history.id,
});
type IState = {
  loading: Loading;
  total: number;
  totalPages: number;
  page: number;
  userName: string;
  resourceName: string;
  type: string;
  startTime: string;
  endTime: string;
  sort: string;
};
export const historySlice = createSlice({
  name: 'baseTemplate',
  initialState: {
    historyList: historyAdapter.getInitialState<IState>({
      loading: Loading.idle,
      total: 0,
      totalPages: 0,
      page: 1,
      userName: '',
      resourceName: '',
      type: '',
      startTime: '',
      endTime: '',
      sort: '',
    }),
  },
  reducers: {
    setPage(state, action) {
      state.historyList.page = action.payload;
    },
    setUserName(state, action) {
      state.historyList.userName = action.payload;
    },
    setResourceName(state, action) {
      state.historyList.resourceName = action.payload;
    },
    setType(state, action) {
      state.historyList.type = action.payload;
    },
    setStartTime(state, action) {
      state.historyList.startTime = action.payload;
    },
    setEndTime(state, action) {
      state.historyList.endTime = action.payload;
    },
    setSort(state, action) {
      state.historyList.sort = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getHistory.pending, (state, action) => {
      state.historyList.loading = Loading.pending;
    });

    builder.addCase(getHistory.fulfilled, (state, action) => {
      const { data, meta } = action.payload;
      state.historyList.loading = Loading.fulfilled;
      state.historyList.total = meta.pagination.total;
      state.historyList.totalPages = meta.pagination.total_pages;
      historyAdapter.setAll(state.historyList, data);
    });

    builder.addCase(getHistory.rejected, (state, action) => {
      state.historyList.loading = Loading.rejected;
      state.historyList.total = 0;
      state.historyList.totalPages = 0;
      historyAdapter.setAll(state.historyList, []);
    });
  },
});

export const { selectAll: selectHistoryList } = historyAdapter.getSelectors(
  (state: AppState) => state.historyReducer.historyList
);

export const selectLoading = (state: AppState) =>
  state.historyReducer.historyList.loading;
export const selectTotal = (state: AppState) =>
  state.historyReducer.historyList.total;
export const selectTotalPages = (state: AppState) =>
  state.historyReducer.historyList.totalPages;
export const selectPage = (state: AppState) =>
  state.historyReducer.historyList.page;
export const selectUserName = (state: AppState) =>
  state.historyReducer.historyList.userName;
export const selectResourceName = (state: AppState) =>
  state.historyReducer.historyList.resourceName;
export const selectType = (state: AppState) =>
  state.historyReducer.historyList.type;
export const selectStartTime = (state: AppState) =>
  state.historyReducer.historyList.startTime;
export const selectEndTime = (state: AppState) =>
  state.historyReducer.historyList.endTime;
export const selectSort = (state: AppState) =>
  state.historyReducer.historyList.sort;

export const {
  setPage,
  setUserName,
  setResourceName,
  setType,
  setStartTime,
  setEndTime,
  setSort,
} = historySlice.actions;
export default historySlice.reducer;
