import React, { useEffect, useRef } from 'react';

import styled from '@emotion/styled';
import clsx from 'clsx';

import Box from 'src/components/Box';
import { composeClasses } from 'src/utils';

import Preview from './Preview';

const classPrefix = 'UploadImage';

const useUtilityClasses = () => {
  const slots = {
    root: ['root'],
    preview: ['preview'],
    label: ['label'],
    hint: ['hint'],
    input: ['input'],
  };

  return composeClasses(classPrefix, slots);
};

const UploadImageRoot = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const UploadImageItem = styled('div')({
  flexShrink: 0,
  textAlign: 'center',
});

const UploadImageInput = styled('input')({
  display: 'none',
});

const UploadImageLabel = styled('div')(({ theme }) => ({
  color: theme.palette.primary?.main,
  fontSize: 18,
  marginBottom: 10,
  cursor: 'pointer',
  userSelect: 'none',
}));

const UploadImageHint = styled('div')(({ theme }) => ({
  color: theme.palette.text?.secondary,
  fontSize: 16,
}));

const UploadImage: React.FC<UploadImageProps> = ({
  value,
  onChange,
  label = '选择/上传封面',
  hint = '支持jpg/png/gif格式的图片',
  accept = 'image/jpeg, image/png, image/gif',
  className,
  id = 'image',
  name = 'image',
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const classes = useUtilityClasses();

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  useEffect(
    () => () => {
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    },
    []
  );

  return (
    <React.Fragment>
      <UploadImageRoot className={clsx(classes.root, className)}>
        <UploadImageItem>
          <Preview image={value} className={classes.preview} />
        </UploadImageItem>
        <Box sx={{ width: 64 }} />
        <UploadImageItem>
          <UploadImageLabel className={classes.label} onClick={handleClick}>
            {label}
          </UploadImageLabel>
          <UploadImageHint className={classes.hint}>{hint}</UploadImageHint>
        </UploadImageItem>
        <UploadImageInput
          type="file"
          ref={inputRef}
          accept={accept}
          onChange={onChange}
          id={id}
          name={name}
        />
      </UploadImageRoot>
    </React.Fragment>
  );
};

interface ChangeEvent {
  (event: React.ChangeEvent<HTMLInputElement>): void;
  (image: string): void;
}

interface UploadImageProps {
  value?: File | string | undefined;
  onChange?: ChangeEvent | undefined;
  label?: string | undefined;
  hint?: string | undefined;
  accept?: string | undefined;
  className?: string | undefined;
  name?: string | undefined;
  id?: string | undefined;
}

export default UploadImage;
