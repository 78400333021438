import { createContext } from 'react';

import { FormControlProps } from './FormControl';

type ContextFromPropsKey = 'disabled' | 'error' | 'required' | 'row';

export type Context = Pick<FormControlProps, ContextFromPropsKey>;

const FormControlContext = createContext<Context | null>(null);

if (process.env.NODE_ENV !== 'production') {
  FormControlContext.displayName = 'FormControlContext';
}

export default FormControlContext;
