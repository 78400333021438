import { ResourceType } from 'src/shared/constants';
import {
  HttpResponse,
  ID,
  ListHttpDataResponse,
  ListHttpResponse,
  PackDetail,
  Pack,
} from 'src/shared/interfaces';
import { httpClient } from 'src/utils';

export type ShareArgs = {
  resource?: ResourceType;
  resourceId?: ID;
  autType?: '1' | '2';
  members: ID[];
  departments: ID[];
  name: string;
};

class PackService {
  baseUrl = '/package';
  /**
   * 加入资源包
   * @param resource
   * @param resourceId
   */
  async add(resource: ResourceType, resourceId: ID) {
    const response = await httpClient.post<HttpResponse<PackDetail>>(
      this.baseUrl,
      {
        resource,
        resourceId,
      }
    );
    return response.data;
  }

  /**
   * 资源包列表
   * @param type receive: 接受, send: 发送
   * @returns
   */
  async list(data: { type: 'receive' | 'send'; keyword: string }) {
    const response = await httpClient.get<ListHttpResponse<Pack>>(
      this.baseUrl,
      { params: data }
    );
    return response.data;
  }

  /**
   * 资源包详情
   *
   * 不传 id 为获取顶部导航栏中资料包列表
   * @param id
   * @returns
   */
  async details(data: { id: ID | undefined; keyword?: string }) {
    const { id = 0, keyword } = data;
    const response = await httpClient.get<ListHttpResponse<PackDetail>>(
      `${this.baseUrl}/${id}`,
      { params: { keyword } }
    );
    return response.data;
  }

  /**
   * 资源包内删除
   * @param id
   * @returns
   */
  async remove(id: ID = 0) {
    const response = await httpClient.delete(`${this.baseUrl}/${id}`);
    return response.data;
  }

  async share({ members, departments, ...rest }: ShareArgs) {
    const usersId = members.join(',');
    const departmentsId = departments.join(',');
    const response = await httpClient.post('/sendPackage', {
      ...rest,
      usersId,
      departmentsId,
    });
    return response.data;
  }
}

const packService = new PackService();

export default packService;
