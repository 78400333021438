import {
  BookOrFolder,
  HttpResponse,
  ID,
  Page,
  PageMenu,
  Template,
} from 'src/shared/interfaces';
import { httpClient } from 'src/utils';

export interface SaveToPageTemplateBody {
  title: string;
  content: string;
  baseId: ID;
  file: File;
}

export interface CopyOptions {
  to?: ID | undefined;
  keep?: boolean;
}

class BookService {
  /**
   * 知识本详情
   * @param id
   * @returns
   */
  async fetchDetails(id: ID) {
    const response = await httpClient.get<HttpResponse<BookOrFolder>>(
      `/baseFolder/${id}`
    );
    return response.data;
  }

  /**
   * 知识本目录
   * @param id
   * @returns
   */
  async fetchMenus(data: { id: ID; keyword?: string }) {
    const response = await httpClient.get<
      HttpResponse<{
        page: PageMenu[];
        template: Template[];
        authority: number;
      }>
    >('/page', {
      params: data,
    });
    return response.data;
  }

  /**
   * 知识页详情
   * @param id
   * @returns
   */
  async fetchPage(id: ID) {
    const response = await httpClient.get<HttpResponse<Page>>(`/page/${id}`);
    return response.data;
  }

  /**
   * 删除知识页
   * @param id
   * @returns
   */
  async removePage(id: ID) {
    const response = await httpClient.delete<HttpResponse<{}>>(`/page/${id}`);
    return response.data;
  }

  /**
   * 保存知识页为模板
   * TODO move to templateService
   * @param data
   * @returns
   */
  async savePageAsTemplate(data: {
    title: string;
    file: File;
    content: string;
  }) {
    const response = await httpClient.post<HttpResponse<Template>>(
      '/template',
      data,
      { headers: { 'Content-Type': 'multiple/form-data' } }
    );
    return response.data;
  }

  /**
   * 知识页重命名
   * @param id
   * @param name
   * @returns
   */
  async renamePage(id: ID, name: string) {
    const response = await httpClient.patch<HttpResponse<Page>>(`/page/${id}`, {
      name,
    });
    return response.data;
  }

  /**
   * 创建副本/复制到
   * @param id
   * @param to
   * @returns
   */
  async copy(id: ID, options: CopyOptions) {
    const { to, keep = true } = options;
    const response = await httpClient.post<HttpResponse<Page>>(
      `/pageCopy/${id}`,
      { baseFolderId: to, type: keep === true ? 1 : 0 }
    );

    return response.data;
  }

  async downloadAsPDF(content: string) {
    const response = await httpClient.post<Blob>(
      '/pdf',
      { content },
      { responseType: 'blob' }
    );
    return response.data;
  }

  async findDraft(pageId: ID) {
    const response = await httpClient.get<HttpResponse<Page>>(
      `/findPageDraft/${pageId}`
    );

    return response.data;
  }
}

const bookService = new BookService();

export default bookService;
