import { createSelector } from '@reduxjs/toolkit';

import { State } from '../interface';
import { getStatusOfSelectAll, getDepartmentById } from '../utils';

/**
 * loading
 * @param type
 * @returns
 */
export const selectLoading = (type: 'department' | 'member' | 'search') => {
  return (state: State) => state.loading[type];
};

/**
 * 部门列表
 * @param state
 * @returns
 */
export const selectDepartmentList = (state: State) => state.list.department;

/**
 * 人员列表
 * @param state
 * @returns
 */
export const selectMemberList = (state: State) => state.list.member;

/**
 * 当前部门
 */
export const selectCurrentDepartment = createSelector(
  (state: State) => state.current.department,
  (departmentId) => {
    if (departmentId !== null) {
      return getDepartmentById(departmentId);
    }

    return null;
  }
);

/**
 * 当前子部门 id
 * @param state
 * @returns
 */
export const selectCurrentSubdepartment = createSelector(
  (state: State) => state.current.subdepartment,
  (subdepartmentId) => {
    if (subdepartmentId !== null) {
      return getDepartmentById(subdepartmentId);
    }

    return null;
  }
);

/**
 * 子部门列表
 */
export const selectSubdepartmentList = createSelector(
  (state: State) => state.current.department,
  (departmentId) => {
    if (departmentId) {
      const department = getDepartmentById(departmentId);
      return department?.children || [];
    }

    return [];
  }
);

/**
 * 已添加列表
 * @param state
 * @returns
 */
export const selectSelectedList = (state: State) => state.selected;

/**
 * 全选 checkbox 状态
 */
export const selectStatusOfSelectAll = createSelector(
  selectSelectedList,
  selectMemberList,
  selectCurrentSubdepartment,
  (checkedList, members, subdepartment) => {
    if (subdepartment) {
      return getStatusOfSelectAll(checkedList, members, subdepartment);
    }
    return '';
  }
);

/**
 * 是否显示“全选”
 */
export const selectIfCanSelectAll = createSelector(
  (state: State) => state.list.member,
  (members) => members.length > 0
);

export const selectSearch = (state: State) => state.search;
