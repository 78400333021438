import styled from '@emotion/styled';

interface ListItemProps {
  selected?: boolean;
  disableGutter?: boolean;
  onClick?: ((event: React.MouseEvent<HTMLLIElement>) => void) | undefined;
}

export default styled('li', {
  label: 'ListItem',
  shouldForwardProp: (prop) =>
    prop !== 'theme' &&
    prop !== 'as' &&
    prop !== 'selected' &&
    prop !== 'disableGutter',
})<ListItemProps>(({ selected, disableGutter, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  padding: 10,
  borderRadius: 10,
  fontSize: 16,
  marginBottom: 10,
  color: theme.palette.text?.primary,
  ...(selected && { backgroundColor: theme.palette.background?.paper || '' }),
  ...(disableGutter && { padding: 0 }),
  '&:hover': {
    backgroundColor: theme.palette.background?.paper || '',
  },
}));
