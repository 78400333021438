import React, { useCallback } from 'react';
import styled from '@emotion/styled';

import { ListItem } from 'src/components/List';

import List from '../common/List';
import Title from '../common/Title';
import Button from '../common/Button';
import { useMemberDispatch, useMemberSelector } from '../Provider';
import { selectSelectedList } from '../reducers/selectors';
import { getRootDepartment } from '../utils';
import { clear, remove } from '../reducers/actions';
import Item from './Item';

const ListRoot = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
});

const StyledListItem = styled(ListItem)({
  cursor: 'default',
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

const Added: React.FC = () => {
  const dispatch = useMemberDispatch();

  const list = useMemberSelector(selectSelectedList);

  const handleRemove = useCallback(
    (id: string | number, type: 'department' | 'member') => {
      return () => {
        dispatch(remove({ id, type }));
      };
    },
    [dispatch]
  );

  const handleClear = useCallback(() => {
    dispatch(clear());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Title actions={<Button onClick={handleClear}>清空</Button>}>
        已添加
      </Title>
      <ListRoot>
        <List isEmpty={list.length === 0}>
          {list.map((item) => {
            const name = item.type === 'department' ? item.title : item.name;
            const root = getRootDepartment(item);
            return (
              <StyledListItem key={item.id}>
                <Item
                  type={item.type}
                  name={name}
                  root={root?.title}
                  onRemove={handleRemove(item.id, item.type)}
                />
              </StyledListItem>
            );
          })}
        </List>
      </ListRoot>
    </React.Fragment>
  );
};

export default Added;
