import React, { useReducer } from 'react';

import initialUploadState from './state';
import uploadReducer from './reducer';
import UploadContext from './context';

const UploadProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(uploadReducer, initialUploadState);

  const childProps = { state, dispatch };

  return (
    <UploadContext.Provider value={childProps}>
      {children}
    </UploadContext.Provider>
  );
};

export default UploadProvider;
