import React from 'react';
import styled from '@emotion/styled';
import { Tooltip } from 'antd';
import filesize from 'filesize';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import {
  CloseOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
} from '@ant-design/icons';

import { shouldForwardProp } from 'src/utils';
import { File } from 'src/components/Icons';

import TableRow from './Table/TableRow';
import TableCell from './Table/TableCell';
import { UploadStatus } from '../Provider';

function parseFilename(file?: File | undefined) {
  if (!file) return null;

  const filenames = file.name.split('.');
  const ext = filenames.pop();
  return {
    ext: `.${ext}`,
    name: filenames.join(),
  };
}

const PREFIX = 'UploadFileItem';

const classes = {
  root: `${PREFIX}-root`,
  cell: `${PREFIX}-cell`,
  filename: `${PREFIX}-filename`,
  action: `${PREFIX}-action`,
  cancel: `${PREFIX}-cancel`,
  success: `${PREFIX}-success`,
  failed: `${PREFIX}-failed`,
  link: `${PREFIX}-link`,
};

const Root = styled(TableRow, {
  shouldForwardProp: (prop) => shouldForwardProp(prop) && prop !== 'progress',
})<{ progress?: number }>(({ progress = 0 }) => ({
  [`&.${classes.root}`]: {
    position: 'relative',
    '& > td:first-of-type::after': {
      content: '""',
      width: `${progress}%`,
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      background: '#f6f7fb',
      zIndex: -1,
      padding: 8,
      backgroundClip: 'content-box',
      borderRadius: 12,
    },
  },
  [`& .${classes.cell}`]: {
    maxWidth: 0,
  },
  [`& .${classes.filename}`]: {
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',
    '& > span:first-of-type': {
      display: 'flex',
      alignItems: 'center',
      marginRight: 8,
      color: '#ffc1c1',
    },
    '& > div:first-of-type': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '& > div:last-of-type': {
      flexShrink: 0,
    },
  },
  [`& .${classes.action}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  [`& .${classes.cancel}`]: {
    '& > span:first-of-type': {
      color: '#f5222d',
    },
  },
  [`& .${classes.success}`]: {
    '& > span:first-of-type': {
      color: '#52c41a',
    },
  },
  [`& .${classes.failed}`]: {
    '& > span:first-of-type': {
      color: '#f5222d',
    },
  },
  [`& .${classes.link}`]: {
    color: '#5a5aee',
    cursor: 'pointer',
  },
}));

function getOwnerAnchor(owner: any) {
  let url = `/folder/${owner.baseFolderId}`;
  if (owner.parentFolder) {
    url += `?pageId=${owner.parentFolder}`;
  }

  return url;
}

export interface FileItemProps {
  file: File;
  owner?: any;
  speed?: number;
  progress?: number;
  status?: UploadStatus;
  onRetry?: ((event: React.MouseEvent<HTMLSpanElement>) => void) | undefined;
  onCancel?: ((event: React.MouseEvent<HTMLElement>) => void) | undefined;
}

const FileItem: React.FC<FileItemProps> = (props) => {
  const {
    file,
    owner,
    speed = 0,
    progress = 0,
    status = UploadStatus.Pending,
    onRetry,
    onCancel,
  } = props;

  const { name: filename, ext } = parseFilename(file) || {};

  return (
    <Root className={classes.root} progress={progress}>
      <TableCell className={classes.cell}>
        <Tooltip title={file?.name} destroyTooltipOnHide>
          <div className={classes.filename}>
            <span>
              <File color="inherit" />
            </span>
            <div>{filename}</div>
            <div>{ext}</div>
          </div>
        </Tooltip>
      </TableCell>
      <TableCell className={classes.cell}>
        {filesize(file?.size || 0, { round: 1 })}
      </TableCell>
      <TableCell className={classes.cell}>
        {owner && <Link to={getOwnerAnchor(owner)}>{owner.name}</Link>}
      </TableCell>
      <TableCell className={classes.cell}>
        <span>{progress}%</span>
        {status === UploadStatus.Uploading && (
          <span>({filesize(speed, { round: 1 })}/s)</span>
        )}
      </TableCell>
      <TableCell className={classes.cell}>
        {status === UploadStatus.Prepare && (
          <div className={classes.action}>
            <span>准备中...</span>
          </div>
        )}
        {status === UploadStatus.Uploading && (
          <div className={clsx(classes.action, classes.cancel)}>
            <span onClick={onCancel} style={{ cursor: 'pointer' }}>
              <CloseOutlined />
            </span>
          </div>
        )}
        {status === UploadStatus.Success && (
          <div className={clsx(classes.action, classes.success)}>
            <CheckCircleFilled />
            <span style={{ flexShrink: 0, marginLeft: 8 }}>上传完成</span>
          </div>
        )}
        {status === UploadStatus.Failed && (
          <div className={clsx(classes.action, classes.failed)}>
            <CloseCircleFilled />
            <span style={{ flexShrink: 0, marginLeft: 8 }}>
              上传失败，
              <span className={classes.link} onClick={onRetry}>
                请重试
              </span>
            </span>
          </div>
        )}
      </TableCell>
    </Root>
  );
};

export default FileItem;
