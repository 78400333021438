import { createSvgIcon } from './utils';

export default createSvgIcon(
  [
    <path
      key="1"
      d="M3.52631599,0 L14.4736843,0 L14.4736842,0 C15.8689309,0 17,1.09893643 17,2.45454545 L17,15.5454545 L17,15.5454545 C17,16.9010636 15.8689309,18 14.4736842,18 L3.52631588,18 L3.52631577,18 C2.13106907,18 1,16.9010616 1,15.5454545 C1,15.5454545 1,15.5454545 1,15.5454545 L1,2.45454545 L1,2.45454583 C1,1.09893681 2.13106907,0 3.52631577,0 L3.52631599,0 Z"
    />,
    <path
      key="2"
      d="M13.8421054,12.2727273 L13.8421054,12.2727273 C14.1909171,12.2727273 14.4736844,12.5474614 14.4736844,12.8863636 C14.4736844,13.2252659 14.1909171,13.5 13.8421054,13.5 L4.15789496,13.5 L4.15789493,13.5 C3.80908325,13.5 3.52631599,13.2252659 3.52631599,12.8863636 C3.52631599,12.5474614 3.80908325,12.2727273 4.15789493,12.2727273 L13.8421054,12.2727273 L13.8421054,12.2727273 Z M13.8421054,8.18181818 L13.8421054,8.18181818 C14.190917,8.18181818 14.4736843,8.45655229 14.4736843,8.79545455 C14.4736843,9.1343568 14.190917,9.40909091 13.8421054,9.40909091 L4.15789491,9.40909091 L4.15789488,9.40909091 C3.80908321,9.40909091 3.52631594,9.1343568 3.52631594,8.79545455 C3.52631594,8.45655229 3.80908321,8.18181818 4.15789488,8.18181818 L13.8421053,8.18181818 L13.8421054,8.18181818 Z M9.63157911,4.09090909 L9.63157908,4.09090909 C9.98039076,4.09090909 10.263158,4.3656432 10.263158,4.70454545 C10.263158,5.04344771 9.98039076,5.31818182 9.63157908,5.31818182 L4.15789491,5.31818182 L4.15789488,5.31818182 C3.80908321,5.31818182 3.52631594,5.04344771 3.52631594,4.70454545 C3.52631594,4.3656432 3.80908321,4.09090909 4.15789488,4.09090909 L9.63157905,4.09090909 L9.63157911,4.09090909 Z"
      fill="#FFFFFF"
    />,
  ],
  'Text',
  '0 0 18 18'
);
