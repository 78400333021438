import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QuestionEntity } from 'src/pages/QA/types';
import qaAdminService from 'src/services/admin/qa';
import { Loading } from 'src/shared/constants';
import { getErrorMessage } from 'src/utils';
import { Report } from 'src/pages/Repository/Admin/Report/types';
import { reportAdapter } from 'src/pages/Repository/Admin/Report/slice';

import { CategoryType, SortType } from './types';
import { AppState } from 'src/app/store';
import { ID } from 'src/shared/interfaces';

const name = 'AdminQA' as const;

export interface State {
  loading: Loading;
  currentRequestId: string | undefined;
  entities: {
    qa: QuestionEntity[];
    report: Report[];
  };
  category: CategoryType;
  sort: SortType;
  status: 0 | 1 | 2 | undefined;
  baseName: string | undefined;
  keyword: string | undefined;
  page: number | undefined;
  total: number | undefined;
  limit: number | undefined;
}

const initialState: State = {
  loading: Loading.idle,
  currentRequestId: undefined,
  entities: { qa: [], report: [] },
  category: 'question',
  sort: 'default',
  baseName: undefined,
  keyword: undefined,
  page: undefined,
  total: undefined,
  limit: undefined,
  status: 1,
};

type Pagination = {
  page?: number | undefined;
  limit?: number | undefined;
};

export type FetchListParams = {
  pagination?: Pagination;
  sort?: SortType;
  status?: 0 | 1 | 2 | undefined;
  filter?: { baseName?: string | undefined; keyword?: string | undefined };
};

/**
 * 获取列表
 */
export const fetchList = createAsyncThunk<
  { entities: QuestionEntity[] | Report[]; total: number },
  FetchListParams | undefined,
  { rejectValue: string; state: AppState }
>(`${name}/fetchList`, async (data = {}, { rejectWithValue, getState }) => {
  try {
    const { pagination, filter, sort, status } = data;
    const { management } = getState();
    const { category } = management.qa;
    if (category === 'question') {
      const response = await qaAdminService.fetchQAList(pagination, filter, sort);

      return {
        entities: response.data.data,
        total: response.data.meta.pagination.total,
      };
    } else if (category === 'report') {
      const response = await qaAdminService.fetchReportList(pagination, filter, status);

      return {
        entities: response.data.data.map((item) => reportAdapter(item)),
        total: response.data.meta.pagination.total,
      };
    }

    return { entities: [], total: 0 };
  } catch (err) {
    return rejectWithValue(getErrorMessage(err));
  }
});

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
    setCategory: (state, action: PayloadAction<CategoryType>) => {
      if (state.category !== action.payload) {
        state.category = action.payload;
        state.page = 1;
      }
    },
    setSort: (state, action: PayloadAction<SortType>) => {
      if (state.sort !== action.payload) {
        state.sort = action.payload;
        state.page = undefined;
      }
    },
    setBaseName: (state, action: PayloadAction<string | undefined>) => {
      if (state.baseName !== action.payload) {
        state.baseName = action.payload;
        state.page = undefined;
      }
    },
    setKeyword: (state, action: PayloadAction<string | undefined>) => {
      if (state.keyword !== action.payload) {
        state.keyword = action.payload;
        state.page = undefined;
      }
    },
    setStatus: (state, action: PayloadAction<0 | 1 | 2 | undefined>) => {
      if (state.status !== action.payload) {
        state.status = action.payload;
        state.page = undefined;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchList.pending, (state, action) => {
      state.loading = Loading.pending;
      state.currentRequestId = action.meta.requestId;
    });
    builder.addCase(fetchList.fulfilled, (state, action) => {
      if (state.currentRequestId === action.meta.requestId) {
        if (state.category === 'question') {
          state.entities.qa = action.payload.entities as QuestionEntity[];
        } else if (state.category === 'report') {
          state.entities.report = action.payload.entities as Report[];
        }
        state.total = action.payload.total;
        state.loading = Loading.fulfilled;
        state.page = action.meta.arg?.pagination?.page;
        state.limit = action.meta.arg?.pagination?.limit;
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(fetchList.rejected, (state, action) => {
      if (state.currentRequestId === action.meta.requestId) {
        state.loading = Loading.rejected;
        state.currentRequestId = undefined;
      }
    });
  },
});

export const { reset, setCategory, setSort, setBaseName, setKeyword, setStatus } = slice.actions;

export default slice.reducer;

// Selectors
export const selectEntities = createSelector(
  [
    (state: AppState) => state.management.qa.category,
    (state: AppState) => state.management.qa.entities,
  ],
  (cate, entities) => {
    switch (cate) {
      case 'question': {
        return entities.qa;
      }
      case 'report': {
        return entities.report;
      }
      default: {
        return [];
      }
    }
  }
);

/** 正在查看详情的举报 */
export const selectReportDetails = createSelector(
  [(state: AppState) => state.management.qa.entities.report, (state: AppState, id: ID) => id],
  (entities, id) => entities.find((item) => item.id === id)
);
