import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
} from '@reduxjs/toolkit';

import { AppState } from 'src/app/store';
import adminService, { IRecycle, IRecycleParams } from 'src/services/admin';
import { Loading } from 'src/shared/constants';
import { ID, ListData } from 'src/shared/interfaces';
import { getErrorMessage } from 'src/utils';

// 回收站
export const getRecycle = createAsyncThunk<
  ListData<IRecycle>,
  Partial<IRecycleParams>
>('recycle/getRecycle', async (data, { rejectWithValue }) => {
  try {
    const response = await adminService.getRecycle(data);
    return response.data;
  } catch (err) {
    const message = getErrorMessage(err);
    return rejectWithValue(message);
  }
});

// 删除
export const delRecycle = createAsyncThunk<void, ID>(
  'recycle/delRecycle',
  async (id, { rejectWithValue }) => {
    try {
      await adminService.delRecycle(id);
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

export const recoveryRecycle = createAsyncThunk<void, ID>(
  'recycle/recoveryRecycle',
  async (id, { rejectWithValue }) => {
    try {
      await adminService.recoveryRecycle(id);
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

const recycleAdapter = createEntityAdapter<IRecycle>({
  selectId: (recycle) => recycle.id,
});
type IState = {
  loading: Loading;
  total: number;
  totalPages: number;
  page: number;
  sort: string;
  keyword: string;
};
export const recycleSlice = createSlice({
  name: 'recycle',
  initialState: {
    recycleList: recycleAdapter.getInitialState<IState>({
      loading: Loading.idle,
      total: 0,
      totalPages: 0,
      page: 1,
      sort: '',
      keyword: '',
    }),
  },
  reducers: {
    setPage(state, action) {
      state.recycleList.page = action.payload;
    },
    setSort(state, action) {
      state.recycleList.sort = action.payload;
    },
    setKeyWord(state, action) {
      state.recycleList.keyword = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRecycle.pending, (state, action) => {
      state.recycleList.loading = Loading.pending;
    });

    builder.addCase(getRecycle.fulfilled, (state, action) => {
      const { data, meta } = action.payload;
      state.recycleList.loading = Loading.fulfilled;
      state.recycleList.total = meta.pagination.total;
      state.recycleList.totalPages = meta.pagination.total_pages;
      recycleAdapter.setAll(state.recycleList, data);
    });

    builder.addCase(getRecycle.rejected, (state, action) => {
      state.recycleList.loading = Loading.rejected;
      state.recycleList.total = 0;
      state.recycleList.totalPages = 0;
      recycleAdapter.setAll(state.recycleList, []);
    });

    builder.addCase(delRecycle.fulfilled, (state, action) => {
      recycleAdapter.removeOne(state.recycleList, action.meta.arg);
    });

    builder.addCase(recoveryRecycle.fulfilled, (state, action) => {
      recycleAdapter.removeOne(state.recycleList, action.meta.arg);
    });
  },
});

export const { selectAll: selectRecycleList } = recycleAdapter.getSelectors(
  (state: AppState) => state.recycleReducer.recycleList
);

export const selectLoading = (state: AppState) =>
  state.recycleReducer.recycleList.loading;
export const selectTotal = (state: AppState) =>
  state.recycleReducer.recycleList.total;
export const selectTotalPages = (state: AppState) =>
  state.recycleReducer.recycleList.totalPages;
export const selectPage = (state: AppState) =>
  state.recycleReducer.recycleList.page;
export const selectSort = (state: AppState) =>
  state.recycleReducer.recycleList.sort;
export const selectKeyWord = (state: AppState) =>
  state.recycleReducer.recycleList.keyword;

export const { setKeyWord, setPage, setSort } = recycleSlice.actions;

export default recycleSlice.reducer;
