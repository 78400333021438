import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
} from '@reduxjs/toolkit';

import { AppState } from 'src/app/store';
import adminService, {
  IAddTemplate,
  IAddTemplateParams,
  ITemplateList,
  ITemplateParams,
} from 'src/services/admin';
import { Loading } from 'src/shared/constants';
import { ID, ListData } from 'src/shared/interfaces';
import { getErrorMessage } from 'src/utils';

// 模板列表
export const getBaseTemplate = createAsyncThunk<
  ListData<ITemplateList>,
  Partial<ITemplateParams>
>('template/getBaseTemplate', async ({ ...rest } = {}, { rejectWithValue }) => {
  try {
    const response = await adminService.getBaseTemplate(rest);
    return response.data;
  } catch (err) {
    const message = getErrorMessage(err);
    return rejectWithValue(message);
  }
});

// 删除
export const delBaseTemplate = createAsyncThunk<void, ID>(
  'template/delBaseTemplate',
  async (id, { rejectWithValue }) => {
    try {
      await adminService.delBaseTemplate(id);
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

// 详情
export const detailBaseTemplate = createAsyncThunk<Partial<ITemplateList>, ID>(
  'template/detailBaseTemplate',
  async (id, { rejectWithValue }) => {
    try {
      const response = await adminService.detailBaseTemplate(id);
      return response.data;
    } catch (err) {
      const message = getErrorMessage(err);
      return rejectWithValue(message);
    }
  }
);

// 新增
export const addBaseTemplate = createAsyncThunk<
  Partial<IAddTemplate> | undefined,
  Partial<IAddTemplateParams>
>('template/addBaseTemplate', async (data, { rejectWithValue }) => {
  try {
    const response = await adminService.addBaseTemplate(data);
    return response.data;
  } catch (err) {
    const message = getErrorMessage(err);
    return rejectWithValue(message);
  }
});

// 修改
type UpdateArgs = { id: ID } & {
  _method?: 'POST' | 'PATCH';
} & Partial<IAddTemplateParams>;
export const updateBaseTemplate = createAsyncThunk<
  Partial<IAddTemplate>,
  UpdateArgs,
  { rejectValue: string }
>('department/patch', async ({ id, ...rest }, { rejectWithValue }) => {
  try {
    const response = await adminService.updateBaseTemplate(id, rest);
    return response.data;
  } catch (err) {
    const message = err.isAxiosError ? err.response.data : err.message;
    return rejectWithValue(message);
  }
});

const baseTemplateAdapter = createEntityAdapter<ITemplateList>({
  selectId: (baseTemplate) => baseTemplate.id,
});

export const baseTemplateSlice = createSlice({
  name: 'baseTemplate',
  initialState: {
    templateList: baseTemplateAdapter.getInitialState<{
      loading: Loading;
      keyword: string;
    }>({
      loading: Loading.idle,
      keyword: '',
    }),

    detailInfo: {},
    detailInfoLoading: '',
  },
  reducers: {
    setKeyword(state, action) {
      state.templateList.keyword = action.payload;
    },
    setDetail(state, action) {
      state.detailInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBaseTemplate.pending, (state, action) => {
      state.templateList.loading = Loading.pending;
    });
    builder.addCase(getBaseTemplate.fulfilled, (state, action) => {
      state.templateList.loading = Loading.fulfilled;
      baseTemplateAdapter.setAll(state.templateList, action.payload.data);
    });

    builder.addCase(delBaseTemplate.fulfilled, (state, action) => {
      baseTemplateAdapter.removeOne(state.templateList, action.meta.arg);
    });

    builder.addCase(detailBaseTemplate.pending, (state, action) => {
      state.detailInfoLoading = Loading.pending;
    });

    builder.addCase(detailBaseTemplate.fulfilled, (state, action) => {
      state.detailInfoLoading = Loading.fulfilled;
      state.detailInfo = action.payload;
    });
  },
});

export const selectDetailInfo = (state: AppState) =>
  state.baseTemplateReducer.detailInfo;
export const selectDetailInfoLoading = (state: AppState) =>
  state.baseTemplateReducer.detailInfoLoading;

export const { setKeyword, setDetail } = baseTemplateSlice.actions;

export const { selectAll: selectTemplateList } =
  baseTemplateAdapter.getSelectors(
    (state: AppState) => state.baseTemplateReducer.templateList
  );

export const selectLoading = (state: AppState) =>
  state.baseTemplateReducer.templateList.loading;
export const selectKeyword = (state: AppState) =>
  state.baseTemplateReducer.templateList.keyword;

export default baseTemplateSlice.reducer;
