import { httpClient } from 'src/utils';

import {
  ListHttpResponse,
  ID,
  StudyRecord,
  HttpResponse,
} from 'src/shared/interfaces';

export interface Options {
  page?: number;
  keyword?: string;
  sort?: string;
}

class StudyService {
  async fetchList(
    folder: ID | null | undefined,
    subfolder: ID | null | undefined,
    options?: Options
  ) {
    const response = await httpClient.get<ListHttpResponse<StudyRecord>>(
      '/studyRecord',
      {
        params: {
          baseFolderId: folder,
          resourceDirectoryId: subfolder,
          ...options,
        },
      }
    );

    return response.data;
  }

  async createRecord(resource: 4 | 5, resourceId: ID) {
    const response = await httpClient.post<HttpResponse<any>>('/studyRecord', {
      resource,
      resourceId,
      startAt: ~~(Date.now() / 1000),
    });
    return response.data;
  }

  async updateRecord(id: ID, duration: number) {
    const response = await httpClient.patch<HttpResponse<any>>(
      `/studyRecord/${id}`,
      {
        endAt: ~~(Date.now() / 1000),
        studyLength: duration,
      }
    );
    return response.data;
  }
}

const studyService = new StudyService();

export default studyService;
