import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/hooks';
import { logout, selectIsManager } from 'src/app/auth.slice';
import { Popover } from 'src/components/Popover';

import UserInfo from './UserInfo';

const StyledPopover = styled(Popover)({
  width: 250,
  position: 'fixed',
});

const AccountRoot = styled('div')({
  width: 250,
  '& > :not(last-of-type)': {
    borderBottom: '1px solid #eee',
  },
});

const MenuItem = styled('div')<any>(({ theme }) => ({
  padding: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 16,
  color: theme.palette.text?.primary,
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.text?.primary,
  },
}));

const Account: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);
  const isManager = useAppSelector(selectIsManager);

  const dispatch = useAppDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <StyledPopover
      trigger="click"
      button={
        <div style={{ padding: 8 }}>
          <Avatar
            src={user?.avatar}
            icon={<UserOutlined />}
            size={38}
            style={{ cursor: 'pointer', flexShrink: 0 }}
          />
        </div>
      }
    >
      <AccountRoot>
        <UserInfo
          name={user?.name}
          id={user?.id}
          department={user?.department.name}
          avatar={user?.avatar}
        />
        <MenuItem as={Link} to="/setting">
          账户设置
        </MenuItem>
        {isManager && (
          <MenuItem as={Link} to="/management">
            管理中心
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>退出</MenuItem>
      </AccountRoot>
    </StyledPopover>
  );
};

export default Account;
