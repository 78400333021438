import React from 'react';
import styled from '@emotion/styled';

import { Book, File, Paper, Folder, QA } from 'src/components/Icons';
import clsx from 'clsx';

const PREFIX = 'AppBar-Notification--ListItemIcon';

const classNames = {
  root: `${PREFIX}__root`,
};

const Icons = {
  repository: Book,
  file: File,
  paper: Paper, // 知识页
  folder: Folder, // 文件夹
  question: QA,
};

const Colors = {
  repository: '#5a5aee',
  file: '#ffc1c1',
  paper: '#c1e0ff',
  folder: '#d5c1ff',
  question: '#5a5aee',
};

const ListItemIconRoot = styled('div')({
  [`&.${classNames.root}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
  },
});

export interface ListItemIconProps {
  resource?: keyof typeof Icons;
  className?: string | undefined;
}

const ListItemIcon: React.FC<ListItemIconProps> = ({ className, resource = 'repository' }) => {
  const Icon = Icons[resource];
  const color = Colors[resource];

  return (
    <ListItemIconRoot className={clsx(classNames.root, className)}>
      <Icon color={color} />
    </ListItemIconRoot>
  );
};

export default ListItemIcon;
