import React from 'react';
import { Button } from 'antd';

import { createChainedFunction } from 'src/utils';
import {
  CloseCircle as ErrorIcon,
  MessageWarning as WarningIcon,
  Confirm as SuccessIcon,
} from 'src/components/Icons';

import Dialog, { DialogProps } from './Dialog';
import DialogTitle from './DialogTitle';
import DialogContent from './DialogContent';
import DialogActions from './DialogActions';
import DialogContext, { DialogInstance } from './DialogContext';
import VariantIcon from './VariantIcon';

interface IconVariant {
  default: React.ReactNode;
  warning: React.ReactNode;
  error: React.ReactNode;
  success: React.ReactNode;
  info: React.ReactNode;
}

export interface DialogProviderProps {
  container?: DialogProps['container'];
  disablePortal?: DialogProps['disablePortal'];
  TransitionComponent?: DialogProps['TransitionComponent'];
  TransitionProps?: DialogProps['TransitionProps'];
  iconVariant?: IconVariant;
}

type State = { instance: (DialogInstance & { open: boolean }) | null };

type Actions =
  | { type: '@@dialog/mount'; payload: DialogInstance & { open: boolean } }
  | { type: '@@dialog/open' }
  | { type: '@@dialog/close' }
  | { type: '@@dialog/dismiss' };

const initialState: State = { instance: null };

function reducer(state: State, action: Actions) {
  switch (action.type) {
    case '@@dialog/mount': {
      return {
        ...state,
        instance: {
          ...state.instance,
          ...action.payload,
        },
      };
    }
    case '@@dialog/open': {
      return {
        ...state,
        instance: {
          ...state.instance,
          open: true,
        },
      };
    }
    case '@@dialog/close': {
      return {
        ...state,
        instance: {
          ...state.instance,
          open: false,
        },
      };
    }
    case '@@dialog/dismiss': {
      return {
        ...state,
        instance: null,
      };
    }
    default:
      return state;
  }
}

const iconStyles = {
  fontSize: 18,
};

const DEFAULT_iCON_VARIANT: IconVariant = {
  default: undefined,
  warning: <WarningIcon style={iconStyles} />,
  error: <ErrorIcon style={iconStyles} />,
  success: <SuccessIcon style={iconStyles} />,
  info: undefined,
};

const DialogProvider: React.FC<DialogProviderProps> = (props) => {
  const {
    children,
    TransitionProps: TransitionPropsProp,
    iconVariant = DEFAULT_iCON_VARIANT,
    ...other
  } = props;

  const [state, dispatch] = React.useReducer(reducer, initialState);

  const [loading, setLoading] = React.useState(false);

  const {
    open = false,
    title,
    content,
    onCancel,
    onOk,
    variant = 'default',
    showActions = true,
    type = 'confirm',
  } = state.instance || {};

  const openDialog = React.useCallback((instance: DialogInstance) => {
    dispatch({ type: '@@dialog/mount', payload: { ...instance, open: false } });
    dispatch({ type: '@@dialog/open' });
  }, []);

  const closeDialog = React.useCallback(() => {
    dispatch({ type: '@@dialog/close' });
    if (onCancel) {
      onCancel();
    }
  }, [onCancel]);

  const handleOk = React.useCallback(async () => {
    if (!onOk) {
      closeDialog();
      return;
    }

    try {
      setLoading(true);
      await onOk();
      setLoading(false);
      closeDialog();
    } catch (err) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(err.message || err);
      }
      setLoading(false);
    }
  }, [onOk, closeDialog]);

  const handleExited = React.useCallback(() => {
    dispatch({ type: '@@dialog/dismiss' });
  }, [dispatch]);

  const childContext = React.useMemo(
    () => ({ openDialog, closeDialog }),
    [openDialog, closeDialog]
  );

  const TransitionProps: any = React.useMemo(
    () => ({
      ...TransitionPropsProp,
      onExited: createChainedFunction(
        handleExited,
        TransitionPropsProp?.onExited
      ),
    }),
    [TransitionPropsProp, handleExited]
  );

  return (
    <DialogContext.Provider value={childContext}>
      {children}
      {state.instance && (
        <Dialog
          open={open}
          onClose={closeDialog}
          TransitionProps={TransitionProps}
          {...other}
        >
          {title && <DialogTitle>{title}</DialogTitle>}
          <DialogContent>
            {React.isValidElement(iconVariant[variant]) && (
              <VariantIcon variant={variant}>
                {iconVariant[variant]}
              </VariantIcon>
            )}
            <span>{content}</span>
          </DialogContent>
          {showActions && (
            <DialogActions>
              {type === 'confirm' && (
                <Button onClick={closeDialog}>取消</Button>
              )}
              <Button loading={loading} type="primary" onClick={handleOk}>
                确定
              </Button>
            </DialogActions>
          )}
        </Dialog>
      )}
    </DialogContext.Provider>
  );
};

export default DialogProvider;
