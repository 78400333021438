import React from 'react';

import styled from '@emotion/styled';
import clsx from 'clsx';

import { composeClasses } from 'src/utils';
import FormControlContext from './FormControlContext';

export interface FormControlProps {
  className?: string | undefined;
  disabled?: boolean;
  error?: boolean;
  required?: boolean;
  row?: boolean;
}

const classNamePrefix = 'FormControl';

const useUtilityClasses = (props: FormControlProps) => {
  const { row, disabled, error, required } = props;
  const slots = {
    root: [
      'root',
      row && 'row',
      disabled && 'disabled',
      error && 'error',
      required && 'required',
    ],
  };

  return composeClasses(classNamePrefix, slots);
};

function shouldForwardProp(prop: PropertyKey) {
  return prop !== 'theme' && prop !== 'as' && prop !== 'row';
}

const FormControlRoot = styled('div', { shouldForwardProp })<{
  row?: boolean;
}>(({ row = false }) => ({
  display: 'inline-flex',
  flexDirection: 'column',
  position: 'relative',
  width: '100%',
  ...(row && { flexDirection: 'row' }),
}));

const FormControl: React.FC<FormControlProps> = (props) => {
  const { className, disabled, error, required, row, children } = props;
  const classes = useUtilityClasses(props);

  const childContext = {
    disabled,
    error,
    required,
    row,
  };

  return (
    <FormControlContext.Provider value={childContext}>
      <FormControlRoot row={row} className={clsx(classes.root, className)}>
        {children}
      </FormControlRoot>
    </FormControlContext.Provider>
  );
};

export default FormControl;
