import React from 'react';

// 404
const NotFound = React.lazy(() => import('src/pages/404'));

// 登录
const Login = React.lazy(() => import('src/pages/Login'));

// 忘记密码
const ForgetPassword = React.lazy(() => import('src/pages/ForgetPassword'));

// Home
const Home = React.lazy(() => import('src/pages/Home/Home'));

// 收藏夹
const Favorites = React.lazy(() => import('src/pages/Home/Favorites'));

// 知识库详情
const RepositoryDetails = React.lazy(() => import('src/pages/Repository/Details'));

// 草稿箱
const Drafts = React.lazy(() => import('src/pages/Home/Drafts'));

// 分享
const Share = React.lazy(() => import('src/pages/Pack'));

// Admin
const Review = React.lazy(() => import('src/pages/Admin/Review'));

// 知识库管理
const KnowledgeBase = React.lazy(() => import('src/pages/Admin/KnowledgeBase'));

// 后台管理
const Backend = React.lazy(() => import('src/pages/Admin/Backend'));

// 回收站
const Trash = React.lazy(() => import('src/pages/Repository/Admin/Trash'));

// 知识库设置
const RepositorySettings = React.lazy(() => import('src/pages/Repository/Admin/Settings'));

// 操作记录
const RepositoryOperations = React.lazy(() => import('src/pages/Repository/Admin/Operations'));

// 成员管理
const KnowledgeBaseMember = React.lazy(() => import('src/pages/Repository/Admin/Member'));

// 分享管理
const KnowledgeBaseShare = React.lazy(() => import('src/pages/Repository/Admin/Share'));

// 模板管理
const Templates = React.lazy(() => import('src/pages/Repository/Admin/Templates'));

const Folder = React.lazy(() => import('src/pages/Repository/Folder'));

// 账户设置
const Setting = React.lazy(() => import('src/pages/Setting'));

// 全局搜索
const Search = React.lazy(() => import('src/pages/Search'));

// 知识本
const KnowledgeBooks = React.lazy(() => import('src/pages/Book'));

// 学习记录
const Study = React.lazy(() => import('src/pages/Repository/Admin/Study'));

// 问答详情页
const QADetails = React.lazy(() => import('src/pages/QA/Details'));

const Ask = React.lazy(() => import('src/pages/QA/Ask'));

const Answer = React.lazy(() => import('src/pages/QA/Answer'));

// Just for test
const Test = React.lazy(() => import('src/pages/Test'));

// 举报管理
const ReportAdmin = React.lazy(() => import('src/pages/Repository/Admin/Report'));

// 我的关注
const Follows = React.lazy(() => import('src/pages/Home/Follows'));

// 问答管理
const QAAdmin = React.lazy(() => import('src/pages/Admin/QA'));

// 强制修改密码页面
const ForceUpdatePassword = React.lazy(() => import('src/pages/Password'));

const RedirectPage = React.lazy(() => import('src/pages/Redirect'));

const AutoLoginPage = React.lazy(() => import('src/pages/AutoLogin'))

export {
  AutoLoginPage,
  RedirectPage,
  NotFound,
  Login,
  ForgetPassword,
  Home,
  Favorites,
  RepositoryDetails,
  Drafts,
  Share,
  Review,
  KnowledgeBase,
  Backend,
  Trash,
  RepositorySettings,
  RepositoryOperations,
  KnowledgeBaseMember,
  KnowledgeBaseShare,
  Templates,
  Folder,
  Setting,
  Search,
  KnowledgeBooks,
  Study,
  QADetails,
  Ask,
  Answer,
  // Navigate,
  Test,
  ReportAdmin,
  Follows,
  QAAdmin,
  ForceUpdatePassword,
};
