import { combineReducers } from '@reduxjs/toolkit';
import favoritesReducer from 'src/layouts/MainLayout/AppBar/Favorites/slice';
import historyReducer from 'src/layouts/MainLayout/AppBar/History/slice';
import searchReducer from 'src/pages/Search/slice';
import notificationReducer from './Notification/slice';

export default combineReducers({
  favorites: favoritesReducer,
  history: historyReducer,
  search: searchReducer,
  notification: notificationReducer,
});
