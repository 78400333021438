import styled from '@emotion/styled';

import { shouldForwardProp } from 'src/utils';

export default styled('div', { label: 'ListItemText', shouldForwardProp })(
  () => ({
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    minWidth: 0,
  })
);
