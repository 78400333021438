import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { AppState } from 'src/app/store';
import adminService, {
  IAddTemplate,
  IAddTemplateParams,
  IDefaultParams,
  ITemplateList,
  ITemplateParams,
} from 'src/services/admin';
import { Loading } from 'src/shared/constants';
import { ID, ListData } from 'src/shared/interfaces';

// 模板列表
export const getTemplate = createAsyncThunk<
  ListData<ITemplateList>,
  Partial<ITemplateParams>
>('template/list', async ({ ...rest } = {}, { rejectWithValue }) => {
  try {
    const response = await adminService.getTemplate(rest);
    return response.data;
  } catch (err) {
    const message = err.isAxiosError ? err.response.data : err.message;
    return rejectWithValue(message);
  }
});

// 删除
export const delTemplate = createAsyncThunk<void, ID>(
  'template/del',
  async (id, { rejectWithValue }) => {
    try {
      await adminService.delTemplate(id);
    } catch (err) {
      const message = err.isAxiosError ? err.response.data : err.message;
      return rejectWithValue(message);
    }
  }
);

// 新增
export const addTemplate = createAsyncThunk<
  Partial<IAddTemplate>,
  Partial<IAddTemplateParams>
>('template/add', async (data, { rejectWithValue }) => {
  try {
    const response = await adminService.addTemplate(data);
    return response.data;
  } catch (err) {
    const message = err.isAxiosError ? err.response.data : err.message;
    return rejectWithValue(message);
  }
});

// 详情
export const detailTemplate = createAsyncThunk<Partial<ITemplateList>, ID>(
  'template/detail',
  async (id, { rejectWithValue }) => {
    try {
      const response = await adminService.detailTemplate(id);
      return response.data;
    } catch (err) {
      const message = err.isAxiosError ? err.response.data : err.message;
      return rejectWithValue(message);
    }
  }
);

// 默认
export const defaultTemplate = createAsyncThunk<void, Partial<IDefaultParams>>(
  'template/defaultTemplate',
  async (data, { rejectWithValue }) => {
    try {
      await adminService.defaultTemplate(data);
      // return response.data;
    } catch (err) {
      const message = err.isAxiosError ? err.response.data : err.message;
      return rejectWithValue(message);
    }
  }
);
type UpdateArgs = { id: ID } & {
  _method?: 'POST' | 'PATCH';
} & Partial<IAddTemplateParams>;
// 修改
export const updateTemplate = createAsyncThunk<
  Partial<IAddTemplate>,
  UpdateArgs,
  { rejectValue: string }
>('department/patch', async ({ id, ...rest }, { rejectWithValue }) => {
  try {
    const response = await adminService.updateTemplate(id, rest);
    return response.data;
  } catch (err) {
    const message = err.isAxiosError ? err.response.data : err.message;
    return rejectWithValue(message);
  }
});

const templateAdapter = createEntityAdapter<ITemplateList>({
  selectId: (department) => department.id,
});

export const templateSlice = createSlice({
  name: 'template',
  initialState: {
    templateList: templateAdapter.getInitialState<{
      loading: Loading;
      current: ID | null;
      keyword: string;
    }>({
      loading: Loading.idle,
      current: null,
      keyword: '',
    }),

    detailInfo: {},
    detailInfoLoading: '',
  },
  reducers: {
    setDetail(state, action) {
      state.detailInfo = action.payload;
    },
    setKeyword(state, action) {
      state.templateList.keyword = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTemplate.pending, (state, action) => {
      state.templateList.loading = Loading.pending;
    });
    builder.addCase(getTemplate.fulfilled, (state, action) => {
      state.templateList.loading = Loading.fulfilled;

      templateAdapter.setAll(state.templateList, action.payload.data);
    });

    builder.addCase(addTemplate.fulfilled, (state, action) => {
      // templateAdapter.addOne(state.templateList, action.payload);
    });

    builder.addCase(delTemplate.fulfilled, (state, action) => {
      templateAdapter.removeOne(state.templateList, action.meta.arg);
    });

    builder.addCase(detailTemplate.pending, (state, action) => {
      state.detailInfoLoading = Loading.pending;
    });
    builder.addCase(detailTemplate.fulfilled, (state, action) => {
      state.detailInfoLoading = Loading.fulfilled;
      state.detailInfo = action.payload;
    });
  },
});

export const { setDetail, setKeyword } = templateSlice.actions;

export const selectDetailInfo = (state: AppState) =>
  state.templateReducer.detailInfo;
export const selectDetailInfoLoading = (state: AppState) =>
  state.templateReducer.detailInfoLoading;

export const selectGetTemplateLoading = (state: AppState) =>
  state.templateReducer.templateList.loading;

export const selectGetTemplateKeyword = (state: AppState) =>
  state.templateReducer.templateList.keyword;

export const { selectAll: selectAllTemplate } = templateAdapter.getSelectors(
  (state: AppState) => state.templateReducer.templateList
);

export default templateSlice.reducer;
