import styled from '@emotion/styled';

export default styled('div')<{ item?: boolean }>(({ item = false }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  minWidth: 0,
  ...(item && {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
}));
