import SparkMD5 from 'spark-md5';

/**
 * Read file or blob as array buffer
 * @param file
 * @returns
 */
export function readAsArrayBuffer(
  file: File | Blob
): Promise<ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target) {
        resolve(event.target.result as ArrayBuffer);
      } else {
        resolve(null);
      }
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  });
}

/**
 * Slice file to chunks
 * @param file
 * @param size size per chunk
 * @returns
 */
export function createFileChunks(
  file: File | Blob,
  size: number = 2 * 1024 * 1024
): Blob[] {
  const result = [];
  const chunks = Math.ceil(file.size / size);
  let current = 0;

  while (current < chunks) {
    const start = current * size;
    const end = start + size >= file.size ? file.size : start + size;
    result.push(file.slice(start, end));
    current += 1;
  }

  return result;
}

/**
 * File md5
 * @param file
 * @returns
 */
export async function md5(file: File): Promise<string> {
  const chunks = createFileChunks(file);
  const spark = new SparkMD5.ArrayBuffer();
  for (const chunk of chunks) {
    const arrayBuffer = await readAsArrayBuffer(chunk);
    if (arrayBuffer) {
      spark.append(arrayBuffer);
    }
  }

  return spark.end();
}

/**
 * File extension name
 * @param file
 * @returns
 */
export function extname(file: File): string | null {
  const ext = file.name.split('.').pop();
  return ext ? `.${ext}` : null;
}
