import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import qaService from 'src/services/QA';
import { Loading } from 'src/shared/constants';
import { ID } from 'src/shared/interfaces';
import { getErrorMessage } from 'src/utils';
import { Question } from '../types';

const name = 'QAWaitingForYou';

function isObject(input: any) {
  return Object.prototype.toString.call(input) === '[object Object]';
}

interface State {
  baseId: ID | null;
  data: Question[] | undefined;
  loading: Loading;
}

const initialState: State = {
  baseId: null,
  data: undefined,
  loading: Loading.idle,
};

/**
 * 获取列表
 */
export const fetchList = createAsyncThunk<Question[], ID, { rejectValue: string }>(
  `${name}/fetchList`,
  async (baseId, { rejectWithValue }) => {
    try {
      const response = await qaService.fetchWaitingForYouList(baseId);
      return isObject(response.data) ? [] : response.data;
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  }
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchList.pending, (state) => {
      state.loading = Loading.pending;
    });

    builder.addCase(fetchList.fulfilled, (state, action) => {
      state.loading = Loading.fulfilled;
      state.data = action.payload;
      state.baseId = action.meta.arg;
    });

    builder.addCase(fetchList.rejected, (state) => {
      state.loading = Loading.rejected;
    });
  },
});

export const { reset } = slice.actions;

export default slice.reducer;
