import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Tabs as AntTabs } from 'antd';
import { TabsProps as AntTabsProps } from 'antd/es/tabs';
import clsx from 'clsx';

import { shouldForwardProp } from 'src/utils';

type Props = {
  className?: string;
  showExtraContent?: (key: string) => boolean;
};

export type TabsProps = Props & AntTabsProps;

const Tabs = React.memo<TabsProps>(function Tabs({
  className,
  showExtraContent: showExtraContentFn = () => true,
  onTabClick,
  tabBarExtraContent,
  tabBarGutter,
  ...rest
}) {
  const [showExtraContent, setShowExtraContent] = useState(true);

  const handleTabClick = (key: string, event: React.MouseEvent | React.KeyboardEvent) => {
    setShowExtraContent(showExtraContentFn(key));
    onTabClick?.(key, event);
  };

  return (
    <AntTabs
      {...rest}
      className={clsx('root', className)}
      onTabClick={handleTabClick}
      tabBarExtraContent={showExtraContent && tabBarExtraContent}
    />
  );
});

export default styled(Tabs, { label: 'Tabs', shouldForwardProp })(({ theme, tabBarGutter }) => ({
  '&.root.ant-tabs': {
    color: theme.palette.text?.secondary || '#999',
    overflow: 'visible',
  },

  '&.root .ant-tabs-tab': {
    ...theme.typography.body1,
    padding: '15px 0',
  },

  '&.root .ant-tabs-tab:hover': {
    color: theme.palette.text?.secondary || '#999',
  },
  '&.root.ant-tabs  .ant-tabs-nav-list > .ant-tabs-tab:not(:first-of-type)': {
    marginLeft: `${tabBarGutter}px`,
  },

  '&.root .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn': {
    color: theme.palette.text?.primary || '#333',
    textShadow: 'none',
    transition: 'font-weight 0ms',
  },

  '&.root.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar': {
    height: 4,
    borderRadius: 2,
  },
  '&.root.ant-tabs-top > .ant-tabs-nav': {
    marginBottom: 30,
  },
}));
