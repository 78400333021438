import React from 'react';
import styled from 'styled-components';

const PREFIX = `TextEditor-Toolbar--ToolbarButton`;

const classNames = {
  root: `${PREFIX}__root`,
};

const ToolbarButtonRoot = styled('button')<{ active?: boolean }>(({ active }) => ({
  [`&.${classNames.root}`]: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 6,
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    fontSize: 20,
    '&:hover': {
      color: '#5a5aee',
    },
    ...(active && { color: '#5a5aee' }),
  },
}));

const ToolbarButton: React.FC<{
  active?: boolean;
  onMouseDown?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}> = (props) => {
  const { children, active = false, onMouseDown } = props;
  return (
    <ToolbarButtonRoot
      type="button"
      active={active}
      className={classNames.root}
      onMouseDown={onMouseDown}
    >
      {children}
    </ToolbarButtonRoot>
  );
};

export default ToolbarButton;
