/**
 * 类型
 *
 * 1: 问题, 2: 答案
 */
export enum Type {
  question = 1,
  answer = 2,
}

/**
 * 赞同 action
 *
 * 1: 赞同, 2: 不赞同/取消赞同
 */
export enum Approve {
  approve = 1,
  disapprove = 2,
}

/**
 * 回答显示模式
 *
 * list: 列表, may_answer: 我的回答
 */
export enum AnswerListMode {
  list = 'list',
  theAnswer = 'the_answer',
}
