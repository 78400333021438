import React from 'react';
import styled from '@emotion/styled';
import { Button } from 'antd';

import { useMemberSelector } from '../Provider';
import { selectSelectedList } from '../reducers/selectors';
import { getCheckedDepartmentsId, getCheckedMembersId } from '../utils';

const FooterRoot = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  '& >:not(last-of-type)': {
    marginRight: 30,
  },
});

const StyledButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.primary?.main,
  padding: '10px 24px',
  height: 44,
}));

interface FooterProps {
  onCancel?: (() => void) | undefined;
  onFinish?:
    | ((data: { departments: (string | number)[]; members: (string | number)[] }) => void)
    | undefined;
  loading?: boolean;
}

const Footer: React.FC<FooterProps> = (props) => {
  const { onCancel, onFinish, loading = false } = props;

  const selectedList = useMemberSelector(selectSelectedList);

  const handleFinish = () => {
    if (onFinish) {
      const membersId = getCheckedMembersId(selectedList);
      const departmentsId = getCheckedDepartmentsId(selectedList);
      onFinish({ members: membersId, departments: departmentsId });
    }
  };

  return (
    <FooterRoot>
      <StyledButton onClick={onCancel}>取消</StyledButton>
      <StyledButton loading={loading} onClick={handleFinish} type="primary">
        完成
      </StyledButton>
    </FooterRoot>
  );
};

export default Footer;
