import React from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';

import { composeClasses } from 'src/utils';

const classNamePrefix = 'DialogContent';

const useUtilityClasses = () => {
  const slots = {
    root: ['root'],
  };
  return composeClasses(classNamePrefix, slots);
};

const DialogContentRoot = styled('div', { label: `${classNamePrefix}Root` })({
  padding: 40,
  flex: '0 0 auto',

  '.DialogTitle-root + &': {
    paddingTop: 0,
  },
});

const DialogContent = React.forwardRef<
  HTMLDivElement,
  { children?: React.ReactNode; className?: string | undefined }
>(function DialogContent(props, ref) {
  const classes = useUtilityClasses();

  const { className, ...other } = props;

  return (
    <DialogContentRoot
      ref={ref}
      className={clsx(classes.root, className)}
      {...other}
    />
  );
});

export default DialogContent;
