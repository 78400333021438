import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { AppState } from 'src/app/store';
import qaService from 'src/services/QA';

import { Loading } from 'src/shared/constants';
import { ID } from 'src/shared/interfaces';
import { getErrorMessage } from 'src/utils';
import { Question, QuestionEntity } from '../types';
import { questionAdapter } from '../utils';
import { Follow } from './constants';

const name = 'QADetails' as const;

interface State {
  baseId: ID | null;
  data: Question | null;
  loading: Loading;
}

const initialState: State = { baseId: null, data: null, loading: Loading.idle };

/**
 * 问答详情（不包含答案）
 */
export const fetchQADetails = createAsyncThunk<Question, ID, { rejectValue: string }>(
  `${name}/fetchQADetails`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await qaService.fetchQADetails(id);
      return questionAdapter(response.data);
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  }
);

/**
 * 关闭问题
 */
export const close = createAsyncThunk<void, void, { rejectValue: string; state: AppState }>(
  `${name}/close`,
  async (_, { rejectWithValue, getState }) => {
    try {
      const { QA } = getState();
      const { id: questionId } = QA.details.data || {};
      if (questionId) {
        await qaService.close(questionId);
      }
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  }
);

/**
 * 删除问题
 */
export const remove = createAsyncThunk<void, void, { rejectValue: string; state: AppState }>(
  `${name}/remove`,
  async (_, { getState, rejectWithValue }) => {
    try {
      const { QA } = getState();
      const { id: questionId } = QA.details.data || {};
      if (questionId) {
        await qaService.remove(questionId);
      }
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  }
);

/**
 * 关注
 */
export const follow = createAsyncThunk<
  string,
  { questionId: ID; type: Follow },
  { rejectValue: string; state: AppState }
>(`${name}/follow`, async ({ questionId, type }, { rejectWithValue }) => {
  try {
    const response = await qaService.follow(questionId, type);
    return response.message;
  } catch (err) {
    return rejectWithValue(getErrorMessage(err));
  }
});

/**
 * 更新问题
 */
export const updateQuestion = createAsyncThunk<
  void,
  Partial<QuestionEntity>,
  { rejectValue: string; state: AppState }
>(`${name}/updateQuestion`, async (changed, { rejectWithValue, getState }) => {
  try {
    const { QA } = getState();
    const { id: questionId, ...rest } = QA.details.data || {};
    if (questionId) {
      await qaService.updateQuestion(questionId, {
        ...rest,
        ...changed,
      });
    }
  } catch (err) {
    return rejectWithValue(getErrorMessage(err));
  }
});

/**
 * 浏览记录
 */
export const view = createAsyncThunk<Question, ID, { rejectValue: string }>(
  `${name}/view`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await qaService.view(id);
      return questionAdapter(response.data);
    } catch (err) {
      return rejectWithValue(getErrorMessage(err));
    }
  }
);

const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
    setBaseId: (state, action) => {
      state.baseId = action.payload;
    },
    update: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchQADetails.pending, (state) => {
      state.loading = Loading.pending;
    });

    builder.addCase(fetchQADetails.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = Loading.fulfilled;
      state.baseId = action.payload.baseId;
    });

    builder.addCase(fetchQADetails.rejected, (state) => {
      state.data = null;
      state.loading = Loading.rejected;
    });

    builder.addCase(follow.fulfilled, (state, action) => {
      const { type } = action.meta.arg;
      if (state.data) {
        if (type === Follow.follow) {
          state.data.followed = true;
          state.data.follows += 1;
        } else {
          state.data.followed = false;
          state.data.follows -= 1;
        }
      }
    });
  },
});

export const { reset, setBaseId, update } = slice.actions;

export default slice.reducer;

// Selectors
export const selectIsOwner = createSelector(
  [(state: AppState) => state.QA.details.data?.userId, (state: AppState) => state.auth.user?.id],
  (userId, currentUserId) => String(userId) === String(currentUserId)
);
