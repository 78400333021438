import styled from '@emotion/styled';
import { Theme } from '@emotion/react';

import { capitalize, composeClasses, shouldForwardProp } from 'src/utils';
import clsx from 'clsx';

export type Align = 'inherit' | 'left' | 'center' | 'right' | 'justify';

export type Color =
  | 'initial'
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'textPrimary'
  | 'textSecondary';

export type Variant =
  | 'body1'
  | 'body2'
  | 'button'
  | 'caption'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'overline'
  | 'subtitle1'
  | 'subtitle2';

export interface TypographyClasses {
  root: string;
  body2: string;
  body1: string;
  caption: string;
  button: string;
  h1: string;
  h2: string;
  h3: string;
  h4: string;
  h5: string;
  h6: string;
  subtitle1: string;
  subtitle2: string;
  overline: string;
  inherit: string;
  alignLeft: string;
  alignCenter: string;
  alignRight: string;
  alignJustify: string;
  noWrap: string;
  gutterBottom: string;
  paragraph: string;
}

export interface StyleProps {
  align?: Align;
  color?: Color;
  gutterBottom?: boolean;
  noWrap?: boolean;
  variant?: Variant;
  paragraph?: boolean;
  classes?: Partial<TypographyClasses>;
}

export interface TypographyProps extends StyleProps {
  className?: string | undefined;
  style?: React.CSSProperties;
  component?: React.ReactNode;
}

const classNamePrefix = 'Typography';

const rootShouldForwardProp = (prop: string | number | symbol) =>
  shouldForwardProp(prop) &&
  prop !== 'align' &&
  prop !== 'color' &&
  prop !== 'gutterBottom' &&
  prop !== 'noWrap' &&
  prop !== 'variant' &&
  prop !== 'paragraph' &&
  prop !== 'classes';

const useUtilityClasses = (styleProps: StyleProps) => {
  const {
    align = 'inherit',
    color = 'initial',
    gutterBottom,
    noWrap,
    variant,
    paragraph,
    classes,
  } = styleProps;

  const slots = {
    root: [
      'root',
      align !== 'inherit' && `align${capitalize(align)}`,
      gutterBottom && 'gutterBottom',
      noWrap && 'noWrap',
      paragraph && 'paragraph',
      variant,
      color !== 'initial' && `color${capitalize(color)}`,
    ],
  };

  return composeClasses(classNamePrefix, slots, classes);
};

const colorTransformations = {
  primary: 'primary.main',
  textPrimary: 'text.primary',
  secondary: 'secondary.main',
  textSecondary: 'text.secondary',
  error: 'error.main',
};

function getColor(theme: Theme, colorPath: string) {
  if (!colorPath) {
    return null;
  }

  return colorPath
    .split('.')
    .reduce(
      (acc, item) => (acc && acc[item] ? acc[item] : null),
      theme.palette as any
    );
}

const TypographyRoot = styled('span', {
  label: classNamePrefix,
  shouldForwardProp: rootShouldForwardProp,
})<StyleProps>(({ theme, ...styleProps }) => ({
  margin: 0,
  ...(styleProps.variant && theme.typography[styleProps.variant]),
  ...(styleProps.align !== 'inherit' && {
    textAlign: styleProps.align,
  }),
  ...(styleProps.gutterBottom && {
    marginBottom: '0.35em',
  }),
  ...(styleProps.paragraph && {
    marginBottom: 16,
  }),
  ...(styleProps.noWrap && {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  ...(styleProps.color &&
    styleProps.color !== 'initial' &&
    styleProps.color !== 'inherit' && {
      color: getColor(theme, colorTransformations[styleProps.color]),
    }),
}));

const variantMapping: any = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle1: 'h6',
  subtitle2: 'h6',
  body1: 'p',
  body2: 'p',
};

const Typography: React.FC<TypographyProps> = (props) => {
  const {
    className,
    align = 'inherit',
    color = 'initial',
    gutterBottom = false,
    paragraph = false,
    noWrap = false,
    variant = 'body1',
    component,
    ...other
  } = props;

  const Component =
    component || (paragraph ? 'p' : variantMapping[variant] || 'span');

  const styleProps = {
    align,
    color,
    gutterBottom,
    paragraph,
    noWrap,
    variant,
    ...other,
  };

  const classes = useUtilityClasses(styleProps);

  return (
    <TypographyRoot
      as={Component}
      className={clsx(classes.root, className)}
      {...styleProps}
    />
  );
};

export default Typography;
