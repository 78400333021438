import { formatDistanceToNow } from 'src/pages/QA/utils';
import { ResourceType } from 'src/shared/constants';
import { resolveDate } from 'src/utils';
import { getResourceLink } from 'src/utils/resource';

export enum NotificationTypes {
  all = 0,
  read = 1,
  unread = 2,
}

/**
 * 通知配置项
 * @param title 标题
 * @param showActions 是否可以操作，查看详情/同意/拒绝
 * @returns
 */
function notificationConfigFactory(title: ReturnType<typeof template>, showActions: boolean) {
  return {
    title,
    showActions,
  };
}

/**
 * Tagged function
 * @param strings
 * @param keys
 * @returns
 */
function template<T extends string>(strings: TemplateStringsArray, ...keys: T[]) {
  return function templated(args: Record<T, string>) {
    const result = [strings[0]];
    keys.forEach((key, i) => {
      result.push(args[key], strings[i + 1]);
    });
    return result.join('');
  };
}

/**
 * 通知配置项
 */
export const notificationConfigure = new Map([
  [1, notificationConfigFactory(template`${'name'}申请: 知识库成员`, true)],
  [2, notificationConfigFactory(template`${'name'}申请: 编辑权限`, true)],
  [3, notificationConfigFactory(template`${'name'}申请: 浏览权限`, true)],
  [4, notificationConfigFactory(template`${'name'}提交编辑`, true)],
  [5, notificationConfigFactory(template`${'name'}提交上传`, true)],
  [6, notificationConfigFactory(template`${'name'}分享给你: 浏览（编辑）权限`, false)],
  [7, notificationConfigFactory(template`${'name'}的分享`, false)],
  [8, notificationConfigFactory(template`${'name'}邀请你加入知识库`, false)],
  [9, notificationConfigFactory(template`您已被移出知识库成员`, false)],
  [10, notificationConfigFactory(template`${'name'}邀请你成为知识库的管理员`, false)],
  [11, notificationConfigFactory(template`您已被取消管理员`, false)],
  [12, notificationConfigFactory(template`通过申请: 编辑（浏览）权限`, false)],
  [13, notificationConfigFactory(template`申请被拒绝: 编辑（浏览）权限`, false)],
  [14, notificationConfigFactory(template`通过审核: 提交编辑（上传）`, false)],
  [15, notificationConfigFactory(template`未通过审核: 提交编辑（上传）`, false)],
  [16, notificationConfigFactory(template`${'name'}更新了知识库公告`, false)],
  [17, notificationConfigFactory(template`${'name'}邀请你回答提问`, false)],
]);

/**
 * 跳转链接
 * @param target
 * @returns
 */
export function getTargetLink(target: any) {
  switch (target.resource) {
    case 2: {
      if (target.baseFolderType === 1) {
        return getResourceLink(target.resource, { baseURL: 'book', id: target.resourceId });
      }
      return getResourceLink(target.resource, { baseURL: 'folder', id: target.resourceId });
    }
    case 3: {
      return getResourceLink(target.resource, {});
    }
    case 4: {
      if (target.type === 4) {
        return getResourceLink(
          target.resource,
          { id: target.baseFolderId },
          { draftId: target.resourceId }
        );
      }
      if (target.type === 5) {
        return '';
      }
      return getResourceLink(
        target.resource,
        { id: target.baseFolderId },
        { pageId: target.resourceId }
      );
    }
    case 8: {
      return getResourceLink(ResourceType.repository, { id: target.baseId });
    }
    case 9: {
      return getResourceLink(ResourceType.question, { id: target.baseId, qId: target.resourceId });
    }
    default:
      return '';
  }
}

/**
 * 资源类型
 * @param target
 * @returns
 */
export function getTargetResource(target: any) {
  switch (target.resource) {
    case 5: {
      return 'file';
    }
    case 2: {
      if (target.baseFolderType === 1) {
        return 'paper';
      }
      return 'folder';
    }
    case 4: {
      return 'paper';
    }
    case 8: {
      return 'repository';
    }
    case 9: {
      return 'question';
    }
    default: {
      return 'folder';
    }
  }
}

export function defineNotificationItem(origin: any) {
  const url = getTargetLink(origin);
  const resource = getTargetResource(origin);
  const { title, showActions } = notificationConfigure.get(origin.type) || {};

  return {
    url,
    resource,
    title: title ? title({ name: origin.joinName }) : '',
    showActions,
    date: formatDistanceToNow(resolveDate(origin.createdAt)),
    target: origin.name,
    base: origin.baseName,
    id: origin.id,
    avatar: origin.avatar,
    read: origin.read,
    authorityId: origin.authorityId,
    status: origin.status,
    isManage: origin.isManage,
  };
}
