class Cache {
  private cached: Record<string, any> = {};

  set<T>(key: string, value: T) {
    this.cached[key] = value;
  }

  append<T>(key: string, value: T) {
    const cached = this.get<T>(key);
    if (cached) {
      this.cached[key] = { ...cached, ...value };
    } else {
      this.cached[key] = value;
    }
  }

  get<T>(key: string): T {
    return this.cached[key] || null;
  }

  clear() {
    this.cached = {};
  }
}

const cache = new Cache();

export default cache;
