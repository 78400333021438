import { QuestionEntity } from 'src/pages/QA/types';
import { ReportEntity } from 'src/pages/Repository/Admin/Report/types';
import { ListHttpResponse } from 'src/shared/interfaces';
import { httpClient } from 'src/utils';

type Pagination = {
  page?: number | undefined;
  limit?: number | undefined;
};

type Order = 'default' | 'create' | 'recent' | 'hot' | 'answer' | undefined;

type Status = 0 | 1 | 2 | undefined;

type Filter = {
  baseName?: string | undefined;
  keyword?: string | undefined;
};

class QAAdminService {
  /**
   * 问答管理问答列表
   * @param pagination
   * @param filter
   * @param order
   * @returns
   */
  async fetchQAList(pagination: Pagination = {}, filter: Filter = {}, order: Order) {
    const response = await httpClient.get<ListHttpResponse<QuestionEntity>>('/authQuestion', {
      params: { ...pagination, ...filter, order: order === 'default' ? undefined : order },
    });
    return response.data;
  }

  /**
   * 问答管理举报列表
   * @param pagination
   * @param filter
   * @param status
   * @returns
   */
  async fetchReportList(pagination: Pagination = {}, filter: Filter = {}, status: Status) {
    const response = await httpClient.get<ListHttpResponse<ReportEntity>>('/reportList', {
      params: { ...pagination, ...filter, status: status === 0 ? undefined : status },
    });
    return response.data;
  }
}

const qaAdminService = new QAAdminService();

export default qaAdminService;
