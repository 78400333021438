import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';

import { AppState } from 'src/app/store';
import adminService, {
  MemberListParams,
  Order,
  BaseAuthority,
} from 'src/services/admin';
import { Loading } from 'src/shared/constants';
import { ID, ListData, Repository } from 'src/shared/interfaces';
import { getErrorMessage } from 'src/utils';

/**
 * 成员
 */
export const getListOfMember = createAsyncThunk<
  ListData<Repository>,
  Partial<MemberListParams>
>(`authBase/list`, async (data, { rejectWithValue }) => {
  try {
    const response = await adminService.authBase(data);
    return response.data;
  } catch (err) {
    const message = getErrorMessage(err);
    return rejectWithValue(message);
  }
});

/**
 * Sidebar
 */
export const getListOfSidebar = createAsyncThunk<ListData<Repository>, void>(
  'authBase/sidebar',
  async (_, { dispatch }) => {
    const resultAction = await dispatch(getListOfMember({ page: 1, limit: 5 }));
    return resultAction.payload as ListData<Repository>;
  }
);

/**
 * 权限管理列表
 */
export const fetchBaseAuthority = createAsyncThunk<
  ListData<BaseAuthority>,
  { baseId: ID; limit?: number; page?: number; sort?: string; keyword?: string }
>(`authBase/baseAuthority`, async (data, { rejectWithValue }) => {
  try {
    const response = await adminService.fetchBaseAuthority(data);
    return response.data;
  } catch (err) {
    const message = getErrorMessage(err);
    return rejectWithValue(message);
  }
});

/**
 * 删除权限管理
 */
export const removeBaseAuthority = createAsyncThunk<
  void,
  { baseId: ID; ids: string },
  { rejectValue: string }
>(`authBase/remove/baseAuthority`, async (data, { rejectWithValue }) => {
  try {
    await adminService.removeBaseAuthority(data);
  } catch (err) {
    const message = getErrorMessage(err);
    return rejectWithValue(message);
  }
});

const authBaseAdapter = createEntityAdapter<Repository>({
  selectId: (authBase) => authBase.id,
});

const baseAuthorityAdapter = createEntityAdapter<BaseAuthority>({
  selectId: (item) => item.id,
});

type OtherMemberState = {
  total: number;
  loading: Loading;
  totalPages: number;
  page: number;
  order: Order | undefined;
  sort?: string;
  keyword: string;
};

export const authBaseSlice = createSlice({
  name: 'authBase',
  initialState: {
    member: authBaseAdapter.getInitialState<OtherMemberState>({
      total: 0,
      loading: Loading.idle,
      totalPages: 0,
      page: 1,
      order: undefined,
      keyword: '',
    }),
    auth: baseAuthorityAdapter.getInitialState<OtherMemberState>({
      total: 0,
      loading: Loading.idle,
      totalPages: 0,
      page: 1,
      order: undefined,
      keyword: '',
      sort: '',
    }),
    sidebar: [] as Repository[],
  },
  reducers: {
    setPage(state, action) {
      state.member.page = action.payload;
    },
    setOrder(state, action) {
      state.member.order = action.payload;
    },
    setKeyword(state, action) {
      state.member.keyword = action.payload;
    },
    setAuthPage(state, action) {
      state.auth.page = action.payload;
    },
    setAuthSort(state, action) {
      state.auth.sort = action.payload;
    },
    setAuthKeyword(state, action) {
      state.auth.keyword = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListOfMember.pending, (state) => {
      state.member.loading = Loading.pending;
    });
    builder.addCase(getListOfMember.fulfilled, (state, action) => {
      state.member.loading = Loading.fulfilled;
      const { data, meta } = action.payload;
      state.member.total = meta.pagination.total;
      state.member.totalPages = meta.pagination.total_pages;

      if (state.member.page !== 1) {
        authBaseAdapter.upsertMany(state.member, data);
      } else {
        authBaseAdapter.setAll(state.member, data);
      }
    });
    builder.addCase(getListOfMember.rejected, (state) => {
      state.member.loading = Loading.rejected;
      authBaseAdapter.setAll(state.member, []);
      state.member.total = 0;
      state.member.totalPages = 0;
    });
    // 权限管理
    builder.addCase(fetchBaseAuthority.pending, (state) => {
      state.auth.loading = Loading.pending;
    });
    builder.addCase(fetchBaseAuthority.fulfilled, (state, action) => {
      state.auth.loading = Loading.fulfilled;
      const { data, meta } = action.payload;
      state.auth.total = meta.pagination.total;
      state.auth.totalPages = meta.pagination.total_pages;
      baseAuthorityAdapter.setAll(state.auth, data);
    });
    builder.addCase(fetchBaseAuthority.rejected, (state) => {
      state.auth.loading = Loading.rejected;
      baseAuthorityAdapter.setAll(state.auth, []);
      state.auth.total = 0;
      state.auth.totalPages = 0;
    });

    builder.addCase(getListOfSidebar.fulfilled, (state, action) => {
      state.sidebar = action.payload.data;
    });

    builder.addCase(getListOfSidebar.rejected, (state) => {
      state.sidebar = [];
    });
  },
});

export const {
  setPage,
  setOrder,
  setKeyword,
  setAuthPage,
  setAuthSort,
  setAuthKeyword,
} = authBaseSlice.actions;

export default authBaseSlice.reducer;

export const { selectAll: selectAuthAll } = baseAuthorityAdapter.getSelectors(
  (state: AppState) => state.authBaseReducer.auth
);
export const selectIsAuthLoading = (state: AppState) =>
  state.authBaseReducer.auth.loading === Loading.pending;
export const selectAuthPage = (state: AppState) =>
  state.authBaseReducer.auth.page;
export const selectAuthTotalPages = (state: AppState) =>
  state.authBaseReducer.auth.totalPages;
export const selectAuthSort = (state: AppState) =>
  state.authBaseReducer.auth.sort;
export const selectAuthKeyword = (state: AppState) =>
  state.authBaseReducer.auth.keyword;

export const { selectAll } = authBaseAdapter.getSelectors(
  (state: AppState) => state.authBaseReducer.member
);

export const selectIsLoading = (state: AppState) =>
  state.authBaseReducer.member.loading === Loading.pending;

export const selectPage = (state: AppState) =>
  state.authBaseReducer.member.page;

export const selectKeyword = (state: AppState) =>
  state.authBaseReducer.member.keyword;

export const selectTotalPages = (state: AppState) =>
  state.authBaseReducer.member.totalPages;

export const selectOrder = (state: AppState) =>
  state.authBaseReducer.member.order;

export const selectHasMore = createSelector(
  selectPage,
  selectTotalPages,
  (page, totalPages) => page < totalPages
);
