import React, { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import clsx from 'clsx';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';

import { Scrollbar } from 'src/components/Scrollbar';
import { SIDEBAR_WIDTH } from 'src/shared/constants';
import { LayoutContext } from '../Provider';
import { Menu, MenuItem } from '../Menu';
import { User } from 'src/shared/interfaces';
import { useAppSelector } from 'src/hooks';
import { message } from 'antd';

const PREFIX = 'MainLayout-SideBar';

const classNames = {
  root: `${PREFIX}__root`,
  paper: `${PREFIX}__paper`,
  wrapper: `${PREFIX}__wrapper`,
  toolbar: `${PREFIX}__toolbar`,
  collapse: `${PREFIX}__collapse`,
  box: `${PREFIX}__box`,
  menus: `${PREFIX}__menus`,
  collapsed: `${PREFIX}__collapsed`,
  extra: `${PREFIX}__extra`,
};

const SideBarRoot = styled('div')(({ theme }) => ({
  [`&.${classNames.root}`]: {
    flex: '0 0 auto',
  },
  [`& .${classNames.paper}`]: {
    boxShadow: 'none',
    backgroundImage: 'none',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flex: '1 0 auto',
    zIndex: 1200,
    position: 'fixed',
    top: 0,
    left: 'auto',
    outline: 0,
    backgroundColor: '#fff',
    color: '#333',
    paddingLeft: 999,
    marginLeft: -999,
  },
  [`& .${classNames.wrapper}`]: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 auto',
    height: '100%',
    transition: theme.transitions.create(['width'], {
      duration: theme.transitions.duration.shortest,
    }),
    [theme.breakpoints.up('xs')]: {
      width: SIDEBAR_WIDTH['xs'],
    },
    [theme.breakpoints.up('md')]: {
      width: SIDEBAR_WIDTH['md'],
    },
    [theme.breakpoints.up('lg')]: {
      width: SIDEBAR_WIDTH['lg'],
    },
  },
  [`&.${classNames.collapsed} .${classNames.wrapper}`]: {
    width: 56,
  },
  [`& .${classNames.toolbar}`]: {
    height: 64,
    flexShrink: 0,
  },
  [`& .${classNames.collapse}`]: {
    height: 45,
    lineHeight: '45px',
    textAlign: 'center',
    flexShrink: 0,
    cursor: 'pointer',
  },
  [`& .${classNames.box}`]: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 auto',
    height: 'calc(100% - 64px - 48px)',
  },
  [`& .${classNames.menus}`]: {
    marginTop: 60,
  },
  [`& .${classNames.extra}`]: {
    marginTop: 100,
  },
}));

export interface SideBarProps {
  className?: string | undefined;
}

const SideBar: React.FC<SideBarProps> = ({ className }) => {
  const {
    menus = [],
    collapsed,
    toggle,
    extra: Extra = React.Fragment,
  } = useContext(LayoutContext);
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth.user);

  const handleClick = useCallback(
    (item: { path: string; guard?: (arg?: User | null) => boolean }) => {
      return () => {
        if (!item.guard || item.guard(user)) {
          navigate(item.path);
        } else {
          message.warning('没有管理员权限');
        }
      };
    },
    [navigate, user]
  );

  if (menus.length === 0) return null;

  return (
    <SideBarRoot
      className={clsx(classNames.root, className, { [classNames.collapsed]: collapsed })}
    >
      <div className={classNames.paper}>
        <div className={classNames.wrapper}>
          <div className={classNames.toolbar} />
          <div className={classNames.box}>
            <Scrollbar style={{ height: '100%' }}>
              <Menu collapsed={collapsed} className={classNames.menus}>
                {menus.map((item) => {
                  return (
                    <MenuItem
                      key={item.path}
                      to={item.path}
                      icon={item.icon}
                      onClick={handleClick(item)}
                    >
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Menu>
              {!collapsed && <Menu className={classNames.extra}>{Extra}</Menu>}
            </Scrollbar>
          </div>
          <div className={classNames.collapse} onClick={() => toggle(!collapsed)}>
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </div>
        </div>
      </div>
    </SideBarRoot>
  );
};

export default React.memo(SideBar);
