import React from 'react';
import { Global, Interpolation, Theme, useTheme } from '@emotion/react';

import { detectScrollbarWidth } from 'src/utils';

import 'antd/lib/style/themes/default.less';
import 'antd/dist/antd.less';

import '../../themes/custom-antd-theme.less';

const scrollbarWidth = detectScrollbarWidth();

export interface CreateGlobalStyles {
  (theme: Theme): Interpolation<Theme>;
}

const createGlobalStyles: CreateGlobalStyles = (theme: Theme) => ({
  // body: {
  //   margin: 0,
  //   WebkitFontSmoothing: 'antialiased',
  //   MozOsxFontSmoothing: 'grayscale',
  //   overflow: 'auto',
  // },

  'header.kwb-fixed': {
    paddingRight: `calc(${scrollbarWidth}px - 100vw + 100%)`,
  },

  // 'html, body, #root': {
  //   position: 'relative',
  //   backgroundColor: '#f8f9fc',
  //   height: '100%',
  //   fontFamily: theme.typography.fontFamily,
  //   fontSize: theme.typography.htmlFontSize,
  // },
  html: {
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    width: '100%',
    height: 'auto',
  },

  body: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    minHeight: '100%',
    width: '100%',
    height: 'auto',
    fontSize: theme.typography.htmlFontSize,
    backgroundColor: '#f8f9fc',
    paddingRight: `calc(${scrollbarWidth}px - 100vw + 100%)`,
  },

  '#root': {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },

  '.ant-message': {
    zIndex: 1300,
  },

  '.ant-dropdown': {
    zIndex: 1300,
  },

  '.infinite-scroll-component__outerdiv > .infinite-scroll-component': {
    overflow: 'visible !important',
  },

  '.ant-select-dropdown': {
    zIndex: 1300,
  },
  '.ant-tooltip': {
    zIndex: 1300,
  },
  '.ant-modal-mask': {
    zIndex: 1300,
  },
  '.ant-modal-wrap': {
    zIndex: 1301,
  },
  '.ant-tabs-dropdown': {
    zIndex: 1300,
  },
  'div#water-mark-wrapper': {},
});

const GlobalStyles: React.FC = ({ children }) => {
  const theme = useTheme();

  const globalStyles = createGlobalStyles(theme);
  return (
    <React.Fragment>
      <Global styles={globalStyles} />
      {children}
    </React.Fragment>
  );
};

export default GlobalStyles;
