import { Status as ReportStatus } from 'src/pages/Repository/Admin/Report/constants';
import { ReportEntity } from 'src/pages/Repository/Admin/Report/types';
import { ID, ListHttpResponse } from 'src/shared/interfaces';
import httpClient from '../../utils/httpClient';

class ReportService {
  /**
   * 某知识库下举报管理列表
   * @returns
   */
  async fetchListByBase(
    baseId: ID,
    status: ReportStatus = 0,
    pagination: { page?: number; limit?: number } = {},
    filter: { keyword?: string }
  ) {
    const { page = 1, limit = 20 } = pagination;
    const { keyword } = filter;
    const response = await httpClient.get<ListHttpResponse<ReportEntity>>('/authTip', {
      params: { baseId, status, page, limit, keyword },
    });
    return response.data;
  }

  /**
   * 举报处理
   * @param id
   * @param status 1: 举报属实 2: 举报不属实
   * @returns
   */
  async handle(id: ID, status: 1 | 2) {
    const response = await httpClient.post(`/tip/${id}/handle`, { status });
    return response.data;
  }
}

const reportService = new ReportService();

export default reportService;
