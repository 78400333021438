import { httpClient } from 'src/utils';

import {
  ListHttpResponse,
  HttpResponse,
  Top,
  Repository,
  ID,
  PaginationParams,
  Recent,
  BookOrFolder,
  FolderDirectory,
  DirectoryBreak,
  Activity,
  ListHttpDataResponse,
} from 'src/shared/interfaces';

export type Order = 't_asc' | 't_desc' | 'p_asc' | 'p_desc';

export type MemberListParams = {
  order: Order | undefined;
  keyword: string | null;
} & PaginationParams;

export type RecentListParams = {
  baseId: ID;
  keyword: string | null;
} & PaginationParams;

type AddMemberData = { members: ID[]; departments: ID[] };

type MoveOptions = {
  keep?: boolean;
  folder: ID | undefined | null;
  type: 'file' | 'folder';
};

class RepositoryService {
  /**
   * 知识库 - 成员 列表
   * @param param0
   * @returns
   */
  async getListOfMember({ order, limit = 16, page = 1, keyword }: Partial<MemberListParams>) {
    const response = await httpClient.get<ListHttpResponse<Repository>>('/base', {
      params: { order, limit, page, keyword },
    });
    return response.data;
  }

  /**
   * 知识库 - 成员 删除
   * @param id
   * @returns
   */
  async removeItemOfMember(id: ID) {
    const response = await httpClient.delete(`/base/${id}`);
    return response.data;
  }

  /**
   * 置顶
   * @param id
   */
  async markTop(id: ID) {
    const response = await httpClient.get<HttpResponse<Top>>(`/actionTop/${id}`);
    return response.data;
  }

  /**
   * 最近
   * @param params
   * @returns
   */
  async getListOfRecent(params: Partial<RecentListParams>) {
    const response = await httpClient.post<ListHttpResponse<Recent> | HttpResponse<Recent>>(
      '/browse',
      { ...params }
    );
    return response.data;
  }

  /**
   * 知识库详情
   * @param id
   */
  async details(id: ID) {
    const response = await httpClient.get<HttpResponse<Partial<Repository>>>(`/base/${id}`);

    return response.data;
  }

  /**
   * 知识库动态
   * @param id
   */
  async fetchActivity(id: ID) {
    const response = await httpClient.get<ListHttpDataResponse<Activity>>(`/baseHistory`, {
      params: {
        baseId: id,
      },
    });
    return response.data;
  }

  /**
   * 知识库更新
   * @param id
   * @param data
   */
  async update(id: ID, data: Partial<Repository> & { _method?: 'PATCH' | 'POST' }) {
    const { _method = 'POST', ...rest } = data;
    let response;

    if (_method === 'POST') {
      response = await httpClient.post<HttpResponse<Partial<Repository>>>(`/base/${id}`, rest);
    }

    if (_method === 'PATCH') {
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
      });
      response = await httpClient.post<HttpResponse<Partial<Repository>>>(`/base/${id}`, formData, {
        headers: { 'content-type': 'multiple/form-data' },
      });
    }

    return response?.data;
  }

  /**
   * 知识本/文件夹详情
   * @param id
   */
  async fetchBookOrFolderDetail(id: ID) {
    const response = await httpClient.get<HttpResponse<BookOrFolder>>(`/baseFolder/${id}`);

    return response.data;
  }

  // 下载文件浏览记录
  async fileDownload(id: ID) {
    const response = await httpClient.get<HttpResponse<{}>>(`/file/download/${id}`);
    return response.data;
  }

  /**
   * 知识本/文件夹
   * @param params
   * @returns
   */
  async fetchBookOrFolder(
    params: Partial<
      {
        type?: 1 | 2 | undefined;
        baseId: ID;
        keyword?: string;
      } & PaginationParams
    >
  ) {
    const response = await httpClient.get<ListHttpResponse<BookOrFolder>>('/baseFolder', {
      params,
    });
    return response.data;
  }

  /**
   * 新建知识本/文件夹
   * @param data
   */
  async createBookOrFolder(data: Partial<BookOrFolder>) {
    const response = await httpClient.post<HttpResponse<BookOrFolder>>('/baseFolder', data);

    return response.data;
  }

  /**
   * 更新知识本/文件夹
   * @param data
   */
  async updateBookOrFolder(id: ID, data: Partial<BookOrFolder>) {
    const response = await httpClient.patch<HttpResponse<BookOrFolder>>(`/baseFolder/${id}`, data);
    return response.data;
  }

  /**
   * 删除知识本/文件夹
   * @param id
   * @returns
   */
  async removeBookOrFolder(id: ID) {
    const response = await httpClient.delete<HttpResponse<{}>>(`/baseFolder/${id}`);
    return response.data;
  }

  /**
   * 子文件夹列表
   * @param params
   * @returns
   */
  async fetchFolder(
    params: Partial<
      {
        folderId: ID;
        directoryId?: ID;
        keyword?: string;
      } & PaginationParams
    >
  ) {
    const response = await httpClient.get<ListHttpResponse<FolderDirectory>>('/directory', {
      params,
    });
    return response.data;
  }

  /**
   * 新建子文件夹
   * @param data
   */
  async createFolder(data: { baseFolderId: ID; name: string; resourceDirectoryId?: ID }) {
    const response = await httpClient.post<HttpResponse<FolderDirectory>>('/directory', data);

    return response.data;
  }

  /**
   * 子文件夹 删除
   * @param id
   * @returns
   */
  async removeDirectory(id: ID) {
    const response = await httpClient.delete(`/directory/${id}`);
    return response.data;
  }

  // 删除文件
  async removeFile(id: ID) {
    const response = await httpClient.delete(`/file/${id}`);
    return response.data;
  }

  /**
   * 批量删除文件
   * @param ids
   */
  async patchRemoveFiles(ids: ID[]) {
    const response = await httpClient.post<HttpResponse<{}>>(`/file/mutiDelete`, {
      ids: ids.join(','),
    });
    return response.data;
  }

  /**
   * 子文件夹面包屑
   * @param id
   * @returns
   */
  async fetchDirectoryBread(id: ID) {
    const response = await httpClient.get<ListHttpDataResponse<DirectoryBreak>>(
      `/directory/breadcrumb/${id}`
    );
    return response.data;
  }

  /**
   * 新建子文件夹
   * @param data
   * @returns
   */
  async createDitectory(data: { baseFolderId: ID; name: string; resourceDirectoryId?: ID }) {
    const response = await httpClient.post<HttpResponse<FolderDirectory>>(`/directory`, data);
    return response.data;
  }

  /**
   * 更新
   * @param data
   * @returns
   */
  async updateDitectory(data: { id: ID; name: string }) {
    const response = await httpClient.patch<HttpResponse<FolderDirectory>>(
      `/directory/${data.id}`,
      data
    );
    return response.data;
  }

  async moveFileOrFolderTo(resource: ID | null, to: ID | undefined, options: MoveOptions) {
    const { keep = true, folder, type } = options;
    const baseUrl = {
      file: '/file/move',
      folder: '/directory/move',
    };
    const data = {
      resourceDirectoryId: to || 0,
      type: keep === true ? 1 : 0,
      baseFolderId: folder,
    };

    const response = await httpClient.post<HttpResponse<any>>(`${baseUrl[type]}/${resource}`, data);

    return response.data;
  }

  /**
   * 文件重命名
   * @param name
   * @param id
   * @returns
   */
  async renameFile(name: string, id: ID) {
    const response = await httpClient.patch<HttpResponse<{ name: string }>>(`/file/${id}`, {
      name,
    });

    return response.data;
  }

  /**
   *  添加成员
   * @param id 知识库 id
   * @param data
   */
  async addMembers(id: ID, { members, departments }: AddMemberData) {
    const response = await httpClient.post<HttpResponse<{}>>('/base/add', {
      baseId: id,
      usersId: members.join(','),
      departmentsId: departments.join(','),
    });
    return response.data;
  }

  /**
   * 知识本/文件夹复制到
   * @param id
   * @param baseFolderId
   * @returns
   */
  async copyPageOrFolderTo(to: ID, baseFolderId: ID) {
    const response = await httpClient.post<HttpResponse<{}>>('/baseFolderCopy', {
      baseId: to,
      baseFolderId,
    });

    return response.data;
  }
}

export default new RepositoryService();
