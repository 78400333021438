import React from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';

import { composeClasses } from 'src/utils';

import { Modal, ModalProps } from '../unstable_Modal';
import { Grow, BaseTransitionProps } from '../Transitions';

const classNamePrefix = 'Dialog';

const useUtilityClasses = () => {
  const slots = {
    root: ['root'],
    container: ['container'],
    paper: ['paper'],
  };
  return composeClasses(classNamePrefix, slots);
};

const DialogRoot = styled(Modal, { label: `${classNamePrefix}Root` })({});

const DialogContainer = styled('div', { label: `${classNamePrefix}Container` })(
  {
    height: '100%',
    outline: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
);

const DialogPaper = styled('div', { label: `${classNamePrefix}Paper` })({
  margin: 32,
  position: 'relative',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  maxHeight: 'calc(100% - 64px)',
  backgroundColor: '#fff',
  borderRadius: 10,
  border: '1px solid #eee',
  minWidth: 400,
  boxShadow: '0 0 30px 0 rgba(0, 0, 0, 0.1)',
});

export interface DialogProps {
  className?: string | undefined;
  open: boolean;
  children?: React.ReactNode;
  TransitionComponent?: React.ElementType;
  TransitionProps?: BaseTransitionProps;
  onBackdropClick?: ModalProps['onBackdropClick'];
  onClose?: ModalProps['onClose'];
  container?: ModalProps['container'];
  disablePortal?: ModalProps['disablePortal'];
}

const Dialog = React.forwardRef<HTMLDivElement, DialogProps>(function Dialog(
  props,
  ref
) {
  const classes = useUtilityClasses();

  const {
    className,
    open,
    children,
    onClose,
    TransitionComponent = Grow,
    TransitionProps,
    onBackdropClick,
  } = props;

  const backdropClick = React.useRef<boolean>();

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    backdropClick.current = event.target === event.currentTarget;
  };

  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!backdropClick.current) {
      return;
    }

    if (onBackdropClick) {
      onBackdropClick(event);
    }

    if (onClose) {
      onClose(event, 'backdropClick');
    }
  };

  return (
    <DialogRoot
      open={open}
      onClose={onClose}
      ref={ref}
      className={clsx(classes.root, className)}
      BackdropProps={{ invisible: false }}
      onClick={handleBackdropClick}
    >
      <TransitionComponent
        in={open}
        role="presentation"
        style={{ height: '100%' }}
        {...TransitionProps}
      >
        <DialogContainer
          className={clsx(classes.container)}
          onClick={handleMouseDown}
        >
          <DialogPaper className={clsx(classes.paper)} role="dialog">
            {children}
          </DialogPaper>
        </DialogContainer>
      </TransitionComponent>
    </DialogRoot>
  );
});

export default Dialog;
